import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HintsService } from 'src/app/shared/services/hints.service';
import { HintDataModel } from './models/hint-data.model';

@Component({
	selector: 'app-hints',
	templateUrl: './hints.component.html',
	styleUrls: ['./hints.component.scss']
})
export class HintsComponent implements OnInit {
	public hintsVisible = false;
	public hintsData: HintDataModel[] = [];

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private hintsService: HintsService) {}

	ngOnInit(): void {
		this.initVisibilityListeners();
	}

	public initVisibilityListeners(): void {
		this.hintsService.showSubject$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			this.showToast(res);
		});

		this.hintsService.dismissSubject$.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.dismiss();
		});
	}

	private showToast(data: HintDataModel[]): void {
		this.setPositionFromElements(data);
		this.hintsData = data;
		this.hintsVisible = true;
	}

	public setPositionFromElements(data: HintDataModel[]): void {}

	public dismiss(): void {
		this.hintsVisible = false;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
