import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-custom-password-field',
	templateUrl: './custom-password-field.component.html',
	styleUrls: ['./custom-password-field.component.scss']
})
export class CustomPasswordFieldComponent implements OnInit {
	@Input() control: UntypedFormControl | AbstractControl;
	@Input() placeholder: String;
	public show: boolean = false;

	constructor() {}

	ngOnInit(): void {}

	public toggleHide(): void {
		this.show = !this.show;
	}
}
