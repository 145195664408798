<div class="page-container">
	<div class="form-area">
		<div class="page-header">
			<div class="back-btn">
				<a (click)="backClicked()">
					<i class="k-icon k-i-arrow-chevron-left"></i>
					Back
				</a>
			</div>
		</div>
		<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<div class="title">Sad to see you go.</div>
					<div class="sub-title">We are sad to see you pausing your account. We really value you as our customer.</div>
					<div class="sub-title2">Your monthly plan with us is paid until {{ getDate }}. To proceed, please select “continue” below.</div>
					<div class="box">
						<span class="icon k-icon k-i-warning"></span>
						<span class="text">
							Are you sure? After the {{ getEndDate }} you will not have the ability to optimise, build or analysis your campaigns.
						</span>
					</div>
				</div>
				<div class="form-btn">
					<button class="button cancel" (click)="onCancel()">
						CANCEL
					</button>
					<button class="button left" (click)="onNext()">
						CONTINUE
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
