import { UserDetails } from '../../../_models/identity-models/identity-detail';
import { UserActions, UserTypes } from './user.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { Modules } from '../../permisions/enums/modules';
import { MiscellaneousPermissions } from '../../permisions/enums/miscellaneous-permissions';
import { StorageKey } from 'src/app/_models/local-storage-key';

export interface UserState {
	userDetails: UserDetails;
	isSubUser: boolean;
}

export const initialUserState: UserState = {
	userDetails: null,
	isSubUser: false
};

const getUserState = createFeatureSelector<UserState>('user');

export const getUserDetails = createSelector(getUserState, state => state.userDetails);
export const isSubUserSignedIn = createSelector(getUserState, state => state.isSubUser);
export const getFiledId = createSelector(getUserState, state => state.userDetails?.FiledId);
export const getFacebookBusinessOwnerId = createSelector(getUserState, state => state.userDetails?.FacebookBusinessOwnerId);
export const getGoogleBusinessOwnerId = createSelector(getUserState, state => state.userDetails?.GoogleBusinessOwnerId);
export const getAccountState = createSelector(getUserState, state => state.userDetails?.AccountState);
export const getPermissions = createSelector(getUserState, state => state.userDetails?.Permissions);
export const isUserImpersonated = createSelector(getUserState, state => state.userDetails?.IsImpersonated);
export const hasBackOfficePermission = createSelector(getPermissions, state => {
	let isAdmin = false;
	if (state) {
		state
			.filter(module => module.module === Modules.Miscellaneous)
			.some(module => {
				module.permissions.forEach(permission => {
					if (
						permission === MiscellaneousPermissions.IsAdmin ||
						permission === MiscellaneousPermissions.IsAccountManagerAdmin ||
						permission === MiscellaneousPermissions.IsSales ||
						permission === MiscellaneousPermissions.IsSalesAdmin ||
						permission === MiscellaneousPermissions.IsAccountManager
					) {
						isAdmin = true;
					}
				});
			});
	}
	return isAdmin;
});
export const hasClientPermission = createSelector(getPermissions, state => {
	let isClientEmployee = false;
	if (state) {
		state
			.filter(module => module.module === Modules.Miscellaneous)
			.some(module => {
				module.permissions.forEach(permission => {
					if (permission === MiscellaneousPermissions.IsClientEmployee) {
						isClientEmployee = true;
					}
				});
			});
	}
	return isClientEmployee;
});
export const isUserFacebookOwner = createSelector(getUserDetails, user => !!user?.FacebookBusinessOwnerId);

// UH2020-2366 disable google from platform
export const isUserGoogleOwner = createSelector(getUserDetails, user => {
	return !!user?.GoogleBusinessOwnerId;
});

// export const getBusinessOwnerIdByChannel = createSelector(getUserState, (state: UserState, properties: { channel: SourceChannel }) =>
// 	ReducerFunctions.getBusinessOwnerIdByChannel(state, properties)
// );

export const getPermissionByModule = (moduleId: Modules) =>
	createSelector(getUserState, state =>
		state.userDetails.Permissions.find(permission => {
			return permission.module === moduleId;
		})
	);

export function userReducer(state = initialUserState, action: UserActions): UserState {
	switch (action.type) {
		case UserTypes.SetUserDetails: {
			return {
				...state,
				userDetails: action.payload
			};
		}
		case UserTypes.ClearUserDetails: {
			return initialUserState;
		}
		case UserTypes.SetSubUserStatus: {
			return {
				...state,
				isSubUser: action.payload
			};
		}
		default:
			return { ...state };
	}
}
