import { Component, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessOwnerBilling, Country } from '../../../../_services/back-office/back-office.models';
import { Observable, Subject, Subscription } from 'rxjs';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { SetCreditCardService } from '../set-creditcard.service';
import { TranslateService } from '@ngx-translate/core';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { FiledCreditCard } from '../set-creditcard.models';
import { select, Store } from '@ngrx/store';
import {
	getActiveBillingPageUserManagement,
	getBillingAccountInfo,
	getEditingCardUserManagementPageStatus,
	UserManagementState
} from '../../../state/user-management.reducer';
import * as UserManagementActions from '../../../state/user-management.actions';
import { ValidatorMessages } from '../../../../shared/form-input/validatorMessagesInputs';
import {
	CardAdressValidator,
	CardCityValidator,
	CardCountryValidator,
	CardCvcValidator,
	CardHolderNameValidator,
	CardNumberValidator,
	CardZipValidator
} from '../../../models/validators';
import { takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner } from '../../../../shared/state/shared.actions';
import { BillingAddress } from '../../../shared/billing-and-payment/billing-and-payment.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownData } from '../../../../shared/dropdown-search-select/dropdown-data.interface';
import { SidePopUpHelper } from 'src/app/shared/side-pop-ups/side-pop-ups.helper';

@Component({
	selector: 'app-card-input',
	templateUrl: './card-input.component.html',
	styleUrls: ['./card-input.component.scss']
})
export class CardInputComponent implements OnInit, OnDestroy {
	@Input() public billingAddressDetails: BillingAddress;

	public readonly cardHolderPlaceholder: string = 'Ms Sara';
	public readonly cardNumberPlaceholder: string = '4275 8392 0497 3938';
	public readonly expirationDatePlaceholder: string = '02/21';
	public readonly cvcPlaceholder: string = '344';
	public readonly cardHolderMinChars: number = 5;
	public readonly cvcMinLength: number = 3;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public isDefault = true;
	public billingActive = false;
	public billingShown = false;
	public disabledForm = false;
	public accountForm: UntypedFormGroup;
	public billings: BusinessOwnerBilling[] = [];
	public cardId: void | boolean;
	public countries: Country[];
	public currentCountry: string;
	public isFistPayment: boolean;
	public dropdownCountries: DropdownData[];
	public firstPartInvalid: boolean;
	public stripeError: string;

	public touched: boolean;
	public valid: boolean;
	public errors: boolean;
	public next: boolean;
	public reset: boolean;
	public submit: boolean;
	public check: boolean;

	public cardNumberValidatorMessages: ValidatorMessages[] = CardNumberValidator;
	public cardNameValidatorMessages: ValidatorMessages[] = CardHolderNameValidator;
	public cardCvcValidatorMessages: ValidatorMessages[] = CardCvcValidator;
	public cardAddressValidatorMessages: ValidatorMessages[] = CardAdressValidator;
	public cardCityValidatorMessages: ValidatorMessages[] = CardCityValidator;
	public cardZipValidatorMessages: ValidatorMessages[] = CardZipValidator;
	public cardCountryMessages: ValidatorMessages[] = CardCountryValidator;

	private readonly lettersAndSpacePattern: RegExp = /^[a-zA-Z\s]*$/;
	private submitSubscription: Subscription = new Subscription();
	private unsubscriber$ = new Subject<void>();
	private card: FiledCreditCard;
	private pageIndex: number;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private setCreditCardService: SetCreditCardService,
		private translate: TranslateService,
		private backOfficeService: BackOfficeService,
		private toastNotificationService: ToastNotificationService,
		private store: Store<UserManagementState>,
		private dialogRef: MatDialogRef<CardInputComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	public ngOnInit(): void {
		this.isFistPayment = this.data.firstCard;
		this.fetchStoreBillingInfo();
		this.getCountries();
		this.createForm();
		this.fetchStore();
		this.fetchStatus();
		this.setFormToStore();
	}

	public ngOnDestroy(): void {
		this.store.dispatch(new UserManagementActions.SetSelectedCardId(null));

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public cancel(): void {
		SidePopUpHelper.closeSIdePopUpDialog(() => this.dialogRef.close(false));
	}

	public changeDefault(): void {
		this.isDefault = this.isDefault ? false : true;
	}

	private getCountries = (): void => {
		this.backOfficeService
			.getAllCountries()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				result => {
					this.countries = result;
					this.currentCountry = this.countries.filter(country => country.name === this.billingAddressDetails.country)[0]?.value;

					let i = 0;
					this.dropdownCountries = this.countries.map(country => {
						return {
							data: i++,
							displayName: country.name,
							value: country.value
						};
					});
				},
				() => {
					SidePopUpHelper.closeSIdePopUpDialog(() => this.dialogRef.close(false));
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	};

	public setFormToStore(): void {
		this.accountForm.statusChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			if (!this.touched) {
				this.touched = true;
				this.store.dispatch(new UserManagementActions.SetTouchedStatusForEditPage(this.touched));
			}
		});
	}

	public setInvalidStatus(): void {
		if (!this.errors) {
			this.store.dispatch(new UserManagementActions.SetErrorsStatusForEditPage(true));
		}
		if (this.valid) {
			this.store.dispatch(new UserManagementActions.SetValidStatusForEditPage(false));
		}
	}

	public setValidStatus(): void {
		if (!this.valid) {
			this.store.dispatch(new UserManagementActions.SetValidStatusForEditPage(true));
		}
		if (this.errors) {
			this.store.dispatch(new UserManagementActions.SetErrorsStatusForEditPage(false));
		}
	}

	public addNewCard(): void {
		this.submitSubscription = Observable.fromPromise(this.validateFinalForm())
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => this.store.dispatch(new HideGlobalSpinner()),
				() => {
					this.store.dispatch(new HideGlobalSpinner());
					this.translate.instant('Error while trying to save your card changes, please contact support team');
				}
			);
	}

	private fetchStore(): void {
		this.store.pipe(select(getActiveBillingPageUserManagement), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.pageIndex = resp;
		});

		this.fetchStatus();
	}

	private fetchStatus(): void {
		this.store.pipe(select(getEditingCardUserManagementPageStatus), takeUntil(this.unsubscriber$)).subscribe(status => {
			this.errors = status.errors;
			this.submit = status.submit;
			this.valid = status.valided;
			this.next = status.next;
			this.reset = status.reset;
			this.touched = status.touched;
			this.check = status.check;

			if (this.check) {
				this.checkForm();
			}

			if (this.submit) {
				this.submitForm();
			}
		});
	}

	private validateAllFormFields(): void {
		this.accountForm.markAllAsTouched();
	}

	public nextStep(): void {
		this.accountForm.get('cardHolder').markAsTouched();
		this.accountForm.get('number').markAsTouched();
		this.accountForm.get('expirationDate').markAsTouched();
		this.accountForm.get('cvc').markAsTouched();

		if (
			!this.accountForm.get('cardHolder').valid ||
			!this.accountForm.get('number').valid ||
			!this.accountForm.get('expirationDate').valid ||
			!this.accountForm.get('cvc').valid
		) {
			this.firstPartInvalid = true;
		} else {
			this.firstPartInvalid = false;
			this.billingActive = true;
			setTimeout(() => {
				this.billingShown = true;
			}, 50);
		}
	}

	public previousStep(): void {
		this.billingActive = false;
		this.billingShown = false;
	}

	private async validateFinalForm(): Promise<void> {
		this.disabledForm = true;
		const stripeResponse: { status: number; response: any } = await this.setCreditCardService.validateStripeCard(
			this.accountForm.controls,
			this.isFistPayment
		);
		if (stripeResponse.status !== 200) {
			const stripeErrors = stripeResponse.response.error;
			if (stripeErrors && stripeErrors.message) {
				this.stripeError = stripeErrors.message;
				this.toastNotificationService.sendErrorToast(stripeErrors.message);
			} else {
				this.toastNotificationService.sendErrorToast('Error while trying to save your card changes, please contact support team');
				this.stripeError = 'Error while trying to save your card changes, please contact support team';
			}
			this.store.dispatch(new HideGlobalSpinner());
			this.disabledForm = false;
			return null;
		}

		const addedCard = this.getFiledCard(stripeResponse.response);
		this.disabledForm = false;
		SidePopUpHelper.closeSIdePopUpDialog(() => this.dialogRef.close({ addedCard: addedCard, isDefault: this.isDefault }));
	}

	private checkForm(): void {
		this.store.dispatch(new UserManagementActions.SetCheckStatusForEditPage(false));
		this.accountForm.updateValueAndValidity();
		this.accountForm.markAllAsTouched();
		if (this.accountForm.status === 'INVALID') {
			this.setInvalidStatus();
			this.toastNotificationService.sendErrorToast('Invalid credit card');
			Object.keys(this.accountForm.controls).forEach(control => {
				if (this.accountForm.controls[control].status === 'INVALID') {
					this.accountForm.controls[control].setErrors({ incorect: true });
				}
			});
		}
		if (this.accountForm.status === 'VALID') {
			this.setValidStatus();
		}
	}

	public submitForm(): void {
		this.store.dispatch(new UserManagementActions.SetSubmitStatusForEditPage(false));
		this.validateAllFormFields();

		if (this.accountForm.status === 'INVALID') {
			this.setValidStatus();
			return;
		}
		this.addNewCard();
	}

	private fetchStoreBillingInfo(): void {
		this.store.pipe(select(getBillingAccountInfo), takeUntil(this.unsubscriber$)).subscribe(billingAddress => {
			this.billingAddressDetails = billingAddress;
		});
	}

	private createForm(): void {
		this.accountForm = this.formBuilder.group({
			cardHolder: new UntypedFormControl(null, [
				Validators.required,
				Validators.pattern(this.lettersAndSpacePattern),
				Validators.minLength(this.cardHolderMinChars),
				Validators.maxLength(21)
			]),
			number: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(19), Validators.maxLength(19)]),
			expirationDate: new UntypedFormControl(null, [Validators.required]),
			cvc: new UntypedFormControl(null, [Validators.required, Validators.minLength(this.cvcMinLength)]),
			address1: new UntypedFormControl(!this.isFistPayment ? { value: this.billingAddressDetails.billingAddress } : null, [Validators.required]),
			address2: new UntypedFormControl(!this.isFistPayment ? { value: '' } : null),
			city: new UntypedFormControl(!this.isFistPayment ? { value: this.billingAddressDetails.city } : null, [Validators.required]),
			zip: new UntypedFormControl(!this.isFistPayment ? { value: this.billingAddressDetails.zipCode } : null, [Validators.required]),
			country: new UntypedFormControl(!this.isFistPayment ? { value: this.currentCountry } : null, [Validators.required])
		});
	}

	private getFiledCard(token: any): FiledCreditCard {
		return {
			cardId: token.id,
			brand: token.card.brand,
			funding: 'Credit',
			last4Digits: token.card.last4,
			expirationMonth: token.card.exp_month,
			expirationYear: token.card.exp_year,
			city: token.card.address_city,
			country: token.card.address_country,
			address1: token.card.address_line1,
			zip: token.card.address_zip,
			holderName: token.card.name,
			isDefault: this.isDefault,
			address2: token.card.address_line2,
			state: null
		};
	}
}
