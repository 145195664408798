<input
	(keyup)="keyInputEvent.emit($event)"
	class="input-text {{ styleClass }}"
	[maxlength]="maxLength"
	[id]="inputId"
	[type]="inputType"
	enterkeyhint="done"
	[formControl]="control"
	[placeholder]="inputPlaceholder"
/>
<div class="error-container" *ngIf="errorMessage && control.invalid && control.touched">
	<span>{{ errorMessage }}</span>
</div>
