import { Observable, of } from 'rxjs';
import { EnumerationEx } from '../../_models/enumeration';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import {
	CardDefaultInterface,
	CardDetailsInterface,
	EditCardInterface,
	NewCardDetailsInterface,
	UpdateSubscriptionInterface
} from 'src/app/authentication/sign-up/subscribe.interface';
import { paymentResponse } from 'src/app/authentication/_models/paymentResponse.interface';
import { PlanDetails, PlanVariants } from 'src/app/shared/models/plan-details.model';
import { AnyAaaaRecord } from 'dns';

@Injectable({
	providedIn: 'root'
})
export class PaymentServiceApi {
	private readonly baseURL = `${BaseApiUrl.Payment}`;

	constructor(protected http: HttpClient) {}

	public getUserTarrifPlan(): Observable<{ [key: string]: EnumerationEx[] }> {
		return of({});
		// return this.http.get<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}billings/my/billing-plan`);
	}

	public checkPaymentStatus(billingId: number): Observable<any> {
		return this.http.post<any>(`${this.baseURL}PaymentManager/verifyPayment/bill/${billingId}`, {});
	}

	public getCurrentTariffModules(): Observable<{ [key: string]: EnumerationEx[] }> {
		return this.http.get<{ [key: string]: EnumerationEx[] }>(`${BaseApiUrl.Users}Users/current-offer-modules`);
	}

	public changUsereOffer(details: UpdateSubscriptionInterface): Observable<object> {
		return this.http.post<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}Subscriptions/changetonewoffer`, details);
	}

	public acceptOffers(): Observable<object> {
		return this.http.post<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}Billings/acceptOffer`, {});
	}

	public getFirstInvoice(): Observable<{ [key: string]: EnumerationEx[] }> {
		return this.http.get<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}billings/firstInvoice`);
	}

	public payForInvoice(billingId: number): Observable<object> {
		return this.http.put(`${this.baseURL}Billings/my/invoices/pay-bs-invoice/${billingId}`, {});
	}

	public getMyCardDetails(details: CardDefaultInterface): Observable<object> {
		return this.http.post<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}PaymentManager/GetCard`, details);
	}

	public postCardDetails(details: CardDetailsInterface): Observable<paymentResponse> {
		return this.http.post<paymentResponse>(`${this.baseURL}PaymentManager/addPaymentWallDetails`, details);
	}
	public postRetryPayment(details: CardDetailsInterface): Observable<paymentResponse> {
		return this.http.put<paymentResponse>(`${this.baseURL}Billings/my/invoices/pay-failed-bs-invoice`, details);
	}

	public getPaymentCards(): Observable<{ [key: string]: EnumerationEx[] }> {
		return this.http.get<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}PaymentManager/GetAllCards`);
	}

	public getbrandPaymentCards(): Observable<{ [key: string]: EnumerationEx[] }> {
		return this.http.get<{ [key: string]: EnumerationEx[] }>(`${BaseApiUrl.SocialInfluencerPython}payments/userAllCards`);
	}
	public getDefaultPaymentCards(): Observable<object> {
		return this.http.get<{ [key: string]: EnumerationEx[] }>(`${BaseApiUrl.SocialInfluencerPython}payments/getUserDefaultCard`);
	}
	public setDefaultPaymentCards(details: CardDefaultInterface): Observable<object> {
		return this.http.post<{ [key: string]: EnumerationEx[] }>(`${BaseApiUrl.SocialInfluencerPython}payments/setUserDefaultCard`, details);
	}

	public addPaymentCards(details: NewCardDetailsInterface): Observable<object> {
		return this.http.post<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}PaymentManager/addCreditCard`, details);
	}

	public addPaymentCardsStripe(details: string): Observable<object> {
		// var formData: any = new FormData();
		// formData.append('card_source', details);
		let newdetails = {
			card_source: details
		};
		return this.http.post<{ [key: string]: EnumerationEx[] }>(`${BaseApiUrl.SocialInfluencerPython}payments/addUserCard`, newdetails);
	}

	public updatePaymentCards(details: EditCardInterface): Observable<object> {
		return this.http.put<{ [key: string]: EnumerationEx[] }>(`${this.baseURL}PaymentManager/updateCreditCard`, details);
	}

	public updateinfluencerPaymentCards(details: AnyAaaaRecord): Observable<object> {
		return this.http.put<{ [key: string]: EnumerationEx[] }>(`${BaseApiUrl.SocialInfluencerPython}payments/editUserCard`, details);
	}

	public deletePaymentCard(cardDetail: any): Observable<object> {
		const url = new URL(`${BaseApiUrl.SocialInfluencerPython}payments/deleteUserCard`);
		url.searchParams.append('card_id', cardDetail.card_id);
		return this.http.delete(url.href);
	}

	public getPfToken(): Observable<string> {
		return this.http.get<string>(`${this.baseURL}PaymentManager/generatePFToken`);
	}
	public getRetryPfToken(bIllingId: string, contactId: string): Observable<string> {
		return this.http.get<string>(`${this.baseURL}PaymentManager/generatePFToken/${bIllingId}/${contactId}`);
	}

	public getRetryBillingDetails(bIllingId: string, contactId: string): Observable<string> {
		return this.http.get<string>(`${this.baseURL}Billings/${bIllingId}/${contactId}`);
	}

	public getRetryCardLists(bIllingId: string, contactId: string): Observable<any> {
		return this.http.get<any>(`${this.baseURL}PaymentManager/getAllCards/${bIllingId}/${contactId}`);
	}

	public fetchAllPlans(): Observable<PlanVariants> {
		return this.http.get<PlanVariants>(`${BaseApiUrl.SocialInfluencerPython}subscriptions/plans`);
	}

	public fetchAllPlansV2(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}subscriptions/plans`);
	}

	// ! MY PLAN APIS

	public updatePlan(planName: string, planPrice: number, token: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/payments?plan=${planName}&price=${planPrice}`, {
			headers: new HttpHeaders({ Authorization: token })
		});
	}
}
