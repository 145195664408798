import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { AdAccountModel, BusinessModel } from 'src/app/accounts/models/business.model';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { getFacebookBusinessOwnerId } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { FacebookAdAccount } from 'src/app/user-management/models/new-user-model';
import { UserAccessLevelEnum } from 'src/app/user-management/models/user-access-level-enum';
import { UsersSelectedTypeEnum } from 'src/app/user-management/models/users-selected-type-enum';
import { SetAdminManagement } from 'src/app/user-management/state/user-management.actions';
import { getUserAdminState, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { AdAccountApiService } from 'src/app/_services/facebook-accounts/ad-account-api.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { FacebookAdAccountPermissions } from '../../../../../../_models/user-models/facebook-ad-account-permissions';

@Component({
	selector: 'app-user-account',
	templateUrl: './user-account.component.html',
	styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {
	@Input() public userDataId: number;
	@Output() public onContinueNextAction: EventEmitter<boolean> = new EventEmitter();
	@Output() public onGoBack: EventEmitter<boolean> = new EventEmitter();

	private unsubscriber$: Subject<void> = new Subject<void>();
	public userAccounts: any[];
	public selectedAccounts: any[] = [];
	public selectedType: string;
	public usersSelectedTypeEnum = UsersSelectedTypeEnum;
	public businessOwnerFacebookId: string;
	//silsrang-migration
	// public BOInfo: BusinessModel[] = [];
	// public businessWithAdAccounts: BusinessModel[] = [];
	// public filteredBOInfo: BusinessModel[] = [];
	// public selectedAdAccounts: any[] = [];
	// public selectedBusiness: BusinessModel[] = [];
	public BOInfo: any[] = [];
	public businessWithAdAccounts: any[] = [];
	public filteredBOInfo: any[] = [];
	public selectedAdAccounts: any[] = [];
	public selectedBusiness: any[] = [];
	public userAccessLevelEnum = UserAccessLevelEnum;
	public allAdAccounts: FacebookAdAccount[];
	public filteredAdAccounts: FacebookAdAccount[];
	public checkBO: boolean;
	public checkAd: boolean;
	public boSearchFormControl: UntypedFormGroup;
	public adSearchFormControl: UntypedFormGroup;
	public accessLevel: boolean;
	public isEdit: boolean = false;
	public userDetail: any;

	public loading: boolean = false;

	constructor(
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private adAccountApiService: AdAccountApiService,
		private store: Store<UserManagementState>,
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService,
		private sharedStore: Store<SharedState>
	) {}

	ngOnInit(): void {
		if (this.userDataId) {
			this.isEdit = true;
		}
		this.getAllAdAccounts();
		this.getClientEmployee();
		this.fetchStore();
		this.getBOBusinesses();
		this.businessManagerSelect();
		this.createForm();
		this.filteredDataBoInfo();
		this.mergeStoreWithData();
	}

	private fetchStore(): void {
		this.store.pipe(select(getFacebookBusinessOwnerId), takeUntil(this.unsubscriber$)).subscribe(businessOwnerFacebookId => {
			this.businessOwnerFacebookId = businessOwnerFacebookId;
		});

		this.store.pipe(select(getUserAdminState), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.userDetail = detail;
			}
		});
	}

	private createForm(): void {
		this.filteredAdAccounts = this.allAdAccounts;
		this.filteredBOInfo = this.BOInfo;
		this.selectedType === this.usersSelectedTypeEnum.BusinessManager;

		this.boSearchFormControl = this.formBuilder.group({
			searchFormBoInfo: new UntypedFormControl(null)
		});
	}
	public accessLevelEvent(accessLevel: UserAccessLevelEnum): void {
		if (accessLevel === UserAccessLevelEnum.AllAdAccounts) {
			this.accessLevel = false;
			this.sendDataBack();
		} else if (UsersSelectedTypeEnum.AdAccounts) {
			this.accessLevel = true;
		}
	}

	public defaultAccessLevel(): void {
		const accessLevel = this.isEdit ? UserAccessLevelEnum.IndividualAdAccounts : UserAccessLevelEnum.AllAdAccounts;
		this.accessLevelEvent(accessLevel);
	}

	public getClientEmployee(): void {
		if (this.userDataId) {
			this.getAllAdAccounts();
			this.userServiceApi
				.getUserById(this.userDataId, false)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					res => {
						res.facebookBusinessPermissions.ids.forEach(clientBusiness => {
							this.BOInfo.forEach(business => {
								if (clientBusiness === business.FacebookBusinessId) {
									business.checked = true;
								}
							});
						});
						res.facebookAdAccountPermissions.ids.forEach(receivedAdAccount => {
							this.BOInfo.forEach(business => {
								business.FacebookAdAccountPermissions.filter(adAccount => adAccount.FacebookAdAccountId === receivedAdAccount).forEach(
									adAccount => {
										adAccount.checked = true;
									}
								);
							});
							this.filteredAdAccounts = this.filteredAdAccounts.map(clientAdAccount => {
								if (receivedAdAccount === clientAdAccount.id) {
									return {
										...clientAdAccount,
										checked: true
									};
								}
								return clientAdAccount;
							});
						});
					},
					() => {
						this.toastNotification.sendErrorToast('An error occurred, please try again later!');
					},
					() => {
						this.checkIfEverythingIsCheckedForAd();
						this.checkIfEverythingIsCheckedForBO();
						this.sendDataBack();
					}
				);
		} else {
			this.filteredBOInfo = this.BOInfo;
		}
	}

	public businessManagerSelect(): void {
		this.selectedType = this.usersSelectedTypeEnum.BusinessManager;
	}

	public adAccountsSelect(): void {
		this.selectedType = this.usersSelectedTypeEnum.AdAccounts;
	}

	public getBOBusinesses(): void {
		this.loading = false;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.adAccountApiService
			.getAllBOBusinesses(this.businessOwnerFacebookId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res =>
					(this.BOInfo = res.businesses
						.filter(business => business.adAccounts.length > 0)
						.map(businesses => {
							return {
								name: businesses.name,
								FacebookBusinessId: +businesses.id,
								FacebookAdAccountPermissions: businesses.adAccounts.map(adAccount => {
									return {
										FacebookAdAccountId: adAccount.id,
										checked: false,
										name: adAccount.name,
										businessId: adAccount.businessId,
										id: adAccount.id
									};
								}),
								checked: false
							};
						})),
				() => this.toastNotification.sendErrorToast('An error occurred, please try again later!'),
				() => {
					this.filteredBOInfo = this.BOInfo;
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
	//silsrang-migration
	// public checkBoxBOChange(event: BusinessModel): void {
	public checkBoxBOChange(event: any): void {
		event.checked = !event.checked;
		this.filteredBOInfo.forEach(business => {
			this.filteredAdAccounts.forEach(adAccounts => {
				if (event.checked) {
					if (adAccounts.businessId === event.FacebookBusinessId) {
						adAccounts.checked = true;
						business.FacebookAdAccountPermissions.forEach(adAccount => {
							if (adAccount.businessId === event.FacebookBusinessId) {
								adAccount.checked = true;
							}
						});
					}
				} else {
					if (adAccounts.businessId === event.FacebookBusinessId) {
						adAccounts.checked = false;
						business.FacebookAdAccountPermissions.forEach(adAccount => {
							if (adAccount.businessId === event.FacebookBusinessId) {
								adAccount.checked = false;
							}
						});
					}
				}
			});
		});

		this.checkIfEverythingIsCheckedForBO();
		this.sendDataBack();
	}
	//silsrang-migration
	// public checkBoxChange(event: AdAccountModel): void {
	public checkBoxChange(event: any): void {
		event.checked = !event.checked;
		this.filteredBOInfo
			.filter(business => business.FacebookBusinessId === event.businessId)
			.forEach(business => {
				if (event.checked) {
					business.checked = true;
				}
				business.FacebookAdAccountPermissions.filter(adAccount => adAccount.FacebookAdAccountId === event.id).forEach(adAccounts => {
					adAccounts.checked = event.checked;
				});
				if (!event.checked && business.FacebookAdAccountPermissions.filter(adAccount => adAccount.checked).length === 0) {
					business.checked = false;
				}
			});

		this.filteredAdAccounts
			.filter(adAccount => adAccount.id === event.id)
			.forEach(adAccount => {
				if (event.checked) {
					adAccount.checked = true;
				} else {
					adAccount.checked = false;
				}
			});
		this.checkIfEverythingIsCheckedForAd();
		this.sendDataBack();
	}

	public sendDataBack(): void {
		this.selectedBusiness = this.filteredBOInfo
			.filter(business => business.checked && business.FacebookAdAccountPermissions.length > 0)
			.map(business => {
				return {
					...business,
					FacebookAdAccountPermissions: this.filteredAdAccounts
						.filter(adAccount => adAccount.businessId?.toString() === business.FacebookBusinessId?.toString())
						.map(adAccounts => {
							if (adAccounts.checked) {
								return {
									FacebookAdAccountId: adAccounts.id,
									name: adAccounts.name,
									checked: adAccounts.checked,
									businessId: adAccounts.businessId ? adAccounts.businessId : '',
									id: adAccounts.id
								};
							}
						})
				};
			});
		if (this.selectedBusiness.length > 0) {
			this.selectedBusiness.forEach(business => {
				business.FacebookAdAccountPermissions.filter(adAccount => adAccount.checked).forEach(account => {
					this.selectedAdAccounts.push(account);
				});
			});
		} else {
			this.selectedAdAccounts = [];
		}
	}

	public checkIfEverythingIsCheckedForBO(): void {
		const filteredBoInfoChecked = this.filteredBOInfo.filter(business => business.checked).length;
		const filteredBoInfoAll = this.filteredBOInfo.length;
		if (filteredBoInfoChecked < filteredBoInfoAll) {
			this.checkBO = false;
		} else if (filteredBoInfoChecked === filteredBoInfoAll) {
			this.checkBO = true;
		}
	}

	public checkIfEverythingIsCheckedForAd(): void {
		const filteredAdAccountsChecked = this.filteredAdAccounts.filter(business => business.checked).length;
		const filteredAdAccountsAll = this.filteredAdAccounts.length;
		if (filteredAdAccountsChecked < filteredAdAccountsAll) {
			this.checkAd = false;
		} else if (filteredAdAccountsChecked === filteredAdAccountsAll) {
			this.checkAd = true;
		}
	}
	public checkAll(): void {
		if (this.selectedType === this.usersSelectedTypeEnum.AdAccounts) {
			this.checkAllBO();
		} else {
			this.checkAllAd();
		}
	}
	public checkAllBO(): void {
		this.checkBO = !this.checkBO;
		if (this.checkBO) {
			this.filteredBOInfo
				.filter(business => !business.checked)
				.forEach(business => {
					this.checkBoxBOChange(business);
				});
			this.filteredBOInfo.forEach(business => {
				business.checked = true;
			});

			this.checkAd = true;
			this.filteredAdAccounts.forEach(adAccount => {
				adAccount.checked = true;
			});
		} else {
			this.filteredBOInfo
				.filter(business => business.checked)
				.forEach(business => {
					this.checkBoxBOChange(business);
				});

			this.checkAd = false;
			this.filteredAdAccounts.forEach(adAccount => {
				adAccount.checked = false;
			});
		}
	}

	public checkAllAd(): void {
		this.checkAd = !this.checkAd;
		if (this.checkAd) {
			this.filteredAdAccounts
				.filter(adAccount => !adAccount.checked)
				.forEach(adAccount => {
					this.checkBoxChange(adAccount);
				});
			this.filteredAdAccounts.forEach(adAccount => {
				adAccount.checked = true;
			});

			this.checkBO = true;
			this.filteredBOInfo.forEach(business => {
				business.checked = true;
			});
		} else {
			this.filteredAdAccounts
				.filter(adAccount => adAccount.checked)
				.forEach(adAccount => {
					this.checkBoxChange(adAccount);
				});

			this.checkBO = false;
			this.filteredBOInfo.forEach(business => {
				business.checked = false;
			});
		}
	}

	public getAllAdAccounts(): void {
		this.adAccountApiService
			.getAllAdAccounts()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res =>
					(this.allAdAccounts = res.map(adAccounts => {
						return {
							id: adAccounts.id,
							name: adAccounts.name,
							checked: false,
							businessId: adAccounts.businessId
						};
					})),
				() => this.toastNotification.sendErrorToast('An error occurred, please try again later!'),
				() => (this.filteredAdAccounts = this.allAdAccounts)
			);
	}

	public filteredDataBoInfo(): void {
		this.boSearchFormControl
			.get('searchFormBoInfo')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(value => {
				this.filteredBOInfo = this.BOInfo.filter(data => {
					return data.name.toLowerCase().includes(value);
				});
				this.filteredBOInfo = [...this.filteredBOInfo];
			});
	}

	public filterDataAdAccounts(): void {
		this.boSearchFormControl
			.get('searchFormBoInfo')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(value => {
				this.filteredAdAccounts = this.allAdAccounts.filter(data => {
					return data.name.toLowerCase().includes(value);
				});
				this.filteredAdAccounts = [...this.filteredAdAccounts];
			});
	}

	public mergeStoreWithData() {
		this.selectedAccounts = [];
		let accounts = this.userDetail.accounts;
		if (accounts) {
			accounts.forEach(account => {
				this.filteredAdAccounts.forEach(adAccount => {
					if (adAccount.id === account.id) {
						adAccount.checked = true;
					}
				});
				this.filteredBOInfo.forEach(business => {
					if (account.businessId === business.FacebookBusinessId) {
						business.checked = true;
					}
				});

				this.selectedAccounts.push(account);
			});
		}
	}
	public removeSelected(account) {
		let index = this.selectedAdAccounts.findIndex(adAccount => adAccount.FacebookAdAccountId == account.FacebookAdAccountId);
		if (index > -1) {
			this.filteredAdAccounts
				.filter(adAccount => adAccount.checked)
				.forEach(adAccount => {
					if (adAccount.id === this.selectedAdAccounts[index].id) {
						adAccount.checked = false;
					}
				});
			this.selectedAdAccounts.splice(index, 1);
			this.sendDataBack();
		}
	}
	public onContinueEdit(): void {
		const payload: any = {
			user: this.userDetail.user,
			acccounts: this.selectedBusiness,
			permissions: this.userDetail.permissions
		};
		this.store.dispatch(new SetAdminManagement(payload));
		this.onContinueNextAction.emit();
	}
	public goBacks() {
		this.onGoBack.emit();
	}
}
