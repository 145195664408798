<div class="container">
	<header>
		<img src="assets/icons/nav-menu/header/lolly-black-logo.svg" />
		<span class="btn" (click)="logout()">
			<img src="assets/icons/nav-menu/signout.svg" />
			Sign out
		</span>
	</header>
	<div class="wrapper">
		<div class="main">
			<button class="primary-btn" (click)="getPaymentLink()">Pay Now</button>

			<section class="faq_pricing_section">
				<div class="container flex flex-col text-center px-6 sm:px-7 md:px-8 lg:px-10 lg:px-12 2xl:px-16">
					<div class="faq_cover">
						<div class="faq_title">
							<h2>
								Frequently
								<br />
								asked questions
							</h2>
						</div>
						<div class="faq_right">
							<div id="brand-faq-items" class="max-w-[900px] w-full m-auto">
								<div class="border-2 border-black text-left px-8 py-4 rounded-2xl my-4 lg:my-8">
									<div id="faq-question" class="flex items-center">
										<h2 class="text-md lg:text-lg xl:text-xl font-semibold">What if I want to upgrade?</h2>
										<div id="faq-toggle-icon" class="relative w-[20px] h-[100%] ml-auto">
											<span class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black"></span>
											<span
												class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black rotate-90"
											></span>
										</div>
									</div>
									<div class="faq-answer hidden">
										<p class="mt-4 text-base lg:text-lg font-medium">
											Simple! Take a look to see all our plan options and find the features that are best for you.
										</p>
									</div>
								</div>
								<div class="border-2 border-black text-left px-8 py-4 rounded-2xl my-4 lg:my-8">
									<div id="faq-question" class="flex items-center">
										<h2 class="text-md lg:text-lg xl:text-xl font-semibold">Can I change my subscription plan at any time?</h2>
										<div id="faq-toggle-icon" class="relative w-[20px] h-[100%] ml-auto">
											<span class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black"></span>
											<span
												class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black rotate-90"
											></span>
										</div>
									</div>
									<div class="faq-answer hidden">
										<p class="mt-4 text-base lg:text-lg font-medium">
											Yes, you can easily change your subscription plan at any time through your account settings. Our platform is
											designed to accommodate your evolving needs.
										</p>
									</div>
								</div>
								<div class="border-2 border-black text-left px-8 py-4 rounded-2xl my-4 lg:my-8">
									<div id="faq-question" class="flex items-center">
										<h2 class="text-md lg:text-lg xl:text-xl font-semibold">What is the cancellation process for subscription plans?</h2>
										<div id="faq-toggle-icon" class="relative w-[20px] h-[100%] ml-auto">
											<span class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black"></span>
											<span
												class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black rotate-90"
											></span>
										</div>
									</div>
									<div class="faq-answer hidden">
										<p class="mt-4 text-base lg:text-lg font-medium">
											Cancelling your subscription plan is simple. You can do so from your account settings, and your plan will remain
											active until the end of the current billing cycle.
										</p>
									</div>
								</div>
								<div class="border-2 border-black text-left px-8 py-4 rounded-2xl my-4 lg:my-8">
									<div id="faq-question" class="flex items-center">
										<h2 class="text-md lg:text-lg xl:text-xl font-semibold">Is customer support included in all subscription plans?</h2>
										<div id="faq-toggle-icon" class="relative w-[20px] h-[100%] ml-auto">
											<span class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black"></span>
											<span
												class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black rotate-90"
											></span>
										</div>
									</div>
									<div class="faq-answer hidden">
										<p class="mt-4 text-base lg:text-lg font-medium">
											Yes, our dedicated customer support is available to all users, regardless of their subscription plan. We're here to
											assist you every step of the way.
										</p>
									</div>
								</div>
								<div class="border-2 border-black text-left px-8 py-4 rounded-2xl my-4 lg:my-8">
									<div id="faq-question" class="flex items-center">
										<h2 class="text-md lg:text-lg xl:text-xl font-semibold">Do I have to commit to a full year?</h2>
										<div id="faq-toggle-icon" class="relative w-[20px] h-[100%] ml-auto">
											<span class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black"></span>
											<span
												class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black rotate-90"
											></span>
										</div>
									</div>
									<div class="faq-answer hidden">
										<p class="mt-4 text-base lg:text-lg font-medium">
											No. There are monthly plans available starting from $120, paid monthly, and which can be cancelled any time. If you
											decide that Modash is a great fit for you long-term, there are discounts available for annual plans.
										</p>
									</div>
								</div>
								<div class="border-2 border-black text-left px-8 py-4 rounded-2xl my-4 lg:my-8">
									<div id="faq-question" class="flex items-center">
										<h2 class="text-md lg:text-lg xl:text-xl font-semibold">How much does Lolly cost?</h2>
										<div id="faq-toggle-icon" class="relative w-[20px] h-[100%] ml-auto">
											<span class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black"></span>
											<span
												class="absolute top-0 bottom-0 m-0 w-[20px] md:w-[25px] xl:w-[30px] h-[2px] lg:h-[3px] bg-black rotate-90"
											></span>
										</div>
									</div>
									<div class="faq-answer hidden">
										<p class="mt-4 text-base lg:text-lg font-medium">
											We create bespoke packages that are competitive in pricing. We ensure our clients have the best experience using the
											platform and are always here to provide recommendations for campaigns.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>
