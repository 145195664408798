import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AdAccountApiService } from './_services/facebook-accounts/ad-account-api.service';
import { SidenavService } from './_services/side-nav.service';
import { AuthenticationService } from './_services/authentication.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	@ViewChild('sidenav')
	public sidenav: MatSidenav;

	public asyncLoadCount: number;

	public isShowingRouteLoadIndicator = false;

	constructor(
		private router: Router,
		private sidenavService: SidenavService,
		private translateService: TranslateService,
		private adAccountService: AdAccountApiService,
		private authenticationService: AuthenticationService
	) {
		this.asyncLoadCount = 0;
		this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof RouteConfigLoadStart) {
				this.asyncLoadCount++;
			} else if (event instanceof RouteConfigLoadEnd) {
				this.asyncLoadCount--;
			}

			this.isShowingRouteLoadIndicator = !!this.asyncLoadCount;
		});
	}

	public ngOnInit(): void {
		this.sidenavService.setSidenav(this.sidenav);
		this.translateService.addLangs(['en', 'fr']);
		this.translateService.setDefaultLang('en');
		const browserLanguage = this.translateService.getBrowserLang();
		this.translateService.use(browserLanguage.match(/en|fr/) ? browserLanguage : 'en');
		this.adAccountService.restoreAdAccountSelectionFromLocalStorage();
		this.authenticationService.initUserRolesLogic();
	}
}
