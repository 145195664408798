import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { getCurrencySymbol } from '@angular/common';
import { CampaignFormService } from '../models/discovery/campaign-form.service';
import { HttpParams } from '@angular/common/http';
import _ from 'lodash';

@Component({
	selector: 'app-create-product-seeding-step-two',
	templateUrl: './create-product-seeding-step-two.component.html',
	styleUrls: ['./create-product-seeding-step-two.component.scss']
})
export class CreateProductSeedingStepTwoComponent implements OnInit {
	@Input() public productSeedingForm: FormGroup;
	@Output() public currentStepValid = new EventEmitter<boolean>();

	public formGroup: FormGroup;
	public products: any[] = [];
	public isLoading: boolean = false;
	private unsubscriber$ = new Subject<void>();

	constructor(private campaignFormService: CampaignFormService) {}

	ngOnInit(): void {
		this.formValidate();
		this.initFormSearch();
		this.getAllProducts();
	}

	public initFormSearch(): void {
		this.formGroup = new FormGroup({
			search: new FormControl()
		});
		this.formGroup
			.get('search')
			.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
			.subscribe((input: string) => {
				this.filterProductsByName(input);
			});
	}

	public getAllProducts(): void {
		this.isLoading = true;
		let params = new HttpParams();
		params = params.append('shop', this.productSeedingForm.get('shop')?.value);
		this.campaignFormService
			.getAllProducts(params)
			.pipe(take(1))
			.subscribe(
				res => {
					this.products = res?.data;
					this.isLoading = false;
				},
				err => {
					this.isLoading = false;
				}
			);
	}

	public filterProductsByName(name: string): void {
		this.isLoading = true;
		let params = new HttpParams();
		params = params.append('shop', this.productSeedingForm.get('shop')?.value);
		params = params.append('search', name);
		this.campaignFormService
			.getAllProducts(params)
			.pipe(take(1))
			.subscribe(
				res => {
					this.products = res?.data;
					this.isLoading = false;
				},
				err => {
					this.isLoading = false;
				}
			);
	}

	public getSymbol(isoCode: string): string {
		return getCurrencySymbol(isoCode, 'narrow');
	}

	public selectProduct(product: any): void {
		let arr: any[] = this.productSeedingForm.value.products;
		const index = arr.findIndex(prod => prod?.id === product?.id);
		if (index >= 0) {
			arr.splice(index, 1);
		} else {
			arr.push(product);
		}

		this.productSeedingForm.get('products').patchValue(arr);
	}

	public selectAll(): void {
		if (this.isLoading) return;
		this.productSeedingForm.get('products').patchValue(_.cloneDeep(this.products));
	}

	public deselectAll(): void {
		if (this.isLoading) return;
		this.productSeedingForm.get('products').patchValue([]);
	}

	public checkIfIncludes(product: any): boolean {
		for (let i = 0; i < this.productSeedingForm.value?.products.length; ++i) {
			if (this.productSeedingForm.value?.products[i].id == product.id) {
				return true;
			}
		}
		return false;
	}

	public formValidate(): void {
		this.productSeedingForm.get('products').setValidators([Validators.required]);
		this.productSeedingForm.get('products').updateValueAndValidity();
		this.currentStepValid.emit(this.productSeedingForm.valid);
		this.checkForm();
	}

	public checkForm(): void {
		this.productSeedingForm.valueChanges.subscribe(res => {
			this.currentStepValid.emit(this.productSeedingForm.valid);
		});
	}

	public formUnValidate(): void {
		this.productSeedingForm.get('products').clearValidators();
		this.productSeedingForm.get('products').updateValueAndValidity();
		this.productSeedingForm.markAsUntouched();
		this.currentStepValid.emit(this.productSeedingForm.valid);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.formUnValidate();
	}
}
