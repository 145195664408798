<section class="dialog-root">
	<mat-icon mat-dialog-close class="close-icon">
		close
	</mat-icon>

	<ng-container *ngIf="loading">
		<div class="loading-wrapper">
			<app-lolly-spinning-loader></app-lolly-spinning-loader>
		</div>
	</ng-container>

	<ng-container *ngIf="!loading">
		<!-- * Header -->
		<div class="d-flex k-align-items-center fm-6 dark-grey" style="gap: 9px;">
			Send Email
		</div>

		<!-- * Body -->
		<div class="dialog-body" [formGroup]="formGroup">
			<div class="email-key-input-wrapper">
				<span class="label">From:</span>
				<div class="value">
					<app-select-drp-wo-input [dropdownControl]="formGroup.get('emailFrom')" [options]="emailFromOptions"></app-select-drp-wo-input>
				</div>
			</div>
			<div class="email-key-input-wrapper">
				<span class="label">To:</span>
				<div class="value">
					<mat-chip-grid
						#custom_chip_list
						id="custom_chip_list"
						class="custom-chip-grid"
						*ngIf="formGroup.get('emailTo')?.value && formGroup.get('emailTo')?.value?.length"
						#chipBox
					>
						<ng-container *ngFor="let chipItem of formGroup.get('emailTo').value">
							<mat-chip *ngIf="chipItem?.email" [disableRipple]="true" (removed)="remove(chipItem)">
								<span>
									{{ chipItem?.email }}
								</span>
								<span class="close" matChipRemove><mat-icon>close</mat-icon></span>
							</mat-chip>
						</ng-container>
					</mat-chip-grid>
				</div>
			</div>
			<div class="email-key-input-wrapper">
				<span class="label">Subject:</span>
				<div class="value">
					<input type="text" formControlName="emailSubject" placeholder="Type your subject.." />
				</div>
			</div>
			<div class="text-editor-wrapper">
				<quill-editor
					class="custom-quill"
					[styles]="{ flex: '1', 'font-family': 'Gilroy', 'font-size': '14px' }"
					[modules]="modules"
					[placeholder]="''"
					formControlName="emailBody"
					(onEditorChanged)="changedEditor($event)"
				></quill-editor>
				<span class="attach-btn" (click)="openAddFilesDialog()">
					<img src="assets\crm\templates\Icon metro-attachment.svg" alt="" />
				</span>
				<div class="attachment-wrapper" *ngIf="selectedFiles.length">
					<div class="attachment" *ngFor="let file of selectedFiles; let fileIndex = index">
						<span class="text" (click)="openFile(file)">
							{{ file.name }}
						</span>
						<mat-icon class="close-btn" (click)="removeFile(fileIndex)">close</mat-icon>
					</div>
				</div>
			</div>
		</div>

		<!-- * Footer -->
		<div class="dialog-footer">
			<button class="secondary-btn {{ formGroup.valid ? '' : 'disabled' }}" (click)="sendMail()">
				Send
			</button>
		</div>
	</ng-container>
</section>

<input
	#fileSelect
	(click)="$event.target.value = null"
	class="d-none"
	type="file"
	accept=".txt, .pdf, .jpg, .png, .jpeg, .gif, .tif, .xlsx, .doc, .docx, .xls, .ppt, .pptx, .zip"
/>
