import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { environment } from 'src/environments/environment';
import { AuthPaymentService } from '../../services/auth-payment.service';
import { MyPlanService } from 'src/app/user-management/new-sprint/components/my-plan/my-plan.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-payment-page',
	templateUrl: './payment-new-page.component.html',
	styleUrls: ['./payment-new-page.component.scss']
})
export class PaymentPageComponent implements OnInit {
	public subscriptionData: any;
	public subscriptionLoading = false;
	private unsubscriber$: Subject<void> = new Subject<void>();
	// public emailFormControl = new UntypedFormControl('', [
	// 	Validators.required,
	// 	Validators.minLength(3),
	// 	Validators.maxLength(70),
	// 	Validators.pattern(
	// 		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	// 	)
	// ]);

	constructor(
		public activatedRoute: ActivatedRoute,
		private authPaymentService: AuthPaymentService,
		public _myPlan: MyPlanService,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit(): void {
		// const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// this.emailFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(emailValidator), Validators.maxLength(70)]);

		this.activatedRoute.queryParams.subscribe(x => {
			let token = x['token'];
			let priceId = x['price_id'];
			if (!token) token = localStorage.getItem(StorageKey.token);
			if (!priceId) priceId = environment.DEFAULT_PRICE_ID;
			localStorage.setItem(StorageKey.token, token);

			// this.authPaymentService
			// 	.requestSession(priceId, token)
			// 	.pipe(takeUntil(this.unsubscriber$))
			// 	.subscribe((res: string) => {
			// 		this.authPaymentService.redirectToCheckout(res);
			// 	});
		});

		// this.getActiveSubscriptions();
	}

	public getActiveSubscriptions(): void {
		this.subscriptionLoading = true;
		const token = localStorage.getItem(StorageKey.token);
		this._myPlan
			.getActiveSubs(token)
			.subscribe(res => {
				this.subscriptionData = res;
			})
			.add(() => {
				this.subscriptionLoading = false;
			});
	}

	public logout(): void {
		this.authenticationService.logout();
	}
}
