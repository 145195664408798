import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
export interface ValidatorMessages {
	validationType: string;
	validationMessage: string;
}

@Injectable({
	providedIn: 'root'
})
export class ErrorMessageService {
	constructor() {}

	public setErrorMessage(validatorMessages: ValidatorMessages[], formControl: FormControl | AbstractControl): string {
		let errorMessage;
		if (validatorMessages && formControl.errors) {
			const error = validatorMessages.find(validationMessage => validationMessage.validationType === Object.keys(formControl.errors)[0]);
			if (error) {
				errorMessage = error.validationMessage;
			}
		} else {
			errorMessage = 'The field is not valid.';
		}
		return errorMessage;
	}
}
