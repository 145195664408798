import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';

@Injectable()
export class AuthenticationRouteGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, private authStore: Store<AuthenticationState>) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const hasCreditCard$ = this.authService.hasCreditCard();
		const hasBusinessOwner$ = this.authService.hasBusinessOwner();
		const hasClientEmployee$ = this.authService.hasClientEmployee();
		const isUserLoggedIn = this.authService.isLoggedIn();

		if (state.url.includes('/authentication/payment?token=')) {
			let token = route.queryParams['token'];
			this.authStore.dispatch(new LogInSuccess(token));
			return of(false);
		}

		return forkJoin([hasCreditCard$, hasBusinessOwner$, hasClientEmployee$]).pipe(
			switchMap(([hasCreditCard, hasBusinessOwner, hasClientEmployee]) => {
				if (isUserLoggedIn) {
					this.router.navigate(['/']);
					return of(true);
				}

				if (hasCreditCard && hasBusinessOwner && isUserLoggedIn) {
					this.router.navigate(['/accounts']);
					return of(false);
				}

				if (hasClientEmployee && isUserLoggedIn) {
					this.router.navigate(['/accounts']);
					return of(false);
				}

				return of(true);
			})
		);
	}
}
