export class StorageKey {
	public static token = 'token';
	public static oldToken = 'oldToken';
	public static oldDecodedJwtIo = 'oldDecodedJwtIo';
	public static decodedJwtIo = 'decodedJwtIo';
	public static selectedAdAccounts = 'selectedAdAccounts';
	public static hideAdBlockerNotification = 'hideAdBlockerNotification';
	public static date = 'selectedDateRange';
	public static installedPlatorm = 'installedPlatorm';
	public static firstTimeDiscovery = 'firstTimeDiscovery';
	public static firstTimeCampaign = 'firstTimeCampaign';
	public static firstTimeCRM = 'firstTimeCRM';
	public static first_time_lolly_user = 'first_time_lolly_user';
	public static first_time_login_lists = 'first_time_login_lists';
}
