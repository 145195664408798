<input
	(keyup)="keyInputEvent.emit($event)"
	class="input-text {{ styleClass }}"
	[id]="inputId"
	[formControl]="control"
	[placeholder]="inputPlaceholder"
	[autocomplete]="autoCorrect"
	[max]="max"
	[min]="min"
	[style.width]="'100%'"
	[value]="value"
	[step]="step"
	[format]="format"
	type="number"
/>
<div class="error-container" *ngIf="errorMessage && control.invalid && control.touched">
	<span>{{ errorMessage }}</span>
</div>
