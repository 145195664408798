<kendo-card>
	<div class="analytics-profile-card d-flex d-j-c-flex-column">
		<div class="content d-flex d-j-c-flex-column d-j-c-c d-align-items-c">
			<img [src]="profile?.InfluencerImage ? profile?.InfluencerImage : 'assets/images/chat/icon-default-user.svg'" class="influencer-image" />
			<lolly-i-tooltip [title]="profile?.InfluencerName">
				<filed-font class="influencer-name">{{ profile?.InfluencerName }}</filed-font>
			</lolly-i-tooltip>
		</div>
		<div class="details">
			<div class="d-flex d-j-c-flex-between d-align-items-c">
				<filed-font [fontSize]="'16px'">Engagement rate</filed-font>
				<filed-font [fontSize]="'16px'">{{ profile?.engagementRate }}%</filed-font>
			</div>
			<div class="d-flex d-j-c-flex-between d-align-items-c">
				<filed-font [fontSize]="'16px'">Average Interactions</filed-font>
				<filed-font [fontSize]="'16px'">
					{{ profile?.averageInteractions }}
				</filed-font>
			</div>
			<div class="d-flex d-j-c-flex-between d-align-items-c">
				<filed-font [fontSize]="'16px'">Follower Authenticity</filed-font>
				&nbsp;
				<filed-font [fontSize]="'16px'">{{ profile?.followerAuthenticity }}%</filed-font>
			</div>
		</div>
		<div class="d-flex d-j-c-c d-align-items-c">
			<app-primary-button
				[buttonLable]="'View Analytics'"
				[buttonType]="'Primary'"
				[isPeddingReduceSmall]="true"
				(buttonClicked)="view()"
			></app-primary-button>
		</div>
	</div>
</kendo-card>
