<div class="page-container">
	<div class="d-flex justify-content-between align-items-center header">
		<div>
			<app-user-sub-header [pageTitle]="NewSettingsPageLabelsEnum.MyAccount"></app-user-sub-header>
		</div>
		<div class="buttons">
			<div>
				<div *appCheckPermissions="permissionModelAccountEdit" class="d-flex align-items-center">
					<p class="edit" *ngIf="!editState" (click)="setEdit()">Edit</p>

					<p class="cancel" *ngIf="editState" (click)="cancelEdit()">Cancel</p>

					<app-button
						(buttonClicked)="setSubmit()"
						*ngIf="editState"
						[buttonClass]="buttonClassEnum.Primary"
						[buttonType]="buttonTypeEnum.Simple"
						buttonLabel="Save"
					></app-button>
				</div>
			</div>
		</div>
	</div>

	<app-account-inputs></app-account-inputs>
</div>
