import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PriorityToasterType } from './models/priority-toaster-type-modified.enum';
import { PriorityToasterService } from './services/priority-toaster.service';
import { PriorityToasterModel } from './models/priority-toaster.model';

/**
 * ? HOW TO USE -
 * * this.priorityToasterService.sendCustomToast(`Emails successfully delivered to creators' inboxes`, PriorityToasterType.Success, 10000, 'Emails Delivered!');
 */
@Component({
	selector: 'app-priority-toaster',
	templateUrl: './priority-toaster.component.html',
	styleUrls: ['./priority-toaster.component.scss']
})
export class PriorityToasterComponent implements OnInit {
	public isToastVisible = false;
	public toastTypes = PriorityToasterType;
	public toastModel: PriorityToasterModel;
	public errorMessageCache: string =
		'Please remember to clear your browser cache before resubmitting your campaign. If error persists, please contact support@lolly.com';

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private priorityToasterService: PriorityToasterService) {}

	public ngOnInit(): void {
		this.priorityToasterService
			.getToasts()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(resp => {
				this.showToast(resp);
			});
		this.priorityToasterService
			.getDismissSubject()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => this.dismiss());
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private showToast(toastModel: PriorityToasterModel): void {
		this.toastModel = toastModel;

		this.isToastVisible = true;
		setTimeout(() => {
			this.isToastVisible = false;
		}, toastModel.duration);
	}

	public dismiss(): void {
		this.isToastVisible = false;
	}
}
