import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { TokenService } from '../../_services/token.service';
import { AuthPaymentService } from '../services/auth-payment.service';
import { LogInSuccess } from '../state/authentication.action';
import { AuthenticationState } from '../state/authentication.reducer';

@Component({
	selector: 'app-shopify-integration-loading',
	templateUrl: './shopify-integration-loading.component.html',
	styleUrls: ['./shopify-integration-loading.component.scss']
})
export class ShopifyIntegrationLoadingComponent implements OnInit {
	public params = {
		code: '',
		hmac: '',
		timestamp: '',
		host: '',
		shop: ''
	};

	constructor(
		private tokenService: TokenService,
		private authStore: Store<AuthenticationState>,
		private activatedRoute: ActivatedRoute,
		private authService: AuthPaymentService
	) {
		activatedRoute.queryParams.subscribe(params => {
			this.params.code = params['code'];
			this.params.hmac = params['hmac'];
			this.params.timestamp = params['timestamp'];
			this.params.host = params['host'];
			this.params.shop = params['shop'];
		});
	}

	ngOnInit(): void {
		this.authService
			.shopifyAuth(this.params)
			.toPromise()
			.then(res => {});
	}

	public refreshToken(): void {
		this.tokenService
			.refreshToken(localStorage.getItem(StorageKey.token))
			.toPromise()
			.then(res => {
				this.authStore.dispatch(new LogInSuccess(res));
			});
	}
}
