import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFbSideStepper, ISideStepper } from './side-stepper.interface';
import { Store } from '@ngrx/store';

@Component({
	selector: 'side-stepper',
	templateUrl: './side-stepper.component.html',
	styleUrls: ['./side-stepper.component.scss']
})
export class SideStepperComponent implements OnInit {
	@Input() public steps: ISideStepper[] | IFbSideStepper[] = [];
	@Output() public stepClick: EventEmitter<ISideStepper | IFbSideStepper> = new EventEmitter();
	@Input() public activeStep;

	constructor(private store: Store) {}

	public ngOnInit(): void {}
}
