import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { NewJobInterface } from './new-job-form.interface';

@Injectable({
	providedIn: 'root'
})
export class SMIJobsService {
	private newJobObject: NewJobInterface;
	private selectedCampaignId: number;

	constructor(
		// private campaignService: OutreachCampaignService,
		private http: HttpClient
	) {}

	public getJobObject(): NewJobInterface {
		return this.newJobObject;
	}

	public payInfluencer(taskId: string, contentId: string, platformId: string, channelPostType: string): Observable<any> {
		let body: any;
		return this.http.post<any>(
			`${BaseApiUrl.SocialInfluencerCS}PaymentManager/pay/task/${taskId}/content/${contentId}/platform/${platformId}/${channelPostType}`,
			body
		);
	}

	// public getPaymentModes(): Array<DropdownInterface> {
	// 	return Object.keys(PaymentModeEnum)
	// 		.filter(x => {
	// 			if (!(parseInt(x, 10) >= 0)) {
	// 				return x;
	// 			}
	// 		})
	// 		.map((x, index) => {
	// 			return {
	// 				id: index + 1,
	// 				name: x
	// 					.split('_')
	// 					.map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
	// 					.join(' ')
	// 			};
	// 		});
	// }
}
