import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-email-input-field',
	templateUrl: './email-input-field.component.html',
	styleUrls: ['./email-input-field.component.scss']
})
export class EmailInputFieldComponent implements OnInit {
	@Input('inputPlaceholder') public inputPlaceholder: string = 'Enter text here';
	@Input('control') public control: UntypedFormControl = new UntypedFormControl();
	@Input() public disabled: boolean = false;
	@Input('readOnly') public readOnly: boolean = false;
	@Input('errorMessage') public errorMessage: string = 'Invalid Email Address';
	@Input('maxLength') public maxLength: number = 180;
	@Input('hideError') public hideError: boolean = false;
	@Input('showValidIcon') public showValidIcon: boolean = false;
	@Input('showCustomIcon') public showCustomIcon: string = null;
	@Input() iconWidth: string = null;
	@Input() marginRight: string = null;
	@Output() public keyInputEvent = new EventEmitter<any>();
	@Input() public styleClass: string = '';
	@Input() public inputStyleClass: string = '';
	constructor() {}

	ngOnInit(): void {}
}
