import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
// import { AdvertPreview, CarouselDto, SingleAdvertDto, MultipleSingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';//silsrang-migration
import { Observable } from 'rxjs';
import { BaseApiUrl } from '../base-api-urls';
import { select, Store } from '@ngrx/store';
import { getFacebookBusinessOwnerId, UserState } from '../../shared/state/user/user.reducer';
import { AdFormats } from 'src/app/shared/models/server-statics-enum-constants';

@Injectable()
export class AdvertService {
	constructor(private http: HttpClient, private userStore: Store<UserState>) {}

	// public generateAdvertPreview(
	// 	pageFacebookId: string,
	// 	adAccountFacebookId: string,
	// 	advertPreviewDetail: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto,
	// 	objective: string,
	// 	instagramId?: string,
	// 	productSetId?: string,
	// 	ad_format?: AdFormats
	// ): Observable<any> {
	// 	return this.userStore.pipe(
	// 		select(getFacebookBusinessOwnerId),
	// 		take(1),
	// 		switchMap(facebookId => {
	// 			const advertPreviewRequest: AdvertPreview = {
	// 				facebookPageId: !!pageFacebookId ? pageFacebookId : '',
	// 				ad_account_id: adAccountFacebookId,
	// 				ad_format: ad_format ?? advertPreviewDetail?.ad_format,
	// 				ad_format_type: ad_format ?? advertPreviewDetail?.ad_format,
	// 				business_owner_facebook_id: facebookId,
	// 				adverts: advertPreviewDetail,
	// 				objective: objective,
	// 				productSetId: productSetId ?? null,
	// 				devicePlacementPosition: advertPreviewDetail?.device_placement_position
	// 			};
	// 			if (productSetId) {
	// 				advertPreviewRequest.productSetId = productSetId;
	// 			}
	// 			if (instagramId) {
	// 				advertPreviewRequest.instagram_id = instagramId;
	// 			}
	// 			const customAdvertPreviewRequest = { ...advertPreviewRequest };
	// 			if (customAdvertPreviewRequest.adverts) {
	// 				let adverts: any = customAdvertPreviewRequest.adverts;
	// 				if (adverts.hasOwnProperty('call_to_action')) {
	// 					const callToAction = adverts.call_to_action;
	// 					adverts = { ...adverts, call_to_action: callToAction };
	// 				}
	// 				advertPreviewRequest.adverts = adverts;
	// 			}
	// 			return this.http.post<any>(BaseApiUrl.FacebookPythonCampaignBuilder + 'advert-preview/', advertPreviewRequest).pipe(
	// 				map(result => {
	// 					return result.response;
	// 				})
	// 			);
	// 		})
	// 	);
	// }
}
