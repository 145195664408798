import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-i-grey-tooltip',
	templateUrl: './i-grey-tooltip.component.html',
	styleUrls: ['./i-grey-tooltip.component.scss']
})
export class IGreyTooltipComponent implements OnInit {
	@Input() public text: string;
	@Input() public link: string;
	@Input() public position: string = 'bottom-left';
	@Input() public linkText: string;
	@Input() public icon: string = 'help';
	@Input() public showLearnMore: boolean = false;
	constructor() {}

	ngOnInit(): void {}
}
