<div class="toggle-column-btn hoverable-btn" [matMenuTriggerFor]="toggleColumnMenu" #toggleColumnMenuTrigger="matMenuTrigger">
	<img src="assets\icons\select-columns-icon.svg" />
	<div>Columns</div>
</div>
<mat-menu #toggleColumnMenu="matMenu" class="toggle-column-menu">
	<ng-container *ngFor="let column of availableColumns">
		<div class="slider-wrapper">
			<mat-slide-toggle
				class="custom-blue-slide-toggle"
				[checked]="inputControl?.value?.includes(column?.value)"
				(click)="onColumnSliderClick($event, column)"
			>
				{{ column?.name }}
			</mat-slide-toggle>
		</div>
	</ng-container>
</mat-menu>
