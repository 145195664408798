import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { TokenService } from 'src/app/_services/token.service';

@Component({
	selector: 'app-shopify-billing',
	templateUrl: './shopify-billing.component.html',
	styleUrls: ['./shopify-billing.component.scss']
})
export class ShopifyBillingComponent implements OnInit {
	private queryParams?: any;
	private unsubscriber$ = new Subject<void>();

	constructor(
		private authStore: Store<AuthenticationState>,
		private authService: AuthenticationService,
		private toast: ToastNotificationService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private tokenService: TokenService
	) {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.queryParams = params;
		});
	}

	ngOnInit(): void {
		if (!this.queryParams || Object.keys(this.queryParams).length <= 0) {
			this.router.navigate(['']);
		}
	}

	ngAfterViewInit(): void {
		if (!this.queryParams || Object.keys(this.queryParams).length <= 0) {
			this.router.navigate(['']);
		} else {
			setTimeout(() => {
				this.updateShopifyBilling();
			}, 3000);
		}
	}

	private updateShopifyBilling(): void {
		let token = localStorage.getItem(StorageKey.token);
		this.tokenService
			.refreshToken(token)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.authStore.dispatch(new LogInSuccess(res));
				},
				err => {
					this.router.navigate(['']);
				}
			);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
