<div class="d-flex message-container" [ngClass]="open ? 'open' : 'close'">
	<img
		class="user-image"
		*ngIf="message.messageType == 'normal' && message.userType != 'brand'"
		[src]="message.userDetails?.usrImg"
		onError="this.src='assets/icons/user-management/account/user-circle-solid.png'"
	/>
	<kendo-card [ngClass]="message.userType == 'brand' ? 'send' : ''">
		<div class="message-card">
			<div class="d-flex align-items-center space-between message-header">
				<div class="d-flex align-items-center" *ngIf="message.userType != 'brand'">
					<img class="warning-image" *ngIf="message.messageType == 'automated'" src="assets/icons/warning-circle.svg" />
					<filed-font [fontSize]="'16px'" *ngIf="message.messageType == 'normal'" class="ml-12px">{{ message.userDetails.name }}</filed-font>
					<filed-font [fontSize]="'14px'" [color]="'#2585fe'" class="ml-12px" *ngIf="message.messageType == 'automated'">
						Today at 15:42Automated noticeled-font
					</filed-font>
				</div>
				<div class="d-flex align-items-center">
					<mat-icon class="time-icon" [ngClass]="message.messageType == 'automated' ? 'automated' : ''">access_time</mat-icon>
					<filed-font [fontSize]="'14px'" [fontWeight]="'400'" [color]="'#ffffff'" *ngIf="message.messageType != 'automated'" class="ml-7px">
						{{ message.date | date: "MMM d 'at' HH:mm" }}
					</filed-font>

					<filed-font [fontSize]="'14px'" [fontWeight]="'400'" [color]="'#7e5f17'" *ngIf="message.messageType == 'automated'" class="ml-7px">
						{{ message.date | date: "MMM d 'at' HH:mm" }}
					</filed-font>
					<img
						*ngIf="message.messageType != 'automated' && message.userType != 'brand'"
						class="ml-25px platform-logo"
						src="assets/icons/social-media-logo/{{ message.platform }}.svg"
					/>
				</div>
			</div>
			<div class="messag-body">
				<div class="inner-body w-80p" [innerHtml]="message.message"></div>
				<div class="doc-card">
					<kendo-card *ngFor="let file of message.attachments">
						<div class="d-flex align-items-center doc">
							<div class="doc-details d-flex align-items-center">
								<img src="assets/icons/doc-logo/google-sheets-logo.png" />
								<i-tooltip [title]="file.name" class="toltip">
									<filed-font [fontSize]="'14px'" [fontWeight]="'400'" [color]="'#2585fe'" class="ml-25px filename">
										{{ file.name }}
									</filed-font>
								</i-tooltip>
							</div>

							<mat-icon class="download-icon" (click)="downloadDoc(file)">keyboard_tab</mat-icon>
						</div>
					</kendo-card>
				</div>
			</div>
		</div>
	</kendo-card>
</div>
