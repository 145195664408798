import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InfluencerDetailsInterface } from './model/influencer-profile-details.interface';

@Component({
	selector: 'app-analytics-profile-details-card',
	templateUrl: './analytics-profile-details-card.component.html',
	styleUrls: ['./analytics-profile-details-card.component.scss']
})
export class AppAnalyticsProfileDetailsCardComponent implements OnInit {
	@Input() public profileDetails: InfluencerDetailsInterface;
	@Input() public smallView: boolean = false;
	@Input() public backTo: string = 'Discovery';
	@Output() public back: EventEmitter<string>;
	@Output() public connect: EventEmitter<any>;
	@Output() public createNewList: EventEmitter<any>;
	@Output() public addToList: EventEmitter<any>;
	@Output() public SendToCampaign: EventEmitter<any>;
	@Output() public visitProfile: EventEmitter<any>;
	constructor() {
		this.back = new EventEmitter<string>();
		this.connect = new EventEmitter<any>();
		this.addToList = new EventEmitter<any>();
		this.createNewList = new EventEmitter<any>();
		this.SendToCampaign = new EventEmitter<any>();
		this.visitProfile = new EventEmitter<any>();
	}

	ngOnInit(): void {}

	public createNewListClick(): void {
		this.createNewList.emit();
	}
	public addToListClick(): void {
		this.addToList.emit();
	}
	public SendToCampaignClick(): void {
		this.SendToCampaign.emit();
	}
	public goBack(): void {
		this.back.emit(this.backTo);
	}
	public connectTouser(): void {
		this.connect.emit();
	}
	public ViewProfile(): void {
		this.visitProfile.emit();
	}
}
