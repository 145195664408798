import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetStartedDataModel } from 'src/app/shared/models/first-timer-user.model';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
	selector: 'app-onboarding-dialog',
	templateUrl: './onboarding-dialog.component.html',
	styleUrls: ['./onboarding-dialog.component.scss']
})
export class OnboardingDialogComponent implements OnInit {
	public data: any;
	public loading: boolean = true;
	public progress: number = 0;
	public checkedCount: number = 0;
	public getStartedData: GetStartedDataModel = {};
	public unsubscriber$ = new Subject<void>();

	constructor(private dialogRef: MatDialogRef<OnboardingDialogComponent>, private router: Router, private sharedService: SharedService) {}

	ngOnInit(): void {
		this.sharedService.getStartedData$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			this.getStartedData = res;
			setTimeout(() => {
				this.loading = false;
			}, 500);
		});
	}

	public routeTo(route: string): void {
		this.router.navigate([route]);
		this.dialogRef.close();
	}

	public routeToLists(): void {
		this.router.navigate(['/list'], { queryParams: { tab: 'lists' } });
		this.dialogRef.close();
	}
}
