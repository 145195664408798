import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getDisableUserState, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { select, Store } from '@ngrx/store';
import { DisableUserModel } from 'src/app/user-management/state/state.model';
import { PauseUserAccount } from 'src/app/user-management/state/user-management.actions';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { SharedState } from 'src/app/shared/state/shared.reducer';

@Component({
	selector: 'app-pausing-account',
	templateUrl: './pausing-account.component.html',
	styleUrls: ['./pausing-account.component.scss']
})
export class PausingAccountComponent implements OnInit {
	public loading: boolean = false;
	public userForm: UntypedFormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	private filedId: number;
	private dropDownData: any = [
		{ label: 'Not fitting in budget', value: 'Not fitting in budget' },
		{ label: 'Support service issue', value: 'Support service issue' },
		{ label: 'Product related issue', value: 'Product related issue' },
		{ label: 'Other reasons', value: 'Other reasons' }
	];

	constructor(
		private sharedStore: Store<SharedState>,
		private store: Store<UserManagementState>,
		private userStore: Store<UserState>,
		private _location: Location,
		private router: Router,
		private formBuilder: UntypedFormBuilder
	) {}

	ngOnInit(): void {
		this.createForm();
		//this.getDataFromStore();
		// this.onFormChange();
	}
	public getDataFromStore(): void {
		this.sharedStore.pipe(select(getDisableUserState), takeUntil(this.unsubscriber$)).subscribe((detail: DisableUserModel) => {
			if (detail) {
				this.userForm.controls['reason'].setValue(detail.reason);
				this.userForm.controls['feedback'].setValue(detail.feedback);
				this.userForm.controls['password'].setValue(detail.password);
			}
		});
	}
	private createForm(): void {
		this.userForm = this.formBuilder.group({
			reason: new UntypedFormControl('Reason for leaving', Validators.required),
			feedback: new UntypedFormControl(''),
			password: new UntypedFormControl('', Validators.required)
		});
		this.userStore.pipe(select(getFiledId), take(1)).subscribe(id => {
			if (id) {
				this.filedId = id;
			}
		});
	}
	public backClicked() {
		this._location.back();
	}

	public onTextChange(content: string): void {
		this.userForm.controls['feedback'].setValue(content);
	}
	public onCancel() {
		this.router.navigate(['user-management/security']);
	}

	public onNext() {
		const payload: DisableUserModel = {
			userId: this.filedId,
			reason: this.userForm.controls['reason'].value,
			feedback: this.userForm.controls['feedback'].value,
			password: this.userForm.controls['password'].value
		};
		this.store.dispatch(new PauseUserAccount(payload));
		this.router.navigate(['user-management/security/appreciation']);
	}
	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.userForm.get('reason').setValue(selected);
		}
	}
	public onFormChange() {
		this.userForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			const payload: DisableUserModel = {
				userId: this.filedId,
				reason: this.userForm.controls['reason'].value,
				feedback: this.userForm.controls['feedback'].value,
				password: this.userForm.controls['password'].value
			};
			this.store.dispatch(new PauseUserAccount(payload));
		});
	}
}
