import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepsEnum } from './stepper.enum';
import { StepType } from '@progress/kendo-angular-layout';

@Component({
	selector: 'app-lolly-stepper',
	templateUrl: './lolly-stepper.component.html',
	styleUrls: ['./lolly-stepper.component.scss']
})
export class LollyStepperComponent {
	@Input() public linear: boolean;
	@Input() public current: number;
	@Input() public width: string;
	@Input() public steps: any;
	@Input() public stepType: StepType;
	@Output() public stepChange = new EventEmitter<number>();

	constructor() {}

	public stepEmitter(changes: StepsEnum): void {
		this.stepChange.emit(changes);
	}
}
