import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SideStepperModel } from './models/side-stepper.model';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-custom-side-stepper',
	templateUrl: './custom-side-stepper.component.html',
	styleUrls: ['./custom-side-stepper.component.scss'],
	animations: [
		trigger('rotateIcon', [
			state('left', style({ transform: 'rotate(0deg)' })),
			state('right', style({ transform: 'rotate(180deg)' })),
			transition('left <=> right', [animate('0.3s ease')])
		])
	]
})
export class CustomSideStepperComponent implements OnInit {
	@Input() public collapsed: boolean = false;
	@Input() public steps: SideStepperModel[] = [];
	@Output() public stepClickEvent: EventEmitter<{ stepIndex: number }> = new EventEmitter();
	@Output() public substepClickEvent: EventEmitter<{ stepIndex: number; subStepIndex: number }> = new EventEmitter();

	public isLeftArrow = true;

	constructor() {}

	ngOnInit(): void {}

	public toggleIcon(): void {
		this.collapsed = !this.collapsed;
		this.isLeftArrow = !this.isLeftArrow;
	}

	public onClickStep(index: number): void {
		this.stepClickEvent.emit({ stepIndex: index });
	}

	public onClickSubStep(stepIndex: number, subStepIndex: number): void {
		this.substepClickEvent.emit({ stepIndex: stepIndex, subStepIndex: subStepIndex });
	}
}
