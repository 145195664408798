import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-credit-limit',
	templateUrl: './credit-limit.component.html',
	styleUrls: ['./credit-limit.component.scss']
})
export class CreditLimitComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
