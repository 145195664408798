import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { VerifyAddUserComponent } from 'src/app/user-management/new-sprint/components/modals/verify-add-user/verify-add-user.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryDropdownValueInterface } from 'src/app/shared/models/country-dropdown-value.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FieldValidators } from 'src/app/user-management/models/validators';
import { CompleteModalComponent } from 'src/app/user-management/new-sprint/components/modals/complete-modal/complete-modal.component';
import { TokenDetailInterface } from 'src/app/_models/token.interface';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { Store } from '@ngrx/store';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { TokenService } from 'src/app/_services/token.service';
import { TimeZoneService } from 'src/app/shared/services/time-zone.service';

@Component({
	selector: 'app-setup',
	templateUrl: './setup.component.html',
	styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
	public loading: boolean = false;
	public defaultCountryCode = 'GB';
	public selectedCountry: CountryDropdownValueInterface;
	public accountForm: UntypedFormGroup;
	public editForm: UntypedFormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();

	public disableAuth: boolean = false;
	public editAuth: boolean = false;
	public showAuth = true;

	constructor(
		private _location: Location,
		private router: Router,
		public dialog: MatDialog,
		private formBuilder: UntypedFormBuilder,
		private activatedRoute: ActivatedRoute,
		private token: TokenService,
		private sharedStore: Store<SharedState>,
		private toaster: ToastNotificationService,
		private timeZoneService: TimeZoneService
	) {
		this.selectedCountry = {
			code: 'GB',
			dialcode: '+44'
		};
		const code = this.timeZoneService.getCountryCode();
		const dialCode = this.timeZoneService.getCountryDialCode();
		this.selectedCountry.code = code;
		this.defaultCountryCode = code;
		this.selectedCountry.dialcode = dialCode;
	}

	ngOnInit(): void {
		this.createForm();
		this.formChanges();
		this.getActivatedRoute();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private createForm(): void {
		this.accountForm = this.formBuilder.group({
			digitcode: new UntypedFormControl(''),
			dialcode: new UntypedFormControl('', Validators.required),
			phone: new UntypedFormControl(null, [
				Validators.minLength(7),
				Validators.required,
				Validators.maxLength(11),
				Validators.pattern(FieldValidators.Phone)
			])
		});

		this.editForm = this.formBuilder.group({
			digitcode: new UntypedFormControl(''),
			dialcode: new UntypedFormControl('', Validators.required),
			phone: new UntypedFormControl(null, [
				Validators.minLength(7),
				Validators.required,
				Validators.maxLength(11),
				Validators.pattern(FieldValidators.Phone)
			])
		});
	}
	public getActivatedRoute() {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params && params?.disable) {
				this.disableAuth = encodeURIComponent(params['disable']) === 'yes' ? true : false;
				this.disableAuthenticate();
			} else if (params && params?.edit) {
				this.editAuth = encodeURIComponent(params['edit']) === 'yes' ? true : false;
				if (this.editAuth) {
					this.sharedStore.dispatch(new ShowGlobalSpinner());
					this.token
						.getContactToken()
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe(
							detail => {
								if (detail) {
									this.showAuth = false;
									this.update2FactorAuth(detail);
								}
							},
							() => {},
							() => {
								this.sharedStore.dispatch(new HideGlobalSpinner());
							}
						);
				}
			}
		});
	}

	public update2FactorAuth(codeSent): void {
		if (codeSent) {
			const dialogRef = this.dialog.open(VerifyAddUserComponent, {
				width: '520px',
				height: '480px',
				disableClose: true
			});
			dialogRef.componentInstance.modelDetail = {
				title: 'Two-Factor Authentication!',
				text: 'A message has been sent to your phone number. Please enter 6 digit code to update your Two-Factor Authentication.',
				closeBtn: true,
				actionBtn: true,
				actionBtnText: 'CONFIRM',
				useOtp: true,
				otp: {
					secret: false,
					count: 6
				}
			};
			dialogRef.afterClosed().subscribe(details => {
				if (details.status === true) {
					//Verify input code here against twilio api. (update2AuthApi)
					this.confirmOtp('', details.code);
				} else {
					if (details.status === 'resend') {
						this.getActivatedRoute();
						this.toaster.sendSuccessToast('OTP sent again successfuly', 3);
					} else {
						this._location.back();
					}
				}
			});
		}
	}

	public formChanges(): void {
		this.accountForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
		this.accountForm.controls['phone'].updateValueAndValidity();
		this.accountForm.controls['dialcode'].updateValueAndValidity();
	}

	public onSelectCountry(event: CountryDropdownValueInterface): void {
		this.selectedCountry = event;
		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');

		this.accountForm.controls['phone'].setValidators(Validators.maxLength(15 - dialCodeNumber.length));

		const dropdownCountryCode = this.accountForm.get('dialcode');
		if (this.selectedCountry?.dialcode !== undefined) {
			dropdownCountryCode.setValidators(null);
		}

		if (this.selectedCountry?.dialcode === undefined) {
			dropdownCountryCode.setValidators([Validators.required]);
		}

		this.accountForm.controls['dialcode'].setValue(this.selectedCountry.dialcode);
		dropdownCountryCode.updateValueAndValidity();
		if (this.accountForm.get('dialcode').valid) {
		}
	}

	public checkForInvalid(): void {
		const NDCandSN = this.accountForm.get('phone').value;
		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');
		if (dialCodeNumber === undefined) {
			this.accountForm.controls['phone'].setErrors({ dialCode: true });
		}

		if (NDCandSN !== null) {
			if (dialCodeNumber === undefined) {
				this.accountForm.controls['phone'].setErrors({ dialCode: true });
			}

			if (isNaN(Number(NDCandSN))) {
				this.accountForm.controls['phone'].setErrors({ numbersOnly: true });
			}

			if ((NDCandSN.toString() === '' || NDCandSN.toString().length < 7) && dialCodeNumber !== undefined) {
				this.accountForm.controls['phone'].setErrors({ phoneMinLength: true });
			}

			if (dialCodeNumber !== undefined && NDCandSN.toString().length > 7) {
				if (NDCandSN.toString().length + dialCodeNumber.length > 15) {
					this.accountForm.controls['phone'].setErrors({ phoneMaxLength: true });
				}
			}
		}
	}

	public backClicked() {
		this._location.back();
	}

	public onCancel() {
		this.router.navigate(['user-management/security']);
	}

	public disableAuthenticate() {
		if (this.disableAuth) {
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			this.token
				.get2FactorToken()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					detail => {
						if (detail) {
							const dialogRef = this.dialog.open(VerifyAddUserComponent, {
								width: '520px',
								height: '480px',
								disableClose: true
							});
							dialogRef.componentInstance.modelDetail = {
								title: 'Two-Factor Authentication!',
								text:
									'A message has been sent to the provided phone number. Please enter 6 digit code to disable your Two-Factor Authentication.',
								closeBtn: true,
								actionBtn: true,
								actionBtnText: 'CONFIRM',
								useOtp: true,
								otp: {
									secret: false,
									count: 6
								}
							};
							dialogRef.afterClosed().subscribe(details => {
								if (details.status) {
									//Verify input code here against twilio api.
									this.confirmOtp('', details.code);
								} else {
									if (details.status === 'resend') {
										this.getActivatedRoute();
										this.toaster.sendSuccessToast('OTP sent again successfuly', 3);
									} else {
										this._location.back();
									}
								}
							});
						}
					},
					() => {},
					() => {
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				);
		}
	}

	public onNext() {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		const phoneNumber = this.accountForm.controls['dialcode'].value + this.accountForm.controls['phone'].value;
		let formData = new FormData();
		formData.append('phone_number', phoneNumber);
		this.token
			.addContactToken(formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detail => {
					if (detail) {
						const dialogRef = this.dialog.open(VerifyAddUserComponent, {
							width: '520px',
							height: '480px',
							disableClose: true
						});
						dialogRef.componentInstance.modelDetail = {
							title: 'Two-Factor Authentication!',
							text: 'A message has been sent to the provided phone number. Please enter 6 digit code to authorize this signin.',
							closeBtn: true,
							actionBtn: true,
							actionBtnText: 'CONFIRM',
							useOtp: true,
							otp: {
								secret: false,
								count: 6
							}
						};
						dialogRef.afterClosed().subscribe(details => {
							if (details.status) {
								//Verify input code here against twilio api.
								this.confirmOtp(phoneNumber, details.code);
							} else {
								if (details.status === 'resend') {
									this.onNext();
									this.toaster.sendSuccessToast('OTP sent again successfuly', 3);
								} else {
									this._location.back();
								}
							}
						});
					}
				},
				() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public confirmOtp(phoneNumber: string, code: string): void {
		if (this.disableAuth) {
			this.sharedStore.dispatch(new ShowGlobalSpinner());

			let formData = new FormData();
			formData.append('code', code);
			this.token
				.enableDisable2FactorAuth(code)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					detail => {
						if (detail) {
							this.enableDisable2Factor(true);
						}
					},
					() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
					() => {
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				);
		} else {
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			let formData = new FormData();
			formData.append('phone_number', phoneNumber);
			formData.append('code', code);
			this.token
				.verifyContactToken(formData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					detail => {
						if (detail) {
							this.enableDisable2Factor(false);
						}
					},
					() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
					() => {
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				);
		}
	}

	public enableDisable2Factor(isToEnable: boolean): void {
		if (isToEnable) {
			this.token
				.enableConfirmToken(false)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					detail => {
						if (detail) {
							this.displayDialog('Two-Factor Authentication disabled!', 'You have successfully disabled two-factor authentication.');
						}
					},
					() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
					() => {
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				);
		} else {
			this.token
				.enableConfirmToken(true)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					detail => {
						if (detail) {
							this.displayDialog('Two-Factor Authentication Enabled!', 'You have successfully enabled two-factor authentication.');
						}
					},
					() => this.toaster.sendErrorToast('Error occured while sending you an sms. Please try again later!'),
					() => {
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				);
		}
	}
	public displayDialog(msg: string, desc: string): void {
		const dialogRef = this.dialog.open(CompleteModalComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: msg,
			text: desc,
			closeBtn: false,
			actionBtn: false
		};
		dialogRef.afterClosed().subscribe(() => {
			this.router.navigate(['user-management/security']);
		});
	}
}
