import { P } from '@angular/cdk/keycodes';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-date-input',
	templateUrl: './date-input.component.html',
	styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit, OnChanges {
	@Input() public control: UntypedFormControl;
	@Input() public min: any;
	@Input() public value: UntypedFormControl;
	public nextYears: number = 0;
	constructor() {}

	ngOnChanges(): void {
		this.min = this.min;
	}
	ngOnInit(): void {
		const moonLanding = new Date();
		this.nextYears = moonLanding.getFullYear() + 1;
	}
}
