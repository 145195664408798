import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GoogleService } from 'src/app/_services/google/google.service';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { environment } from 'src/environments/environment';
import { ConnectToGoogleRequest } from 'src/app/authentication/_models/ConnectToGoogleRequest';

declare var gapi: any;

@Component({
	selector: 'app-connect-to-google-new',
	templateUrl: './connect-to-google.component.html',
	styleUrls: ['./connect-to-google.component.scss']
})
export class ConnectToGoogleNewComponent implements OnInit, OnDestroy {
	@Output() connectionResponse = new EventEmitter<boolean>();

	private auth2: any;
	private googleButton: Element;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private googleService: GoogleService, private store: Store<UserState>) {}

	ngOnInit() {
		setTimeout(() => {
			this.googleButton = document.getElementById('googleBtn');
			this.googleInit();
		});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private googleInit(): void {
		gapi.load('auth2', () => {
			this.auth2 = gapi.auth2.init({
				client_id: environment.Google.ClientId,
				cookiepolicy: 'single_host_origin',
				scope: 'https://www.googleapis.com/auth/adwords'
			});
			this.renderGoogleButton();
		});
	}

	private renderGoogleButton(): void {
		gapi.signin2.render('googleBtn', {
			scope: 'https://www.googleapis.com/auth/adwords',
			width: 245,
			height: 40,
			longtitle: true,
			theme: 'dark',
			onsuccess: () => {},
			onfailure: () => {}
		});
	}

	public onGoogleSignInSuccess(event: MouseEvent): void {
		event.preventDefault();
		let userSignedIn = false;
		this.auth2.grantOfflineAccess().then((resp: any) => {
			this.auth2.isSignedIn.listen(() => {
				if (userSignedIn) {
					return;
				}
				userSignedIn = true;
				this.createGoogleConnectionRequest(resp['code']).then(request => {
					this.googleService
						.connectToGoogleAccount(request)
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe(
							() => {
								this.connectionResponse.emit(true);
								this.auth2.signOut();
							},
							() => {
								this.connectionResponse.emit(false);
								this.auth2.signOut();
							}
						);
				});
			});
		});
	}

	private async createGoogleConnectionRequest(code: string): Promise<ConnectToGoogleRequest> {
		const currentUserProfile = this.auth2.currentUser.get();
		return {
			code: code,
			email: currentUserProfile.getBasicProfile().getEmail(),
			userId: await this.store.pipe(select(getFiledId), take(1)).toPromise()
		};
	}
}
