import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'filed-font',
	template: `
		<div
			class="filed-font"
			[style.font-size]="fontSize"
			[style.font-weight]="fontWeight"
			[style.color]="color"
			[style.letter-spacing]="letterSpacing"
			[style.font-family]="fontFamily"
			[style.cursor]="cursor"
			[style.text-align]="textAlign"
		>
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: []
})
export class FiledFontComponent implements OnInit {
	@Input() public fontSize: string = '';
	@Input() public fontFamily: string = 'Gilroy';
	@Input() public fontWeight: string = '500';
	@Input() public color: string = '#0b0b0b';
	@Input() public letterSpacing: string = this.fontSize ? '0.' + this.fontSize : '';
	@Input() public cursor: string = 'unset';
	@Input() public textAlign: string = 'unset';
	constructor() {}

	ngOnInit(): void {}
}
