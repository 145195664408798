<div class="graph-section">
	<div class="mGraph-wrapper">
		<div class="graphTitle">
			<div class="">
				<img [src]="plaform.icon" alt="" class="w-16px ml-5px" *ngFor="let plaform of graphData?.platforms" />
			</div>
			<div class="ml-10px">
				{{ graphTitle | titlecase }}
			</div>
		</div>

		<div class="mt-1p" *ngFor="let progressbar of graphData.graphDetails">
			<div class="text-on-progress-bar">
				<div>
					{{ progressbar.name | uppercase }}
				</div>
				<div>{{ progressbar.width }}%</div>
			</div>

			<div class="progress-container-horizontal">
				<div class="progress-horizontal" [style.width.%]="progressbar.width" [style.background]="progressbar.backgroundColor"></div>
			</div>
		</div>
	</div>
</div>
