<div class="page-container">
	<div class="form-area">
		<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<div class="title">Our appreciation to you.</div>
					<div class="sub-title">
						We truly value our customer, to show how much we value you, we are offering you an exclusive offer enhance your experiences with us.
					</div>

					<div class="box">
						<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
						<div class="detail">
							<div class="title">Our Exclusive Offer</div>
							<div class="tag">30% off next month</div>
							<div class="subtitle">Billed monthly.</div>
							<div class="tagline">We will automatically reduce your next month payment by 30%.</div>
						</div>
					</div>
				</div>
				<div class="form-btn">
					<button class="button" (click)="onCancel()">
						CANCEL
					</button>
					<button class="button left" (click)="onNext()">
						CONTINUE
					</button>
					<button class="btn-signup left" (click)="onAcceptOffer()">
						ACCEPT OFFER
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
