import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'lolly-i-tooltip',
	templateUrl: './i-tooltip.component.html',
	styleUrls: ['./i-tooltip.component.scss']
})
export class ITooltipComponent implements OnInit {
	@Input() public title: string;
	@Input() public toolTipWidth: string = '300px';
	@Input() public position: string = 'top';

	constructor() {}

	ngOnInit(): void {}
}
