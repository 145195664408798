import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PaymentLinkStatusEnum } from 'src/app/shared-components/models/payment-status.enum';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MyPlanService } from 'src/app/user-management/new-sprint/components/my-plan/my-plan.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-payment-pending',
	templateUrl: './payment-pending.component.html',
	styleUrls: ['./payment-pending.component.scss']
})
export class PaymentPendingComponent implements OnInit {
	public priceId: string = '';
	private unsubscriber$ = new Subject<void>();

	constructor(private sharedService: SharedService, private authenticationService: AuthenticationService, private router: Router) {}

	ngOnInit(): void {
		// const token = localStorage.getItem(StorageKey.token);
		if (!this.priceId) {
			this.priceId = environment.DEFAULT_PRICE_ID;
		}
	}

	public logout(): void {
		this.authenticationService.logout();
	}

	public getPaymentLink(): void {
		const token = localStorage.getItem(StorageKey.token);
		this.sharedService
			.getUserPaymentStatus()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res.status == PaymentLinkStatusEnum.SUCCESS) {
						const paymentLink = res.data;
						window.open(paymentLink, '_self');
					} else {
						this.router.navigate(['/authentication/payment'], { queryParams: { token, price_id: this.priceId } });
					}
				},
				err => {}
			);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
