<ng-container>
	<div class="simple-text-input cursor-pointer">
		<mat-form-field appearance="outline" class="outline" [floatLabel]="'never'">
			<input matInput class="search-text-2" type="text" (keyup.enter)="onSave()" [placeholder]="placeholder" [formControl]="inputControl" />
			<mat-icon matSuffix class="cursor-pointer" *ngIf="showSuffix" (click)="onSuffixClick()">
				close
			</mat-icon>
		</mat-form-field>
	</div>
</ng-container>
