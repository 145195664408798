<ng-container>
	<div class="action-button-2 cursor-pointer">
		<mat-form-field appearance="outline" class="outline" [floatLabel]="'never'" (click)="onClickInput()">
			<mat-chip-list #chipList2 class="custom-chip-auto">
				<mat-chip *ngFor="let item of chipList" (removed)="remove(item)" [disableRipple]="true">
					<div>
						{{ item }}
					</div>
					<div class="close" matChipRemove><mat-icon>close</mat-icon></div>
				</mat-chip>
				<input
					#multiChipInput
					matInput
					class="search-text-2"
					type="text"
					[placeholder]="placeholder"
					[formControl]="textControl"
					[matChipInputFor]="chipList2"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[matChipInputAddOnBlur]="false"
					(matChipInputTokenEnd)="add($event)"
				/>
			</mat-chip-list>
		</mat-form-field>
	</div>
</ng-container>
