import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { Observable, of } from 'rxjs';
import { OutreachCreatorResponseData } from '../models/outreach-creators.model';
import { objToQueryString } from 'src/app/shared/utils';
import { ContentPostUrlDataModel } from '../models/content-post-url.model';
import { IMyJobResponseModel } from '../../content-review/models/jobs.model';
import { EmailContactModel, EmailConversationResponseModel, SendCRMEmailRequestModel } from '../models/email-conversation.model';
import { OutreachMailboxModel } from '../models/outreach-mailbox.model';
import { OutreachCampaignModel } from '../models/outreach-campaign.model';
import { delay } from 'rxjs/operators';
import { PaginationResponseModel } from 'src/app/shared/models/pagination.model';

export interface OutreachStateModel {
	pageNumber?: number;
	pageSize?: number;
	selectedCampaign?: any;
	selectedCampaignId?: number;
	selectedJob?: any;
	selectedFilter?: string;
	selectedStage?: string;
}

@Injectable({
	providedIn: 'root'
})
export class OutreachService {
	public outreachState: OutreachStateModel = {
		pageNumber: 0,
		pageSize: 10,
		selectedFilter: 'in-campaign',
		selectedStage: 'outreach'
	};

	constructor(private http: HttpClient) {}

	public clearState(): void {
		this.outreachState = {
			pageNumber: 0,
			pageSize: 10,
			selectedFilter: 'in-campaign',
			selectedStage: 'outreach'
		};
	}

	/**************************
	 * * OUTREACH MAIN * *
	 ***************************/

	public getAllCampaigns(queryParams?: HttpParams): Observable<{ campaigns: OutreachCampaignModel[] }> {
		return this.http.get<{ campaigns: OutreachCampaignModel[] }>(`${BaseApiUrl.SocialMessengerPython}crm/campaigns`, { params: queryParams });
	}

	public getAllJobsOfACampaign(campaignId: number): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}crm/campaigns/${campaignId}/jobs`);
	}

	public getStages(queryParams: HttpParams): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}applications/stats`, { params: queryParams });
	}

	public getOutreachCreators(queryParams: HttpParams): Observable<OutreachCreatorResponseData> {
		return this.http.get<OutreachCreatorResponseData>(`${BaseApiUrl.SocialMessengerPython}applications`, { params: queryParams });
	}

	public moveApplications(payload: any): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}applications`, payload);
	}

	public sendReminderEmail(payload: any): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}approval/reminder`, payload);
	}

	public reviveCreator(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}applications`, payload);
	}

	public deleteCreators(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}applications`, payload);
	}

	public reactivateSequence(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}sequences/reactivate`, payload);
	}

	public createNote(application_id: number, payload: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}crm/${application_id}/note`, payload);
	}

	public updateNote(application_id: number, payload: any): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}crm/${application_id}/note`, payload);
	}

	public addContract(application_id: number, formData: FormData): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.SocialMessengerPython}crm/${application_id}/contracts`, formData);
	}

	public deleteContract(application_id: number): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.SocialMessengerPython}crm/${application_id}/contracts`);
	}

	public sendEmail(formData: FormData): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}sendmail/bulk`, formData);
	}

	public sendCreatorRequest(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}applications/action`, payload);
	}

	public getJobById(jobId: number): Observable<IMyJobResponseModel> {
		return this.http.get<IMyJobResponseModel>(`${BaseApiUrl.SocialMessengerPython}my-jobs/${jobId}`);
	}

	public getContentUrls(jobId: number, platform: string): Observable<{ data: ContentPostUrlDataModel }> {
		return this.http.get<{ data: ContentPostUrlDataModel }>(`${BaseApiUrl.SocialMessengerPython}completion/${jobId}/uploaded/${platform}`);
	}

	public updatePostUrlDetails(jobId: number, payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}crm/${jobId}/post-url-update`, payload);
	}

	/***************************
	 * * AUTOMATION  * *
	 ***************************/
	public getAutomationStatus(data): Observable<any> {
		const queryString = objToQueryString(data);
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}crm/status${queryString}`);

		return this.http.get(url.href);
	}

	public getEmailFilters(campaign_id: number, job_id: number): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}crm/to_user_list/${campaign_id}`);

		return this.http.get(url.href);
	}

	public getOwnerFilters(campaign_id: number, job_id: number): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}crm/from_user_list/${campaign_id}`);

		return this.http.get(url.href);
	}

	public getAllAutomationEmails(campaign_id: number, job_id: number, data): Observable<any> {
		const queryString = objToQueryString(data);
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}crm/automation/${campaign_id}/emails${queryString}`);

		return this.http.get(url.href);
	}

	public deleteAutomation(data): Observable<any> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};

		return this.http.delete(`${BaseApiUrl.SocialMessengerPython}emails/`, options);
	}

	public skipAutomation(data): Observable<any> {
		let payload = data;
		return this.http.post(`${BaseApiUrl.SocialMessengerPython}emails/skip`, payload);
	}

	public exportAutomation(campaignId, data): Observable<any> {
		return this.http.post(`${BaseApiUrl.SocialMessengerPython}crm/export?campaign_id=${campaignId}`, data, { responseType: 'blob' });
	}

	public templateStatus(id, data): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}templates/${id}`);

		return this.http.post(url.href, data);
	}

	public getOwnersList(): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}subusers`);

		return this.http.get(url.href);
	}

	public getConnectedEmails(): Observable<{ data: OutreachMailboxModel[] }> {
		return this.http.get<{ data: OutreachMailboxModel[] }>(`${BaseApiUrl.SocialMessengerPython}emails`);
	}

	// ! APIs for EMAIL Conversation
	public getEmailContacts(queryParams: HttpParams): Observable<{ data: EmailContactModel[]; pagination: PaginationResponseModel }> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}email-conversation`);
		return this.http.get<{ data: EmailContactModel[]; pagination: PaginationResponseModel }>(url.href, { params: queryParams });
	}

	public getEmailConversationList(queryParams: HttpParams): Observable<{ data: EmailConversationResponseModel }> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}email-conversation/details`);
		return this.http.get<{ data: EmailConversationResponseModel }>(url.href, { params: queryParams });
	}

	public sendCRMEmail(payload: SendCRMEmailRequestModel): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}email-conversation/send`);
		return this.http.post<any>(url.href, payload);
	}
}
