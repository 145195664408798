<div class="container">
	<div class="left-dock">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="right-dock">
		<app-lolly-spinning-loader [hidden]="!loading"></app-lolly-spinning-loader>
		<div [hidden]="loading" class="wrapper">
			<div class="logo">
				<img src="assets/logo/Brand_logo.svg" />
			</div>
			<div class="welcome-text">{{ 'Welcome Back' }}</div>
			<div class="d-flex w-100 k-align-items-center k-justify-content-center">
				<div class="continue-btn-wrapper">
					<div id="google-btn"></div>
				</div>
			</div>
			<div class="rule-a-text">
				<hr />
				<div class="text">{{ 'Or Login with your email' }}</div>
			</div>
			<div class="full-text-input" (keyup.enter)="onSubmit()">
				<div class="label">{{ 'Email Address' }}</div>
				<app-input-field
					[showValidIcon]="true"
					[hideError]="false"
					[errorMessage]="'Please enter a valid email'"
					class="title-input"
					[placeHolderTextBox]="'Enter your email'"
					[inputControl]="emFormControl"
				></app-input-field>
			</div>
			<div class="full-text-input l-margin" (keyup.enter)="onSubmit()">
				<div class="label">{{ 'Password' }}</div>
				<app-input-field-password
					[showValidIcon]="true"
					[hideError]="false"
					[errorMessage]="'Please enter a password'"
					class="title-input"
					[placeHolderTextBox]="'Enter your password'"
					[inputControl]="passFormControl"
				></app-input-field-password>
			</div>
			<div class="margin-bottom-spacing-ps l-margin">
				<a (click)="onForgotPasswordClick()" class="link password_forgot">Forgot password?</a>
			</div>
			<div (click)="onSubmit()" class="signin-btn-wrapper">
				<div class="btn">
					<app-primary-button [buttonLable]="'Login'" [buttonType]="'Secondary'"></app-primary-button>
				</div>
			</div>
			<div class="bottom-text-action">
				<div class="dark">{{ "Don't have an account?" }}</div>
				<div (click)="onSubscribeToAnOffer()" class="theme">{{ 'Signup' }}</div>
			</div>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
