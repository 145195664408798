import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
	@Input() public disabled: boolean;
	@Output() valueChanges = new EventEmitter<boolean>();

	@Input() public value = false;
	constructor() {}

	ngOnInit(): void {}
	public toggle(): void {
		this.valueChanges.emit(this.value);
	}
}
