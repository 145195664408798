import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-select-drp-with-custom-search',
	templateUrl: './select-drp-with-custom-search.component.html',
	styleUrls: ['./select-drp-with-custom-search.component.scss']
})
export class SelectDrpWithCustomSearchComponent implements OnInit {
	@Input() list: any[] = [];
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl;
	@Input() searchControl: UntypedFormControl;
	@Input('disabled') public disabled: boolean = false;
	@Output() emitChange: EventEmitter<any> = new EventEmitter<any>();
	public filteredList: any[] = [];
	public unsubscriber$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		this.filteredList = this.list;
	}

	public onSearch(value): void {
		this.filteredList = value ? this.search(value) : [...this.list];
	}

	public resetList(event): void {
		this.searchControl.patchValue(null);
		this.filteredList = [...this.list];

		const index = this.filteredList.findIndex(i => i.id == this.inputControl.value.id);
		const selected = this.filteredList.splice(index, 1);
		this.filteredList.unshift(selected[0]);
	}

	private search(value: string): any[] {
		let filter = value.toLowerCase();
		return this.list.filter(option => option.name.toLowerCase().startsWith(filter));
	}

	public onSelectionChange(): void {
		this.emitChange.emit(this.inputControl.value);
	}
}
