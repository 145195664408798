import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';

@Component({
	selector: 'app-google-account-landing',
	templateUrl: './google-account-landing.component.html',
	styleUrls: ['./google-account-landing.component.scss']
})
export class GoogleAccountLandingComponent implements OnInit {
	private token?: any;

	constructor(private router: Router, private store: Store<AuthenticationState>) {
		this.token = router.getCurrentNavigation()?.extras?.state?.token;
	}

	ngOnInit(): void {
		if (!this.token) {
			this.router.navigate(['']);
		} else {
			this.store.dispatch(new LogInSuccess(this.token));
		}
	}
}
