<div class="d-flex d-j-c-flex-column d-j-c-flex-between h-100p">
	<div>
		<div class="d-flex justify-content-end">
			<mat-icon mat-dialog-close class="cursor-pointer">
				close
			</mat-icon>
		</div>
		<div class="d-flex d-j-c-flex-column d-j-c-center d-align-items-c t-align-c pay-details">
			<div class="pay-icon">
				<img src="assets/icons/Money_Tag.svg" />
			</div>
			<filed-font [fontSize]="'18px'">Are you sure you want to pay ${{ data.amount }} to {{ data.InfluencerName }}?</filed-font>

			<filed-font [fontSize]="'14px'" [fontWeight]="'400'">
				If you are happy with your influencer’s post, check your payment
				<br />
				details and confirm payment.
			</filed-font>
		</div>
		<div>
			<div class="table-header">
				<div class="cards">
					<filed-font [fontSize]="'14px'" [color]="'#2585fe'">Your Credit Cards</filed-font>
				</div>
				<div class="d-flex">
					<div class="name">
						<filed-font [fontSize]="'14px'">Name on card</filed-font>
					</div>
					<div class="expiry">
						<filed-font [fontSize]="'14px'">Expiry</filed-font>
					</div>
				</div>
			</div>
			<ng-container *ngIf="!loading">
				<ng-container *ngIf="cardDetails?.length != 0">
					<div *ngFor="let card of cardDetails">
						<div
							class="table-data cursor-pointer"
							[class.active-class]="paymentCard?.cardLastFourDigits == card.cardLastFourDigits && paymentCard?.cardType == card.cardType"
							[class.processing]="isprocessing"
							(click)="changeDefaultPaymentCard(card)"
						>
							<div class="left-card">
								<span class="check">
									<img
										src="assets/icons/Listing-Icon.svg"
										*ngIf="paymentCard?.cardLastFourDigits !== card.cardLastFourDigits || paymentCard?.cardType !== card.cardType"
									/>
									<img
										src="assets/icons/fullfilled.png"
										*ngIf="paymentCard?.cardLastFourDigits == card.cardLastFourDigits && paymentCard?.cardType == card.cardType"
									/>
								</span>
								<span class="card ml-10px">
									<img class="visa" src="assets/icons/Visa.svg" *ngIf="card.cardType.toLowerCase() == 'visa'" />
									<img class="mastercard" src="assets/icons/Master.svg" *ngIf="card.cardType.toLowerCase() == 'mastercard'" />
								</span>
								<span class="number ml-10px">
									<strong>{{ card.cardType }} {{ card.funding }} Card</strong>
									/ {{ card.cardLastFourDigits }}
								</span>
							</div>

							<div class="right-card">
								<span class="name">{{ userDetail.user_firstname }}</span>
								<span
									class="status"
									*ngIf="defaultCard?.cardLastFourDigits == card.cardLastFourDigits && defaultCard?.cardType == card.cardType"
									[class.default-card]="card.isDefault"
								>
									Default
								</span>
								<span
									class="status"
									*ngIf="defaultCard?.cardLastFourDigits !== card.cardLastFourDigits || defaultCard?.cardType !== card.cardType"
									[class.default-card]="card.isDefault"
								></span>
								<span class="expiry">{{ card.expirationMonth }}/{{ card.expirationYear }}</span>
							</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="cardDetails?.length == 0">
					<div class="d-flex d-j-c-center d-align-items-c">
						<filed-font [fontSize]="'18px'">No Cards Added</filed-font>
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="loading">
				<app-lolly-spinning-loader></app-lolly-spinning-loader>
			</ng-container>
		</div>
	</div>
	<div class="d-flex justify-content-between">
		<btn15 mat-dialog-close>Cancel</btn15>
		<div>
			<app-primary-button
				[buttonLable]="'Add New Card'"
				[buttonType]="'Secondary'"
				[isPeddingReduceSmall]="true"
				(buttonClicked)="gotoBiling()"
			></app-primary-button>

			<app-primary-button
				[buttonLable]="'Confirm'"
				[buttonType]="'Primary'"
				[isPeddingReduceSmall]="true"
				(buttonClicked)="pay()"
				[isDisabled]="cardDetails?.length == 0"
			></app-primary-button>
		</div>
	</div>
</div>
