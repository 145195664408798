<ng-container>
	<div class="action-button {{ disabled ? 'disabled' : 'cursor-pointer' }}">
		<mat-form-field
			[matMenuTriggerFor]="selectMenu2"
			#selectMenuTrigger2="matMenuTrigger"
			appearance="outline"
			(menuClosed)="onMenuClosed()"
			class="outline {{ disabled ? 'disabled-mat' : '' }}"
			[floatLabel]="'never'"
		>
			<input matInput class="search-text {{ disabled ? 'light' : '' }}" type="text" [placeholder]="placeholder" [formControl]="searchControl" />
		</mat-form-field>
	</div>

	<mat-menu #selectMenu2="matMenu" class="drp-menu" [ngStyle]="styleMenu()">
		<div class="menu-list-item-wrapper" (click)="$event.stopPropagation()" *ngFor="let outerItem of filteredList; let outerItemSequence = index">
			<mat-checkbox
				id="outer-{{ outerItemSequence }}"
				class="custom-checkbox-black"
				type="checkbox"
				style="padding-inline: 10px;"
				(click)="$event.preventDefault(); onOuterItemClick(outerItem)"
				[checked]="areAllInnerItemsOfOuterSelected(outerItem)"
				[indeterminate]="!areAllInnerItemsOfOuterSelected(outerItem) && isAnyInnerItemOfOuterSelected(outerItem)"
				[labelPosition]="'before'"
			>
				<span class="search-drp-name label w-100" matTooltip="{{ outerItem?.parentName }}">
					{{ outerItem?.parentName }}
				</span>
			</mat-checkbox>
			<ng-container *ngFor="let innerItem of outerItem.list; let innerSequence = index">
				<div class="menu-list-item">
					<mat-checkbox
						id="inner-{{ outerItemSequence }}-item-{{ innerSequence }}"
						class="custom-checkbox-black"
						type="checkbox"
						(click)="$event.preventDefault(); onInnerItemClick(innerItem)"
						[checked]="isSelected(innerItem)"
						[labelPosition]="'before'"
					>
						<span class="search-drp-name w-100" matTooltip="{{ innerItem }}">
							{{ innerItem }}
						</span>
					</mat-checkbox>
				</div>
			</ng-container>
		</div>
	</mat-menu>
</ng-container>
