<div class="form-input" id="input-form-component">
	<kendo-formfield>
		<span class="text-wrapper">
			<kendo-textbox
				#password
				[placeholder]="placeHolderTextBox"
				[disabled]="disabled"
				[readonly]="readOnly"
				[formControl]="inputControl"
				[showSuccessIcon]="inputControl.valid"
				[showErrorIcon]="'initial'"
				[style.width]="'100%'"
				[maxlength]="maxLength"
			>
				<ng-template *ngIf="showCustomIcon" kendoTextBoxSuffixTemplate>
					<img [ngStyle]="showIconStyle()" [src]="showCustomIcon" />
				</ng-template>
				<ng-template *ngIf="inputControl.valid && showValidIcon" kendoTextBoxSuffixTemplate></ng-template>
			</kendo-textbox>
			<span class="inner-span" *ngIf="showControl">
				<mat-icon class="eye-icon" (click)="toggleType()" *ngIf="showPass">visibility</mat-icon>
				<mat-icon class="eye-icon" (click)="toggleType()" *ngIf="!showPass">visibility_off</mat-icon>
			</span>
		</span>
		<kendo-formerror *ngIf="!hideError">{{ errorMessage }}</kendo-formerror>
	</kendo-formfield>
</div>
