import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-video-popup',
	templateUrl: './video-popup.component.html',
	styleUrls: ['./video-popup.component.scss']
})
export class VideoPopupComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<VideoPopupComponent>) {}

	ngOnInit(): void {}
}
