<button
	(click)="buttonClick()"
	[ngClass]="{
		primary_button: buttonType === 'Primary',
		secondary_button: buttonType === 'Secondary',
		tertiary_button: buttonType === 'Tertiary',
		disable_button: isDisabled,
		reduce_pedding: isPeddingReduce,
		reduce_pedding_small: isPeddingReduceSmall
	}"
	[class]="styleClass"
	[disabled]="isDisabled"
>
	{{ buttonLable }}
</button>
