import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-website-input',
	templateUrl: './website-input.component.html',
	styleUrls: ['./website-input.component.scss']
})
export class WebsiteInputComponent implements OnInit {
	@Input() public inputControl: UntypedFormControl;

	constructor() {}

	ngOnInit(): void {}

	public onPaste(event: ClipboardEvent): void {
		event.preventDefault();
		const pastedText = event.clipboardData?.getData('text') || '';
		const sanitizedText = pastedText.replace(/^(https?:\/\/)/, '');
		this.inputControl.patchValue(sanitizedText);
	}
}
