<div class="grid-section">
	<div class="form-area">
		<div class="container">
			<app-lolly-spinning-loader class="spinner" *ngIf="submitting"></app-lolly-spinning-loader>
			<div *ngIf="!submitting">
				<!-- <div class="sub-title left back">
					<a [routerLink]="'/authentication/dexter-welcome'" class="signin">
						<i class="k-icon k-i-arrow-chevron-left"></i>
						Back
					</a>
				</div> -->
				<div class="logo"><img width="30%" alt="" src="assets/images/signup/sign-up.png" /></div>

				<div class="title">Create your password</div>
				<div class="form">
					<div class="margin-bottom-spacing">
						<app-lolly-passwordoutline-tb
							(enterPress)="continueSignup()"
							[placeHolderTextBox]="'Enter your password'"
							name="password"
							[control]="passwordControl"
							class="input-box"
							pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$"
							[validColor]="validColor"
							(focus)="(resetLabel)"
						></app-lolly-passwordoutline-tb>
					</div>

					<app-password-strength
						[passwordToCheck]="passwordControl.value"
						(passwordStrength)="passwordValid($event)"
						(passwordStepMarker)="passwordStepMarker($event)"
					></app-password-strength>
				</div>

				<div class="form strength-meter">
					<div class="sub-title">Your password must contain the following:</div>
					<div class="indicator-remark">
						<div class="indicator-remark-label">
							<span>
								<img src="assets/icons/Listing-Icon.svg" *ngIf="!minimumChar && !allValidated" />
								<img src="assets/icons/fullfilled.png" *ngIf="minimumChar && !allValidated" />
								<img src="assets/icons/verified.png" *ngIf="minimumChar && allValidated" />
							</span>
							<span class="label">Minimum 8 characters</span>
						</div>
						<div class="indicator-remark-label">
							<span>
								<img src="assets/icons/Listing-Icon.svg" *ngIf="!upperChar && !allValidated" />
								<img src="assets/icons/fullfilled.png" *ngIf="upperChar && !allValidated" />
								<img src="assets/icons/verified.png" *ngIf="upperChar && allValidated" />
							</span>
							<span class="label">Contain 1 Capital Letter</span>
						</div>
					</div>
					<div class="indicator-remark">
						<div class="indicator-remark-label">
							<span>
								<img src="assets/icons/Listing-Icon.svg" *ngIf="!numberChar && !allValidated" />
								<img src="assets/icons/fullfilled.png" *ngIf="numberChar && !allValidated" />
								<img src="assets/icons/verified.png" *ngIf="numberChar && allValidated" />
							</span>
							<span class="label">Contain 1 number</span>
						</div>
						<div class="indicator-remark-label">
							<span>
								<img src="assets/icons/Listing-Icon.svg" *ngIf="!specialChar && !allValidated" />
								<img src="assets/icons/fullfilled.png" *ngIf="specialChar && !allValidated" />
								<img src="assets/icons/verified.png" *ngIf="specialChar && allValidated" />
							</span>
							<span class="label">Contain 1 Special Letter</span>
						</div>
					</div>
				</div>
				<div class="form-btn">
					<app-primary-button
						[buttonLable]="'Continue'"
						[buttonType]="'Primary'"
						[isPeddingReduceSmall]="true"
						*ngIf="passwordControl.invalid"
						[isDisabled]="passwordControl.invalid"
					></app-primary-button>

					<app-primary-button
						[buttonLable]="'Continue'"
						[buttonType]="'Primary'"
						[isPeddingReduceSmall]="true"
						(buttonClicked)="continueSignup()"
						*ngIf="passwordControl.valid"
						[isDisabled]="passwordControl.invalid"
					></app-primary-button>
				</div>
				<div class="footer-link">
					Got a question?
					<a href="https://www.filed.com/contact-us-filed/">Don't worry. We here to help you</a>
				</div>
			</div>
		</div>
	</div>
</div>
<app-toast-notification></app-toast-notification>
