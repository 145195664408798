import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { AuthenticationState, getResetPasswordDialogConstants } from '../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FieldValidators } from '../../user-management/models/validators';

@Component({
	selector: 'app-reset-password-with-email',
	templateUrl: './reset-password-with-email.component.html',
	styleUrls: ['./reset-password-with-email.component.scss']
})
export class ResetPasswordWithEmailComponent implements OnInit {
	public resetEmailFormGroup: UntypedFormGroup;
	public validationMessages: ValidatorMessages[];
	public switchDivSection: string;
	public resetEmailInput: string;
	public loader: boolean;

	private unsubscriber$: Subject<void> = new Subject<void>();
	constructor(
		private toastNotificationService: ToastNotificationService,
		private userServiceApi: UserServiceApi,
		private formBuilder: UntypedFormBuilder,
		private authStore: Store<AuthenticationState>
	) {}
	public ngOnInit(): void {
		this.authStore.pipe(select(getResetPasswordDialogConstants), take(1)).subscribe(messages => {
			this.validationMessages = messages;
		});
		this.resetEmailFormGroup = this.formBuilder.group({
			resetEmailFormControl: new UntypedFormControl('', [Validators.required, Validators.pattern(FieldValidators.Email)])
		});
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	public onSubmit(): void {
		this.resetEmailFormGroup.markAllAsTouched();
		if (this.resetEmailFormGroup.valid) {
			this.userServiceApi
				.newResetPassword(this.resetEmailFormGroup.get('resetEmailFormControl').value)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					res => {
						if (res?.status != false) {
							this.toastNotificationService.sendSuccessToast('Check your email ');
							this.resetEmailInput = this.resetEmailFormGroup.value.resetEmailFormControl;
							this.goTo('resendEmailVerification');
							this.resetEmailFormGroup.reset();
						} else {
							this.toastNotificationService.sendErrorToast('No such email address found. Please contact us for more details.');
						}
					},
					err => {
						this.toastNotificationService.sendErrorToast('No email address found. Please contact admin for more details');
					}
				);
		}
	}
	public goTo(switchDivSection: string): void {
		this.switchDivSection = switchDivSection;
	}
	private resendOnsubmit(): void {
		this.userServiceApi
			.newResetPassword(this.resetEmailInput)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.toastNotificationService.sendSuccessToast('Your email was Successfully sent again');
				this.goTo('resendEmailVerification');
			});
	}
}
