<div class="container">
	<div class="body">
		<div class="email-integration">
			<div class="title">
				{{ 'Your Account has been Successfully Connected' }}
			</div>
			<button class="add-acc-btn" (click)="refreshToken()" mat-button>
				<span style="text-transform: capitalize; font-family: 'Gilroy';">Continue</span>
			</button>
		</div>
	</div>
</div>
