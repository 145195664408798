import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { result } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
	selector: 'app-dropdown-with-input',
	templateUrl: './dropdown-with-input.component.html',
	styleUrls: ['./dropdown-with-input.component.scss']
})
export class DropdownWithInputComponent implements OnInit {
	@Input('dropdownLabel') public dropdownLabel: string;
	@Input('inputControl') public inputControl: UntypedFormControl;
	@Input('inputPlaceholder') public inputPlaceholder: string;
	@Input() public styleClass: string;
	@Input() public cssStyle: string;
	@Input('data') public data: any[];

	@Output() selectionChange = new EventEmitter<{ name: string; id: number }>();

	filteredOptions: Observable<any[]>;
	prompt = 'Add "';
	showAddButton: boolean = false;
	results = [];

	constructor() {}

	ngOnInit(): void {
		this.filteredOptions = this.inputControl.valueChanges.pipe(
			startWith(''),
			map(value => (value ? this._filter(value) : this.data.slice()))
		);
		// this.inputControl.valueChanges.subscribe(res => {
		//   this.data = this.data.filter(ele => {
		//     if (ele.name.includes(res)) {
		//       return ele;
		//     }
		//   });
		//   console.log(this.data)
		// });
	}

	private _filter(value: any): { name: string; id: number }[] {
		if (value.name !== undefined) {
			this.results = this.data.filter(item => item.name?.toLowerCase().indexOf(value.name.toLowerCase()) === 0);
			if (this.results.length === 0) {
				this.results = [{ name: this.prompt + value.name + '"', id: this.data.length + 1 }];
			}
		} else {
			this.results = this.data.filter(item => item.name?.toLowerCase().indexOf(value.toLowerCase()) === 0);
			if (this.results.length === 0) {
				this.results = [{ name: this.prompt + value + '"', id: this.data.length + 1 }];
			}
		}

		return this.results;
	}

	addOption($event) {
		let option;
		option = this.removePromptFromOption(this.inputControl.value);
		if (!this.data.some(entry => entry?.name === option)) {
			const index = this.data.push(option) - 1;
			this.inputControl.setValue(this.data[index]);
		}
	}

	removePromptFromOption(option) {
		if (option.name.startsWith(this.prompt)) {
			option = option.name.substring(this.prompt.length, option.name.length - 1);
		}
		return option;
	}

	optionSelected($event) {
		// if ($event.option.value.indexOf(this.prompt) === 0) {
		// 	this.addOption($event);
		// }
		console.log(this.data.map(e => e.name).indexOf($event.option.value));

		// else {
		//   this.inputControl.setValue(option);
		// }
	}
}
