import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-custom-calendar-date-picker',
	templateUrl: './custom-calendar-date-picker.component.html',
	styleUrls: ['./custom-calendar-date-picker.component.scss']
})
export class CustomCalendarDatePickerComponent implements OnInit {
	@Output() onChoosedDate = new EventEmitter<any>();
	@Input() control: FormControl;
	@Input() minDate: string;

	constructor() {}

	ngOnInit(): void {}

	choseDate(event): void {
		const selectedDate = event.chosenLabel;
		this.control.setValue(selectedDate);
		if (this.onChoosedDate) {
			this.onChoosedDate.emit(selectedDate);
		}
	}
}
