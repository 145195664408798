import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { EmailRegisterComponent } from './new-signup/email-register/email-register.component';
import { EmailVerificationComponent } from './new-signup/email-verification/email-verification.component';
import { PasswordComponent } from './new-signup/password/password.component';
import { PaymentConfirmation } from './new-signup/payment-confirmation/payment-confirmation.component';
import { PaymentPageComponent } from './new-signup/payment-page/payment-page.component';
import { AuthenticationRouteGuard } from '../shared/guards/authentication-route.guard';
import { ResetPasswordWithEmailComponent } from './reset-password-with-email/reset-password-with-email.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { NewSignupWelcomePopupComponent } from './new-signup/new-signup-welcome-popup/new-signup-welcome-popup.component';
import { UserBlockGuard } from '../shared/guards/user-block.guard';
import { SubUserRegisterComponent } from './sub-user-register/sub-user-register.component';
import { EmailIntegrationsLoadingComponent } from '../user-management/new-sprint/components/email-integrations/email-integrations-loading/email-integrations-loading.component';
import { ShopifyIntegrationLoadingComponent } from './shopify-integration-loading/shopify-integration-loading.component';
import { BillingPlansV2Component } from './billing-plans-v2/billing-plans-v2.component';
import { AuthPlansGuard } from '../shared/guards/auth-plans.guard';
import { PaymentPendingComponent } from './new-signup/payment-pending/payment-pending.component';
import { VerifyTokenComponent } from './verify-token/verify-token.component';
import { NewVerifyEmailComponent } from './new-signup/components/new-verify-email/new-verify-email.component';

export const routes: Routes = [
	{ path: '', component: SigninComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'register-email', component: EmailRegisterComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'verification', component: NewVerifyEmailComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'reset-password-with-email', component: ResetPasswordWithEmailComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'billing-plans', component: BillingPlansV2Component, canActivate: [AuthPlansGuard] },
	{ path: 'forgot-password', component: SetPasswordComponent },
	{ path: 'verify-email', component: EmailVerificationComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'register-password', component: PasswordComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'set-first-password', component: PasswordComponent },
	{ path: 'payment', component: PaymentPageComponent },
	{ path: 'verify-token', component: VerifyTokenComponent },
	{ path: 'payment-pending', component: PaymentPendingComponent },
	{ path: 'payment-confirmation', component: PaymentConfirmation, canActivate: [AuthenticationRouteGuard] },
	{ path: 'connect-channel', component: NewSignupWelcomePopupComponent, canActivate: [UserBlockGuard] },
	{ path: 'payment-success', component: PaymentConfirmation, data: { paymentSuccess: true } },
	{ path: 'payment-failure', component: PaymentConfirmation, data: { paymentSuccess: false } },
	{ path: 'register-sub-user', component: SubUserRegisterComponent },
	{ path: 'email-integration', component: EmailIntegrationsLoadingComponent, data: { success: true } },
	{ path: 'email-integration-failure', component: EmailIntegrationsLoadingComponent, data: { success: false } },
	{ path: 'shopify-integration', component: ShopifyIntegrationLoadingComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
