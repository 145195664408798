import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { ConnectToGoogleRequest } from '../../authentication/_models/ConnectToGoogleRequest';
import { data } from 'jquery';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
// import { AdAccount } from 'src/app/accounts/models/ad-account.model';//silsrang-migration

@Injectable()
export class GoogleService {
	private readonly baseURL = BaseApiUrl.GoogleAccounts;
	public selectedGoogleAccount: BehaviorSubject<any> = new BehaviorSubject(null); //silsrang-migration
	public allGoogleAdAccounts: Map<SourceChannel, any[]> = new Map<SourceChannel, any[]>(); //silsrang-migration

	public constructor(private http: HttpClient) {}

	public connectToGoogleAccount(request: ConnectToGoogleRequest): Observable<void> {
		return this.http.post<void>(`${this.baseURL}managed-customer/connect-to-google-account`, request);
	}
	public connectToGoogleUserAccount(redirectUrl: string): Observable<string> {
		return this.http.post<string>(`${BaseApiUrl.GoogleAccountsMain}user`, {
			redirectUrl: redirectUrl
		});
	}
	public checkGoogleStatus(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.GoogleAccountsMain}user`);
	}
	public checkGoogleStatusFromSignin(auth: string): Observable<{ status: boolean }> {
		const header = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${auth}`
		});
		return this.http.get<{ status: boolean }>(`${BaseApiUrl.GoogleAccountsMain}user/is-connected`, { headers: header });
	}
	public getListGoogleAds(): Observable<any> {
		return this.http.get(`${BaseApiUrl.GoogleAccountsMain}accounts?uncached=False`);
	}
	public listGoogleAds(uncached: string = 'False'): Observable<HttpResponse<any>> {
		// return this.http.get<any>(`https://4718-2405-201-4022-a0cf-dcba-f49b-182a-e743.ngrok.io/api/v1/accounts?uncached=True`, { observe: 'response' });
		return this.http.get(`${BaseApiUrl.GoogleAccountsMain}accounts?uncached=${uncached}`, { observe: 'response' });
	}
	public trackGoogleAds(data: any): Observable<HttpResponse<any>> {
		return this.http.post(`${BaseApiUrl.GoogleAccountsMain}accounts`, data, { observe: 'response' });
	}

	public googleSignIn(idToken: string): Observable<any> {
		const payload = {
			gtoken: idToken
		};
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}users/sign-in`, payload);
	}

	public googleSignup(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}users/sign-up`, payload);
	}
}
