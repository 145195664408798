import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { DiscountDataModel } from '../create-product-seeding-step-three/create-product-seeding-step-three.component';

@Component({
	selector: 'app-campaign-creation-product-seeding',
	templateUrl: './campaign-creation-product-seeding.component.html',
	styleUrls: ['./campaign-creation-product-seeding.component.scss']
})
export class CampaignCreationProductSeedingComponent implements OnInit {
	@Input() public discountCoupons: DiscountDataModel[] = [];
	@Input() public currentProductSeedingStep: number;
	@Input() public productSeedingForm: FormGroup;
	@Output() public onBackPressed = new EventEmitter<void>();
	@Output() public onNextPressed = new EventEmitter<void>();

	public loading: boolean = false;
	public isCurrentStepValid: boolean = false;
	private unsubscriber$ = new Subject<void>();

	constructor(private toastNotificationService: ToastNotificationService) {}

	ngOnInit(): void {}

	public onNext(): void {
		if (this.isCurrentStepValid) {
			if (this.currentProductSeedingStep == 2) {
				this.onNextPressed.emit();
				return;
			}
			this.currentProductSeedingStep++;
		} else {
			this.productSeedingForm.markAllAsTouched();
			if (this.currentProductSeedingStep == 0) {
				if (this.productSeedingForm.get('shop')?.value) {
					// * This condition means shop not integrated
					this.toastNotificationService.sendInfoToast('Please wait while we validate the shop integration');
				} else {
					// * This condition means shop not selected
					this.toastNotificationService.sendInfoToast('Please select a shop to move further');
				}
			}
			if (this.currentProductSeedingStep == 1) {
				this.toastNotificationService.sendInfoToast('Please select at least one product to move further');
			}
		}
	}

	public onBack(): void {
		if (this.currentProductSeedingStep == 0) {
			this.onBackPressed.emit();
			return;
		}
		this.currentProductSeedingStep--;
	}

	public checkStepValid(isValid: boolean): void {
		this.isCurrentStepValid = isValid;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
