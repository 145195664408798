import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-tickets-view',
	templateUrl: './tickets-view.component.html',
	styleUrls: ['./tickets-view.component.scss']
})
export class TicketsViewComponent implements OnInit {
	public todayDate: Date = new Date();

	constructor(public router: Router) {}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {}
}
