import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { InstagramAccount } from '../../campaign-builder/models/instagram-account.model';
import { BaseApiUrl } from '../base-api-urls';
// import { InstagramPagesInterface } from '../../shared/smart-create-edit-components/step-two-components/pages-card/instagram-pages.interface';

@Injectable()
export class InstagramService {
	constructor(private http: HttpClient) {}

	public getInstagramAccounts(adAccountId: string): Observable<any[]> {
		//silsrang-migration
		return this.http.get<any[]>(`${BaseApiUrl.GoogleTuring}instagram-accounts/${adAccountId}`); //silsrang-migration
	}

	public getInstagramPages(facebookId: string): Observable<any[]> {
		//silsrang-migration
		return this.http.get<any[]>(`${BaseApiUrl.GoogleTuring}page-instagram-account/${facebookId}`); //silsrang-migration
	}
}
