<div class="super-con" *ngIf="!showAllPlans">
	<!-- !overview -->
	<div class="container-2 card">
		<div>
			<div class="body">
				<div class="body-form">
					<div class="container-with-bottons">
						<div class="header-text">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 24px; width: 160px;"
								[theme]="{ height: '24px', width: '160px' }"
								*ngIf="loading"
							></ngx-skeleton-loader>
							<ng-container *ngIf="!loading">
								<ng-container *ngIf="!isTrialPeriodActive">
									<b>{{ planData?.name | titlecase }} | Per {{ planData?.price_interval | titlecase }}</b>
								</ng-container>
								<ng-container *ngIf="isTrialPeriodActive">
									Free Trial
								</ng-container>
							</ng-container>
						</div>
						<div class="action-button">
							<!-- *skeleton loader -->
							<ngx-skeleton-loader
								appearance="line"
								style="height: 40px; width: 240px;"
								[theme]="{ height: '40px', width: '240px' }"
								*ngIf="loading"
							></ngx-skeleton-loader>

							<ul class="menus" *ngIf="!loading">
								<li>
									<a *ngIf="!planData?.billing_object?.url" class="btn">
										<ng-container *ngIf="!isTrialPeriodActive">
											<b>Next Payment:</b>
										</ng-container>
										<ng-container *ngIf="isTrialPeriodActive">
											<b>Trial Ends:</b>
										</ng-container>
										{{ planData?.current_period_end | date: 'longDate' }}
									</a>
									<a *ngIf="planData?.billing_object?.url" class="btn btn-danger">
										<b>Payment Failed</b>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="PlanCharged">
					<!-- *skeleton loader -->
					<ngx-skeleton-loader
						appearance="line"
						style="height: 50px; width: 200px;"
						[theme]="{ height: '50px', width: '200px' }"
						*ngIf="loading"
					></ngx-skeleton-loader>

					<ng-container *ngIf="!loading">
						{{ (planData?.price_interval == 'yearly' ? planData?.price / 12 : planData?.price) | currency }}
						<span class="perMonthText">/m</span>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<!-- !payment details -->
	<div class="credit-cards payment-history card">
		<span>Payment Details</span>
		<div class="btn-closure {{ false ? 'tooltip' : '' }}">
			<button class="secondary-btn {{ isSubUser && planData?.role != 'admin' ? 'disabled' : '' }}" (click)="reviewHistory()">Edit</button>
			<!-- <div class="tooltiptext">{{ 'This action can only be performed by Admin' }}</div> -->
		</div>
	</div>

	<!-- !payment history -->
	<div class="payment-history card">
		<span>Payment History</span>
		<div class="btn-closure {{ false ? 'tooltip' : '' }}">
			<button class="secondary-btn {{ isSubUser && planData?.role != 'admin' ? 'disabled' : '' }}" (click)="reviewHistory()">Review Invoices</button>
			<!-- <div class="tooltiptext">{{ 'This action can only be performed by Admin' }}</div> -->
		</div>
	</div>
</div>
