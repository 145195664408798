import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EcommerceService } from '../social-media-influencer/modules/ecommerce-v2/services/ecommerce.service';
import { ToastNotificationService } from '../shared/toast-notification/toast-notification.service';
import { AuthenticationService } from '../_services/authentication.service';
import { AuthenticationState } from '../authentication/state/authentication.reducer';
import { Store } from '@ngrx/store';
import { LogInSuccess } from '../authentication/state/authentication.action';

@Component({
	selector: 'app-shopify-landing',
	templateUrl: './shopify-landing.component.html',
	styleUrls: ['./shopify-landing.component.scss']
})
export class ShopifyLandingComponent implements OnInit {
	private queryParams?: any;
	private unsubscriber$ = new Subject<void>();

	constructor(
		private authService: AuthenticationService,
		private toast: ToastNotificationService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private ecommerceService: EcommerceService,
		private authStore: Store<AuthenticationState>
	) {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.queryParams = params;
		});
	}

	ngOnInit(): void {
		if (!this.queryParams || Object.keys(this.queryParams).length <= 0) {
			this.router.navigate(['']);
		}
	}

	ngAfterViewInit(): void {
		this.installShopify();
	}

	public installShopify(): void {
		this.ecommerceService
			.shopifyLanding(this.queryParams)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res?.token) {
						this.authService.logout();
						this.authStore.dispatch(new LogInSuccess(res?.token));
					} else {
						this.authService.logoutWithNoRedirect();
						this.router.navigate(['authentication/register-email'], { queryParams: { fromShopify: 'true' }, queryParamsHandling: 'merge' });
					}
					// setTimeout(() => {
					// 	this.router.navigate(['e-commerce/integrations']);
					// }, 2000);
				},
				err => {
					this.toast.sendErrorToast(err?.error?.detail);
					setTimeout(() => {
						this.router.navigate(['e-commerce/integrations']);
					}, 2000);
				}
			);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
