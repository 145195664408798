import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlanDetailsV2 } from 'src/app/shared/models/plan-details.model';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-billing-plans-v2',
	templateUrl: './billing-plans-v2.component.html',
	styleUrls: ['./billing-plans-v2.component.scss']
})
export class BillingPlansV2Component implements OnInit {
	public plans: PlanDetailsV2[] = [];
	public selectedPlan: PlanDetailsV2;
	private unsubscriber$ = new Subject<void>();
	public loading: boolean = false;
	public currency: string = '$';
	public planStartIndex = 0;
	public planEndIndex = 2;

	constructor(private toast: ToastNotificationService, private router: Router, private paymentService: PaymentServiceApi) {}

	ngOnInit(): void {
		this.loading = true;
		this.paymentService
			.fetchAllPlansV2()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.plans = res?.data;
				this.selectedPlan = this.plans[0];
				this.loading = false;
				this.currency = '$';
			});
	}

	public changePlan(plan: PlanDetailsV2): void {
		this.selectedPlan = plan;
	}

	public applyChanges(): void {
		let token = localStorage.getItem(StorageKey.token);
		let plan = this.selectedPlan;
		this.updatePlan(plan, token);
	}

	public updatePlan(plan: PlanDetailsV2, token): void {
		this.loading = true;
		this.paymentService.updatePlan(plan.name, plan.price_per_month, token).subscribe(
			res => {
				this.toast.sendSuccessToast('Plan was Successfully Updated');
				window.location.replace(res?.data?.confirmation_url);
				this.loading = false;
			},
			err => {
				this.loading = false;
				this.toast.sendErrorToast('Something went wrong, Please try again later');
			}
		);
	}

	public slide(direction: string): void {
		if (direction === 'right') {
			if (this.planEndIndex + 2 >= this.plans.length) {
				this.planStartIndex = this.plans.length - 2;
				this.planEndIndex = this.plans.length;
				return;
			}
			this.planStartIndex = this.planStartIndex + 2;
			this.planEndIndex = this.planEndIndex + 2;
		} else {
			if (this.planStartIndex - 2 <= 0) {
				this.planStartIndex = 0;
				this.planEndIndex = 2;
				return;
			}
			this.planStartIndex = this.planStartIndex - 2;
			this.planEndIndex = this.planEndIndex - 2;
		}
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
