import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ErrorMessageService } from './error-message.service';
export interface ValidatorMessages {
	validationType: string;
	validationMessage: string;
}

@Component({
	selector: 'app-lolly-outline-tb',
	templateUrl: './outline-tb.component.html',
	styleUrls: ['./outline-tb.component.scss']
})
export class OutlineTbComponent {
	@Input() public control: FormControl | AbstractControl;
	@Input() public validatorMessages: ValidatorMessages[];
	@Input() public label: string;
	@Input() public placeholder: string;
	@Input() public readonlyInput: boolean;
	@Input() public asterisk: boolean;

	public isDestinationFocused: boolean;
	public errorMessage: string;

	constructor(public errorMessageService: ErrorMessageService) {}
	public ngOnInit(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}
}
