<app-lolly-spinning-loader *ngIf="loading"></app-lolly-spinning-loader>
<div *ngIf="!loading" class="container-wrapper">
	<div class="container-bills" style="font-family: Gilroy;" #wrapper>
		<div class="content-wrapper">
			<div class="plans">
				<div class="header">
					<div class="about">
						<p class="title">All Plans</p>
					</div>
				</div>
				<div class="plans-wrapper">
					<div class="slider">
						<mat-icon (click)="slide('left')" *ngIf="planStartIndex !== 0">
							keyboard_arrow_left
						</mat-icon>
					</div>
					<div
						class="plan"
						*ngFor="let plan of plans.slice(planStartIndex, planEndIndex)"
						(click)="changePlan(plan)"
						[class.selected]="selectedPlan?.name == plan.name"
					>
						<div class="circle"></div>
						<div class="plan-head">
							<span>{{ plan.name | titlecase }} Plan</span>
							<div class="amount">
								<h4>
									{{ currency }}{{ plan.price_per_month }}
									<span>/{{ 'mo' }}</span>
								</h4>
							</div>
						</div>
						<div class="plan-body" [class.selected-input]="selectedPlan?.name == plan.name">
							<ul class="item-lists">
								<li>
									<em class="k-icon k-i-check k-i-checkmark"></em>
									{{ plan.campaigns }} Campaigns
								</li>
								<li>
									<em class="k-icon k-i-check k-i-checkmark"></em>
									{{ plan.influencers_per_month }} live influencers
								</li>
								<li>
									<em class="k-icon k-i-check k-i-checkmark"></em>
									Onboarding and Platform Training (1 hr call)
								</li>
								<ng-container *ngIf="plan?.support && plan?.support?.length">
									<li *ngFor="let feature of plan?.support">
										<em class="k-icon k-i-check k-i-checkmark"></em>
										{{ feature }}
									</li>
								</ng-container>
							</ul>
						</div>
					</div>
					<div class="slider">
						<mat-icon (click)="slide('right')" *ngIf="planEndIndex !== plans.length">
							keyboard_arrow_right
						</mat-icon>
					</div>
				</div>
			</div>
			<div class="side-details">
				<button class="for-mobile">Next</button>
				<div class="card-toggle">
					<div class="card-body">
						<div class="custom-switch">
							<label class="{{ plans[0]?.product_price_interval === 'month' ? 'active' : 'inactive' }}">Monthly Plans</label>
							<!-- <app-switch [value]="false" (toggle)="onCheckToggleView($event)"></app-switch>
							<label class="{{ plans[0].product_price_interval === 'year' ? 'active' : 'inactive' }}">Annually</label> -->
						</div>
					</div>
				</div>
				<div class="foot-button" #target>
					<app-primary-button
						[buttonLable]="'Apply Changes'"
						[buttonType]="'Primary'"
						[isPeddingReduceSmall]="true"
						(buttonClicked)="applyChanges()"
						[isDisabled]="!selectedPlan"
					></app-primary-button>

					<p class="stripe">Payments are secured by Stripe</p>

					<div class="info-box">
						<p class="title">Why is the amount due today less than my monthly subscription cost?</p>
						<p class="para">When making a change to your plan, the amount due today is prorated based on the cost of your previous plan.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
