import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	CompanyValidator,
	EmailValidator,
	FieldValidators,
	FirstNameValidator,
	LastNameValidator,
	PhoneValidator,
	PositionValidator
} from '../../../models/validators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '../../../../_models/user-models/user';
import { UserServiceApi } from '../../../../_services/user/user.api.service';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateProfile } from '../../../models/update-profile';
import { EditImageComponent } from '../../../dialogs/edit-image/edit-image.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { getAccount, getActiveUserPage, getUserProfileUserManagement, UserManagementState } from '../../../state/user-management.reducer';
import {
	LoadFiledUser,
	SetAccountEditState,
	SetAccountSubmitState,
	SetAddEditNextStatus,
	SetFirstStepData,
	UpdateUserPicture,
	SetUserProfileUserManagement
} from '../../../state/user-management.actions';
import { getFiledId, hasBackOfficePermission, UserState } from '../../../../shared/state/user/user.reducer';
import { HideGlobalSpinner } from '../../../../shared/state/shared.actions';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PermissionsDirectiveInterface } from 'src/app/shared/permisions/models/permissions-directive.interface';
import { Modules } from 'src/app/shared/permisions/enums/modules';
import { MiscellaneousPermissions } from 'src/app/shared/permisions/enums/miscellaneous-permissions';

@Component({
	selector: 'app-account-inputs',
	templateUrl: './account-inputs.component.html',
	styleUrls: ['./account-inputs.component.scss']
})
export class AccountInputsComponent implements OnInit, OnDestroy {
	@Input() user: User;
	@Input() editUser: boolean;

	public userProfile: User = null;

	public accountFirstNameValidatorMessages = FirstNameValidator;
	public accountLastNameValidatorMessages = LastNameValidator;
	public accountEmailValidatorMessages = EmailValidator;
	public accountCompanyValidatorMessages = CompanyValidator;
	public accountPhoneValidatorMessages = PhoneValidator;
	public accountPositionValidatorMessages = PositionValidator;

	public businessOwnerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Miscellaneous,
		permissions: [MiscellaneousPermissions.IsBusinessOwner]
	};
	public clientEmployeePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Miscellaneous,
		permissions: [MiscellaneousPermissions.IsClientEmployee]
	};

	public accountForm: UntypedFormGroup;
	public editState: boolean;
	public userPageStatus: boolean;

	private unsubscriber$ = new Subject<void>();
	private addUser = false;
	private editUserImage: SafeUrl;
	private fileToUpload: File;
	private submitState: boolean;
	private next: boolean;
	private filedId: number;

	constructor(
		public dialog: MatDialog,
		private userServiceApi: UserServiceApi,
		private formBuilder: UntypedFormBuilder,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService,
		private domSanitizer: DomSanitizer,
		private store: Store<UserManagementState>,
		private userStore: Store<UserState>
	) {}

	public ngOnInit(): void {
		this.store.dispatch(new HideGlobalSpinner());
		this.clearForm();
		this.fetchStore();
		this.createForm();
		this.fetchFormData();
	}

	public ngOnDestroy(): void {
		this.editUser = false;
		this.store.dispatch(new SetAccountEditState(false));
		this.user = null;
		this.userProfile = null;
		this.editState = false;
		this.accountForm = null;

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public openDialog(): void {
		const dialogRef = this.dialog.open(EditImageComponent);
		dialogRef
			.beforeClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				if (result && result.data) {
					this.fileToUpload = result.data.file;
					this.editUserImage = this.domSanitizer.bypassSecurityTrustUrl(result.data.src.changingThisBreaksApplicationSecurity);
					this.store.dispatch(new LoadFiledUser({ id: this.filedId, setSelectedUser: false, isBackoffice: false }));
					this.uploadPicture();
				}
			});
	}

	public getImage(): string | SafeUrl {
		if (this.editUserImage) {
			return this.editUserImage;
		}
		if (this.userProfile && this.userProfile.pictureUrl) {
			const type = this.getImageMimeType(this.userProfile.pictureUrl);

			const pictureUrl = `data:${type};base64,${this.userProfile.pictureUrl}`;
			return pictureUrl;
		}
		return '/assets/icons/user-management/account/user-circle-solid.png';
	}

	private fetchStore(): void {
		this.userStore.pipe(select(getFiledId), take(1)).subscribe(id => {
			if (id) {
				this.filedId = id;
				this.store.dispatch(new LoadFiledUser({ id: this.filedId, setSelectedUser: false, isBackoffice: false }));
			}
		});

		this.store.pipe(select(getAccount), takeUntil(this.unsubscriber$)).subscribe(state => {
			this.editState = state.myAccount.state.edit;
			this.submitState = state.myAccount.state.submit;
			this.next = state.addEditUser.status.next;
			if (this.next) {
				this.store.dispatch(new SetAddEditNextStatus(false));
				this.addUser = true;
				if (this.checkForErrors()) {
					return;
				} else {
					if (this.accountForm) {
						const data = {
							Company: this.accountForm.get('companyNameControl').value,
							PhoneNumber: this.accountForm.get('phoneNumberControl').value,
							FirstName: this.accountForm.get('firstNameControl').value,
							LastName: this.accountForm.get('lastNameControl').value,
							Position: this.accountForm.get('positionControl').value,
							Access: this.accountForm.get('accessControl').value,
							Email: this.accountForm.get('emailAddressControl').value
						};
						this.store.dispatch(new SetFirstStepData(data));
					}
				}
			}

			if (this.submitState) {
				this.saveAccountEdit();
			}
			if (this.editState) {
				this.editMode(this.editState);
			}
		});

		this.store.pipe(select(getActiveUserPage), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.userPageStatus = resp;

			if (this.userPageStatus) {
				this.store.dispatch(new SetAccountEditState());
			}
		});
	}

	private createForm(): void {
		this.accountForm = this.formBuilder.group({
			firstNameControl: this.formBuilder.control({ value: '', disabled: !this.editState }, [Validators.required, Validators.maxLength(50)]),
			lastNameControl: this.formBuilder.control({ value: '', disabled: !this.editState }, [Validators.required, Validators.maxLength(50)]),
			emailAddressControl: this.formBuilder.control(
				{
					value: '',
					disabled: !this.editState
				},
				[Validators.required, Validators.maxLength(70), Validators.pattern(FieldValidators.Email)]
			),
			companyNameControl: this.formBuilder.control({ value: '', disabled: true }, [Validators.maxLength(100)]),
			phoneNumberControl: this.formBuilder.control(
				{
					value: '',
					disabled: !this.editState
				},
				[Validators.minLength(7), Validators.required, Validators.maxLength(15), Validators.pattern(FieldValidators.Phone)]
			),
			positionControl: this.formBuilder.control({ value: '', disabled: true }, [Validators.maxLength(50), Validators.required]),
			accessControl: this.formBuilder.control({ value: '', disabled: true }, [Validators.maxLength(50), Validators.required])
		});
	}

	private clearForm(): void {
		this.userProfile = null;
		this.accountForm = null;
	}

	private fillForm(user: User): void {
		this.userProfile = user;
		if (this.accountForm) {
			this.accountForm.patchValue({
				companyNameControl: this.userProfile.companyName ? this.userProfile.companyName : '',
				phoneNumberControl: this.userProfile.phoneNumber,
				emailAddressControl: this.userProfile.email,
				firstNameControl: this.userProfile.firstName,
				lastNameControl: this.userProfile.lastName,
				positionControl: this.userProfile.position ? this.userProfile.position : '',
				accessControl: this.userProfile.access
			});
			this.accountForm.get('emailAddressControl').disable();
			this.accountForm.get('phoneNumberControl').disable();
		}
	}

	private fetchFormData(): void {
		if (this.user) {
			this.fillForm(this.user);
		}
		this.store.pipe(select(getAccount), takeUntil(this.unsubscriber$)).subscribe(state => {
			this.user = null;
			const firstStepData = state.addEditUser.firstStepData;
			if (firstStepData && !this.addUser && this.accountForm) {
				this.accountForm.patchValue({
					companyNameControl: firstStepData.Company,
					phoneNumberControl: firstStepData.PhoneNumber,
					emailAddressControl: firstStepData.Email,
					firstNameControl: firstStepData.FirstName,
					lastNameControl: firstStepData.LastName,
					positionControl: firstStepData.Position
				});
				this.accountForm.get('emailAddressControl').disable();
				this.accountForm.get('phoneNumberControl').disable();
			}
		});

		if (!this.userPageStatus) {
			this.userStore.pipe(select(hasBackOfficePermission), take(1)).subscribe(isAdmin => {
				if (isAdmin) {
					this.userServiceApi
						.getUserById(this.filedId, true)
						.pipe(take(1))
						.subscribe(user => {
							if (user) {
								this.fillForm(user);
							}
						});
				} else {
					this.store.pipe(select(getUserProfileUserManagement), takeUntil(this.unsubscriber$)).subscribe(user => {
						if (user) {
							this.fillForm(user);
						}
					});
				}
			});
		}
	}

	public checkForErrors(): boolean {
		let err = false;
		if (!this.accountForm) {
			return err;
		}
		this.accountForm.updateValueAndValidity();
		this.accountForm.markAllAsTouched();

		if (this.accountForm.status === 'INVALID') {
			err = true;
			return err;
		}

		return err;
	}

	private saveAccountEdit(): boolean {
		if (this.checkForErrors()) {
			return false;
		}

		let userUpdate: UpdateProfile;
		if (this.accountForm) {
			userUpdate = {
				companyName: this.accountForm.get('companyNameControl').value,
				phoneNumber: this.accountForm.get('phoneNumberControl').value,
				firstName: this.accountForm.get('firstNameControl').value,
				lastName: this.accountForm.get('lastNameControl').value,
				position: this.accountForm.get('positionControl').value,
				access: this.accountForm.get('accessControl').value
			};
		} else {
			return true;
		}
		this.userServiceApi
			.updateProfile(userUpdate)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					for (const control in this.accountForm.controls) {
						this.accountForm.controls[control].disable();
					}
					this.userProfile.companyName = userUpdate.companyName;
					this.userProfile.phoneNumber = userUpdate.phoneNumber;
					this.userProfile.firstName = userUpdate.firstName;
					this.userProfile.lastName = userUpdate.lastName;
					this.userProfile.position = userUpdate.access;
					this.store.dispatch(new SetUserProfileUserManagement(this.userProfile));
					this.store.dispatch(new SetAccountEditState(false));
					this.store.dispatch(new SetAccountSubmitState(false));
					this.toastNotificationService.sendSuccessToast('Your account information was successfully updated.');
				},
				() => {
					this.store.dispatch(new SetAccountEditState(false));
					this.store.dispatch(new SetAccountSubmitState(false));
					this.toastNotificationService.sendErrorToast('Something went wrong, please contact support');
				}
			);
	}

	private editMode(state: boolean): void {
		this.editState = state;
		if (this.accountForm) {
			for (const control in this.accountForm.controls) {
				if (state) {
					if (control !== 'emailAddressControl' && control !== 'phoneNumberControl') {
						this.accountForm.controls[control].enable();
					}
				} else {
					this.accountForm.controls[control].disable();
				}
			}
		}
	}

	private uploadPicture(): void {
		const formData = new FormData();
		formData.append('picture', this.fileToUpload, this.fileToUpload.name);
		this.store.dispatch(new UpdateUserPicture({ pictureForm: formData }));
	}

	private getImageMimeType(imageUrl: string): string {
		const mimeTypes = [
			{ type: 'image/jpg', signature: '/' },
			{ type: 'image/png', signature: 'i' },
			{ type: 'image/gif', signature: 'R' },
			{ type: 'image/webp', signature: 'U' }
		];

		let type = mimeTypes.find(x => x.signature == imageUrl.charAt(0)).type;
		return type;
	}
}
