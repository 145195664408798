import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-primary-button',
	templateUrl: './primary-button.component.html',
	styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent implements OnInit {
	@Input() buttonLable: string;
	@Input() buttonType: string;
	@Input() isDisabled: boolean = false;
	@Input() isPeddingReduce: boolean = false;
	@Input() isPeddingReduceSmall: boolean = false;
	@Input() styleClass: string = '';

	@Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

	ngOnInit(): void {}

	public buttonClick(): void {
		this.buttonClicked.emit();
	}
}
