import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from '../base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class ShopifyService {
	constructor(private http: HttpClient) {}

	public connectAccount(value: string, state: string): Observable<any> {
		const payload = {
			shop: value,
			state: state
		};
		return this.http.patch<any>(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/user`, payload);
	}

	public connectAccountV2(queryParams: HttpParams, token?): Observable<any> {
		if (token) {
			queryParams = queryParams.append('token', token);
		}
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/callback`, { params: queryParams });
	}

	public clientAPI(charge_id: number): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/charge/details?charge_id=${charge_id}`);
	}

	public validate(): Observable<HttpResponse<any>> {
		return this.http.get<HttpResponse<any>>(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/validate`, { observe: 'response' });
	}

	public disconnect(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/uninstall`);
	}

	// SHOPIFY LANDING COMPONENT API
	public loadShopifyApp(data: any): Observable<string> {
		let url = `${BaseApiUrl.SocialMessengerPython}oauth/shopify/preinstall`;
		url += `?shop=${data.shop}`;
		url += `&code=${data.code}`;
		url += `&hmac=${data.hmac}`;
		url += `&timestamp=${data.timestamp}`;
		url += `&host=${data.host}`;
		return this.http.post<string>(url, {});
	}
}
