<!-- <div class="upperHeader">

    <div (click)="backToMyPlan()" class="BackButton"><span class="k-icon k-i-chevron-left backIcon"></span> Back</div> -->

<!-- <div class="monthlySwitch">
        <section class="example-section">
            Monthly<mat-slide-toggle class="example-margin" [checked]="true" [disabled]="false">
                Annually
            </mat-slide-toggle>
        </section>
    </div> -->
<!-- </div> -->

<div class="container">
	<div class="containerLeft">
		<div (click)="backToMyPlan()" class="BackButton">
			<span class="k-icon k-i-chevron-left backIcon"></span>
			Back
		</div>
		<app-add-credit [dropBoxData]="dropDownData" *ngIf="_myPlan.componentToLoad == 'add-credit'" [title]="pageTitle"></app-add-credit>

		<div *ngIf="_myPlan.componentToLoad == 'all-plan'" class="leftMainContainer">
			<h4 class="title">All Plans</h4>
			<div *ngFor="let data of allTarrifPlans; index as i" class="padding-all">
				<div class="header">
					<div style="width: 100%;">
						<hr class="hr-line" />
						<div class="oneRow">
							<input
								([ngModel])="(selectedPlanIndex)"
								[checked]="data.currentOfferId"
								(change)="onPlanChange($event)"
								[value]="i"
								[id]="i"
								type="radio"
								name="change-plan"
							/>
							<label class="radioLabel" [for]="i">{{ data.tag }}</label>
							<div class="contentToRight colorBlue usdvalue">
								{{ data.amount | currency }}
								<span class="colorBlack perMonth">/m</span>
							</div>
						</div>
					</div>
				</div>
				<div class="planDesc">
					{{ data.influencerSearchCredit }} credits, Influencer matching, Influencer relationship management, in app payment, Premium support,
					{{ data.allowdedSubUser }} users, Unlimited campaign creation, Content management, 7500 monthly credits, Analytical reports.
				</div>
			</div>
		</div>
	</div>

	<div class="rightMainContainer stickyPosition">
		<div class="monthlySwitch">
			<section class="example-section">
				Monthly
				<mat-slide-toggle class="example-margin" [checked]="true" [disabled]="false">
					Annually
				</mat-slide-toggle>
			</section>
		</div>
		<div class="rightMainContent">
			<div class="padding-all">
				<div class="reviewChrgesHeader">Review Charges</div>
				<div class="oneRow">
					<div>
						<div class="textColor1 marginBottom">plan type</div>
						<div class="textStyle2 marginBottom">Boss Mode</div>
					</div>
					<div *ngIf="selectedPlan" class="contentToCenter textStyle3">{{ selectedPlan[0].amount | currency }}/m</div>
				</div>
				<hr class="hr-line marginBottom" />

				<div class="oneRow">
					<div>
						<div class="textColor1 marginBottom">Credits</div>
						<div *ngIf="selectedPlan" class="textStyle2 marginBottom">{{ selectedPlan[0].influencerSearchCredit }}/m</div>
					</div>
					<div class="contentToCenter textStyle3">Included</div>
				</div>
				<hr class="hr-line marginBottom" />

				<div class="oneRow">
					<div>
						<div class="textColor1 marginBottom">Subusers</div>
						<div class="textStyle2 marginBottom" *ngIf="selectedPlan">{{ selectedPlan[0].allowdedSubUser }}</div>
					</div>
					<div class="contentToCenter textStyle3">Included</div>
				</div>
				<hr class="hr-line marginBottom" />

				<div class="oneRow">
					<div>
						<div class="textColor1 marginBottom">Total</div>
					</div>
					<div class="contentToCenter textStyle3">{{ selectedPlan[0].amount | currency }}/m</div>
				</div>
			</div>
		</div>
		<button mat-button color="primary" (click)="aplyChangeBtn()" class="offerSubmitBtn" [disabled]="disableApplyChangeButton()">Apply Chnages</button>
	</div>
	<!-- <div class="rightMainContainer stickyPosition"> -->
	<!-- <app-review-charges class="rightMainContainer stickyPosition" [SelectedItemdata]="selectedPlan"
        [isBtnDisable]="btnDisable"></app-review-charges> -->
	<!-- </div> -->
</div>
