import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { GenericSidePopupComponent } from 'src/app/shared-components/generic-side-popup/generic-side-popup.component';
import { GenericSidePopupModel } from 'src/app/shared-components/generic-side-popup/models/generic-side-popup-data.model';
import { UtilsService } from '../utils';

@Injectable({
	providedIn: 'root'
})
export class GenericSidePopupService {
	public dialogRef: MatDialogRef<GenericSidePopupComponent>;
	public bookADemoDialogData: GenericSidePopupModel = {
		title: 'Find influencers that match your interest',
		videoPoster: 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
		videoSrc: 'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
		footerText: 'Request a personalized call with one of our experts.',
		oneButtonFooter: true,
		primaryBtnText: 'Book a demo call',
		list: [
			{
				title: 'Get list of similar creators',
				subtitle: 'Based on just one seed profile'
			},
			{
				title: 'Learn which influencers share similar audiences',
				subtitle: 'Or even or completely different ones'
			},
			{
				title: 'Shortlist relevant profiles',
				subtitle: 'Find out which accounts produce comparable content'
			}
		]
	};

	constructor(public dialog: MatDialog) {}

	public showPopup(matDialogData = this.bookADemoDialogData): Promise<any> {
		if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
			return Promise.resolve();
		}
		return new Promise<any>((resolve, reject) => {
			this.dialogRef = this.dialog.open(GenericSidePopupComponent, {
				width: '606px',
				height: '100%',
				panelClass: ['sharp-dialog-no-shadow', 'slide', 'slideInRight'],
				backdropClass: 'light-backdrop',
				position: {
					right: '0'
				},
				scrollStrategy: new NoopScrollStrategy(),
				data: matDialogData
			});

			this.dialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(res => {
					resolve(res);
				});
		});
	}

	public showPredefinedSidePopups(type: string): Promise<any> {
		let matDialogData: GenericSidePopupModel;

		switch (true) {
			case type.includes('discovery'):
				matDialogData = {
					title: 'Find Influencers For Your Brand Today',
					videoSrc: UtilsService.DISCOVERY_KB_VIDEO_URL,
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Tailored Recommendations',
							subtitle: 'Get matched with influencers who embody your brand values and audience demographics.'
						},
						{
							title: 'Analytics at Your Fingertips',
							subtitle: 'Monitor Influencer effectiveness through real-time data insights.'
						},
						{
							title: 'Creator Database',
							subtitle: 'Adapt and evolve your strategy with data of more than 200 million creators.'
						}
					]
				};
				break;
			case type.includes('list'):
				matDialogData = {
					title: 'Build Your Perfect Influencer List',
					videoSrc: UtilsService.DISCOVERY_LIST_KB_VIDEO_URL,
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Curated Lists',
							subtitle: 'Create & Manage creator lists that match your specific needs.'
						},
						{
							title: 'Save and Organise',
							subtitle: 'Easily save and categorise influencers for different campaigns.'
						},
						{
							title: 'Effortless Collaboration',
							subtitle: 'Share your lists with team members within the platform without any hassle.'
						}
					]
				};
				break;
			case type.includes('campaign'):
				matDialogData = {
					title: 'Streamline Your Influencer Campaign Management',
					videoSrc: UtilsService.CAMPAIGN_KB_VIDEO_URL,
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Campaign Wizard',
							subtitle: 'Use our step-by-step guide to set up campaigns effortlessly.'
						},
						{
							title: 'Comprehensive Management Tools',
							subtitle: 'Track post performance, engagement rates, and ROI in one place.'
						},
						{
							title: 'Customizable Templates',
							subtitle: 'Use pre-designed templates that make campaign creation quick and simple.'
						}
					]
				};
				break;
			case type.includes('outreach'):
				matDialogData = {
					title: 'Communicate Effectively with Influencers',
					videoPoster: 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc: 'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Direct Messaging',
							subtitle: 'Contact any influencer directly through our secure platform.'
						},
						{
							title: 'Template Responses',
							subtitle: 'Save time with customizable message templates.'
						},
						{
							title: 'Message Tracking',
							subtitle: 'Keep track of conversations and follow-ups.'
						}
					]
				};
				break;
			case type.includes('adv-search'):
				matDialogData = {
					title: 'Let Our Experts Find the Perfect Match',
					videoPoster: 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc: 'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Expert Data Team',
							subtitle: 'Our data team will go and find influencers that match your needs.'
						},
						{
							title: 'Tailored Searches',
							subtitle: 'Specify your needs and let our team do the heavy lifting.'
						},
						{
							title: 'Regular Updates',
							subtitle: 'Receive ongoing recommendations as your brand evolves.'
						}
					]
				};
				break;
			case type.includes('payments'):
				matDialogData = {
					title: 'Simplify Your Influencer Payouts',
					videoPoster: 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc: 'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Automated Payments',
							subtitle: 'Set up hassle-free, automatic payments to your influencers.'
						},
						{
							title: 'Transparent Tracking',
							subtitle: 'Access real-time reports on payouts and budget status.'
						},
						{
							title: 'Customizable Payment Schedules',
							subtitle: 'Choose payment schedules that work best for your business model.'
						}
					]
				};
				break;
			case type.includes('reporting'):
				matDialogData = {
					title: 'Access Insights That Matter',
					videoPoster: 'https://bucket-influencer-service-danish.s3.eu-west-1.amazonaws.com/portfolio/welcome-to-lolly.png',
					videoSrc: 'https://prod-influencer-service-danish.s3.us-east-2.amazonaws.com/video/Comp+1_6_1.mp4',
					footerText: 'Upgrade for instant access or consult expert.',
					primaryBtnText: 'Upgrade',
					secondaryBtnText: 'Book a Demo',
					list: [
						{
							title: 'Detailed Analytics',
							subtitle: 'View engagement rates, reach, and ROI details for precise campaign tracking.'
						},
						{
							title: 'Engagement Analysis',
							subtitle: 'Learn which demographics engage the most to tailor your campaigns.'
						},
						{
							title: 'Historical Data Comparison',
							subtitle: 'Compare current performance against past results to assess growth.'
						}
					]
				};
				break;
			default:
				return Promise.resolve();
		}
		return this.showPopup(matDialogData);
	}

	public dismissPopup(): void {
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}
}
