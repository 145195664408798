import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { GenericSmDialogComponent } from 'src/app/shared-components/generic-sm-dialog/generic-sm-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmPopupService {
	public dialogRef: MatDialogRef<GenericSmDialogComponent>;

	constructor(private dialog: MatDialog) {}

	public showPopup(title: string, primaryActionTitle: string, secondaryActionTitle: string): Promise<any> {
		let matDialogData = {
			title: title,
			primaryActionTitle: primaryActionTitle,
			secondaryActionTitle: secondaryActionTitle
		};
		let matDialogConfig: MatDialogConfig = {
			panelClass: 'center-dialog-no-shadow',
			width: '400px',
			backdropClass: 'light-backdrop',
			data: matDialogData
		};
		if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
			return Promise.resolve();
		}
		return new Promise<any>((resolve, reject) => {
			this.dialogRef = this.dialog.open(GenericSmDialogComponent, matDialogConfig);

			this.dialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(res => {
					resolve(res);
				});
		});
	}

	public dismissPopup(): void {
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}
}
