import { Environment } from './models/environment.models';

export const environment: Environment = {
	Flags: {
		production: false,
		hasTimestamp: true
	},
	// need to change as per the domain
	// ShareUrl: 'https://dev3.filed.com/share-link/report/',
	ShareUrl: 'https://dexter.filed.com/share-link/report/',

	Payment: 'https://9ck3ekes9c.execute-api.us-east-2.amazonaws.com/dev/api/v1/',
	BlueSnap: 'https://sandbox.bluesnap.com/web-sdk/4/bluesnap.js',
	SharedServices: {
		Users: 'https://qk4rq24ooj.execute-api.us-east-2.amazonaws.com/dev/api/v1/',
		Subscription: 'https://9ck3ekes9c.execute-api.us-east-2.amazonaws.com/dev/api/v1/',
		GeneralSettings: 'https://cs-general-settings-api.dev3.filed.com/api/v1/',
		Support: 'https://py-filed-support-api.dev3.filed.com/api/v1/',
		Contact: 'https://9ck3ekes9c.execute-api.us-east-2.amazonaws.com/dev/api/v1/'
	},

	CreativeBuilders: {
		CreativeBuilder: 'https://cs-creative-builders-api.dev3.filed.com/api/v1/',
		SingleAdPreview: 'https://razvan-test-bucket-051552587355.s3.eu-west-2.amazonaws.com/'
	},

	ProductCatalogs: {
		ProductCatalog: 'https://cs-product-catalogs-api.dev3.filed.com/api/v1/'
	},
	PythonCatalogLambdas: {
		PythonCatalogLamda: 'https://frwoecnvkf.execute-api.us-east-2.amazonaws.com/dev/'
	},

	SharedMarketing: {
		Reporting: 'https://cs-reports-api.dev3.filed.com/api/v1/',
		ReportFbPY: 'https://py-facebook-reports-api.dev3.filed.com/api/v1/',
		ReportingPY: 'https://py-filed-reports-api.dev3.filed.com/api/v1/',
		Assets: 'https://cs-assets-api.dev3.filed.com/api/v1/',
		schedules: 'https://py-filed-notifications-api.dev3.filed.com/api/v1/'
	},

	Dexter: {
		Optimization: 'https://py-facebook-dexter-api.dev3.filed.com/api/v1/',
		OptimizationGoogle: 'https://py-google-dexter-api.dev3.filed.com/api/v1/',
		DexterAnalytics: 'https://py-facebook-dexter-analytics-api.dev3.filed.com/api/v1/',
		Sync: 'https://py-facebook-dexter-sync-api.dev3.filed.com/api/v1/',
		Genesis: 'https://py-filed-dexter-genesis-api.dev3.filed.com/api/v1/',
		DexterDashboard: 'https://py-filed-dexter-analytics-api.dev3.filed.com/api/v1/',
		DexterAnalyticsGoogle: 'https://py-google-dexter-analytics-api.dev3.filed.com/api/v1/'
	},

	Facebook: {
		DataPipeline: 'https://py-facebook-turing-api.dev3.filed.com/api/v1/',
		FacebookAccounts: 'https://py-facebook-accounts-api.dev3.filed.com/api/v1/',
		Account: 'https://cs-facebook-accounts-api.dev3.filed.com/api/v1/',
		// CampaignBuilder: 'https://cs-facebook-campaigns-builder-api.dev3.filed.com/api/v1/',
		CampaignBuilderPython: 'https://py-facebook-campaign-builder-api.dev3.filed.com/api/v1/',
		CampaignBuilderAquaPython: 'https://py-facebook-aqua-api.dev3.filed.com/api/v1/',
		// CampaignBuilderSmartCreate: 'https://cs-facebook-smart-create-api.dev3.filed.com/api/v1/',
		Apps: 'https://cs-facebook-apps-api.dev3.filed.com/api/v1/',
		// Audience: 'https://cs-facebook-audiences-api.dev3.filed.com/api/v1/',
		// Pixel: 'https://cs-facebook-pixels-api.dev3.filed.com/api/v1/',
		// PixelPython: 'https://py-facebook-pixels-api.dev3.filed.com/api/v1/',
		Dexter: 'https://py-facebook-dexter-api.dev3.filed.com/api/v1/'
	},

	Google: {
		Accounts: 'https://cs-google-accounts-api.dev3.filed.com/api/v1/',
		PythonAccounts: 'https://py-google-turing-api.dev3.filed.com/api/v1/',
		PythonAccountsMain: 'https://py-google-accounts-api.dev3.filed.com/api/v1/',
		CampaignManager: 'https://py-campaign-manager-api.dev3.filed.com/api/v1/',
		CampaignBuilder: 'https://cs-campaign-builder-api.dev3.filed.com/api/v1/',
		DataPipeLine: 'https://py-google-turing-api.dev3.filed.com/api/v1/',
		ClientId: '17118844476-ff344s34figbe2sfjmqa5qg7msrjta7m.apps.googleusercontent.com', // GOOGLE-CLIENT-ID
		CrmClientId: '17118844476-2a4qpoddr7mju0i54lfpgjr2pdcjtgvh.apps.googleusercontent.com',
		Scopes:
			'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.readonly openid https://www.googleapis.com/auth/gmail.send',
		CampaignBuilderPython: 'https://py-google-campaign-builder-api.dev3.filed.com/api/v1/'
	},

	Misc: {
		HubSpot: 'https://api.hubapi.com/contentsearch/v2/',
		LogErrors: 'https://py-logging-api.dev3.filed.com/api/v1/'
	},

	SocialInfluencerApi: {
		SocialInfluencerPython: 'https://api-dev.lolly.com/api/v1/',
		SocialMessengerPython: 'https://api-dev.lolly.com/api/v1/',
		SocialInfluencerListAPI: 'https://api-dev.lolly.com/api/v1/',
		SocialMessengerSocket: 'https://api-dev.lolly.com/social'
	},
	InfluencerFacebookClientId: '174014546372191',
	UserVerification: 'https://py-users-verification-api.dev.filed.com/api/v1/',
	Microsoft: {
		ClientId: '078321f7-1d08-43c7-8e42-37956eee2a81'
	},
	STRIPE_KEY: 'pk_test_51LR5EiIp52mziCZnbTCzks4g89yp3gUe0Us1qjaULS89QaTpnrT81VmUGtMcDFxYzxH3mQKsnkO23iyDNi3A570b002uSurglQ',
	DEFAULT_PRICE_ID: 'price_1LvJ1HIp52mziCZnjgPgx0Ki',

	Shopify: {
		SHOPIFY_API_KEY: '349d7cb722fc5a735a574d948d81bbeb',
		SHOPIFY_SECRET: 'a3ac79638cb223090178078bde1cfbc1',
		SHOPIFY_API_VERSION: '2022-07',
		REDIRECT_URI: 'https://dev.lolly.com/authentication/shopify-integration',
		SCOPES: ['read_orders']
	},

	LOLLY_URL: 'https://dev.lolly.com/gmailAuth'
};
