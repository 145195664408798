<div class="confirmation-dialog-container">
	<h3 mat-dialog-title>{{ title | translate }}</h3>
	<mat-dialog-content>
		<div class="popup-text">{{ message | translate }}</div>
	</mat-dialog-content>
	<div class="button-container" mat-dialog-actions>
		<app-button
			(buttonClicked)="decline()"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="{{ btnCancelText | translate }}"
		></app-button>

		<app-button
			(buttonClicked)="accept()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			class="button-confirm"
			buttonLabel="{{ btnOkText | translate }}"
		></app-button>
	</div>
</div>
