export enum UserPlanLimitCodesEnum {
	DISCOVERY_SEARCH = 1,
	DISCOVERY_PROFILE = 2,
	DISCOVERY_EMAILS = 3,
	TEAM_SIZE = 4,
	EMAILS_INTEGRATION = 5,
	EXPORTS = 6,
	RESETED_COUNTERS = 7
}

export enum UserPaymentSubscriptionCodesEnum {
	SUB_EXPIRED = 1,
	TRIAL_EXPIRED = 2,
	PAYMENT_FAILED = 3,
	SUB_NOT_FOUND = 4,
	EMAIL_IS_NOT_CONFIRMED = 5
}

export interface UserPlanLimitResponseModel {
	detail: UserPlanLimitModel;
}

export interface UserPlanLimitModel {
	error_code: UserPlanLimitCodesEnum;
	message: string;
}
