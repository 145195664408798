import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
	selector: 'app-auto-multi-drp-with-chips',
	templateUrl: './auto-multi-drp-with-chips.component.html',
	styleUrls: ['./auto-multi-drp-with-chips.component.scss']
})
export class AutoMultiDrpWithChipsComponent implements OnInit, OnDestroy {
	@ViewChild('multiChipInput') multiChipInput;
	@Input() width: string = '100%';
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl;

	public separatorKeysCodes: number[] = [ENTER, COMMA];
	public textControl = new UntypedFormControl();
	public chipList: string[] = [];
	public unsubscriber$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		this.inputControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(val => {
			if (!val) {
				this.chipList = [];
				this.multiChipInput.nativeElement.value = '';
				this.textControl.reset();
			}
		});
		if (this.inputControl.value && this.inputControl.value.length) {
			this.mapInitialValues();
		}
	}

	public mapInitialValues(): void {
		this.chipList = [];
		this.inputControl.value.forEach(x => {
			this.chipList.push(x);
		});
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our item
		if ((value || '').trim()) {
			this.chipList.push(value.trim());
			this.inputControl.patchValue(this.chipList);
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.textControl.setValue(null);
	}

	remove(item: string): void {
		const index = this.chipList.indexOf(item);

		if (index >= 0) {
			this.chipList.splice(index, 1);
			this.inputControl.patchValue(this.chipList);
		}
	}

	public onClickInput(): void {
		const nativeElement = this.multiChipInput.nativeElement;
		nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
