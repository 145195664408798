import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { LocationDataModel } from '../models/locations.model';

@Injectable({
	providedIn: 'root'
})
export class LocationService {
	constructor(private http: HttpClient) {}

	// * Accepts fetch=country,state,city & search=name
	public fetchLocations(queryParams: HttpParams): Observable<{ data: LocationDataModel[] }> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}location`);
		return this.http.get<{ data: LocationDataModel[] }>(url.href, { params: queryParams });
	}
}
