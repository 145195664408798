import { Injectable } from '@angular/core';
import { BaseApiUrl } from '../base-api-urls';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class BigcommerceService {
	public BIG_COMMERCE_CLIENT_ID: string = 'nimmex6d8qbte50c00p1cmv9xa5gtmy';

	constructor(private http: HttpClient) {}

	public validate(): Observable<HttpResponse<any>> {
		return this.http.get<HttpResponse<any>>(`${BaseApiUrl.SocialMessengerPython}oauth/bigcommerce/validate`, { observe: 'response' });
	}

	public user(storeHash: string): Observable<HttpResponse<any>> {
		return this.http.patch<HttpResponse<any>>(`${BaseApiUrl.SocialMessengerPython}oauth/bigcommerce/user`, { store: storeHash });
	}

	public disconnect(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}oauth/bigcommerce/uninstall`);
	}

	// BIGCOMMERCE LANDING COMPONENT API
	public loadBigcommerceApp(data: any): Observable<string> {
		let url = `${BaseApiUrl.SocialMessengerPython}oauth/bigcommerce/preinstall`;
		url += `?shop=${data.shop}`;
		url += `&code=${data.code}`;
		url += `&hmac=${data.hmac}`;
		url += `&timestamp=${data.timestamp}`;
		url += `&host=${data.host}`;
		return this.http.post<string>(url, {});
	}
}
