<ng-container>
	<div class="action-button cursor-pointer">
		<mat-form-field
			[matMenuTriggerFor]="parentMenu"
			(focusout)="onFocusOut()"
			#parentMenuTrigger="matMenuTrigger"
			appearance="outline"
			class="outline"
			[floatLabel]="'never'"
		>
			<input matInput class="example-right-align" placeholder="Name Your Job" type="text" [formControl]="searchFormControl" />
		</mat-form-field>
	</div>
	<mat-menu #parentMenu="matMenu" class="drp-menu" [ngStyle]="styleMenu()">
		<mat-accordion (click)="$event.stopPropagation()" [multi]="true">
			<ng-container *ngFor="let parent of filteredData; let parentSequence = index">
				<mat-expansion-panel
					class="drp-panel"
					[ngClass]="parent.is_expanded ? 'drp-panel-open' : 'drp-panel-closed'"
					hideToggle
					[(expanded)]="parent.is_expanded"
					(opened)="parent.is_expanded = true"
					(closed)="parent.is_expanded = false"
				>
					<mat-expansion-panel-header>
						<div class="d-flex w-100 k-align-items-center gap-12">
							<mat-icon *ngIf="parent.is_expanded" class="drp-arrow">keyboard_arrow_down</mat-icon>
							<mat-icon *ngIf="!parent.is_expanded" class="drp-arrow">keyboard_arrow_up</mat-icon>
							<mat-checkbox
								id="parent-{{ parent?.id }}"
								(click)="$event.stopPropagation()"
								[indeterminate]="someComplete(parent)"
								[checked]="parent.is_checked"
								(change)="setAll(parent, $event.checked)"
								class="drp-checkbox"
								type="checkbox"
							></mat-checkbox>
							<label
								for="parent-{{ parent?.id }}"
								(click)="$event.stopPropagation()"
								class="search-drp-name w-100"
								(click)="toggleParent(parent)"
							>
								{{ parent?.name }}
							</label>
						</div>
					</mat-expansion-panel-header>
					<div class="child-drp-list-wrapper" (click)="$event.stopPropagation()">
						<ul class="child-drp-list">
							<li
								class="child-drp-list-item d-flex w-100 k-align-items-center gap-12"
								*ngFor="let child of parent.children; let childSequence = index"
							>
								<mat-checkbox
									id="{{ parent?.id }}-child-{{ child?.id }}"
									class="drp-checkbox"
									type="checkbox"
									[(ngModel)]="child.is_checked"
									(ngModelChange)="updateAllComplete(parent)"
								></mat-checkbox>
								<label for="{{ parent?.id }}-child-{{ child?.id }}" class="search-drp-name w-100" (click)="toggleChild(parent, child)">
									{{ child?.name }}
								</label>
							</li>
						</ul>
					</div>
				</mat-expansion-panel>
			</ng-container>
		</mat-accordion>
	</mat-menu>
</ng-container>
