import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { MyPlanService } from '../my-plan/my-plan.service';
import { InvoiceInterface } from './components/invoice-lists/invoice-lists.model';
import { DropdownData } from 'src/app/shared-components/models/dropdown.interface';
import { getStripePlanType } from 'src/app/shared/utils';

@Component({
	selector: 'app-payment-billing',
	templateUrl: './payment-billing.component.html',
	styleUrls: ['./payment-billing.component.scss']
})
export class PaymentBillingComponent implements OnInit {
	public userForm: UntypedFormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public userId: string;
	public decodeJwtToken: UserDetailsInterface;
	public countries: Country[];
	public dropdownCountries: DropdownData[];
	public currPlanPriceId: string;
	public planDuration: string;
	public invoicesData: Array<InvoiceInterface>;

	public planData: any;
	public loading = false;
	public isSubUser = true;
	public showAllPlans: boolean = false;
	public isTrialPeriodActive: boolean = false;

	public paymentCard: any;
	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private router: Router,
		public _myPlan: MyPlanService,
		private toast: ToastNotificationService
	) {
		let showPlans = this.router.getCurrentNavigation().extras?.state?.showAllPlans;
		if (showPlans) this.showAllPlans = true;
	}

	ngOnInit(): void {
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
		this.isSubUser = this.decodeJwtToken?.sub_user === true ? true : false;
		this.getActiveSubscriptions();
	}

	public getActiveSubscriptions(): void {
		this.loading = true;
		const token = localStorage.getItem(StorageKey.token);
		this._myPlan
			.getActiveSubs(token)
			.subscribe(res => {
				// console.log(res);
				this.planData = {
					...res,
					name: getStripePlanType(res.name)
				};
				// this.currPlanPriceId = res.product_price_id;
				this.currPlanPriceId = res.price_id;
				this.isTrialPeriodActive = res.status === 'trialing';
			})
			.add(() => {
				this.loading = false;
			});
	}

	public reviewHistory(): void {
		const token = localStorage.getItem(StorageKey.token);
		this._myPlan
			.getStripeUrl(token)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((response: string) => {
				if (response && typeof response === 'string') {
					window.open(response, '_blank');
				} else {
					this.toast.sendErrorToast('Something went wrong, Please try again later');
				}
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onUpdateCurrentDefaultCard(cardDetail: any): void {
		this.paymentCard = cardDetail;
	}

	// public addCard(): void {
	// 	this.router.navigate(['/user-management/payment-and-billing/add-card']);
	// }

	// public payNow(): void {
	// 	window.open(this.planData.billing_object.url, '_blank');
	// }
}
