import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { InfluencerCardInterface } from './models/influencer-card-interface';
import { InfluencerCardOrientation } from './models/influencer-card-orientation.enum';

@Component({
	selector: 'app-card-list',
	templateUrl: './card-list.component.html',
	styleUrls: ['./card-list.component.scss']
})
export class AppCardListComponent {
	@Input() public data: InfluencerCardInterface;
	@Input() public width = '16.875rem';
	@Input() public height = '2.813rem';
	@Input() public selected = false;
	@Input() public disabled = false;
	@Input() public withRemove = false;

	@Output() public onCheckToggle: EventEmitter<boolean>;
	@Output() public onRemoveCardList: EventEmitter<InfluencerCardInterface>;

	@Output() public onClick: EventEmitter<InfluencerCardInterface>;
	@Output() public sendToCampaign: EventEmitter<InfluencerCardInterface>;
	@Output() public saveToList: EventEmitter<InfluencerCardInterface>;
	@Output() public newList: EventEmitter<InfluencerCardInterface>;

	public cardOrientation = InfluencerCardOrientation;

	@HostListener('click') public onCardClick(): void {
		this.onClick.emit(this.data);
	}

	constructor() {
		this.onCheckToggle = new EventEmitter<boolean>();
		this.onClick = new EventEmitter<InfluencerCardInterface>();
		this.sendToCampaign = new EventEmitter<InfluencerCardInterface>();
		this.saveToList = new EventEmitter<InfluencerCardInterface>();
		this.newList = new EventEmitter<InfluencerCardInterface>();
		this.onRemoveCardList = new EventEmitter<InfluencerCardInterface>();
	}

	public toggle(): void {
		this.onCheckToggle.emit(this.selected);
	}

	public removeCardList(): void {
		this.onRemoveCardList.emit(this.data);
	}

	public sendInfluencerToCampaign(): void {
		this.sendToCampaign.emit(this.data);
	}
	public saveInfluencerToist(): void {
		this.saveToList.emit(this.data);
	}
	public createNewListWithInfluencer(): void {
		this.newList.emit(this.data);
	}
}
