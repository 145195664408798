<div class="form-input">
	<kendo-multiselect
		class="wrapper filed-combobox"
		(valueChange)="changes($event)"
		[data]="data"
		[disabled]="disabled"
		[formControl]="control"
		[placeholder]="placeholder"
		[style.width]="width"
		[textField]="'name'"
		[valueField]="'id'"
		[clearButton]="allowClear"
		(removeTag)="onRemove($event)"
		(filterChange)="filters($event)"
		[filterable]="true"
	></kendo-multiselect>
</div>
