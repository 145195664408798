<div class="disclosure-card card">
	<div class="title">Disclosure</div>
	<div class="desc">
		<p>
			Lolly strictly uses Google’s Application Programming Interface (API) Services to send emails to the creator on your behalf and to read any reply
			made by the creator to the sent email. Lolly’s use and transfer to any other app of information received from Google APIs will adhere to
			<span>
				<a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">
					Google API Services User Data Policy
				</a>
			</span>
			, including the
			<span>
				<a
					href="https://support.google.com/cloud/answer/9110914#explain-types&zippy=%2Ccould-you-explain-the-limited-use-requirements-from-the-google-api-services-user-data-policy"
					target="_blank"
				>
					Limited Use Requirements.
				</a>
			</span>
		</p>
		<p>
			We value and understand the security and privacy of your google account, hence we maintain and comply with the Google API Services User Data Policy.
			Refer to our
			<span><a href="https://acquire.io/privacy-policy/" target="_blank">Privacy Policy</a></span>
			for more information regarding the use of Lolly.com platform and its services.
		</p>
		<p>
			Lolly assures that your Google account is used to facilitate better communication between you and the creator as a part of our CRM (creator
			relationship management).
		</p>
	</div>
</div>
