import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface DropDownInterface {
	label: string;
	value: any;
}
@Component({
	selector: 'app-select-item',
	templateUrl: './select-item.component.html',
	styleUrls: ['./select-item.component.scss']
})
export class SelectItemComponent implements OnInit {
	@Input() public dropDownControl: UntypedFormControl;
	@Input() public dropDownData: DropDownInterface[] = [];
	@Input() public placeholderText?: string = '';
	@Input() public showCaretIcon?: boolean = true;
	@Input() public allowFilter?: boolean = false;
	@Input() public caretColor?: string = '';
	@Output() public onItemSelected: EventEmitter<any> = new EventEmitter();

	public showDropdown: boolean = false;
	public textToShow: string = this.placeholderText;

	public searchControl: UntypedFormControl;
	public filterList: any = [];
	public disable?: boolean = false;

	public data: any;

	private unsubscriber$: Subject<void> = new Subject<void>();
	constructor() {}

	ngOnInit(): void {
		this.searchControl = new UntypedFormControl('');
		this.data = this.dropDownData;
		this.filterList = this.dropDownData;
		this.whatTextToShow();
		this.filterDropDownData();
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.filterList = this.dropDownData;
	}

	public toggleDropdown(): void {
		this.showDropdown = !this.showDropdown;
	}
	public onSelectItem(value: any): void {
		this.onItemSelected.emit(value);
		this.whatTextToShow();
		this.showDropdown = false;
	}

	public whatTextToShow() {
		if (this.dropDownControl.value == '') {
			this.textToShow = this.placeholderText;
		} else {
			this.textToShow = this.dropDownControl.value instanceof Object ? this.dropDownControl.value.name : this.dropDownControl.value;
		}
	}
	public filterDropDownData() {
		this.searchControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (value && value !== '') {
				this.filterList = this.dropDownData.filter(data => data.label.toString().toLowerCase().includes(value.toString().toLowerCase()));
			} else {
				this.filterList = this.dropDownData;
			}
		});
	}
}
