<div class="page-container">
	<div class="form-area">
		<div class="page-header">
			<div class="back-btn">
				<a (click)="backClicked()">
					<i class="k-icon k-i-arrow-chevron-left"></i>
					Back
				</a>
			</div>
		</div>
		<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<div class="title">Set Up Two-Factor Authentication</div>
					<div class="sub-title">
						Enabling this will provide an extra layer of security for your account. When logging in, we will ask for a special authentication code
						from your device.
					</div>
					<div *ngIf="editAuth">
						<div class="form-btn form-update">
							<div class="phone-input">
								<div class="title">Phone Number</div>
								<div class="sub-title">
									Enter below to change to a new phone number. This phone number will be used to authenticate you account.
								</div>
								<!-- <intl-tb
									(selectCountry)="onSelectCountry($event)"
									[control]="accountForm.get('phone')"
									[defaultFlagIcon]="defaultCountryCode"
									[validatorMessages]="validationMessages"
									[placeHolderTextBox]="'Enter your phone number'"
									class="input-box telephone"
								></intl-tb> -->
							</div>
							<div class="form-btn dual">
								<button class="button left" (click)="onCancel()" [disabled]="accountForm.controls['digitcode'].invalid">
									CANCEL
								</button>
								<button class="button left" (click)="onNext()" [disabled]="accountForm.controls['digitcode'].invalid">
									CONFIRM
								</button>
							</div>
						</div>
					</div>

					<div *ngIf="showAuth">
						<div class="phone-input">
							<div class="title">phone number</div>
							<div class="sub-title">
								Enter your phone number below to enable two-factor authentication. This phone number will be used to authenticate you account.
							</div>
							<!-- <intl-tb
								(selectCountry)="onSelectCountry($event)"
								[control]="accountForm.get('phone')"
								[defaultFlagIcon]="defaultCountryCode"
								[validatorMessages]="validationMessages"
								[placeHolderTextBox]="'Enter your phone number'"
								class="input-box telephone"
							></intl-tb> -->
						</div>
						<div class="form-btn">
							<button class="button left" (click)="onNext()" [disabled]="accountForm.invalid">
								CONTINUE
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
