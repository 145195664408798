import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarGraph } from '../models/progressbar-chart.interface';

@Component({
	selector: 'lolly-graph06-progressbar',
	templateUrl: './graph06-progressbar.component.html',
	styleUrls: ['./graph06-progressbar.component.scss']
})
export class Graph06ProgressbarComponent implements OnInit {
	@Input() public graphWidth: number;
	@Input() public graphData: ProgressBarGraph;
	@Input() public graphTitle: string;
	@Input() public infoToolTip: boolean = false;
	@Input() public infoToolTipText: string;

	constructor() {}

	ngOnInit(): void {}
}
