import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LollyLoadingDataModel } from './lolly-loading-data.model';

@Component({
	selector: 'app-lolly-loading-dialog',
	templateUrl: './lolly-loading-dialog.component.html',
	styleUrls: ['./lolly-loading-dialog.component.scss']
})
export class LollyLoadingDialogComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data: LollyLoadingDataModel, public dialogRef: MatDialogRef<LollyLoadingDialogComponent>) {}

	ngOnInit(): void {}
}
