<input
	class="date-input"
	[formControl]="control"
	type="date"
	id="start"
	name="trip-start"
	value="{{ value | date: 'y-MM-dd' }}"
	min="{{ min | date: 'y-MM-dd' }}"
	max="{{ nextYears }}-12-31"
/>
