import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-new-budget-input',
	templateUrl: './new-budget-input.component.html',
	styleUrls: ['./new-budget-input.component.scss']
})
export class NewBudgetInputComponent implements OnInit {
	@Input() public currencyControl = new UntypedFormControl();
	@Input() public inputControl = new UntypedFormControl();
	@Input() public currencies: any[] = [];
	@Input() public disableCurrencyChange: boolean = false;
	@Input() public placeholder: string = '0';
	@Input() public currencyDisabledTooltip: string = '';

	constructor() {}

	ngOnInit(): void {}

	public selectCurrency(currency): void {
		this.currencyControl.patchValue(currency);
	}
}
