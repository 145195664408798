<div class="container">
	<div class="text-center wrapper">
		<div class="login-image">
			<img src="../../../../../../assets/logo/lolly-logo.svg" width="79" height="81" />
		</div>
		<div class="info-1">
			<filed-font [fontSize]="'14px'" [fontWeight]="'400'">
				<span>
					<b>Lolly</b>
					would like access your
					{{
						(channel.source === 'facebook-normal' || channel.source === 'facebook' ? 'Facebook' : channel.title) ||
							(channel.source === 'google-normal' || channel.source === 'google' ? 'Google' : channel.title)
					}}
					API credentials connected to your account.
				</span>
			</filed-font>
		</div>
		<filed-font [fontSize]="'14px'" [fontWeight]="'400'">
			<span class="info-2">
				By continuing, Lolly will receive ongoing access to the information and products that you add to
				{{ channel.title }}.
			</span>
		</filed-font>
	</div>
	<div class="footer-button">
		<app-primary-button
			[buttonLable]="'Continue'"
			[buttonType]="'Secondary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="closeDialog(false)"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="'Continue'"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="closeDialog(true)"
		></app-primary-button>
	</div>
</div>
