import { userPermission, userPermissionNewUserAdmin } from './permissions-user.enum';
// import { BusinessModel } from '../../accounts/models/business.model';//silsrang-migration

export class NewUserModel {
	public id?: number;
	public firstName: string;
	public lastName?: string;
	public fullName?: string;
	public email?: string;
	public phoneNumber: string;
	public position?: string;
	public accountStateId?: string;
	public FiledPermissionsIds?: object;
	public FacebookBusinessPermissions?: any[]; //silsrang-migration
	public googleAdAccountPermissions?: any[]; //silsrang-migration
	public ModuleAccessTypes?: userPermission;
	public adAccountType?: string;
	public accountState?: number = 1;
	public access?: string;

	constructor() {}
}

export interface FacebookAdAccount {
	facebookAdAccountId?: string;
	businessId: number;
	checked: boolean;
	name: string;
	id: string;
}
