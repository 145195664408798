<!-- confirm password when logIn -->
<div class="input-wrapper">
	<div class="password-wrapper">
		<mat-form-field
			*ngIf="isLogin"
			[ngClass]="{
				'password-error-message': control.invalid && control.touched,
				'password-success-message': control.valid && control.touched,
				'password-disabled-message': control.disabled
			}"
			appearance="outline"
			floatLabel="always"
		>
			<mat-label>{{ label }}</mat-label>

			<input
				(focus)="autocompleteFocus()"
				(focusout)="setErrorMessage()"
				[formControl]="control"
				matInput
				[type]="hidePassword ? 'password' : 'text'"
				placeholder="{{ placeholder }}"
				type="password"
				(keyup.enter)="onEnterKeyUp()"
				lazyAutofocus
			/>

			<!--Icon-->
			<button mat-icon-button matSuffix type="button" (click)="toggleEvent()">
				<i class="fas fa-eye"></i>
			</button>

			<!--Error-->
			<mat-error *ngIf="control.invalid && control.hasError('required')">
				Password is required
			</mat-error>
		</mat-form-field>

		<!--confirm password when !logIn -->
		<form [formGroup]="myForm" *ngIf="!isLogin" autocomplete="off">
			<mat-form-field
				[ngClass]="{
					'password-error-message': myForm.controls['password'].invalid && myForm.controls['password'].touched,
					'password-success-message': myForm.controls['password'].valid,
					'password-disabled-message': myForm.controls['password'].disabled
				}"
				appearance="outline"
				floatLabel="always"
			>
				<mat-label>{{ label }}</mat-label>

				<input
					(focus)="autocompleteFocus()"
					(focusout)="setErrorMessage()"
					name="secret"
					formControlName="password"
					matInput
					[type]="hidePassword ? 'password' : 'text'"
					placeholder="{{ placeholder }}"
					type="password"
					autocomplete="off"
					lazyAutofocus
				/>

				<!--Icon-->
				<button mat-icon-button matSuffix (click)="toggleEvent()">
					<i class="fas fa-eye"></i>
				</button>
			</mat-form-field>

			<!--Confirm password-->
			<mat-form-field
				[ngClass]="{
					'password-error-message': myForm.controls['confirmPassword'].invalid && myForm.controls['confirmPassword'].touched,
					'password-success-message': myForm.controls['confirmPassword'].valid,
					'password-disabled-message': myForm.controls['confirmPassword'].disabled
				}"
				class="section-confPassword"
				appearance="outline"
				floatLabel="always"
			>
				<mat-label>Confirm Password</mat-label>

				<input
					(focus)="autocompleteFocus()"
					(focusout)="setErrorMessage()"
					formControlName="confirmPassword"
					[type]="isConfirmHidden ? 'password' : 'text'"
					matInput
					name="secret2"
					autocomplete="off"
				/>

				<!--Icon -->
				<button mat-icon-button matSuffix (click)="toggleEvent(true)">
					<i class="fas fa-eye"></i>
				</button>
			</mat-form-field>
			<!--Error message-->
			<mat-error *ngIf="myForm.controls['password'].valid && myForm.controls['confirmPassword'].hasError('NoPasswordMatch')">
				Password do not match
			</mat-error>
			<ng-container *ngIf="myForm.get('password')?.touched || myForm.get('confirmPassword')?.touched || customTrigger">
				<!--Error password-->
				<mat-error *ngIf="isLogin && myForm.controls['password'].hasError('required')">
					Password is required
				</mat-error>
				<mat-error *ngIf="myForm.controls['password'].hasError('required') || myForm.controls['password'].hasError('minlength')">
					Must be at least 8 characters!
				</mat-error>
				<mat-error *ngIf="myForm.controls['password'].hasError('required') || myForm.controls['password'].hasError('hasNumber')">
					Must contain at least 1 number!
				</mat-error>
				<mat-error *ngIf="myForm.controls['password'].hasError('required') || myForm.controls['password'].hasError('hasCapitalCase')">
					Must contain at least 1 in Capital Case!
				</mat-error>
				<mat-error *ngIf="myForm.controls['password'].hasError('required') || myForm.controls['password'].hasError('hasSmallCase')">
					Must contain at least 1 Letter in Small Case!
				</mat-error>
				<mat-error *ngIf="myForm.controls['password'].hasError('required') || myForm.controls['password'].hasError('hasSpecialCharacters')">
					Must contain at least 1 Special Character!
				</mat-error>
			</ng-container>
		</form>
	</div>
</div>
