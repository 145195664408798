import { Component, Injector, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
	@Input() cardDetails: UntypedFormGroup;
	@Input() isEdit: boolean = false;
	@Input() formControlsRequests: any = {};

	private unsubscriber$: Subject<void> = new Subject<void>();
	public expirationDate: UntypedFormControl;
	public countries: Country[];
	public countryData: any[];

	constructor(private injector: Injector, private formBuilder: UntypedFormBuilder, public backOfficeService: BackOfficeService) {}

	ngOnInit(): void {
		this.setDropdownCountries();
		this.createForm();
	}

	public createForm(): void {
		this.expirationDate = new UntypedFormControl('', [
			Validators.required,
			Validators.pattern('^(?=.*[0-9])[/0-9]+$'),
			Validators.minLength(6),
			Validators.maxLength(7)
		]);

		if (this.checkInRequest('firstName')) {
			this.cardDetails.addControl('firstName', this.formBuilder.control(''));
			this.cardDetails.controls['firstName'].setValidators(Validators.required);
		}
		if (this.checkInRequest('lastName')) {
			this.cardDetails.addControl('lastName', this.formBuilder.control(''));
			this.cardDetails.controls['lastName'].setValidators(Validators.required);
		}
		if (this.checkInRequest('companyName')) {
			this.cardDetails.addControl('companyName', this.formBuilder.control(''));
			this.cardDetails.controls['companyName'].setValidators(Validators.required);
		}
		if (this.checkInRequest('phone')) {
			this.cardDetails.addControl('phone', this.formBuilder.control(''));
			this.cardDetails.controls['phone'].setValidators(Validators.required);
		}
		if (this.checkInRequest('firstAddress')) {
			this.cardDetails.addControl('firstAddress', this.formBuilder.control(''));
			this.cardDetails.controls['firstAddress'].setValidators(Validators.required);
		}
		if (this.checkInRequest('secondAddress')) {
			this.cardDetails.addControl('secondAddress', this.formBuilder.control(''));
		}
		if (this.checkInRequest('city')) {
			this.cardDetails.addControl('city', this.formBuilder.control(''));
			this.cardDetails.controls['city'].setValidators(Validators.required);
		}
		if (this.checkInRequest('zipCode')) {
			this.cardDetails.addControl('zipCode', this.formBuilder.control(''));
			this.cardDetails.controls['zipCode'].setValidators(Validators.required);
		}
		if (this.checkInRequest('country')) {
			this.cardDetails.addControl('country', this.formBuilder.control(null));
			this.cardDetails.controls['country'].setValidators(Validators.required);
		}
		let expiryDate = this.cardDetails.controls['expirationMonth'].value ? this.cardDetails.controls['expirationMonth'].value + '/' : '';
		expiryDate += this.cardDetails.controls['expirationYear'].value
			? this.cardDetails.controls['expirationYear'].value[2] + this.cardDetails.controls['expirationYear'].value[3]
			: '';
		this.expirationDate.setValue(expiryDate);
	}
	public checkInRequest(key: string): boolean {
		const index = this.formControlsRequests.indexOf(key);
		return index > -1;
	}

	private setDropdownCountries(): void {
		this.backOfficeService
			.getAllCountriesList()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.countries = result;
				this.countryData = this.countries.map((value, index) => {
					return {
						value: value,
						label: value.name,
						id: index
					};
				});
			});
	}

	public formatExpiryDate(value: any): void {
		value = this.expirationDate.value;
		if (!value.includes('/')) {
			value = value
				.replace(/\W/gi, '')
				.replace(/(.{2})/g, '$1/')
				.replace(/\/+$/, '');
			this.expirationDate.setValue(value);
		}

		const expires = value.split('/');
		this.cardDetails.controls['expirationMonth'].setValue(expires[0]);
		let yearValue = expires[1] || expires[0];
		if (expires[1] && expires[1].length == 3) {
			this.expirationDate.setErrors({ incorrect: true });
		}
		this.cardDetails.controls['expirationYear'].setValue(yearValue);
	}

	public creditCardNumberSpacing(value: any): void {
		value = this.cardDetails.get('cardNumber').value;
		value = value
			.replace(/\W/gi, '')
			.replace(/(.{4})/g, '$1 ')
			.replace(/\s+$/, '');
		this.cardDetails.get('cardNumber').setValue(value);
	}

	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.cardDetails.controls['country'].setValue(selected);
		}
	}
}
