<div class="page-container">
	<div class="form-area">
		<div class="page-header">
			<div class="back-btn">
				<a [routerLink]="'/user-management/my-plan'">
					<i class="k-icon k-i-arrow-chevron-left"></i>
					Back
				</a>
			</div>
		</div>
		<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="left-pane">
				<div class="inner-div">
					<div class="duration">
						<div class="title">Payment Info</div>
						<div class="selectableDiv" [class.active-class]="duration == 'annual'" (click)="onChangeDuration('annual')">
							<div class="check">
								<img src="assets/icons/Listing-Icon.svg" *ngIf="duration !== 'annual'" />
								<img src="assets/icons/fullfilled.png" *ngIf="duration === 'annual'" />
							</div>
							<div class="name">Annually</div>
							<div class="discount"><span>Save 20%</span></div>
							<div class="pricing">
								<div class="amount">{{ calculateDiscount }}/month</div>
								<div class="sub-title">{{ getAnnulaSummary }} billed annually</div>
							</div>
						</div>
						<div class="selectableDiv" [class.active-class]="duration == 'month'" (click)="onChangeDuration('month')">
							<div class="check">
								<img src="assets/icons/Listing-Icon.svg" *ngIf="duration !== 'month'" />
								<img src="assets/icons/fullfilled.png" *ngIf="duration === 'month'" />
							</div>
							<div class="name">Monthly</div>
							<div class="discount"></div>
							<div class="pricing">
								<div class="amount">{{ getTarrifAmount }}/month</div>
								<div class="sub-title">{{ getTarrifAmount }} billed monthly</div>
							</div>
						</div>
					</div>

					<app-payment-cards
						[source]="'plan'"
						[userDetail]="decodeJwtToken"
						[hideRemove]="true"
						(updateCurrentDefaultCard)="onUpdateCurrentDefaultCard($event)"
					></app-payment-cards>
				</div>
				<div class="pay-form-btn">
					<app-primary-button
						[buttonLable]="'+ Add Card'"
						[buttonType]="'Primary'"
						[isPeddingReduceSmall]="true"
						(buttonClicked)="addCard()"
					></app-primary-button>
				</div>
			</div>
			<div class="container">
				<div class="title">Order Summary</div>
				<div class="plan">LOLLY Plan - {{ planDetails?.subscriptionName }}</div>
				<div class="logo"><img src="assets/images/signup/flash-icon.svg" /></div>

				<div class="price">
					<div class="title">Total due today</div>
					<div class="amount">{{ getPlanAmount }}</div>
				</div>
				<div class="duration">
					<div class="remark">Billed {{ duration == 'month' ? 'monthly' : 'annual' }}.</div>
					<div class="sub-title">We will bill you every {{ planDetails?.billingInterval }} on the 1st, unless you cancel.</div>
					<div class="sub-title2">
						Your next bill will be on the {{ duration == 'month' ? planDetails?.nextInvoiceDate : planDetails?.nextInvoiceYear }}
					</div>
				</div>

				<div class="form-btn">
					<app-primary-button
						[buttonLable]="'Subscribe'"
						[buttonType]="'Primary'"
						[isPeddingReduceSmall]="true"
						(buttonClicked)="subscribeToNewPlan()"
					></app-primary-button>
				</div>
			</div>
		</div>
	</div>
</div>
