import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { PermissionsApiOptionsService } from 'src/app/shared/permisions/permissions-api-options.service';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { NewUserModel } from 'src/app/user-management/models/new-user-model';
import { NewuserPermission, PermissionsUserEnum, userPermission } from 'src/app/user-management/models/permissions-user.enum';
import { FrontEndModule } from 'src/app/user-management/models/user-permissions-table.model';
import { getUserAdminState, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { CompleteModalComponent } from '../../../modals/complete-modal/complete-modal.component';
import { VerifyAddUserComponent } from '../../../modals/verify-add-user/verify-add-user.component';
import { DropdownInterface } from 'src/app/shared-components/models/dropdown.interface';

export enum StepEnum {
	step1 = 'profile',
	step2 = 'account',
	step3 = 'standard',
	step4 = 'view'
}

@Component({
	selector: 'app-user-process',
	templateUrl: './user-process.component.html',
	styleUrls: ['./user-process.component.scss']
})
export class UserProcessComponent implements OnInit {
	public allStep: typeof StepEnum;
	public userDetail: any;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public isEdit: boolean = false;
	public userId: string;
	public userRoles: any;
	public dropDownListItems: DropdownInterface[] = [
		{ name: 'Standard', id: 1 },
		{ name: 'View Only', id: 2 }
	];

	public defaultPermissions: NewuserPermission[] = [
		{
			frontEndModule: FrontEndModule.Accounts,
			accessType: PermissionsUserEnum.View,
			title: 'Accounts',
			desc: "Track & monitor all of your ad account's performance from a single dashboard",
			isEdit: { status: false, active: false },
			isViewOnly: { status: true, active: true },
			isAccessBoth: { status: false, active: false }
		},
		{
			frontEndModule: FrontEndModule.AdsManager,
			accessType: PermissionsUserEnum.Full,
			title: 'Ads Manager',
			desc: 'Manage and edit all your campaign at one place',
			isEdit: { status: true, active: true },
			isViewOnly: { status: true, active: true },
			isAccessBoth: { status: true, active: true }
		},
		{
			frontEndModule: FrontEndModule.CampaignBuilder,
			accessType: PermissionsUserEnum.Full,
			title: 'Campaign Builder',
			desc: 'Create dexter assist high performing campaigns',
			isEdit: { status: true, active: true },
			isViewOnly: { status: true, active: true },
			isAccessBoth: { status: true, active: true }
		},
		{
			frontEndModule: FrontEndModule.Audience,
			accessType: PermissionsUserEnum.Full,
			title: 'Audience',
			desc: 'Experiment with high performing pre built audience suggestions. Or create high performing audiences based on your existing data',
			isEdit: { status: true, active: true },
			isViewOnly: { status: true, active: true },
			isAccessBoth: { status: true, active: true }
		},
		{
			frontEndModule: FrontEndModule.Reports,
			accessType: PermissionsUserEnum.Full,
			title: 'Reports',
			desc: "Create personalized dashboards to analyze your ad account's performances",
			isEdit: { status: true, active: true },
			isViewOnly: { status: true, active: true },
			isAccessBoth: { status: true, active: true }
		},
		{
			frontEndModule: FrontEndModule.Optimize,
			accessType: PermissionsUserEnum.Full,
			title: 'Optimize',
			desc: "Optimize your advertising campaign's performance based on AI based recommendations and new ideas",
			isEdit: { status: false, active: false },
			isViewOnly: { status: true, active: true },
			isAccessBoth: { status: false, active: false }
		},
		{
			frontEndModule: FrontEndModule.Pixel,
			accessType: PermissionsUserEnum.View,
			title: 'Pixel',
			desc: 'Manage your tracking codes across different platforms. All from a single place',
			isEdit: { status: false, active: false },
			isViewOnly: { status: true, active: true },
			isAccessBoth: { status: false, active: false }
		},
		{
			frontEndModule: FrontEndModule.UserManagement,
			accessType: PermissionsUserEnum.View,
			title: 'Feed manager',
			desc: 'Import and export product data from one channel to another',
			isEdit: { status: false, active: true },
			isViewOnly: { status: false, active: true },
			isAccessBoth: { status: false, active: true }
		},
		{
			frontEndModule: FrontEndModule.BillingAndPayment,
			accessType: PermissionsUserEnum.View,
			title: 'Influencer',
			desc: "Make quality influencer marketing campaigns that expands your brand's awareness instantly",
			isEdit: { status: false, active: true },
			isViewOnly: { status: false, active: true },
			isAccessBoth: { status: false, active: true }
		}
	];

	public userPermissions: userPermission[] | userPermission;
	public currentStep: StepEnum = StepEnum.step1;

	public userData: any;
	public allPermissionsForAllModules: Map<FrontEndModule, Map<PermissionsUserEnum, boolean>>;

	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private formBuilder: UntypedFormBuilder,
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService,
		private store: Store<UserManagementState>,
		private activatedRoute: ActivatedRoute,
		private roleServices: PermissionsApiOptionsService,
		private router: Router,
		private sharedStore: Store<SharedState>
	) {}

	public ngOnInit(): void {
		this.allStep = StepEnum;
		this.currentStep = this.allStep.step1;
		this.getActivatedRoute();
		this.fetchStore();
		this.getBOPermissions();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params && params['userId'] !== undefined) {
				this.userId = encodeURIComponent(params['userId']);
				this.isEdit = true;
			}
		});
	}

	private fetchStore(): void {
		this.store.pipe(select(getUserAdminState), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.userDetail = detail;
			}
		});
	}
	public getBOPermissions(): void {
		if (this.isEdit) {
			this.userServiceApi
				.getUserById(+this.userId, false)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					let newUserPermission = [];
					if (res.moduleAccessTypes.length > 0) {
						this.defaultPermissions.map(x => {
							let filtered = res.moduleAccessTypes.filter(item => item.frontEndModule == x.frontEndModule);
							if (filtered && filtered.length > 0) {
								if (filtered[0].accessType === 1) {
									x.isViewOnly.status = true;
								} else if (filtered[0].accessType === 2) {
									x.isEdit.status = true;
								} else {
									x.isViewOnly.status = true;
									x.isEdit.status = true;
									x.isAccessBoth.status = true;
								}
							}
							newUserPermission.push(x);
						});
					}
					this.userPermissions = newUserPermission as userPermission[];
				});
		} else {
			this.userPermissions = this.defaultPermissions;
		}
	}

	private fixPermission(permissions: any): any {
		let newPermission = [];
		permissions.map(permission => {
			if (permission.isEdit.status && permission.isViewOnly.status) {
				newPermission.push({
					frontEndModule: permission.frontEndModule,
					accessType: PermissionsUserEnum.Full
				});
			} else if (
				(!permission.isEdit.status && permission.isViewOnly.status && permission.isAccessBoth.status) ||
				(!permission.isEdit && permission.isViewOnly && !permission.isAccessBoth.status)
			) {
				newPermission.push({
					frontEndModule: permission.frontEndModule,
					accessType: PermissionsUserEnum.View
				});
			}
		});
		return newPermission;
	}

	public openConfirmModal(): void {
		const dialogRef = this.dialog.open(VerifyAddUserComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Adding User',
			text: 'Are you sure you want to continue to adding a new user admin?',
			closeBtn: true,
			actionBtn: true,
			actionBtnText: 'CONFIRM'
		};
		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.createUser();
			}
		});
	}
	public onCcreateUser(): void {}
	public createUser(): void {
		const dialogRef = this.dialog.open(VerifyAddUserComponent, {
			width: '480px',
			height: '370px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: this.isEdit ? 'Editing User' : 'Adding User',
			text: `Are you sure you want to continue to ` + (this.isEdit ? 'editing this' : 'adding  a new') + ` user admin?`,
			closeBtn: true,
			actionBtn: true,
			actionBtnText: 'CONFIRM'
		};
		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				if (this.isEdit) {
					this.processUser();
				} else {
					this.processCreate();
				}
			}
		});
	}

	public processCreate() {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.fetchStore();
		let user: NewUserModel = {
			firstName: this.userDetail.user.firstname,
			lastName: this.userDetail.user.lastname,
			email: this.userDetail.user.email,
			phoneNumber: this.userDetail.user.telephone,
			position: this.userDetail.user.position,
			accountState: this.userDetail.user.accountState,
			FiledPermissionsIds: [],
			FacebookBusinessPermissions: this.userDetail.acccounts,
			ModuleAccessTypes: this.fixPermission(this.userDetail.permissions),
			access: this.userDetail.user.roles
		};
		this.userServiceApi
			.createClientEmployee(user)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					const dialogRef = this.dialog.open(CompleteModalComponent, {
						width: '480px',
						height: '360px',
						disableClose: true
					});
					dialogRef.componentInstance.modelDetail = {
						title: 'User Added',
						text:
							`New user of “` +
							this.userDetail.user.firstname +
							`” has been added to your account! An email invitation has sent to the new user.`,
						closeBtn: false,
						actionBtn: false
					};
					dialogRef.afterClosed().subscribe(() => {
						this.router.navigate(['/user-management/user-admin']);
					});
				},
				err => {
					if (err.error[0].code === 'Filed__Domain__NA__User__ExistsWithSameCriteria') {
						this.toastNotification.sendErrorToast('A user with this email already exists!');
					}
					// else if(err.error[0].code === "Filed__Api__CreateClientEmployee__FiledPermissions__IsNullOrEmpty") {
					// 	this.toastNotification.sendErrorToast('You do not have permission to create a user');
					// }
					else {
						this.toastNotification.sendErrorToast('Cannot create user. Please try again later!');
					}
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public processUser(): void {
		this.fetchStore();
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		let user: NewUserModel = {
			id: +this.userId,
			firstName: this.userDetail.user.firstname,
			lastName: this.userDetail.user.lastname,
			email: this.userDetail.user.email,
			phoneNumber: this.userDetail.user.telephone,
			position: this.userDetail.user.position,
			accountState: this.userDetail.user.accountState,
			FiledPermissionsIds: [],
			FacebookBusinessPermissions: this.userDetail.acccounts,
			ModuleAccessTypes: this.fixPermission(this.userDetail.permissions),
			access: this.userDetail.user.roles
		};
		this.userServiceApi
			.updateClientEmployee(user, +this.userId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					const dialogRef = this.dialog.open(CompleteModalComponent, {
						width: '480px',
						height: '360px',
						disableClose: true
					});
					dialogRef.componentInstance.modelDetail = {
						title: 'User Updated',
						text: `New user of ${this.userDetail.user.firstname} has been added to your account! An email invitation has sent to the new user.`,
						closeBtn: false,
						actionBtn: false
					};
					dialogRef.afterClosed().subscribe(() => {
						this.router.navigate(['/user-management/user-admin']);
					});
				},
				err => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					if (err.error[0].code === 'Filed__Domain__NA__User__ExistsWithSameCriteria') {
						this.toastNotification.sendErrorToast('A user with this email already exists!');
					} else {
						this.toastNotification.sendErrorToast('Cannot create user. Please try again later!');
					}
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public onContinueEdit(event: boolean): void {
		switch (this.currentStep) {
			case StepEnum.step1:
				this.currentStep = StepEnum.step2;
				break;

			case StepEnum.step2:
				this.currentStep = StepEnum.step3;
				break;

			case StepEnum.step3:
				this.currentStep = StepEnum.step4;
				break;
		}
	}

	public onGoBack(): void {
		switch (this.currentStep) {
			case StepEnum.step4:
				this.currentStep = StepEnum.step4;
				break;

			case StepEnum.step3:
				this.currentStep = StepEnum.step2;
				break;

			case StepEnum.step2:
				this.currentStep = StepEnum.step1;
				break;
		}
	}
}
