import { Component, Input, OnInit } from '@angular/core';
import {
	ExpandCollapseIconVisibilityEnum,
	FilteringListItemModel,
	GenericTableColumnsModel,
	GenericTableColumnTypeEnum,
	GenericTableDatasetModel,
	GenericTableDatasetTypeEnum,
	GenericTableRowsModel,
	IconSourceTypeEnum,
	SortByOptionsEnum,
	SortFilterMenuDataModel,
	SuffixIconDetailModel,
	UnlockEmailAttributeDataModel
} from './models/generic-table-row.model';
import { SocialPlatformsEnum } from '../../enums/social-platforms.enum';
import { UntypedFormControl } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-generic-table',
	templateUrl: './generic-table.component.html',
	styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {
	@Input() public isMulti: boolean = false;
	@Input() public columns: GenericTableColumnsModel[] = [];
	@Input() public rows: GenericTableRowsModel[] = [];
	@Input() public showTableHeader: boolean = true;
	@Input() public rowHeight: string;

	public GenericTableColumnTypeEnum = GenericTableColumnTypeEnum;
	public GenericTableDatasetTypeEnum = GenericTableDatasetTypeEnum;
	public ExpandCollapseIconVisibilityEnum = ExpandCollapseIconVisibilityEnum;
	public SocialPlatformsEnum = SocialPlatformsEnum;
	public IconSourceTypeEnum = IconSourceTypeEnum;
	public SortByOptionsEnum = SortByOptionsEnum;

	constructor(private clipboard: Clipboard) {}

	ngOnInit(): void {}

	public onMatExpansionOpen(row: GenericTableRowsModel): void {
		row.isExpanded = true;
	}

	public onMatExpansionClosed(row: GenericTableRowsModel): void {
		row.isExpanded = false;
	}

	public onColumnClick(column: GenericTableColumnsModel): void {
		if (column?.columnCallback) {
			column?.columnCallback();
		}
	}

	public onColumnSuffix(column: GenericTableColumnsModel): void {
		if (column?.suffixCallback) {
			column?.suffixCallback();
		}
	}

	public onDatasetClick(dataset: GenericTableDatasetModel): void {
		if (dataset?.datasetCallback) {
			dataset?.datasetCallback();
		}
	}

	public onDataRowTitleClick(dataRow: GenericTableDatasetModel): void {
		if (dataRow?.titleCallback) {
			dataRow?.titleCallback();
		}
	}

	public onDataRowPrefix(dataRow: GenericTableDatasetModel): void {
		if (dataRow?.prefixCallback) {
			dataRow?.prefixCallback();
		}
	}

	public onDataRowSuffixClick(suffixIconDetail: SuffixIconDetailModel): void {
		if (suffixIconDetail?.suffixCallback) {
			suffixIconDetail?.suffixCallback();
		}
	}

	public onColumnCheckboxChange(event, column: GenericTableColumnsModel): void {
		event.stopPropagation();
		column?.checkboxChange(event);
	}

	public onDatasetCheckboxChange(event, dataRow: GenericTableDatasetModel): void {
		event.stopPropagation();
		if (dataRow?.checkboxChange) {
			dataRow?.checkboxChange(event);
		}
	}

	public onSelectMenuOption(option, dataRow): void {
		dataRow.menuOptionSelectCallback(option);
	}

	public toggleFilterSelection(filterMenuItem: FilteringListItemModel): void {}

	public applyFilters(sortMenuData: SortFilterMenuDataModel): void {
		if (sortMenuData?.applyFilterCallback) {
			sortMenuData.applyFilterCallback();
		}
	}

	public copyToClipboard(unlockEmailData: UnlockEmailAttributeDataModel): void {
		this.clipboard.copy(unlockEmailData?.email);
		unlockEmailData.copied = true;
		setTimeout(() => {
			unlockEmailData.copied = false;
		}, 5000);
	}

	public onUnlockEmailClick(unlockEmailData: UnlockEmailAttributeDataModel): void {
		if (unlockEmailData?.callback) {
			unlockEmailData.callback();
		}
	}

	public clearFilters(sortMenuData: SortFilterMenuDataModel): void {
		if (sortMenuData?.clearFilterCallback) {
			sortMenuData.clearFilterCallback();
		}
	}

	public selectSortOption(sortBy: SortByOptionsEnum, sortMenuData: SortFilterMenuDataModel): void {
		sortMenuData.sortBy = sortBy;
		if (sortMenuData?.sortByCallback) {
			sortMenuData.sortByCallback(sortBy);
		}
	}

	public onFilterSearchValueChange(val: string, sortMenuData: SortFilterMenuDataModel): void {
		if (!val || val.trim() == '') {
			sortMenuData.filteredList = sortMenuData.list;
		} else {
			sortMenuData.filteredList = sortMenuData.list.filter(j =>
				j.name.toLowerCase().replace(/ /g, '').trim().includes(val.toLowerCase().replace(/ /g, '').trim())
			);
		}
	}
}
