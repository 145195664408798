import { Component, Input, OnInit } from '@angular/core';
import 'rxjs/add/observable/fromPromise';
import { select, Store } from '@ngrx/store';
import { getNavigationBillingState, UserManagementState } from '../../state/user-management.reducer';
import { NavLinkImage } from '../../models/nav-link-image';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { BillingAddress } from './billing-and-payment.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BillingLabelsEnum } from './billing-tab.enum';
import { BillingAndSubscriptionService } from '../../../shared/services/billing-and-subscriptions.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { DropdownInterface } from '../../../../app-components/interfaces/dropdown-interface';

@Component({
	selector: 'app-billing-and-payment',
	templateUrl: './billing-and-payment.component.html',
	styleUrls: ['./billing-and-payment.component.scss']
})
export class BillingAndPaymentComponent implements OnInit {
	@Input() public billingAddressDetails: BillingAddress;
	public navLinks: NavLinkImage[];
	public billingInfo: UntypedFormGroup;
	public validationMessages: Map<string, ValidatorMessages[]>;
	public countryName: string = null;
	public oldData: BillingAddress;
	public billingNote: string = BillingLabelsEnum.BillingNote;
	public countries: Country[];
	public dropdownCountries: DropdownInterface[];
	public isEditable: boolean = false;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private store: Store<UserManagementState>,
		private formBuilder: UntypedFormBuilder,
		private backOfficeService: BackOfficeService,
		private billingAndSubscriptionService: BillingAndSubscriptionService,
		private toastNotification: ToastNotificationService
	) {}

	public ngOnInit(): void {
		this.setDropdownCountries();
		this.initFormGroup();
	}

	public enableEditableFormControls(): void {
		this.isEditable = true;
		const { Company, FullName, ...controls } = this.billingInfo.controls;
		const controlNames = Object.keys(controls);
		controlNames.forEach(controlName => this.billingInfo.get(controlName).enable());
	}

	private initFormGroup(): void {
		this.billingInfo = this.formBuilder.group({
			Company: new UntypedFormControl({ disabled: true, value: this.billingAddressDetails.companyName ? this.billingAddressDetails.companyName : '' }, [
				Validators.required
			]),
			FullName: new UntypedFormControl({ disabled: true, value: this.billingAddressDetails.fullName ? this.billingAddressDetails.fullName : '' }, [
				Validators.required
			]),
			BillingAddress: new UntypedFormControl(
				{ disabled: true, value: this.billingAddressDetails.billingAddress ? this.billingAddressDetails.billingAddress : '' },
				[Validators.required]
			),
			City: new UntypedFormControl({ disabled: true, value: this.billingAddressDetails.city ? this.billingAddressDetails.city : '' }, [
				Validators.required
			]),
			ZipCode: new UntypedFormControl({ disabled: true, value: this.billingAddressDetails.zipCode ? this.billingAddressDetails.zipCode : '' }, [
				Validators.required
			]),
			Country: new UntypedFormControl({ disabled: true, value: this.billingAddressDetails.country ? this.billingAddressDetails.country : '' }, [
				Validators.required
			]),
			CountryId: new UntypedFormControl({ disabled: true, value: null }, [Validators.required])
		});
	}

	private setDropdownCountries(): void {
		this.backOfficeService
			.getAllCountriesList()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.countries = result;
				this.dropdownCountries = this.countries.map((country, index) => {
					return {
						data: country,
						name: country.name,
						id: index
					};
				});
				const defaultSelectedData = this.dropdownCountries.find(country => country.name === this.billingAddressDetails.country);
				this.billingInfo.patchValue({ CountryId: defaultSelectedData });
			});
	}

	public setCountryId(): void {
		if (this.billingInfo.get('CountryId').invalid || this.billingInfo.get('CountryId').untouched) {
			const defaultSelectedData = this.dropdownCountries.find(country => country.name === this.billingAddressDetails.country);
			this.billingInfo.get('CountryId').patchValue(defaultSelectedData);
			this.countryName = defaultSelectedData.name;
		}
		if (this.billingInfo.get('CountryId').dirty && this.billingInfo.get('CountryId').valid) {
			const CountryIdValue = this.billingInfo.get('CountryId').value.id;
			const selectedDropdownData = this.dropdownCountries.find(country => country.id === CountryIdValue);
			this.countryName = selectedDropdownData.data.name;
			selectedDropdownData ? this.billingInfo.get('CountryId').patchValue(selectedDropdownData) : null;
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public editEvent(): void {
		this.oldData = this.billingInfo.value;
		this.enableEditableFormControls();
	}

	public cancelEvent(): void {
		this.isEditable = false;
		this.billingInfo.disable();
		this.billingInfo.setValue(this.oldData);
	}

	public saveEvent(): void {
		this.setCountryId();
		const billingInfo = this.billingInfo.value;
		const billingData = {
			BillingAddress: billingInfo.BillingAddress,
			City: billingInfo.City,
			CountryId: billingInfo.CountryId.data.id,
			ZipCode: billingInfo.ZipCode
		};
		this.billingAndSubscriptionService
			.updateBillingDetails(billingData)
			.pipe(take(1))
			.subscribe(
				() => {
					this.isEditable = false;
					this.billingInfo.get('Country').patchValue(this.countryName);
					this.toastNotification.sendSuccessToast('Your billing details have been updated.');
					this.backOfficeService.billingUpdatedSuccess$.next(true);
				},
				err => {
					this.toastNotification.sendErrorToast(
						err.error[0].description || 'There has been a problem with updating your billing details. Please contact support.'
					);
					this.billingInfo.setValue(this.oldData);
				}
			);
		this.billingInfo.disable();
		this.isEditable = false;
	}

	public fetchStore(): void {
		this.store.pipe(select(getNavigationBillingState), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.navLinks = resp;
		});
	}
}
