import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-success-dialog',
	templateUrl: './success-dialog.component.html',
	styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {
	@Input() public title: string;
	@Input() public message: string;
	@Input() public selectedAudience: string;

	constructor(public dialogRef: MatDialogRef<SuccessDialogComponent>) {}

	ngOnInit(): void {}

	close() {
		this.dialogRef.close();
	}
}
