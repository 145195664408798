<div class="dialog-root">
	<mat-icon class="close-icon" mat-dialog-close>clear</mat-icon>

	<div class="title">{{ 'BigCommerce Integration Steps' }}</div>

	<div class="description">
		<ul>
			<li>
				Login to your Lolly account at
				<a target="_blank" href="https://app.lolly.com">https://app.lolly.com</a>
			</li>
			<li>Click on campaigns from the navigation panel on the left</li>
			<li>Click on "Setup Integration" on the top right</li>
			<li>Scroll to big commerce section</li>
			<li>Click on connect. This will take you to your BigCommerce store</li>
			<li>Approve the requested permissions</li>
			<li>Lolly's setup integration page should load in the BigCommerce app which shows that the BigCommerce account is connected</li>
		</ul>
	</div>
</div>
