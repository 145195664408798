import { Injectable } from '@angular/core';
import { ValidatorMessages } from './validatorMessagesInputs';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Injectable()
export class ErrorMessageService {
	constructor() {}

	public setErrorMessage(validatorMessages: ValidatorMessages[], formControl: UntypedFormControl | AbstractControl): string {
		let errorMessage;
		if (validatorMessages && formControl.errors) {
			const error = validatorMessages.find(validationMessage => validationMessage.validationType === Object.keys(formControl.errors)[0]);
			if (error) {
				errorMessage = error.validationMessage;
			}
		} else {
			errorMessage = 'The field is not valid.';
		}
		return errorMessage;
	}
}
