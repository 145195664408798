import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { GenericSmDialogComponent } from 'src/app/shared-components/generic-sm-dialog/generic-sm-dialog.component';
import { GenericDialogDataModel } from 'src/app/shared-components/generic-sm-dialog/model/generic-dialog-data.model';

@Injectable({
	providedIn: 'root'
})
export class GenericSmDialogService {
	public dialogRef: MatDialogRef<GenericSmDialogComponent>;

	constructor(public dialog: MatDialog) {}

	public showPopup(matDialogData: GenericDialogDataModel, disableClose = false): Promise<any> {
		if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
			return Promise.resolve();
		}
		return new Promise<any>((resolve, reject) => {
			let matDialogConfig: MatDialogConfig = {
				panelClass: 'center-dialog-no-shadow',
				minHeight: '321px',
				width: '530px',
				backdropClass: 'light-backdrop',
				disableClose: disableClose,
				data: matDialogData
			};
			this.dialogRef = this.dialog.open(GenericSmDialogComponent, matDialogConfig);

			this.dialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(res => {
					resolve(res);
				});
		});
	}

	public showPredefinedPopups(type: string, disableClose = false): Promise<any> {
		let matDialogData: GenericDialogDataModel;

		switch (type) {
			case 'limit':
				matDialogData = {
					icon: 'assets/icons/warning-icon.svg',
					iconStyles: { height: '61px', width: '71px' },
					title: "You've maxed out your usage for this month!",
					showDescription: true,
					description: 'To continue enjoying our product, please upgrade your plan or talk to us.',
					descriptionStyles: { 'font-size': '14px' },
					secondaryActionTitle: 'Speak To Us',
					primaryActionTitle: 'Upgrade Plan'
				};
				break;
			case 'sub_expired':
				matDialogData = {
					icon: 'assets/icons/warning-icon.svg',
					iconStyles: { height: '61px', width: '71px' },
					title: 'Subscription Expired',
					showDescription: true,
					hideCloseIcon: true,
					description: 'To regain access, simple select a plan and complete the payment or talk to us now',
					descriptionStyles: { 'font-size': '14px' },
					secondaryActionTitle: 'Reactivate Account',
					primaryActionTitle: 'Speak To Us'
				};
				break;
			case 'payment_fail':
				matDialogData = {
					icon: 'assets/icons/warning-icon.svg',
					iconStyles: { height: '61px', width: '71px' },
					title: 'Oh No! Your Payment Failed',
					showDescription: true,
					hideCloseIcon: true,
					description: "Don't Worry, Try Paying again or update your payment details now.",
					descriptionStyles: { 'font-size': '14px' },
					oneButtonFooter: true,
					primaryActionTitle: 'Retry Payment',
					footerStyles: { 'justify-content': 'center' }
				};
				break;
		}
		return this.showPopup(matDialogData, disableClose);
	}

	public dismissPopup(): void {
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}
}
