import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-extend-budget-dialog',
	templateUrl: './extend-budget-dialog.component.html',
	styleUrls: ['./extend-budget-dialog.component.scss']
})
export class ExtendBudgetDialogComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
