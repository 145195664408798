<div class="form-group-wrapper">
	<div class="label-wrapper">
		<label>{{ inputLabel }}</label>
		<div>
			<div class="counter">
				<span>{{ control?.value ? control?.value?.length : 0 }}/{{ maxLength }}</span>
			</div>
		</div>
	</div>
	<app-custom-textarea [control]="control" [maxLength]="maxLength" [inputPlaceholder]="placeholder"></app-custom-textarea>
</div>
