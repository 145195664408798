<div class="form">
	<div class="perrows">
		<div class="column">
			<label>
				Name as seen on Card
				<span>*</span>
			</label>
			<app-input-card-number
				[control]="cardDetails.controls['cardName']"
				[placeholder]="'Enter Name as seen on Card'"
				class="input-box"
				[isIcon]="false"
				[ngClass]="{ 'no-edit': isEdit === true }"
			></app-input-card-number>
		</div>
		<div class="column">
			<div>
				<label>
					Card Number
					<span>*</span>
				</label>
				<app-input-card-number
					class="input-box"
					[ngClass]="{ 'no-edit': isEdit === true }"
					[control]="cardDetails.controls['cardNumber']"
					label="Card Number"
					placeholder="Enter 16 digit number"
				></app-input-card-number>
			</div>
			<div class="perrows">
				<div class="columnp expiry">
					<label>
						Expiration Date
						<span>*</span>
					</label>
					<app-input-card-number
						[control]="expirationDate"
						[placeholder]="'MM/YYYY'"
						class="input-box"
						[isIcon]="false"
						(keyup)="formatExpiryDate($event)"
					></app-input-card-number>
				</div>
				<div class="columnp cvv">
					<label>
						CVV
						<span>*</span>
					</label>
					<input type="password" class="password" placeholder="CVV" [formControl]="cardDetails.controls['securityCode']" />
				</div>
			</div>
		</div>
	</div>

	<div>
		<div class="sub-title billing-title">Billing details & address</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('firstName')">
				<label>
					First Name
					<span>*</span>
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['firstName']"
					[placeholder]="'Enter your first name'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
			<div class="column" *ngIf="checkInRequest('lastName')">
				<label>
					Last Name
					<span>*</span>
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['lastName']"
					[placeholder]="'Enter your second name'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('companyName')" [class.fullWidth]="!checkInRequest('phone')">
				<label>
					Company Name
					<span>*</span>
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['companyName']"
					[placeholder]="'Enter your company name'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
			<div class="column" *ngIf="checkInRequest('phone')">
				<label>
					Phone Number
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['phone']"
					[placeholder]="'+ _ _ (_ _ _) _ _ _ - _ _ - _ _'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('firstAddress')">
				<label>
					First line of address
					<span>*</span>
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['firstAddress']"
					[placeholder]="'Enter first line of your address'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
			<div class="column" *ngIf="checkInRequest('secondAddress')">
				<label>
					Second line of address
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['secondAddress']"
					[placeholder]="'Enter your address'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('city')">
				<label>
					City
					<span>*</span>
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['city']"
					[placeholder]="'Enter in your City'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
			<div class="column" *ngIf="checkInRequest('zipCode')">
				<label>
					Zipcode/ Postcode
					<span>*</span>
				</label>
				<app-input-card-number
					[control]="cardDetails.controls['zipCode']"
					[placeholder]="'Enter your zipcode/postcode'"
					class="input-box"
					[isIcon]="false"
				></app-input-card-number>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('country')">
				<label>
					Country
					<span>*</span>
				</label>
				<app-select-item
					[dropDownControl]="cardDetails.controls['country']"
					[placeholderText]="'Select country'"
					[showCaretIcon]="true"
					[dropDownData]="countryData"
					[allowFilter]="true"
					(onItemSelected)="dropDownValueChanges($event)"
				></app-select-item>
			</div>
			<div class="column"></div>
		</div>
	</div>
</div>
