<div class="page-container">
	<div class="form-area">
		<div class="page-header">
			<div class="back-btn">
				<a (click)="backClicked()">
					<i class="k-icon k-i-arrow-chevron-left"></i>
					Back
				</a>
			</div>
		</div>
		<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<div class="title">Pausing Account</div>
					<div>
						<div class="sub-title">Reason for leaving *</div>
						<div>
							<label>
								What is the reason for you to wish to pause our account?
							</label>
							<select class="reason"></select>
						</div>
					</div>
					<div>
						<div class="sub-title">What should we improve?</div>
						<div>
							<label>
								We appreciate feedback you have on our services or product.
							</label>
							<textarea placeholder="Enter your feedback here" class="textarea"></textarea>
						</div>
					</div>
					<div>
						<div class="sub-title">Password confirmation *</div>
						<div>
							<label>
								This is require for account owner verification and prevention from unauthorised account access.
							</label>
							<input type="password" class="password" />
						</div>
					</div>
				</div>
				<div class="form-btn">
					<button class="button" (click)="onCancel()">
						CANCEL
					</button>
					<button class="button left" (click)="onNext()">
						CONTINUE
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
