<div class="container">
	<div class="title">Two-Factor Authentication</div>
	<div class="sub-title">
		Enabling this will provide an extra layer of security for your account. When logging in, we will ask for a special authentication code from your device.
	</div>
	<div class="buttons">
		<app-primary-button
			[buttonLable]="'ENABLE TWO FACTOR AUTHENTICATION'"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="onEnable2Factor()"
			*ngIf="!is2FactorEnabled"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="'UPDATE TWO FACTOR AUTHENTICATION'"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="onEdit2Factor()"
			*ngIf="is2FactorEnabled"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="'DISABLE TWO FACTOR AUTHENTICATION'"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="onDisable2Factor()"
			*ngIf="is2FactorEnabled"
		></app-primary-button>
	</div>
</div>
