import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { Subject, Subscriber } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CampaignService } from '../social-media-influencer/modules/dashboard/services/campaign.service';

@Component({
	selector: 'app-stripe-landing',
	templateUrl: './stripe-landing.component.html',
	styleUrls: ['./stripe-landing.component.scss']
})
export class StripeLandingComponent implements OnInit {
	private unsubscriber$ = new Subject<void>();

	constructor(private route: ActivatedRoute, public campaignService: CampaignService) {}

	ngOnInit(): void {
		this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.campaignService.getStripeConnectionId(params.code, params.state, params.scope).subscribe(data => {
				window.close();
			});
		});
	}
}
