import { PaginationInterface } from './pagination.interface';

export enum NotificationActionEnum {
	CREATOR_MESSAGE_RECEIVED = 'creator_message_received',
	CREATOR_EMAIL_RECEIVED = 'creator_email_received',
	CREATOR_JOB_RECEIVED = 'creator_job_received',
	CREATOR_HIRED = 'creator_hired',
	CREATOR_REJECTED = 'creator_rejected',
	CREATOR_CREATIVE_APPROVED = 'creator_creative_approved',
	CREATOR_CREATIVE_DECLINED = 'creator_creative_declined',
	CREATOR_POST_URL_ACCEPTED = 'creator_post_url_accepted',
	CREATOR_CREATIVE_DEADLINE = 'creator_creative_deadline',
	CREATOR_PAYMENT_COMPLETED = 'creator_payment_completed',
	CREATOR_STRIPE_CONNECTED = 'creator_stripe_connected',
	CREATOR_SOCIAL_MEDIA_CONNECTED = 'creator_social_media_connected',
	CREATOR_EMAIL_CONNECTED = 'creator_email_connected',

	BRAND_THIRD_PARTY_CONNECTION = 'brand_third_party_connection', // Redirect to setup integration
	BRAND_MESSAGE_RECEIVED = 'brand_message_received',
	BRAND_EMAIL_RECEIVED = 'brand_email_received',
	BRAND_JOB_APPLIED = 'brand_job_applied',
	BRAND_JOB_ACCEPTED = 'brand_job_accepted',
	BRAND_JOB_DECLINED = 'brand_job_declined',
	BRAND_CREATIVE_UPLOADED = 'brand_creative_uploaded', // REDIRECT TO Creatives page
	BRAND_POST_UPLOADED = 'brand_post_uploaded', // REDIRECT TO Creatives page
	BRAND_CRM_EMAIL_SENT = 'brand_crm_email_sent', // REDIRECT TO CRM PAGE
	BRAND_PAYMENT_SUCCESSFUL = 'brand_payment_success',
	BRAND_PAYMENT_FAILED = 'brand_payment_failed',
	BRAND_NOTIFY_PENDING_PAYMENT = 'brand_notify_pending_payment',
	BRAND_CAMPAIGN_LIMIT = 'brand_campaign_limit', // REDIRECT TO PLANS PAGE
	BRAND_JOB_LIMIT_PER_CAMPAIGN = 'brand_job_limit_per_campaign', // REDIRECT TO PLANS PAGE
	BRAND_INFLUENCER_LIMIT_PER_JOB = 'brand_influencer_limit_per_job', // REDIRECT TO PLANS PAGE
	BRAND_DISCOVERY_CREDIT_FINISH = 'brand_discovery_credit_finish', // REDIRECT TO PLANS PAGE
	BRAND_EMAIL_CONNECTED = 'brand_email_connected', // Easy redirect to email integration in settings
	BRAND_SUBUSER_ACCEPTED_INVITE = 'brand_subuser_accepted_invite' // Easy redirect to members page
}

export interface NotificationResponse {
	data: {
		unread_count: number;
		notifications: NotificationModel[];
	};
	pagination: PaginationInterface;
}

export interface NotificationModel {
	user_id?: number;
	user_name?: string;
	user_type?: string;
	type?: NotificationActionEnum;
	action?: string;
	resource_id?: number;
	resource_type?: string;
	created_by?: number;
	created_by_name?: string;
	created_by_type?: string;
	updated_by?: number;
	updated_by_name?: string;
	updated_by_type?: string;
	status?: string;
	message?: string;
	id?: number;
	created_at?: string;
	updated_at?: string;
	active?: boolean;
}
