<div class="grid-section">
	<div class="form-area">
		<div class="container">
			<app-lolly-spinning-loader class="spinner" *ngIf="submitting"></app-lolly-spinning-loader>
			<div>
				<div class="logo">
					<img class="logo-shakespeare" src="assets/logo/lolly-logo.svg" />
				</div>
				<div class="title">Create your password</div>
				<div class="form">
					<form [formGroup]="passwordForm">
						<div class="text-input-split">
							<div class="left">
								<div class="label">{{ 'First Name' }}</div>
								<app-input-field
									[showValidIcon]="true"
									[hideError]="false"
									[placeHolderTextBox]="'Enter first name'"
									[inputControl]="passwordForm.get('firstName')"
									[showCustomIcon]="firstNameValidationIcon"
									[errorMessage]="'Enter a valid first name'"
									[iconWidth]="'14px'"
									[marginRight]="'8px'"
								></app-input-field>
							</div>
							<div class="right">
								<div class="label">{{ 'Last Name' }}</div>
								<app-input-field
									[showValidIcon]="true"
									[hideError]="false"
									class="title-input"
									[placeHolderTextBox]="'Enter last name'"
									[inputControl]="passwordForm.get('lastName')"
									[showCustomIcon]="lastNameValidationIcon"
									[errorMessage]="'Enter a valid last name'"
									[iconWidth]="'14px'"
									[marginRight]="'8px'"
								></app-input-field>
							</div>
						</div>
						<!-- <div class="full-text-input l-margin">
							<div class="label">{{ 'Phone Number' }}</div>
							<app-input-field
								[showValidIcon]="true"
								[hideError]="false"
								class="title-input"
								[placeHolderTextBox]="'Enter Phone Number'"
								[inputControl]="passwordForm.get('phone')"
								[showCustomIcon]="lastNameValidationIcon"
								[errorMessage]="'Enter a Phone Number'"
								[iconWidth]="'14px'"
								[marginRight]="'8px'"
							></app-input-field>
						</div> -->
						<div class="full-text-input l-margin">
							<div class="label">{{ 'Password' }}</div>
							<app-input-field-password
								[showValidIcon]="true"
								[hideError]="false"
								class="title-input"
								[placeHolderTextBox]="'Enter your password'"
								[inputControl]="passwordForm.get('password')"
								[showCustomIcon]="passwordValidationIcon"
								[errorMessage]="'Strong password required with 1 upper, 1 lowercase, 1 special character and 1 number'"
								[iconWidth]="'14px'"
								[marginRight]="'8px'"
							></app-input-field-password>
						</div>
					</form>
				</div>
				<div (click)="continueSignup()" class="signin-btn-wrapper">
					<div class="btn">
						<div class="items">
							<div class="text">{{ 'Continue' }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-toast-notification></app-toast-notification>
