<!--Popup content-->
<div class="popup-content" *ngIf="showPopup">
	<div class="popup-modal modal-effect">
		<div class="modal-content">
			<!--header-->
			<header>
				<h4>{{ titlePopup }}</h4>
				<!--X button -->
				<mat-icon (click)="onNoClick()">clear</mat-icon>
			</header>

			<div class="body">
				<div class="navigation">
					<app-lolly-stepper
						(stepChange)="stepChange($event)"
						[current]="currentStep"
						[stepType]="'full'"
						[steps]="steps"
						[width]="'100%'"
					></app-lolly-stepper>
				</div>

				<!--generic main content -->
				<div class="popup-main-content">
					<app-user-details
						[activeStep]="!firstStep"
						[form]="userFromControl"
						[userData]="user"
						(formIsValid)="FormIsValid($event)"
						[hidden]="firstStep"
					></app-user-details>
					<app-user-account-access
						(isDoneLoading)="isDoneLoading()"
						[changes]="secondStepChanges"
						[activeStep]="!secondStep"
						(userData)="getAdAccounts($event)"
						[isEdit]="edit"
						[userDataId]="user.id"
						(formIsValid)="FormIsValid($event)"
						[hidden]="secondStep"
					></app-user-account-access>
					<app-user-permissions
						[activeStep]="!thirdStep"
						(userData)="getPermissions($event)"
						(formIsValid)="FormIsValid($event)"
						[permission]="userPermissions"
						[hidden]="thirdStep"
					></app-user-permissions>
				</div>
			</div>

			<!-- footer buttons-->
			<footer>
				<app-primary-button
					[buttonLable]="labelButtonLeftSide"
					[buttonType]="'Secondary'"
					[isDisabled]="backButtonDisable"
					(buttonClicked)="backButton()"
				></app-primary-button>

				<app-primary-button
					[buttonLable]="labelButtonRightSide"
					[buttonType]="'Primary'"
					(buttonClicked)="clickAction()"
					[isDisabled]="!nextButtonState"
				></app-primary-button>
			</footer>
		</div>
	</div>
	<div class="modal-overlay"></div>
</div>
