import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class MyPlanService {
	private readonly baseURL = `${BaseApiUrl.Payment}`;

	constructor(private _http: HttpClient) {}

	public dropDownData = {
		data: [],
		selectedValue: 0,
		type: ''
	};
	public componentToLoad: string = 'my-plan';

	public setCredits(payload): Observable<any> {
		return this._http.post(`${this.baseURL}api/v1/Subscriptions/credit/topup`, payload);
	}

	public getActiveSubs(token: string): Observable<any> {
		return this._http.get(`${BaseApiUrl.SocialMessengerPython}subscriptions`, {
			headers: new HttpHeaders({ Authorization: token })
		});
	}

	public getStripePlans(): Observable<any> {
		return this._http.get(`${BaseApiUrl.SocialMessengerPython}subscriptions/stripe_plans`);
	}

	public updatePlan(priceId: string, planBit: number, token: string): Observable<any> {
		return this._http.get(`${BaseApiUrl.SocialInfluencerPython}subscriptions/change-plan?price_id=${priceId}&plan_bit=${planBit}`, {
			headers: new HttpHeaders({ Authorization: token })
		});
	}

	public getAllInvoices(token: string): Observable<any> {
		return this._http.get(`${BaseApiUrl.SocialInfluencerPython}subscriptions/get-all-subscriptions`, {
			headers: new HttpHeaders({ Authorization: token })
		});
	}

	public getStripeUrl(token) {
		return this._http.get(`${BaseApiUrl.SocialInfluencerPython}subscriptions/billing-portal`, {
			headers: new HttpHeaders({ Authorization: token })
		});
	}
}
