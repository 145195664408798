<div class="page-container">
	<div class="form-area">
		<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<div class="title">Reactivate your account</div>
					<div class="sub-title">You can reactivate your account which is currently in the paused state.</div>

					<div class="box">
						<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
						<div class="detail">
							<div class="title">Welcome back to lolly.</div>
							<div class="tag">Great to see you again {{ this.userProfile?.firstName }}!</div>
						</div>
					</div>
				</div>
				<div class="form-btn">
					<button class="btn-signup left" (click)="onReactivateAcct()">
						REACTIVATE ACCOUNT
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
