<div class="modalContainer">
	<div class="header">
		<i class="k-icon k-i-close close" (click)="closeDialog()"></i>
	</div>
	<div class="body">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div class="title">{{ modelDetail.title }}</div>
		<div class="text">
			{{ modelDetail.text }}
		</div>
		<div *ngIf="modelDetail.useOtp" class="otp" [class.for4Chars]="modelDetail.otp.count === 6">
			<app-opt
				[isCodeHidden]="modelDetail.otp.secret ? true : false"
				[codeLength]="modelDetail.otp.count"
				(codeChanged)="onCodeChanged($event)"
				(codeCompleted)="onCodeCompleted($event)"
			></app-opt>
		</div>
	</div>
	<div class="footer">
		<app-primary-button
			[buttonLable]="'CANCEL'"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="resendOTP()"
			*ngIf="modelDetail.closeBtn && !modelDetail.useOtp"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="leadingZero(seconds)"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="resendOTP()"
			*ngIf="modelDetail.useOtp && showResend"
			[isDisabled]="showResend"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="'RESEND'"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="actionButtonClick()"
			*ngIf="modelDetail.useOtp"
			[isDisabled]="showResend"
		></app-primary-button>

		<app-primary-button
			[buttonLable]="modelDetail.actionBtnText"
			[buttonType]="'Primary'"
			[isPeddingReduceSmall]="true"
			(buttonClicked)="actionButtonClick()"
			*ngIf="modelDetail.actionBtn"
		></app-primary-button>
	</div>
</div>
