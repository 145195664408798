import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HintsService {
	public showSubject$ = new Subject<any>();
	public dismissSubject$ = new Subject<void>();

	constructor() {}

	public showHints(data: any): void {
		this.showSubject$.next(data);
	}

	public dismissHints(): void {
		this.dismissSubject$.next();
	}
}
