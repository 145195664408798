import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { TokenService } from 'src/app/_services/token.service';
import { environment } from 'src/environments/environment';
import { AuthPaymentService } from '../../services/auth-payment.service';
import { LogInSuccess } from '../../state/authentication.action';
import { AuthenticationState } from '../../state/authentication.reducer';
@Component({
	selector: 'app-payment-confirmation',
	templateUrl: './payment-confirmation.component.html',
	styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmation implements OnInit, OnDestroy {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public successPage: boolean = false;
	constructor(
		private authStore: Store<AuthenticationState>,
		public activeRoute: ActivatedRoute,
		public authPaymentService: AuthPaymentService,
		private tokenService: TokenService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.activeRoute.data.subscribe(data => {
			this.successPage = data.paymentSuccess;
			setTimeout(() => {
				let token = localStorage.getItem(StorageKey.token);
				if (this.successPage) {
					this.tokenService
						.refreshToken(token)
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe(res => {
							if (res) {
								this.authStore.dispatch(new LogInSuccess(res));
							}
						});
				} else {
					this.router.navigate(['/']);
					// let priceId = environment.DEFAULT_PRICE_ID;

					// this.authPaymentService
					// 	.requestSession(priceId, token)
					// 	.pipe(takeUntil(this.unsubscriber$))
					// 	.subscribe((res: string) => {
					// 		this.authPaymentService.redirectToCheckout(res);
					// 	});
				}
			}, 2000);
		});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
