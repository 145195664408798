import { Component, Input, OnInit } from '@angular/core';
import { MyPlanService } from '../../my-plan.service';

@Component({
	selector: 'app-add-credit',
	templateUrl: './add-credit.component.html',
	styleUrls: ['./add-credit.component.scss']
})
export class AddCreditComponent implements OnInit {
	@Input() title: string;
	@Input() dropBoxData: Array<string>;
	constructor(public _myPlan: MyPlanService) {}

	ngOnInit(): void {}
	dropDownValue = 0;
	onDropDownChange() {
		this._myPlan.dropDownData.selectedValue = this.dropDownValue;
	}
}
