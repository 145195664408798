import { Component, Input } from '@angular/core';

@Component({
	selector: 'lolly-info-tooltip',
	templateUrl: './info-tooltip.component.html',
	styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent {
	@Input() public title: string;
	@Input() public width: number = 330;
	@Input() public position = 'top';
	@Input() public styleClass: string;
}
