<div class="connect-shop">
	<section class="side-container closed">
		<div class="header closed">
			<mat-toolbar class="header_toolbar">
				<div class="top-logo-img">
					<img src="../../../assets/logo/lolly-L-logo-white.svg" />
				</div>

				<div class="header_icons">
					<div #settingsIcon (click)="toggleProfileAction()" class="profile-icon">
						{{ userFName?.charAt(0) + userLName?.charAt(0) }}
					</div>
				</div>
			</mat-toolbar>
		</div>
	</section>
	<div #settingsPopup class="profile-card" *ngIf="profileOpen">
		<div class="avatar-personal">
			<div class="avatar">
				{{ userFName?.charAt(0) + userLName?.charAt(0) }}
			</div>
			<div class="personal">
				<div class="top">{{ userFName + ' ' + userLName }}</div>
				<div class="bottom">{{ userEmail }}</div>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div (click)="logout()" class="action">
			<img src="assets/icons/nav-menu/signout.svg" />
			<div class="text">{{ 'Sign out' }}</div>
		</div>
	</div>
	<div class="container">
		<app-lolly-spinning-loader *ngIf="loading"></app-lolly-spinning-loader>
		<ng-container *ngIf="!loading">
			<ng-container>
				<div class="body width-589">
					<div>
						<filed-font [fontSize]="'14px'" [fontWeight]="'400'" [color]="'#ffffff'">
							Automatically import products from your e - commerce shop.
						</filed-font>
						<filed-font [fontSize]="'14px'" [color]="'#60100b'" class="ml-8px">
							Today at 15:42
						</filed-font>
					</div>
					<div class="billing margin-auto-40" style="border: 0;">
						<img src="/assets/icons/social-media-icons/shopify2x.png" />
					</div>
					<div class="campaign-form-column" [formGroup]="mainForm">
						<div class="campaign-form-wrapper">
							<div class="campaign-form-label">
								<filed-font [fontSize]="'14px'">Enter your shop name"</filed-font>
							</div>
							<div class="campaign-form-input .campaign-form-column">
								<mat-form-field appearance="outline" class="form-field-max">
									<filed-font [fontSize]="'14px'" matPrefix>https://</filed-font>
									<input formControlName="shop" type="text" matInput placeholder="" />
									<filed-font [fontSize]="'14px'" matSuffix>.myshopify.com</filed-font>
								</mat-form-field>
							</div>
						</div>
					</div>
					<div dialog-actions style="margin-top: 25px;">
						<app-primary-button
							[buttonLable]="'Confirm'"
							[buttonType]="'Primary'"
							(buttonClicked)="confirmShopConnection()"
							[isPeddingReduceSmall]="true"
							[isDisabled]="connectShop"
						></app-primary-button>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
