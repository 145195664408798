import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { UserDetailModel } from '../../_models/login.model';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { NewSignUpSubscribe } from '../../sign-up/subscribe.interface';
import { sleep } from '../utils/signup-utils';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-email-verification',
	templateUrl: './email-verification-new.component.html',
	styleUrls: ['./email-verification-new.component.scss']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public emailControl: UntypedFormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = true;
	public error: boolean = false;
	public subscribeData: NewSignUpSubscribe;
	public token: string = '';
	public priceId: string = '';

	public registrationDetail: UserDetailModel;
	emailSent: boolean;

	constructor(
		private router: Router,
		private store: Store<AuthenticationState>,
		private sharedStore: Store<SharedState>,
		private backOfficeService: BackOfficeService,
		private toastNotificationService: ToastNotificationService
	) {
		this.token = this.router.getCurrentNavigation().extras?.state?.token;
		this.priceId = this.router.getCurrentNavigation().extras?.state?.priceId;
		if (!this.token || !this.priceId) {
			this.router.navigate(['/authentication/register-email']);
		}
	}

	public ngOnInit(): void {
		this.getRegistrationDetails();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$;
	}

	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.registrationDetail = detail;
			}
		});
	}

	public resendVerificationEmail(): void {
		this.backOfficeService
			.resendActivationEmail(this.token, this.priceId)
			.toPromise()
			.catch(error => {
				this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
				this.submitting = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
			})
			.then(() => {
				this.submitting = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
				if (!this.error) {
					this.emailSent = true;
					this.toastNotificationService.sendSuccessToast('Email was successfully sent.');
				}
			});
	}

	public tryDifferentEmail(): void {
		this.router.navigate(['/authentication/register-email'], { queryParams: { planId: 191 } });
	}

	public openGmailAccount(): void {
		window.location.href = `https://mail.google.com/mail/u/?authuser=${this.registrationDetail.email}`;
	}

	public openOutlookAccount(): void {
		window.location.href = `https://outlook.office365.com/mail/`;
	}
}
