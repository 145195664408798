import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { Module as StripeModule } from 'stripe-angular';
import { UserRoleClientGuard } from '../shared/guards/user-role-client-guard.service';
import { UserRoleBusinessGuardService } from '../shared/guards/user-role-business-guard.service';
import { FiledUsersResolver } from '../_services/user-management/resolvers/filed-users.resolver';
import { AccountResolver } from '../_services/user-management/resolvers/account.resolver';
import { MyAccountNewComponent } from './new-sprint/components/my-account/my-account-new.component';
import { MyPaymentComponent } from './new-sprint/components/my-plan/components/my-payment/my-payment.component';
import { SubscriptionConfirmationComponent } from './new-sprint/components/my-plan/components/subscription-confirmation/subscription-confirmation.component';
import { MyPlanComponent } from './new-sprint/components/my-plan/my-plan.component';
import { FiledUsersNewComponent } from './new-sprint/components/users/filed-users-new.component';
import { PaymentBillingComponent } from './new-sprint/components/payment-billing/payment-billing.component';
import { AddCardComponent } from './new-sprint/components/payment-billing/components/add-card/add-card.component';
import { MyProfileComponent } from './new-sprint/components/my-profile/my-profile.component';
import { SecurityComponent } from './new-sprint/components/security/security.component';
import { UserProcessComponent } from './new-sprint/components/user-admin/components/user-process/user-process.component';
import { AppreciationComponent } from './new-sprint/components/security/components/appreciation/appreciation.component';
import { OfferAcceptedComponent } from './new-sprint/components/security/components/offer-accepted/offer-accepted.component';
import { PausingAccountExtraComponent } from './new-sprint/components/security/components/pausing-account-extra/pausing-account-extra.component';
import { PausingAccountComponent } from './new-sprint/components/security/components/pausing-account/pausing-account.component';
import { ReactivateAccountComponent } from './new-sprint/components/security/components/reactivate-account/reactivate-account.component';
import { SadFinalComponent } from './new-sprint/components/security/components/sad-final/sad-final.component';
import { SadComponent } from './new-sprint/components/security/components/sad/sad.component';
import { EditSetupComponent } from './new-sprint/components/security/components/two-factor-authentication/components/edit-setup/edit-setup.component';
import { SetupComponent } from './new-sprint/components/security/components/two-factor-authentication/components/setup/setup.component';
import { TwoFactorAuthenticationComponent } from './new-sprint/components/security/components/two-factor-authentication/two-factor-authentication.component';

const routes: Routes = [
	{
		canActivate: [UserRoleClientGuard],
		path: '',
		component: MyAccountNewComponent,
		resolve: {
			store: AccountResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'user-admin',
		component: FiledUsersNewComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'user-admin/add',
		component: UserProcessComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'user-admin/edit',
		component: UserProcessComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'my-profile',
		component: MyProfileComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'my-plan',
		component: MyPlanComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'my-plan/payment',
		component: MyPaymentComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'my-plan/subscription-confirmation',
		component: SubscriptionConfirmationComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'payment-and-billing',
		component: PaymentBillingComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'payment-and-billing/add-card',
		component: AddCardComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'payment-and-billing/edit-card',
		component: AddCardComponent
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security',
		component: SecurityComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/sad-tosee-you-go',
		component: SadComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/pausing-account',
		component: PausingAccountComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/pausing-account-extra',
		component: PausingAccountExtraComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/appreciation',
		component: AppreciationComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/offer-accepted',
		component: OfferAcceptedComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/sad-final',
		component: SadFinalComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},

	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/reactivate-account',
		component: ReactivateAccountComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/enable-2-factor-authentication',
		component: TwoFactorAuthenticationComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/enable-2-factor-authentication/setup',
		component: SetupComponent,
		resolve: {
			store: FiledUsersResolver
		}
	},
	{
		canActivate: [UserRoleBusinessGuardService],
		path: 'security/enable-2-factor-authentication/edit-setup',
		component: EditSetupComponent,
		resolve: {
			store: FiledUsersResolver
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	// imports: [RouterModule.forChild(routes), StripeModule.forRoot()],
	exports: [RouterModule]
})
export class UserManagementRoutingModule {}
