import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective, SelectableSettings } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { element } from 'protractor';
import { elementAt } from 'rxjs/operators';

@Component({
	selector: 'app-kendo-grid',
	templateUrl: './kendo-grid.component.html',
	styleUrls: ['./kendo-grid.component.scss']
})
export class KendoGridComponent implements OnInit {
	@ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
	@ViewChild('grid') public kendo: any;
	@Input() public sortable = true;
	@Input() public gridColumns;
	@Input() public gridData;
	@Input() public gridView: any[];
	@Input() public checkbox: boolean;
	@Input() public selectedKeys: number[];
	@Input() public pageable;
	@Input() public hidePaginationArrows: boolean = false;
	@Input() public cellAction: any;
	@Input() public headerAction: any;
	@Input() public pageNumber: number;
	@Input() public pageSize: number;
	@Input() public totalPages: number;
	@Input() public pageSizeOptions: number[] = [10, 15, 20, 100];
	@Output() public pageNumberChange = new EventEmitter<any>();
	@Output() public changePageSize = new EventEmitter<any>();
	@Output() public linkClick = new EventEmitter<number>();
	@Output() public objectClick = new EventEmitter<number>();
	@Output() public cellClick = new EventEmitter<any>();
	@Output() public selectedRows = new EventEmitter<any>();
	@Output() public editEmit = new EventEmitter<any>();
	@Output() public editClickedEvent = new EventEmitter<number>();
	@Output() public deleteEvent = new EventEmitter<number>();
	@Output() public dialog = new EventEmitter<any>();
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
	public mySelection: any = [];
	public search = new UntypedFormControl();
	public editinput: string;
	public selectableSettings: SelectableSettings;
	public selectedId: any;
	currentRoute: string = '';
	public showActionRow: number;

	constructor(private router: Router, public formBuilder: UntypedFormBuilder) {
		this.selectableSettings = {
			checkboxOnly: true,
			mode: 'multiple',
			drag: false
		};
	}

	public ngOnInit(): void {
		this.currentRoute = this.router.url;
	}

	public ngAfterViewInit(): void {}

	public showAction(event: any, index: number): void {
		this.showActionRow = index;
	}

	public styleHeader(i) {
		return {
			'text-align': this.gridColumns[i].alignment,
			'font-weight': '500',
			'padding-left': this.gridColumns[i].alignment === 'center' && this.sortable ? '2em' : '',
			'font-size': '12px'
		};
	}

	public styleRows(i, value) {
		if (!this.gridColumns[i].highlight) {
			return {
				color: this.gridColumns[i].color,
				'font-weight': '500',
				'text-align': this.gridColumns[i].alignment,
				cursor: 'pointer',
				'font-size': '12px',
				overflow: 'hidden',
				'white-space': 'nowrap',
				'text-overflow': 'ellipsis'
			};
		} else {
			return {
				color: this.gridColumns[i].highlightText == value ? this.gridColumns[i].highlightColor : this.gridColumns[i].color,
				'font-weight': '500',
				'text-align': this.gridColumns[i].alignment,
				cursor: 'pointer',
				'font-size': '12px'
			};
		}
	}

	public styleObjectRows(i, rowIndex: number) {
		return {
			color: this.gridData[rowIndex]?.objectName?.color,
			'font-weight': '500',
			'text-align': this.gridColumns[i].alignment,
			cursor: 'pointer',
			'font-size': '12px',
			overflow: 'hidden',
			'white-space': 'nowrap',
			'text-overflow': 'ellipsis'
		};
	}

	public onLinkClick(rowIndex: number): void {
		this.linkClick.emit(rowIndex);
	}

	public onObjectClick(rowIndex: number): void {
		this.objectClick.emit(rowIndex);
	}

	public edit(details: any, value: any, index: number): void {
		this.editClickedEvent.emit(index);
	}

	public deleteEmit(details: any, value: any, index: number): void {
		this.deleteEvent.emit(index);
	}

	public save(column: string): void {
		let index = -1;
		if (this.gridColumns.length > 0 && this.gridColumns[0].value == 'Campaign Name') {
			index = this.gridView.findIndex(x => x?.Id == this.selectedId);
		}
		if (this.gridColumns.length > 0 && this.gridColumns[0].value == 'Job Name') {
			index = this.gridView.findIndex(x => x?.jobId == this.selectedId || x?.campaignJobId == this.selectedId);
		}
		if (index >= 0) {
			if (this.gridView[index][column] != this.editinput) {
				this.gridView[index][column] = this.gridView[index][column].split(' ')[0] + ' ' + this.editinput;
				let emitData = {
					id: this.selectedId,
					edit: this.editinput,
					column: column
				};
				this.editEmit.emit(emitData);
			}
		}
	}

	public onKeydown(inputP: string): void {
		this.editinput = inputP;
	}

	public savestring(column: string): void {
		let index = -1;
		if (this.gridColumns.length > 0 && this.gridColumns[0].value == 'Campaign Name') {
			index = this.gridView.findIndex(x => x?.Id == this.selectedId);
		}
		if (this.gridColumns.length > 0 && this.gridColumns[0].value == 'Job Name') {
			index = this.gridView.findIndex(x => x?.jobId == this.selectedId || x?.campaignJobId == this.selectedId);
		}
		if (index >= 0) {
			if (this.gridView[index][column] != this.editinput) {
				this.gridView[index][column] = this.editinput;
				let emitData = {
					id: this.selectedId,
					edit: this.editinput,
					column: column
				};
				this.editEmit.emit(emitData);
			}
		}
	}

	public pageSizeChange(event): void {
		this.pageSize = event?.target.value;
		this.changePageSize.emit(this.pageSize);
	}

	public previousPage(): void {
		this.pageNumberChange.emit(this.pageNumber - 1);
	}

	public nextPage(): void {
		this.pageNumberChange.emit(this.pageNumber + 1);
	}

	public campaignClick(event): void {
		this.cellClick.emit(event.dataItem);
	}

	public keyChange(e): void {
		// console.log(e)
	}

	public selectRow(e): void {
		if (e.selectedRows.length > 0) {
			e.selectedRows.forEach(x => {
				this.mySelection.push(x.dataItem);
			});
			this.selectedRows.emit({ type: 'add', data: this.mySelection });
		} else {
			let data = [];
			e.deselectedRows.forEach(x => {
				data.push(x.dataItem);
			});
			this.selectedRows.emit({ type: 'remove', data: data });
		}
	}
	public openModal(id) {
		this.dialog.emit(id);
	}
	getColor(number) {
		return Number(number) > 0 ? true : false;
	}
}
