import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils';
import { GenericSidePopupModel } from './models/generic-side-popup-data.model';

@Component({
	selector: 'app-generic-side-popup',
	templateUrl: './generic-side-popup.component.html',
	styleUrls: ['./generic-side-popup.component.scss']
})
export class GenericSidePopupComponent implements OnInit {
	@ViewChild('videoPlayer') videoPlayer: ElementRef;
	public isFirstTimePlay: boolean = true;
	public APPOINTMENT_URL: string = UtilsService.BOOK_DEMO_URL;

	constructor(private dialogRef: MatDialogRef<GenericSidePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: GenericSidePopupModel) {}

	ngOnInit(): void {}

	public async togglePlayPause() {
		if (this.isFirstTimePlay) {
			this.isFirstTimePlay = false;
		}
		const video: HTMLVideoElement = this.videoPlayer.nativeElement;

		if (video.paused) {
			await video.play();
		} else {
			await video.pause();
		}
	}

	public onSecondaryBtnClick(): void {
		this.dialogRef.close({ secondary: true });
	}

	public onPrimaryBtnClick(): void {
		this.dialogRef.close({ primary: true });
	}

	public openInNewTab(url: string): void {
		window.open(url, '_blank');
	}
}
