import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-input-with-add-more',
	templateUrl: './input-with-add-more.component.html',
	styleUrls: ['./input-with-add-more.component.scss']
})
export class InputWithAddMoreComponent implements OnInit, OnChanges {
	@Input() public control: UntypedFormControl | AbstractControl = new UntypedFormControl('');
	@Output() public addMoreClicked = new EventEmitter();
	@Input() public maxInput = 3;
	@Input() public disabled = false;
	@Input() public inputPlaceholder = 'Enter here';
	@Input() public inputPlaceholders = [];
	@Input() public errorMessage: string = 'Please fill this field';
	public inputForm: UntypedFormGroup;
	public showError = false;
	public inputKey = '';
	constructor(private formBuilder: UntypedFormBuilder) {
		this.inputForm = this.formBuilder.group({
			inputs: this.formBuilder.array([])
		});
	}

	get inputFields() {
		return this.inputForm.get('inputs') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.inputKey = btoa(Math.random().toString()).substring(10, 15);
		this.inputFields.push(new UntypedFormControl(''));
		this.patchValue();
	}

	public addMore(i: number): void {
		const activeControl = this.inputFields.at(i);
		if (activeControl.value.length <= 0) {
			this.showError = true;
			return;
		}
		if (this.inputFields.length >= this.maxInput) {
			return;
		}
		this.patchValue();
		const input = new UntypedFormControl('');
		this.inputFields.push(input);
		setTimeout(() => {
			document.getElementById(`${this.inputKey}-${this.inputFields.length}`).focus();
		}, 200);
	}

	public removeInput(index: number): void {
		this.inputFields.removeAt(index);
		this.patchValue();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.inputForm.valueChanges.subscribe(res => {
			this.showError = false;
			this.patchValue();
		});
		this.disabled ? this.inputForm.disable() : this.inputForm.enable();
	}

	public getPlaceholder(index: number): string {
		return this.inputPlaceholders[index] ?? this.inputPlaceholder;
	}
	private patchValue(): void {
		const value = this.inputFields.value ?? [];
		const text = value.length > 1 ? value.join(', ') : value[0];
		this.control?.patchValue(text);
	}
	public generateId(index: number): string {
		return `${this.inputKey}-${index + 1}`;
	}
}
