<app-user-management-header></app-user-management-header>

<div class="page-container">
	<app-user-sub-header [pageTitle]="SettingsPageLabelsEnum.BillingAndPayment"></app-user-sub-header>

	<app-billing-and-payment></app-billing-and-payment>
	<div class="text flex">
		<h4>We accept :</h4>
		<div class="flex">
			<div class="icon"><img alt="card" src="/assets/icons/user-management/card/visa.svg" /></div>
			<div class="icon"><img alt="card" src="/assets/icons/user-management/card/american-express.svg" /></div>
			<div class="icon"><img alt="card" src="/assets/icons/user-management/card/mastercard.svg" /></div>
			<div class="icon"><img alt="card" src="/assets/icons/user-management/card/maestro.svg" /></div>
		</div>
	</div>
	<app-card-input></app-card-input>
</div>

<ng-template [ngIf]="touched">
	<div class="buttons-wrapper flex d-j-c-center">
		<app-button (buttonClicked)="addNewCard()" [buttonClass]="buttonClassEnum.Primary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="SAVE"></app-button>
	</div>
</ng-template>
