import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUserDetails } from '../state/user/user.reducer';
import { Modules } from '../permisions/enums/modules';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Injectable({
	providedIn: 'root'
})
export class AuthPlansGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, public store: Store) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const lastUrl = state.url;
		const isUserLoggedIn$ = of(this.authService.isLoggedIn());

		const hasCreditCard$ = this.authService.hasCreditCard();
		const isAdmin$ = this.authService.isAdmin();
		const isFreemiumExpired$ = this.authService.isFreemiumExpired();
		const isFreeTrialExpired$ = this.authService.isFreeTrialExpired();

		return forkJoin([isUserLoggedIn$, hasCreditCard$, isAdmin$, isFreemiumExpired$, isFreeTrialExpired$]).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			switchMap(([[isUserLoggedIn, hasCreditCard, isAdmin, isFreemiumExpired, isFreeTrialExpired], userDetails]) => {
				const fbId = userDetails?.FacebookBusinessOwnerId;
				const googleBO = userDetails?.GoogleBusinessOwnerId;
				const canskip = localStorage.getItem('skipConnect');
				const connectShopify = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo))?.user_account_state;
				const isInfluencer = userDetails?.Permissions.find(module => {
					return module.module === Modules.Influencer;
				});
				return of(true);
			})
		);
	}
}
