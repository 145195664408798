import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageInterface } from './model/message.interface';

@Component({
	selector: 'app-message-card',
	templateUrl: './message-card.component.html',
	styleUrls: ['./message-card.component.scss']
})
export class AppMessageCardComponent implements OnInit {
	@Input() public message: MessageInterface;
	@Input() public open: boolean;
	@Output() public selectedCampaign: EventEmitter<any>;
	@Output() public download: EventEmitter<any>;
	constructor() {
		this.selectedCampaign = new EventEmitter<any>();
		this.download = new EventEmitter<any>();
	}

	ngOnInit(): void {}

	public selectCampaign(): void {
		this.selectedCampaign.emit();
	}
	public downloadDoc(location: any): void {
		this.download.emit(location);
	}
}
