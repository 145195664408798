import { Component, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject, Subscription, interval } from 'rxjs';
import { NotificationActionEnum, NotificationModel } from 'src/app/shared/models/notifications.model';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
	selector: 'app-notifications-main',
	templateUrl: './notifications-main.component.html',
	styleUrls: ['./notifications-main.component.scss']
})
export class NotificationsMainComponent implements OnInit {
	public notifications: NotificationModel[] = [];
	public firstRun: boolean = true;
	public loading: boolean = false;
	private notificationSubscription: Subscription;
	private unsubscriber$ = new Subject<void>();

	constructor(public router: Router, public notificationService: NotificationsService) {}

	ngOnInit(): void {
		this.markAllAsRead();
		this.getNotifications();
		this.pollNotifications();
	}

	public pollNotifications(): void {
		interval(60000)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(x => {
				this.getNotifications();
			});
	}

	public getNotifications(): void {
		if (this.notificationSubscription && !this.notificationSubscription.closed) {
			this.notificationSubscription.unsubscribe();
		}
		if (this.firstRun) {
			this.loading = true;
			this.firstRun = false;
		}
		this.notificationSubscription = this.notificationService
			.getAllNotifications(50)
			.pipe(take(1))
			.subscribe(
				res => {
					this.notifications = res.data.notifications;
					this.loading = false;
				},
				err => {
					this.loading = false;
				}
			);
	}

	public titleCase = s => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));

	public onNotificationClick(notification: NotificationModel): void {
		// this.implementAction(notification);
	}

	public onNotificationActionClick(notification: NotificationModel): void {
		this.implementAction(notification);
	}

	public implementAction(notification: NotificationModel): void {
		switch (notification.type) {
			case NotificationActionEnum.BRAND_MESSAGE_RECEIVED:
				this.router.navigate(['messages'], { queryParams: { creatorId: notification.created_by } });
				break;
			case NotificationActionEnum.BRAND_EMAIL_RECEIVED:
				this.router.navigate(['messages'], { queryParams: { emailCreatorId: notification.created_by } });
				break;
			case NotificationActionEnum.BRAND_NOTIFY_PENDING_PAYMENT:
			case NotificationActionEnum.BRAND_PAYMENT_SUCCESSFUL:
			case NotificationActionEnum.BRAND_PAYMENT_FAILED:
				this.router.navigate(['payments']);
				break;
			case NotificationActionEnum.BRAND_JOB_ACCEPTED:
			case NotificationActionEnum.BRAND_JOB_DECLINED:
			case NotificationActionEnum.BRAND_JOB_APPLIED:
				this.router.navigate(['marketplace', notification.resource_id, 'view']);
				break;
			case NotificationActionEnum.BRAND_CREATIVE_UPLOADED:
			case NotificationActionEnum.BRAND_POST_UPLOADED:
				this.router.navigate(['creatives/view', notification.resource_id]);
				break;
			case NotificationActionEnum.BRAND_CRM_EMAIL_SENT:
				this.router.navigate(['outreach']);
				break;
			case NotificationActionEnum.BRAND_CAMPAIGN_LIMIT:
			case NotificationActionEnum.BRAND_JOB_LIMIT_PER_CAMPAIGN:
			case NotificationActionEnum.BRAND_INFLUENCER_LIMIT_PER_JOB:
			case NotificationActionEnum.BRAND_DISCOVERY_CREDIT_FINISH:
				this.router.navigate(['user-management/payment-and-billing']);
				break;
			case NotificationActionEnum.BRAND_THIRD_PARTY_CONNECTION:
				this.router.navigate(['campaign/setupIntegration']);
				break;
			case NotificationActionEnum.BRAND_EMAIL_CONNECTED:
				this.router.navigate(['user-management/integrations']);
				break;
			case NotificationActionEnum.BRAND_SUBUSER_ACCEPTED_INVITE:
				this.router.navigate(['user-management/teams']);
				break;
		}
	}

	public onEditNotificationClick(notification: NotificationModel, editAction: string): void {
		if (editAction == 'delete') {
			this.deleteNotification(notification);
		} else if (editAction == 'mute') {
			this.toggleMuteNotification(notification);
		}
	}

	public deleteNotification(notification: NotificationModel): void {
		const index = this.notifications.findIndex(n => n.id == notification.id);
		this.notifications.splice(index, 1);
		this.notificationService
			.deleteNotification(notification.id)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public toggleMuteNotification(notification: NotificationModel): void {
		if (notification.active) {
			this.muteNotification(notification);
		} else {
			this.unmuteNotification(notification);
		}
		notification.active = !notification.active;
	}

	public muteNotification(notification: NotificationModel): void {
		this.notificationService
			.muteNotification(notification.type)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public unmuteNotification(notification: NotificationModel): void {
		this.notificationService
			.unmuteNotification(notification.type)
			.pipe(take(1))
			.subscribe(res => {});
	}

	// function to mark all notitications as read
	public markAllAsRead(): void {
		const payload = {
			notification_ids: [],
			status: 'read'
		};
		this.notificationService
			.readNotifications(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
		if (this.notificationSubscription && !this.notificationSubscription.closed) {
			this.notificationSubscription.unsubscribe();
		}
	}
}
