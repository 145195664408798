<div class="notifications-root">
	<div class="notifications-wrapper">
		<div class="notification-header">
			<h1 class="notification-title">Notifications</h1>
		</div>

		<ng-container *ngIf="loading">
			<div class="loading-wrapper">
				<app-lolly-spinning-loader></app-lolly-spinning-loader>
			</div>
		</ng-container>

		<ng-container *ngIf="!loading">
			<ng-container *ngIf="notifications?.length > 0">
				<div class="notification-body">
					<ng-container *ngFor="let notification of notifications; let notificationSequence = index">
						<div (click)="onNotificationClick(notification)" class="menu-list-item-notif {{ notification?.status == 'unread' ? 'unread' : '' }}">
							<div class="d-flex flex-column w-100 notification-col">
								<div class="w-100 d-flex align-items-center justify-content-between">
									<div class="message">
										{{ notification?.message }}
									</div>
									<div class="date">
										{{ notification?.created_at | date: 'shortTime' }}
									</div>
								</div>
								<div class="d-flex align-items-center justify-content-between">
									<div class="action" (click)="onNotificationActionClick(notification); notificationsMenuTrigger.closeMenu()">
										{{ titleCase(notification?.action) }}
									</div>
									<div class="more-btn" [matMenuTriggerFor]="actionsMenu" #actionsMenuTrigger="matMenuTrigger">
										<img class="icon-btn" src="assets/creators/general/more-horizontal.svg" />
									</div>
									<mat-menu #actionsMenu="matMenu" class="edit-notification-menu">
										<div class="edit-body-wrapper">
											<div
												class="edit-menu-item"
												(click)="
													onEditNotificationClick(notification, 'delete'); $event.stopPropagation(); actionsMenuTrigger.closeMenu()
												"
											>
												<img class="edit-icon mr-s" src="assets/creators/general/trash.svg" />
												<div class="d-flex w-100 flex-column">
													<span class="txt-b">Delete</span>
													<span class="txt-n mt-xs">Delete this notification</span>
												</div>
											</div>

											<div
												class="edit-menu-item"
												(click)="
													onEditNotificationClick(notification, 'mute'); $event.stopPropagation(); actionsMenuTrigger.closeMenu()
												"
											>
												<ng-container *ngIf="notification?.active">
													<img class="edit-icon mr-s" src="assets/creators/general/unmute-notification.svg" />
													<div class="d-flex w-100 flex-column">
														<span class="txt-b">Turn on</span>
														<span class="txt-n mt-xs">Start receiving notification like this</span>
													</div>
												</ng-container>
												<ng-container *ngIf="!notification?.active">
													<img class="edit-icon mr-s" src="assets/creators/general/mute-notification.svg" />
													<div class="d-flex w-100 flex-column">
														<span class="txt-b">Turn off</span>
														<span class="txt-n mt-xs">Stop receiving notification like this</span>
													</div>
												</ng-container>
											</div>
										</div>
									</mat-menu>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>

			<ng-container *ngIf="!notifications || notifications.length <= 0">
				<div class="no-data">
					<img class="no-data-img" src="assets/icons/notifications-empty.svg" />

					<span>
						No Notifications Yet
					</span>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
