import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomValidator } from 'src/app/shared/form-input/input-password/custom-validator';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { getApiEnums, SharedState } from 'src/app/shared/state/shared.reducer';
import { FiledUsersApiCodesEnum } from 'src/app/shared/state/state-entities/errors/api-codes.enum';
import { FiledApiModels, FiledUsersApiModel } from 'src/app/shared/state/state-entities/errors/errors.model';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { PasswordValidator } from 'src/app/user-management/models/validators';
import { ChangePassword } from 'src/app/_models/identity-models/change-password';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';

@Component({
	selector: 'app-security',
	templateUrl: './security.component.html',
	styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public passwordChangePopup = false;
	private apiCodes: FiledApiModels;
	public changePasswordForm: UntypedFormGroup;
	public validColor: string;
	public currentPasswordControl = new UntypedFormControl('', [Validators.required]);
	public newPasswordControl = new UntypedFormControl('', [
		Validators.required,
		CustomValidator.patternValidator(/\d/, {
			hasNumber: true
		}),
		CustomValidator.patternValidator(/[A-Z]/, {
			hasCapitalCase: true
		}),
		CustomValidator.patternValidator(/[a-z]/, {
			hasSmallCase: true
		}),
		CustomValidator.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
			hasSpecialCharacters: true
		}),
		Validators.minLength(8)
	]);
	public passwordValidator = PasswordValidator;
	public passwordIsValid: boolean = false;

	public minimumChar: boolean = false;
	public upperChar: boolean = false;
	public numberChar: boolean = false;
	public specialChar: boolean = false;
	public allValidated: boolean = false;

	constructor(
		private dialog: MatDialog,
		private userServiceApi: UserServiceApi,
		private formBuilder: UntypedFormBuilder,
		private store: Store<SharedState>,
		private toastNotificationService: ToastNotificationService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.fetchStore();
		this.initializeFormControls();
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	private initializeFormControls(): void {
		this.changePasswordForm = this.formBuilder.group({
			currentPasswordControl: this.currentPasswordControl,
			newPasswordControl: this.newPasswordControl
		});
		this.changePasswordForm.disable();
	}

	public checkValidForm(): boolean {
		return !!(this.currentPasswordControl.errors || this.newPasswordControl.errors);
	}

	public changePassword() {
		// this.passwordChangePopup = true;

		// public checkValidation(): void {
		if (this.checkValidForm()) {
			this.changePasswordForm.markAllAsTouched();
		} else {
			this.onSubmit();
		}
		// }
	}

	public closePasswordChangePopup() {
		this.passwordChangePopup = false;
	}

	public passwordValid(event: boolean): void {
		this.passwordIsValid = event;
	}
	public passwordStepMarker(event: string[]): void {
		this.allValidated = false;
		this.minimumChar = false;
		this.upperChar = false;
		this.numberChar = false;
		this.specialChar = false;
		this.validColor = '';

		if (event.includes('minimum')) {
			this.minimumChar = true;
		}
		if (event.includes('uppers')) {
			this.upperChar = true;
		}

		if (event.includes('numbers')) {
			this.numberChar = true;
		}

		if (event.includes('symbols')) {
			this.specialChar = true;
		}

		if (this.minimumChar && this.upperChar && this.numberChar && this.specialChar) {
			this.allValidated = true;
			this.validColor = '#000000'; //'#34D24A';
		}
	}

	private fetchStore(): void {
		this.store.pipe(select(getApiEnums), takeUntil(this.unsubscriber$)).subscribe(apiCodes => {
			this.apiCodes = apiCodes;
		});
	}

	public onSubmit(): void {
		const changePassword = {
			CurrentPassword: this.changePasswordForm.get('currentPasswordControl').value,
			NewPassword: this.changePasswordForm.get('newPasswordControl').value,
			ConfirmNewPassword: this.changePasswordForm.get('newPasswordControl').value
		} as ChangePassword;
		this.store.dispatch(new ShowGlobalSpinner());
		this.userServiceApi
			.changePassword(changePassword)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				data => {
					this.store.dispatch(new HideGlobalSpinner());
					this.passwordChangePopup = true;
					this.changePasswordForm.reset();
					this.changePasswordForm.disable();
					console.log(data);
				},
				err => {
					this.store.dispatch(new HideGlobalSpinner());
					console.log(err);
					if (err) {
						const errors = err.error;
						let messages = '';
						errors.forEach((error: FiledUsersApiModel) => {
							const code = error.code;
							let message = '';
							for (let value in FiledUsersApiCodesEnum) {
								if (Number(FiledUsersApiCodesEnum[code]) === +value) {
									message = this.apiCodes.filedApiUsersState.filedUsersApiCodesState[Number(value) - 1].message;
								}
								if (Number(value) === FiledUsersApiCodesEnum.Filed__Domain__NA__SetPassword__IsInvalidPasswordMismatch) {
									this.currentPasswordControl.setErrors({ incorect: true });
								}
							}

							messages += message + '\n';
						});

						this.toastNotificationService.sendErrorToast(messages);
					} else {
						this.toastNotificationService.sendErrorToast('An error occurred while trying to change your password!');
					}
				}
			);
	}

	public onPauseAccount(): void {
		this.router.navigate(['user-management/security/sad-tosee-you-go']);
	}
}
