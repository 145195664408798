<div class="dialog-root">
	<h4>
		{{ data?.title }}
	</h4>

	<div class="video-wrapper" (click)="togglePlayPause()">
		<video #videoPlayer [src]="data?.videoSrc" [poster]="data?.videoPoster"></video>
		<mat-icon class="open-in-new-tab" (click)="$event.stopPropagation(); openInNewTab(data?.videoSrc)">open_in_new</mat-icon>
		<img *ngIf="videoPlayer?.paused" src="assets/icons/video-play.svg" class="center-icon {{ isFirstTimePlay ? 'visibleimp' : '' }}" />
		<img *ngIf="!videoPlayer?.paused" src="assets/icons/video-pause.svg" class="center-icon" />
	</div>

	<ul>
		<li *ngFor="let listItem of data?.list">
			<img [src]="'assets/icons/rating-star.svg'" height="20" width="20" />

			<div class="d-flex flex-column" [style.gap]="'8px'">
				<h5>{{ listItem?.title }}</h5>
				<p>{{ listItem?.subtitle }}</p>
			</div>
		</li>
	</ul>

	<div class="footer">
		<p>
			{{ data?.footerText }}
		</p>

		<div class="d-flex w-100 k-align-items-center" [style.gap]="'20px'">
			<button class="secondary-btn flex-1" *ngIf="!data?.oneButtonFooter" (click)="onSecondaryBtnClick()">{{ data?.secondaryBtnText }}</button>
			<button class="primary-btn flex-1" (click)="onPrimaryBtnClick()">{{ data?.primaryBtnText }}</button>
		</div>
	</div>
</div>
