import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SigninComponent } from './signin/signin.component';
import { TokenService } from '../_services/token.service';
import { SharedModule } from '../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GoogleService } from '../_services/google/google.service';
import { ConnectToSourceComponent } from './connect-to-source/connect-to-source.component';
import { ConnectToGoogleComponent } from './connect-to-google/connect-to-google.component';
import { ConnectToFacebookComponent } from './connect-to-facebook/connect-to-facebook.component';
import { authenticationReducer } from './state/authentication.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './state/authentication.effects';
import { GoogleLandingPageComponent } from './landing-pages/google-landing-page/google-landing-page.component';
import { FacebookLandingPageComponent } from './landing-pages/facebook-landing-page/facebook-landing-page.component';
import { ResetPasswordWithEmailComponent } from './reset-password-with-email/reset-password-with-email.component';
import { TrialDialogComponent } from './trial-dialog/trial-dialog.component';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { SubUserRegisterComponent } from './sub-user-register/sub-user-register.component';
import { ShopifyIntegrationLoadingComponent } from './shopify-integration-loading/shopify-integration-loading.component';
import { BillingPlansV2Component } from './billing-plans-v2/billing-plans-v2.component';
import { VerifyTokenComponent } from './verify-token/verify-token.component';

@NgModule({
	imports: [
		AuthenticationRoutingModule,
		TranslateModule,

		FlexLayoutModule,
		SharedModule,
		RouterModule,
		StoreModule.forFeature('authentication', authenticationReducer),
		EffectsModule.forFeature([AuthenticationEffects])
	],
	declarations: [
		SigninComponent,
		SetPasswordComponent,
		ConnectToSourceComponent,
		ConnectToGoogleComponent,
		ConnectToFacebookComponent,
		FacebookLandingPageComponent,
		GoogleLandingPageComponent,
		ResetPasswordWithEmailComponent,
		TrialDialogComponent,
		SubUserRegisterComponent,
		ShopifyIntegrationLoadingComponent,
		BillingPlansV2Component,
		VerifyTokenComponent
	],
	exports: [SigninComponent, SetPasswordComponent, FacebookLandingPageComponent, GoogleLandingPageComponent, ConnectToFacebookComponent],
	providers: [TokenService, GoogleService, InterceptorsProvider],
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthenticationModule {}
