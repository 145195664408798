import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-input-no-special',
	templateUrl: './input-no-special.component.html',
	styleUrls: ['./input-no-special.component.scss']
})
export class InputNoSpecialComponent implements OnInit {
	@Input('placeHolderTextBox') public placeHolderTextBox: string = 'Enter text here';
	@Input('inputControl') public inputControl: UntypedFormControl;
	@Input() public disabled: boolean = false;
	@Input('readOnly') public readOnly: boolean = false;
	@Input('errorMessage') public errorMessage: string = 'Enter text';
	@Input('maxLength') public maxLength: number = 180;
	@Input('hideError') public hideError: boolean = false;
	@Input('showValidIcon') public showValidIcon: boolean = false;
	@Input('showCustomIcon') public showCustomIcon: string = null;
	@Input() iconWidth: string = null;
	@Input() marginRight: string = null;
	@Output() public kendoInputEvent = new EventEmitter<any>();
	private unsubscriber$ = new Subject<void>();

	constructor() {}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public showIconStyle(): Object {
		let styles = {};
		if (this.iconWidth && this.marginRight) {
			styles = {
				width: this.iconWidth,
				'margin-right': this.marginRight
			};
		}
		return styles;
	}
}
