import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-gmail-landing',
	templateUrl: './gmail-landing.component.html',
	styleUrls: ['./gmail-landing.component.scss']
})
export class GmailLandingComponent implements OnInit {
	private code: string = '';
	public error?: string;
	public loading: boolean = true;
	private unsubscriber$ = new Subject<void>();

	constructor(private route: ActivatedRoute, private router: Router, private sharedService: SharedService, private toast: ToastNotificationService) {
		this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.code = params['code'];
			this.callGoogleConnectionAPI();
		});
	}

	ngOnInit(): void {}

	private callGoogleConnectionAPI(): void {
		this.loading = true;
		this.sharedService
			.googleSignUpApi(this.code)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res?.data) {
						window.location.href = res.data;
					}
				},
				err => {
					this.toast.sendErrorToast(err?.error?.message ?? 'Some Error Occurred!');
					this.error = err?.error?.message;
					if (err?.data) {
						window.location.href = err?.error?.data;
					} else {
						this.router.navigate(['/user-management/integrations']);
					}
					this.loading = false;
				}
			);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
