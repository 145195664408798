<kendo-card>
	<div class="analytics-content-card d-flex d-j-c-flex-column">
		<div class="d-flex d-j-c-flex-between d-align-items-c header">
			<filed-font [fontSize]="'16px'" *ngIf="content?.totalEngagement && content?.totalEngagement != 0">
				{{ content?.totalEngagement }} Engagements
			</filed-font>
			<filed-font [fontSize]="'16px'" *ngIf="content?.totalEngagement && content?.totalEngagement == 0">
				{{ content?.totalEngagement }} Engagements
			</filed-font>
			<app-primary-button [buttonLable]="'View'" [buttonType]="'Primary'" [isPeddingReduceSmall]="true" (buttonClicked)="view()"></app-primary-button>
		</div>
		<div class="content" (click)="view()">
			<img
				*ngIf="content?.media_type != 'video'"
				[src]="content?.imageUrl ? content?.imageUrl : 'assets/images/chat/icon-default-user.svg'"
				class="content-image"
			/>
			<video *ngIf="content?.media_type == 'video'" [src]="content?.imageUrl" class="content-image"></video>
			<div class="play" *ngIf="content?.contentType && content?.contentType == 'video'">
				<mat-icon>play_arrow</mat-icon>
			</div>
		</div>
		<div class="footer d-flex d-j-c-flex-between">
			<div>
				<filed-font [fontSize]="'16px'">
					From
					<span class="font-bold">{{ content?.campaignName }}</span>
				</filed-font>
				<filed-font [fontSize]="'16px'">
					By
					<span class="font-bold">{{ content?.influencerName }}</span>
				</filed-font>
				<div class="d-flex d-align-items-c time-stamp">
					<filed-font [fontSize]="'12px'" [color]="'#484848'">{{ content?.date | date: 'M/dd/y HH:mm' }}</filed-font>
					<mat-icon>access_time</mat-icon>
				</div>
			</div>
			<div class="platform">
				<img src="assets/images/social-media-logo/{{ content?.platformName }}.svg" class="platform-logo" />
			</div>
		</div>
	</div>
</kendo-card>
