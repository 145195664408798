import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidatorMessages } from '../validatorMessagesInputs';
import { ErrorMessageService } from '../error-message.service';

@Component({
	selector: 'app-input-textarea',
	templateUrl: './input-textarea.component.html',
	styleUrls: ['./input-textarea.component.scss']
})
export class InputTextareaComponent {
	@Input() control: UntypedFormControl | AbstractControl;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() label: string;
	@Input() placeholder: string;

	public isDestinationFocused: boolean;
	public errorMessage: string;

	constructor(public errorMessageService: ErrorMessageService) {}

	ngOnInit() {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}
}
