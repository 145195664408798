<div mat-dialog-title>
	<div (click)="close()" class="x">
		<img alt="close" src="assets/icons/user-management/account/x.png" />
	</div>
	<div class="question">
		<img alt="dialog" src="assets/icons/user-management/account/question.png" />
	</div>
	<h4>Are you sure you want to change your payment method?</h4>
</div>

<div class="buttons">
	<app-button (buttonClicked)="close()" [buttonClass]="buttonClassEnum.Secondary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="CANCEL"></app-button>
	<app-button (buttonClicked)="send()" [buttonClass]="buttonClassEnum.Primary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="YES"></app-button>
</div>
