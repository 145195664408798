<div class="custom-drp-with-search-option">
	<mat-form-field>
		<mat-select
			panelClass="sample-drp-panel"
			disableOptionCentering
			[formControl]="inputControl"
			(selectionChange)="onSelectionChange()"
			(openedChange)="resetList($event)"
		>
			<mat-select-trigger>{{ placeholder }}</mat-select-trigger>
			<div class="mat-select-search-custom">
				<img src="assets\crm\automation\Union 1.svg" alt="" />
				<input placeholder="Search" [formControl]="searchControl" (keyup)="onSearch($event?.target?.value)" />
			</div>
			<mat-option *ngFor="let item of filteredList" [value]="item">
				{{ item?.name }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
