<!-- <app-lolly-spinning-loader></app-lolly-spinning-loader> -->
<div class="container">
	<header>
		<img src="assets/icons/nav-menu/header/lolly-black-logo.svg" />
		<span class="btn" (click)="logout()">
			<img src="assets/icons/nav-menu/signout.svg" />
			Sign out
		</span>
	</header>
	<div class="wrapper">
		<div class="heading">Explore which plan is better for your business</div>
		<span class="desc">
			Lolly’s plans & pricing are designed to meet your needs as you grow
		</span>
		<div class="main">
			<!-- <app-manage-subscription [isLoggedIn]="false" [data]="subscriptionData" [buttonText]="'Start free 7-day trial'"></app-manage-subscription> -->
			<app-manage-subscription [isLoggedIn]="false" [data]="subscriptionData" [buttonText]="'Start'"></app-manage-subscription>
		</div>

		<section class="icons">
			<div class="icons-wrapper">
				<div class="flexed-inner align-center justify-center gap-l wrap margin-top---s">
					<div class="d-flex k-align-items-center justify-content-center" style="gap: 16px;">
						<img
							alt="Green Check"
							src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/64b5a7c1d48b7aea67102d34_Time%20Reverse%20Icon.svg"
							class="icon---medium"
							data-airgap-id="571"
						/>
						<div class="body-small">
							7-day risk
							<br />
							free cancelation
						</div>
					</div>
					<div class="d-flex k-align-items-center justify-content-center" style="gap: 16px;">
						<img
							src="https://assets-global.website-files.com/60e5f2de011b86acebc30db7/64b5a7c2febdbcd70ff5a11e_Satisfaction%20Guarantee.svg"
							alt="Green Check"
							class="icon---medium"
							data-airgap-id="573"
						/>
						<div class="body-small">
							Award-winning
							<br />
							customer&nbsp;support
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
