export enum PlanDurationEnum {
	yearly = 'yearly',
	monthly = 'monthly'
}

export enum PlanTypeEnum {
	business = 'Business',
	teams = 'Teams',
	starter = 'Starter'
}

export interface PlansDataModel {
	interval: PlanDurationEnum;
	price: number;
	trial_days: number;
	title: PlanTypeEnum;
	description: string;
	stripe_price_id: string;
	currency: string;
	limits: PlanLimitsDataModel;
}

export interface PlanLimitsDataModel {
	viewed_profiles_limit?: number;
	team_size_limit?: number;
	search_results_limit?: number;
	is_payment_integration_allow?: boolean;
	emails_integration_limit?: number;
	exports_limit?: number;
	viewed_emails_limit?: number;
	has_advanced_search?: boolean;
}
