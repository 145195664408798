<!-- notification input type number card -->
<div class="input-wrapper">
	<div class="input-number-wrapper">
		<mat-form-field
			[ngClass]="{
				'error-color-message': control.invalid && control.touched,
				'success-color-message': control.valid && control.touched,
				'disabled-color-message': control.disabled
			}"
			appearance="outline"
		>
			<mat-label>{{ label }}</mat-label>

			<input
				(focus)="autocompleteFocus()"
				(focusout)="setErrorMessage()"
				[formControl]="control"
				ngDefaultControl
				matInput
				autocomplete="off"
				placeholder="{{ placeholder }}"
				type="text"
				maxlength="19"
				#ccNumber
				(keyup)="creditCardNumberSpacing()"
			/>

			<!--Icon-->
			<ng-container *ngIf="isIcon">
				<div [ngSwitch]="cardType">
					<i *ngSwitchCase="cardTypeEnum.Visa" class="fab fa-cc-visa"></i>
					<i *ngSwitchCase="cardTypeEnum.Mastercard" class="fab fa-cc-mastercard"></i>
					<i *ngSwitchCase="cardTypeEnum.Amex" class="fab fa-cc-amex"></i>
					<i *ngSwitchCase="cardTypeEnum.Discover" class="fab fa-cc-discover"></i>
					<i *ngSwitchCase="cardTypeEnum.Diners" class="fab fa-cc-diners-club"></i>
					<i *ngSwitchCase="cardTypeEnum.JCB" class="fab fa-cc-jcb"></i>
					<i *ngSwitchCase="cardTypeEnum.VisaElectron" class="fab fa-cc-visa"></i>
					<i *ngSwitchDefault class="far fa-credit-card"></i>
				</div>
			</ng-container>

			<!--Error message -->
			<mat-error *ngIf="control.invalid">{{ errorMessage }}</mat-error>
		</mat-form-field>
	</div>
</div>
