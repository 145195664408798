import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { DiscountDataModel } from 'src/app/shared-components/create-product-seeding-step-three/create-product-seeding-step-three.component';
import { url } from 'inspector';
import { budgetType, campaignCurrency, campaignForm, campaignObjective } from './campaign-form.interface';
import { CatalogueModel } from './catalogue.model';
import { ProductModel } from './product.model';
@Injectable({
	providedIn: 'root'
})
export class CampaignFormService {
	public curency = new BehaviorSubject<campaignCurrency[]>(null);
	public castcurency = this.curency.asObservable();

	constructor(private http: HttpClient) {}

	public getObjective(): Observable<campaignObjective[]> {
		return this.http.get<campaignObjective[]>(`${BaseApiUrl.SocialInfluencerCS}Campaign/campaignObjectives`);
	}

	public getpaymentRecords(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}payments/campaigns`);
	}

	public getpaymentCampaign(id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}payments/campaigns/${id}`);
	}

	public getpaymentsOverview(id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}payments/${id}`);
	}

	public getpaymentCards(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}payments/cards`);
	}

	public getAddCards(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.SocialMessengerPython}payments/cards`, {});
	}

	public createpaymentIntent(body): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}payments/create-payment-intent`, body);
	}

	public getCurrency(): Observable<campaignCurrency[]> {
		return this.http.get<campaignCurrency[]>(`${BaseApiUrl.SocialInfluencerCS}Campaign/currencies`);
	}

	public getBudgetType(): Observable<budgetType[]> {
		return this.http.get<budgetType[]>(`${BaseApiUrl.SocialInfluencerCS}Campaign/campaignBudgetTypes`);
	}
	public createCampaign(body: campaignForm): Observable<number> {
		return this.http.post<number>(`${BaseApiUrl.SocialInfluencerCS}Campaign`, body);
	}

	public createNewCampaign(
		body: campaignForm
	): Observable<{
		campaign_id: number;
		job_id: number;
	}> {
		return this.http.post<{
			campaign_id: number;
			job_id: number;
		}>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/createCampaigns`, body);
	}

	public createACampaign(body: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialInfluencerPython}campaigns`, body);
	}

	public sendJobsToCreators(jobIds: number[], influencerIds: number[], bulkSelectCount?: number, filterString?: string): Observable<any> {
		let payload = { discovery_ids: influencerIds, job_ids: jobIds, type: 'individual' };
		if (bulkSelectCount) {
			payload['size'] = bulkSelectCount;
			payload['type'] = 'bulk';
		}
		if (filterString) {
			payload['filter'] = filterString;
		}
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}applications`);
		return this.http.post<any>(url.href, payload);
	}

	public sendJobToList(jobIds: number[], listId: number): Observable<any> {
		let payload = { job_ids: jobIds };

		const url = new URL(`${BaseApiUrl.SocialMessengerPython}applications/list/${listId}`);
		return this.http.post<any>(url.href, payload);
	}

	public createAJob(campaignId: number, body: any): Observable<{ campaign_id: number; job_id: number }> {
		return this.http.post<{
			campaign_id: number;
			job_id: number;
		}>(`${BaseApiUrl.SocialInfluencerPython}campaigns/${campaignId}/jobs`, body);
	}

	public updateExistingCampaign(campaignId: number, body: campaignForm): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.SocialInfluencerPython}campaigns/${campaignId}`, body);
	}

	public uploadImageForCampaignJobCreation(job_id: number, file: File, fileName: string): Observable<any> {
		const body = new FormData();
		body.append('image', file);
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}upload/jobs/${job_id}/image`, body);
	}

	public getRewrds(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/getUserRewards`);
	}

	public getOffers(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/getUserOffers`);
	}

	public addUserRewadrs(body: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/addUserReward`, body);
	}

	public editUserRewadrs(body: any, oldName: string): Observable<any> {
		let payload = body;
		payload.old_name = oldName;
		return this.http.post<any>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/editUserReward/`, payload);
	}

	public addUserOffers(body: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/addUserOffer`, body);
	}

	public editUserOffers(body: any, oldName: string): Observable<any> {
		let payload = body;
		payload.old_name = oldName;
		return this.http.post<any>(`${BaseApiUrl.SocialInfluencerPython}campaignsPage/editUserOffer/`, payload);
	}

	public updateCampaign(campaignId: number, campaignStatusId: string, name: string): Observable<number> {
		let body = {
			campaignId: campaignId,
			campaignStatusId: campaignStatusId,
			name: name
		};
		return this.http.post<number>(`${BaseApiUrl.SocialInfluencerCS}Campaign/updateCampaign`, body);
	}

	public validateCampName(name: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}campaigns/exists?name=${name}`);
	}

	public getCuratedCurrencyList(): Observable<{ data: campaignCurrency[] }> {
		return this.http.get<{ data: campaignCurrency[] }>(`${BaseApiUrl.SocialMessengerPython}currency/available`);
	}

	public getAllCatalogues(shop: string, payload?: any): Observable<CatalogueModel[]> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}ecommerce/catalogues?shop=${shop}`;
		if (payload?.search) {
			urlString = urlString + `?search=${payload?.search}`;
		}
		const url = new URL(urlString);
		return this.http.get<CatalogueModel[]>(url.href);
	}

	public getAllProducts(queryParams: HttpParams): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}ecommerce/products`, { params: queryParams });
	}

	public getDiscountCodes(): Observable<DiscountDataModel[]> {
		// return this.http.get<DiscountDataModel[]>(`${BaseApiUrl.SocialInfluencerPython}bigcommerce/coupons`);
		const mock: DiscountDataModel[] = [
			{
				id: 1,
				name: '1%'
			},
			{
				id: 2,
				name: '2%'
			},
			{
				id: 3,
				name: '5%'
			},
			{
				id: 4,
				name: '10%'
			},
			{
				id: 5,
				name: '20%'
			},
			{
				id: 6,
				name: '50%'
			}
		];
		return of(mock);
	}
}
