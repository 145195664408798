import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[lazyAutofocus]'
})
export class AutofocusDirective {
	constructor(private element: ElementRef) {}

	ngAfterViewInit(): void {
		this.element.nativeElement.focus();
	}
}
