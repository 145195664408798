import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-new-generic-dialog',
	templateUrl: './new-generic-dialog.component.html',
	styleUrls: ['./new-generic-dialog.component.scss']
})
export class NewGenericDialogComponent implements OnInit {
	@ViewChild('videoPlayer') videoPlayer: ElementRef;
	public isFirstTimePlay: boolean = true;

	constructor(public dialogRef: MatDialogRef<NewGenericDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {}

	public async togglePlayPause() {
		if (this.isFirstTimePlay) {
			this.isFirstTimePlay = false;
		}
		const video: HTMLVideoElement = this.videoPlayer.nativeElement;

		if (video.paused) {
			await video.play();
		} else {
			await video.pause();
		}
	}

	public primaryAction(): void {
		this.dialogRef.close({ primaryAction: 'true' });
	}

	public secondaryAction(): void {
		this.dialogRef.close({ secondaryAction: 'true' });
	}

	public openInNewTab(url: string): void {
		window.open(url, '_blank');
	}
}
