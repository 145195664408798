<div class="container">
	<div class="body">
		<div class="email-integration">
			<div class="title">
				{{
					successPage
						? 'Your Account has been Successfully Connected, You can now Close this window'
						: 'Your Account has not been Connected, You can now Close this window'
				}}
			</div>
			<button class="add-acc-btn" (click)="close()" mat-button>
				<span style="text-transform: capitalize; font-family: 'Gilroy';">Close</span>
			</button>
		</div>
		<app-google-disclosure *ngIf="successPage"></app-google-disclosure>
	</div>
</div>
