import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-email-integrations-loading',
	templateUrl: './email-integrations-loading.component.html',
	styleUrls: ['./email-integrations-loading.component.scss']
})
export class EmailIntegrationsLoadingComponent implements OnInit {
	successPage: boolean;

	constructor(private activeRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.activeRoute.data.subscribe(data => {
			this.successPage = data.success;
		});
	}

	public close(): void {
		window.close();
	}
}
