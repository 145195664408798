<div class="container">
	<div class="step1" *ngIf="allStep.step1 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="button">
			<img src="assets/icons/Dexter.svg" />
			<filed-font [fontSize]="'24px'">Welcome to LOLLY {{ decodeJwtToken.user_firstname }}.</filed-font>
			<button (click)="getStarted(allStep.step2)" class="btn-gcb-06">CONNECT ACCOUNTS</button>
		</div>
	</div>

	<div class="step2" *ngIf="allStep.step2 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body">
				<filed-font [fontSize]="'22px'">Connect your accounts</filed-font>
				<filed-font [fontSize]="'16px'">Select a channel and start setting you up for your account!</filed-font>
				<div class="box-wrapper">
					<div
						*ngFor="let channel of Channels"
						class="box"
						(click)="selectChannel(channel)"
						[ngClass]="{
							'selected-channel': selectedChannel?.name === channel.name,
							'box-disabled': channel.status === false,
							'disabled-channel': channel.name === 'Facebook' ? isFacebookConnected : isGoogleConnected || channel.status === false
						}"
					>
						<div>
							<div class="ch-name d-flex">
								<filed-font [fontSize]="'18px'">{{ channel.name }}</filed-font>
								<filed-font [fontSize]="'18px'" *ngIf="channel.name === 'Facebook' && isFacebookConnected">CONNECTED</filed-font>
								<filed-font [fontSize]="'18px'" *ngIf="channel.name === 'Google' && isGoogleConnected">CONNECTED</filed-font>
								<filed-font [fontSize]="'18px'" *ngIf="channel.status === false">COMING SOON</filed-font>
							</div>

							<div class="image">
								<img class="icon" src="{{ channel.icon }}" />
							</div>
							<filed-font [fontSize]="'18px'">Your channel must:</filed-font>

							<div class="bullet-container">
								<div class="bullet-wrapper" *ngFor="let bullet of channel.bullets">
									<div class="dot">•</div>
									<filed-font [fontSize]="'16px'">{{ bullet }}</filed-font>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="connect-buttons">
				<button type="button" class="connect-btn-grey" (click)="skipConnection()">Skip</button>
				<ng-container *ngIf="selectedChannel?.name === 'Google'">
					<img (mouseleave)="googleImg(0)" (mouseenter)="googleImg(1)" (click)="getStarted(allStep.step3)" [src]="googleImgSrc" />
				</ng-container>
				<ng-container *ngIf="selectedChannel?.name !== 'Google'">
					<app-primary-button
						*ngIf="selectedChannel"
						[buttonLable]="'CONNECT'"
						[buttonType]="'Primary'"
						(buttonClicked)="getStarted(allStep.step3)"
						[isPeddingReduceSmall]="true"
					></app-primary-button>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="step3" *ngIf="allStep.step3 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body">
				<div class="step3-body">
					<img *ngIf="selectedChannel.name !== 'Google'" src="assets/icons/Dexter-Facebook-Loading.svg" />
					<img *ngIf="selectedChannel.name === 'Google'" src="assets/icons/GoogleLoading.svg" />
				</div>
				<div class="text-body">
					<filed-font [fontSize]="'24px'">Just one moment.</filed-font>
					<filed-font [fontSize]="'14px'">
						We are gathering data from your {{ selectedChannel?.name }} account to help you get started with Lolly.
					</filed-font>
				</div>
			</div>
		</div>
	</div>
	<div class="step4" *ngIf="allStep.step4 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body" *ngIf="!googleSelected && !facebookSelected">
				<img class="img" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
				<filed-font [fontSize]="'24px'">Connected!</filed-font>
				<filed-font [fontSize]="'14px'">Your {{ selectedChannel?.name }} account is now connected with us.</filed-font>
				<filed-font [fontSize]="'14px'">Would you like to connect another channel? Or start exploring?</filed-font>
				<div class="button">
					<app-primary-button
						[buttonLable]="'CONNECT ANOTHER CHANNEL'"
						[buttonType]="'Secondary'"
						(buttonClicked)="getStarted(allStep.step2)"
						[isPeddingReduceSmall]="true"
					></app-primary-button>

					<app-primary-button
						[buttonLable]="'START EXPLORING'"
						[buttonType]="'Primary'"
						(buttonClicked)="getStarted(allStep.step5)"
						[isPeddingReduceSmall]="true"
					></app-primary-button>
				</div>
			</div>
			<div class="body" *ngIf="googleSelected || facebookSelected">
				<app-google-list *ngIf="googleSelected" (connectAccount)="lastSteponGoogle($event)" [googleAdsData]="googleAdsData"></app-google-list>
				<app-facebook-list
					*ngIf="facebookSelected"
					(connectAccount)="lastSteponFacebook($event)"
					[facebookAdsData]="facebookAdsData"
				></app-facebook-list>
			</div>
		</div>
	</div>
	<div class="step4" *ngIf="allStep.step4b === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con">
			<div class="body">
				<img class="img" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
				<filed-font [fontSize]="'24px'">Connection Error!</filed-font>
				<filed-font [fontSize]="'14px'">Connecting Google account has encounter an error.</filed-font>
				<filed-font [fontSize]="'14px'">Would you like to try again or connect a different channel?</filed-font>
				<div class="button">
					<app-primary-button
						[buttonLable]="'CONNECT ANOTHER CHANNEL'"
						[buttonType]="'Secondary'"
						(buttonClicked)="getStarted(allStep.step2)"
						[isPeddingReduceSmall]="true"
					></app-primary-button>

					<app-primary-button
						[buttonLable]="'RETRY'"
						[buttonType]="'Primary'"
						(buttonClicked)="getStarted(allStep.step1)"
						[isPeddingReduceSmall]="true"
					></app-primary-button>
				</div>
			</div>
		</div>
	</div>
	<div class="step5" *ngIf="allStep.step5 === currentStep">
		<div class="header">
			<img class="header-icon" alt="" src="assets/logo/lolly-white.svg" />
			<app-welcome-popup-header></app-welcome-popup-header>
		</div>
		<div class="body-con" [class.hasSkip]="!skipIsFired">
			<div style="margin-top: 120px;" class="body">
				<filed-font [fontSize]="'22px'">Get Started...</filed-font>
				<filed-font [fontSize]="'16px'">Select a product below, and let us walk you through how the product works.</filed-font>
				<div class="d-wrapper">
					<div class="box-wrapper">
						<div *ngFor="let item of planDetails">
							<div
								class="module"
								(click)="selectPage(item)"
								[class.selected-module]="pageSelected?.text === item.text"
								*ngIf="skipIsFired && item.skip"
							>
								<div class="img"><img src="assets/new-signup/dexter.png" /></div>
								<div class="title">
									<filed-font [fontSize]="'18px'">{{ item.text }}</filed-font>
								</div>
								<div class="sub-title">
									<filed-font [fontSize]="'14px'">{{ item.description }}</filed-font>
								</div>
							</div>
							<div class="module" (click)="selectPage(item)" [class.selected-module]="pageSelected?.text === item.text" *ngIf="!skipIsFired">
								<div class="img"><img src="assets/new-signup/dexter.png" /></div>
								<div class="title">
									<filed-font [fontSize]="'18px'">{{ item.text }}</filed-font>
								</div>
								<div class="sub-title">
									<filed-font [fontSize]="'14px'">{{ item.description }}</filed-font>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="connect-buttons">
				<button type="button" style="opacity: 0;" class="connect-btn-grey">Skip</button>
				<div>
					<app-primary-button
						[buttonLable]="'CONTINUE'"
						[buttonType]="'Secondary'"
						[isPeddingReduceSmall]="true"
						*ngIf="!isPageSelected"
						[isDisabled]="!isPageSelected"
					></app-primary-button>

					<app-primary-button
						[buttonLable]="'CONTINUE'"
						[buttonType]="'Primary'"
						(buttonClicked)="routeRequest()"
						[isPeddingReduceSmall]="true"
						*ngIf="isPageSelected"
					></app-primary-button>
				</div>
			</div>
		</div>
	</div>
</div>
