import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
	@Input() public control: UntypedFormControl = new UntypedFormControl();
	@Input() public inputPlaceholder: string = 'Search by name';
	@Output() public searchButtonClicked: EventEmitter<string> = new EventEmitter<string>();
	@Input() public maxLength: any;
	@Input() public styleClass: string = '';
	@Input() public inputStyleClass: string = '';

	constructor() {}

	ngOnInit(): void {}

	searchClick($event: any) {
		this.searchButtonClicked.emit(this.control.value);
	}
}

export function capitalizeFirst(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}
