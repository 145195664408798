<div class="spinner" *ngIf="loading">
	<app-lolly-spinning-loader></app-lolly-spinning-loader>
</div>
<div *ngIf="!loading" style="height: 100%; background-color: #fafafa;">
	<div fxLayout="column" fxLayoutGap="2%" class="w-100 h-100">
		<div *ngIf="currentProductSeedingStep == 0" fxFlex="90" class="campaign-stepper-align-center position-still">
			<app-create-product-seeding-step-one
				[productSeedingForm]="productSeedingForm"
				(currentStepValid)="checkStepValid($event)"
			></app-create-product-seeding-step-one>
		</div>

		<div *ngIf="currentProductSeedingStep == 1" fxFlex="90" class="campaign-stepper-align-center position-still">
			<app-create-product-seeding-step-two
				[productSeedingForm]="productSeedingForm"
				(currentStepValid)="checkStepValid($event)"
			></app-create-product-seeding-step-two>
		</div>

		<div *ngIf="currentProductSeedingStep == 2" fxFlex="90" class="campaign-stepper-align-center position-still">
			<app-create-product-seeding-step-three
				[discountCoupons]="discountCoupons"
				[productSeedingForm]="productSeedingForm"
				(currentStepValid)="checkStepValid($event)"
			></app-create-product-seeding-step-three>
		</div>

		<div fxFlex="10" class="footer" fxLayoutAlign="space-between center">
			<div>
				<button class="secondary-btn-01" (click)="onBack()">Back</button>
			</div>
			<div>
				<button class="primary-btn-01" (click)="onNext()">Next</button>
			</div>
		</div>
	</div>
</div>
