import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
	itemsPerPageLabel = 'Show rows:';
	getRangeLabel = (page: number, pageSize: number, length: number) => {
		return `Page Number : ${page + 1}`;
	};
}
