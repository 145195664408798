<kendo-card [ngClass]="smallView ? 'small' : ''">
	<div>
		<div class="user">
			<div class="d-flex align-centre space-between" *ngIf="!smallView">
				<btn09 [icon]="'arrow-chevron-left'" (click)="goBack()">Back to {{ backTo }}</btn09>
			</div>
			<div class="d-flex align-centre direction-column user-details">
				<img class="user-img" [src]="profileDetails?.usrImg" onError="this.src='assets/icons/user-management/account/user-circle-solid.png'" />
				<filed-font [fontSize]="'16px'" class="name fw-600">{{ profileDetails.name }}</filed-font>
				<filed-font [fontSize]="'14px'" class="fw-400" *ngIf="profileDetails.gender">{{ profileDetails.gender }}</filed-font>
				<filed-font [fontSize]="'14px'" class="fw-400" *ngIf="profileDetails.location && !smallView">{{ profileDetails.location }}</filed-font>
				<filed-font [fontSize]="'14px'" class="fw-400" *ngIf="profileDetails.language && !smallView">
					Language: {{ profileDetails.language }}
				</filed-font>
				<filed-font [fontSize]="'14px'" class="fw-400" *ngIf="profileDetails.accountType && !smallView">{{ profileDetails.accountType }}</filed-font>
				<div class="d-flex category" *ngIf="profileDetails.catgegories">
					<div class="category-chip" *ngFor="let catgegory of profileDetails.catgegories">
						<filed-font [fontSize]="'14px'" class="fw-400">{{ catgegory }}</filed-font>
					</div>
				</div>
				<div class="text-align-c bio" *ngIf="profileDetails.bio && !smallView">
					<filed-font [fontSize]="'14px'">Bio</filed-font>
					<div class="bio-body">
						<filed-font [fontSize]="'14px'" [fontWeight]="'400'">
							{{
								(profileDetails.bio == null ? 'Unknown' : (profileDetails.bio | slice: 0:120)) + (profileDetails.bio.length > 120 ? '...' : '')
							}}
						</filed-font>
					</div>
				</div>
				<div class="d-flex mt-15px direction-column align-centre text-align-c stats" *ngIf="!smallView">
					<div class="stats-title">
						<filed-font [fontSize]="'18px'">Social Stats</filed-font>
					</div>
					<div class="d-flex">
						<div class="d-flex direction-column align-centre stats-media" *ngFor="let platform of profileDetails.platforms">
							<img src="assets/images/social-media-logo/{{ platform.platformName }}.svg" height="17px" width="17px" />
							<filed-font [fontSize]="'12px'" [color]="'#484848'">{{ platform.followers | shortnumber }}</filed-font>
						</div>
					</div>
					<div class="d-flex direction-column align-centre stats-overall">
						<filed-font [fontSize]="'14px'">{{ profileDetails.engagementRate }}% Engagement Rate</filed-font>
						<filed-font [fontSize]="'14px'">{{ profileDetails.audience | shortnumber }} Audience</filed-font>
						<div class="d-flex align-centre mt-15px">
							<filed-font [fontSize]="'14px'">Follower Authenticity</filed-font>
							<filed-font class="ml-7px">{{ profileDetails.followers }}%</filed-font>
							<info-tooltip class="ml-7px" [position]="'bottom'" [title]="'percentage of authentic followers of the influencer'"></info-tooltip>
						</div>
					</div>
				</div>
				<div class="connect" *ngIf="!smallView">
					<lolly-btn03 [icon]="'plus'" [matMenuTriggerFor]="connectMenu" (click)="connectTouser()">Contact</lolly-btn03>
					<mat-menu #connectMenu="matMenu">
						<button (click)="createNewListClick()" mat-menu-item>
							<span>Create New List</span>
						</button>
						<button (click)="addToListClick()" mat-menu-item>
							<span>Add To List</span>
						</button>
						<button (click)="SendToCampaignClick()" mat-menu-item>
							<span>Send To campaign</span>
						</button>
					</mat-menu>
				</div>
				<div class="mt-11px" *ngIf="smallView">
					<btn07 (click)="ViewProfile()">Visit Profile</btn07>
				</div>
			</div>
			<div></div>
		</div>
	</div>
</kendo-card>
