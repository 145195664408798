import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUserDetails } from '../state/user/user.reducer';
import { Modules } from '../permisions/enums/modules';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { ShopifyService } from 'src/app/_services/shopify/shopify.service';
import { HttpParams } from '@angular/common/http';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { LollyLoadingDialogService } from 'src/app/shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { UserStateEnum } from 'src/app/_services/user/user-state.enum';
import { MyPlanService } from 'src/app/user-management/new-sprint/components/my-plan/my-plan.service';
import { environment } from 'src/environments/environment';
import { PaymentLinkStatusEnum } from 'src/app/shared-components/models/payment-status.enum';
import { SharedService } from '../services/shared.service';

// * ACTIVE PARENT GUARD ON SMI
@Injectable()
export class AuthenticationGuard implements CanActivate {
	public priceId: string = environment.DEFAULT_PRICE_ID;
	constructor(
		public sharedService: SharedService,
		public authService: AuthenticationService,
		private shopifyService: ShopifyService,
		public router: Router,
		public store: Store,
		private lollyLoadingDialogService: LollyLoadingDialogService,
		public _myPlan: MyPlanService,
		private authStore: Store<AuthenticationState>
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const lastUrl = state.url;
		const isUserLoggedIn$ = of(this.authService.isLoggedIn());

		const hasCreditCard$ = this.authService.hasCreditCard();
		const isAdmin$ = this.authService.isAdmin();
		const isFreemiumExpired$ = this.authService.isFreemiumExpired();
		const isFreeTrialExpired$ = this.authService.isFreeTrialExpired();

		return forkJoin([isUserLoggedIn$, hasCreditCard$, isAdmin$, isFreemiumExpired$, isFreeTrialExpired$]).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			switchMap(([[isUserLoggedIn, hasCreditCard, isAdmin, isFreemiumExpired, isFreeTrialExpired], userDetails]) => {
				const fbId = userDetails?.FacebookBusinessOwnerId;
				const googleBO = userDetails?.GoogleBusinessOwnerId;
				const canskip = localStorage.getItem('skipConnect');
				const connectShopify = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo))?.user_account_state;
				const isInfluencer = userDetails?.Permissions.find(module => {
					return module.module === Modules.Influencer;
				});

				if (!isUserLoggedIn) {
					if (route?.queryParams && route?.queryParams['shop'] && route.queryParams['state']) {
						const params = new HttpParams({ fromObject: route.queryParams });
						console.log('SHOPIFY PARAMS -\n' + params);
						this.lollyLoadingDialogService.showLoader();
						this.shopifyService
							.connectAccountV2(params)
							.pipe(take(1))
							.subscribe(
								res => {
									this.lollyLoadingDialogService.dismissLoader();
									this.authStore.dispatch(new LogInSuccess(res?.data));
								},
								err => {
									this.lollyLoadingDialogService.dismissLoader();
								}
							);
					} else {
						this.router.navigate(['/authentication'], { queryParams: route?.queryParams });
						return of(false);
					}
				}
				if (userDetails.AccountState == UserStateEnum.Removed || userDetails.AccountState == UserStateEnum.InTransition) {
					this.getPaymentLink();
					return of(false);
				}
				if (!fbId && !googleBO && !isAdmin && !canskip && !isInfluencer) {
					this.router.navigate(['/authentication/connect-channel']);
					return of(false);
				} else if (!hasCreditCard && !isAdmin) {
					// this.router.navigate(['/authentication/payment']);
					this.router.navigate(['/authentication/payment-pending']);
					return of(false);
				} else if (isFreemiumExpired || isFreeTrialExpired) {
					this.router.navigate(['/authentication/trial-end']);
					return of(false);
				} else if (connectShopify == 99) {
					this.router.navigate(['/connect-to-shopify']);
					return of(false);
				}
				return of(true);
			})
		);
	}

	public getPaymentLink(): void {
		const token = localStorage.getItem(StorageKey.token);
		this.sharedService
			.getUserPaymentStatus()
			.pipe(take(1))
			.subscribe(
				res => {
					if (res.status == PaymentLinkStatusEnum.SUCCESS) {
						const paymentLink = res.data;
						window.open(paymentLink, '_self');
					} else {
						this.router.navigate(['/authentication/payment'], { queryParams: { token, price_id: this.priceId } });
					}
				},
				err => {}
			);
	}
}
