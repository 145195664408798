import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { DropdownData } from '../../../../../shared/dropdown-search-select/dropdown-data.interface';
import { select, Store } from '@ngrx/store';
import { getFacebookPermissions, UserManagementState } from '../../../../state/user-management.reducer';
import { UpdateAdAccountData } from '../../../../state/user-management.actions';
import { AdAccountFormData } from '../../../../models/account';
import { AccesLevelValidator, AdAccountValidator, positiveNumberValidator } from '../../../../models/validators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-manage-ad-account-permissions',
	templateUrl: './manage-ad-account-permissions.component.html',
	styleUrls: ['./manage-ad-account-permissions.component.scss']
})
export class ManageAdAccountPermissionsComponent implements OnInit {
	@Input() adAccounts: DropdownData[];
	@Input() adAccountsLevels: DropdownData[];
	@Input() index: number;
	@Input() adAccountPermission: AdAccountFormData;
	@Input() fullAdAccounts: DropdownData[];
	@Input() selectedBusinessId: number;
	@Input() radioValue: number;

	public saveFullAdAccounts: DropdownData[];
	public oldValue: string;

	public adAccountValidator = AdAccountValidator;
	public accesLevelValidator = AccesLevelValidator;

	public adAccountPermissionForms: UntypedFormGroup;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private formBuilder: UntypedFormBuilder, private store: Store<UserManagementState>) {}

	ngOnInit() {
		this.adAccountPermission = { ...this.adAccountPermission };
		this.saveFullAdAccounts = this.fullAdAccounts;
		this.oldValue = this.adAccountPermission.name;
		this.fetchStore();
		this.initData();
		this.createForm();
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private fetchStore(): void {
		this.store.pipe(select(getFacebookPermissions), takeUntil(this.unsubscriber$)).subscribe(facebookPermissions => {
			if (facebookPermissions) {
				facebookPermissions.forEach(adAccountSelected => {
					if (this.adAccounts) {
						this.adAccounts = this.adAccounts.filter(adAccount => {
							return adAccountSelected.name !== adAccount.value;
						});
					}
				});
			}
		});
	}

	private createForm(): void {
		this.adAccountPermissionForms = this.formBuilder.group({
			addAccount: new UntypedFormControl('', [Validators.required]),
			addAccountAccessLevel: new UntypedFormControl('', [Validators.required, positiveNumberValidator()])
		});

		const adAccount = {
			value: this.adAccountPermission.name,
			displayName: this.adAccountPermission.displayName
		};
		// add option to dropdown
		this.adAccounts.push(adAccount);
		// select option
		this.adAccountPermissionForms.setValue({
			addAccount: this.adAccountPermission.name,
			addAccountAccessLevel: this.adAccountPermission.level
		});

		this.adAccountPermissionForms.markAllAsTouched();

		this.adAccountPermissionForms.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			const formData = {
				id: this.index,
				name: this.adAccountPermissionForms.get('addAccount').value,
				level: this.adAccountPermissionForms.get('addAccountAccessLevel').value
			} as AdAccountFormData;
			if (formData.name) {
				this.store.dispatch(new UpdateAdAccountData({ oldValue: this.oldValue, formData: formData }));
			}
		});
	}

	private initData(): void {
		let selectedAdAccount;
		if (this.fullAdAccounts) {
			selectedAdAccount = this.fullAdAccounts.find(adAccount => {
				return adAccount.value === this.adAccountPermission.name;
			});
		}
		if (selectedAdAccount && selectedAdAccount.value) {
			this.adAccounts.push(selectedAdAccount);
		}
		if (!this.adAccountPermission.level) {
			this.adAccountPermission.level = 0;
		}
	}
}
