import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'lolly-btn03',
	templateUrl: './btn03.component.html',
	styleUrls: ['./btn03.component.scss']
})
export class LollyBtn03Component implements OnInit {
	@Input() public icon: string;
	@Input() public disabled: boolean;
	@Output() public onClick: EventEmitter<void>;

	constructor() {
		this.onClick = new EventEmitter<void>();
	}

	public ngOnInit(): void {}

	public buttonClick(): void {
		this.onClick.emit();
	}
}
