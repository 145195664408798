<div class="side-stepper-wrapper {{ collapsed ? '' : 'wide' }}">
	<div class="side-stepper">
		<ng-container *ngFor="let step of steps; let stepSequence = index">
			<div class="step-parent">
				<div class="stepper-left">
					<div
						class="circle-wrapper"
						[ngClass]="{
							current: step?.selected === true,
							checked: step?.selected !== true && step?.openSubStepCount == step?.substeps?.length && stepSequence != steps.length - 1
						}"
					>
						<img
							*ngIf="step?.selected !== true && step?.openSubStepCount == step?.substeps?.length && stepSequence != steps.length - 1"
							src="assets/icons/check-white-tick.svg"
						/>
					</div>
					<div *ngIf="stepSequence < steps.length - 1" class="line-wrapper"></div>
				</div>
				<div class="stepper-right {{ collapsed ? '' : 'wide' }}">
					<div (click)="onClickStep(stepSequence)" class="title {{ step?.selected === true ? 'selected' : '' }}">{{ step?.name }}</div>
					<ng-container *ngIf="step?.substeps && step?.selected === true">
						<ng-container *ngFor="let substep of step?.substeps; let substepSequence = index">
							<div (click)="onClickSubStep(stepSequence, substepSequence)" class="step-child {{ substep?.selected === true ? 'selected' : '' }}">
								{{ substep?.name }}
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>

	<div (click)="toggleIcon()" class="icon-container">
		<mat-icon [@rotateIcon]="isLeftArrow ? 'left' : 'right'">
			{{ !isLeftArrow ? 'keyboard_arrow_left' : 'close' }}
		</mat-icon>
	</div>
</div>
