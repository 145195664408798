import { Component, Input } from '@angular/core';
import { ErrorMessageService } from '../error-message.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidatorMessages } from '../validatorMessagesInputs';
import moment from 'moment';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-input-billing-date',
	templateUrl: './input-billing-date.component.html',
	styleUrls: ['./input-billing-date.component.scss', '../shared.scss']
})
export class InputBillingDateComponent {
	constructor(public errorMessageService: ErrorMessageService, private fb: UntypedFormBuilder) {}

	@Input() control: UntypedFormControl | AbstractControl;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() label: string;
	@Input() placeholder: string;

	public isDestinationFocused: boolean;
	public errorMessage: string;
	public monthFormControl = new UntypedFormControl('', Validators.required);
	public yearFormControl = new UntypedFormControl('', Validators.required);
	public myForm: UntypedFormGroup;
	public invalidMonth: boolean;
	public invalidYear: boolean;

	private unsubscriber$: Subject<void> = new Subject<void>();

	ngOnInit() {
		this.control.setValidators([Validators.pattern('^\\d{2}\\/\\d{2}$')]);
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);

		this.myForm = this.fb.group({
			monthFormControl: this.monthFormControl,
			yearFormControl: this.yearFormControl
		});

		if (this.control && this.control.value) {
			let date = this.control.value;
			let month = date.slice(0, 2);
			if (!month) {
				month = null;
			}
			let year = date.slice(3, 5);

			if (!year) {
				year = null;
			}

			this.myForm.setValue({
				monthFormControl: month,
				yearFormControl: year
			});
		}

		this.monthFormControl.valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscriber$)).subscribe(month => {
			if (month > 12 || month < 0 || isNaN(month)) {
				this.monthFormControl.setValue('', { emitEvent: false });
				this.invalidMonth = true;
			} else {
				this.invalidMonth = false;
				this.control.markAsTouched();
				const result = `${month ? month : ''}/${this.yearFormControl.value}`;
				this.control.setValue(result);
			}
		});

		this.yearFormControl.valueChanges.pipe(debounceTime(1000), takeUntil(this.unsubscriber$)).subscribe(year => {
			const currentYear = moment(new Date()).format('YY');
			if ((year as number) < parseInt(currentYear)) {
				this.invalidYear = true;
				this.yearFormControl.setValue('', { emitEvent: false });
			} else {
				this.invalidYear = false;
				this.control.markAsTouched();
				const result = `${this.monthFormControl.value}/${year ? year : ''}`;
				this.control.setValue(result);
			}
		});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}
}
