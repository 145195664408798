<div class="container">
	<div class="body">
		<div *ngIf="allStep.step1 == currentStep">
			<app-add-user [isEdit]="isEdit" (onContinueNextAction)="onContinueEdit(event)" (onGoBack)="onGoBack()"></app-add-user>
		</div>
		<div *ngIf="allStep.step2 == currentStep">
			<app-user-account [userDataId]="userId" (onContinueNextAction)="onContinueEdit(event)" (onGoBack)="onGoBack()"></app-user-account>
		</div>
		<div *ngIf="allStep.step3 == currentStep">
			<app-user-permission [permissions]="userPermissions" (onCcreateUser)="createUser()" (onGoBack)="onGoBack()"></app-user-permission>
		</div>
	</div>
</div>
