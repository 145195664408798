import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-auto-input-chip',
	templateUrl: './auto-input-chip.component.html',
	styleUrls: ['./auto-input-chip.component.scss']
})
export class AutoInputChipComponent implements OnInit {
	@ViewChild('autoTrigger', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
	@ViewChild('multiChipInput') multiChipInput;
	@Input() list: string[] = [];
	@Input() width: string = '100%';
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl;
	@Input('disabled') public disabled: boolean = false;
	@Output() emitChanges = new EventEmitter<any>();
	public autocompleteControl = new UntypedFormControl();
	public separatorKeysCodes: number[] = [ENTER, COMMA];
	public chipList: string[] = [];
	public unsubscriber$ = new Subject<void>();

	constructor(private toast: ToastNotificationService) {}

	ngOnInit(): void {
		this.autocompleteControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.unsubscriber$)).subscribe(val => {
			this.emitChanges.emit(val);
		});
		this.inputControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(val => {
			if (!val) {
				this.chipList = [];
				this.multiChipInput.nativeElement.value = '';
				this.autocompleteControl.reset();
			}
		});
		if (this.inputControl.value && this.inputControl.value.length) {
			this.mapInitialValues();
		}
	}

	ngAfterViewInit() {
		window.addEventListener('scroll', this.scrollEvent, true);
	}

	scrollEvent = (event: any): void => {
		if (this.autoComplete.panelOpen) this.autoComplete.updatePosition();
	};

	public mapInitialValues(): void {
		this.chipList = [];
		this.inputControl.value.forEach(x => {
			this.chipList.push(x);
		});
	}

	add(value): void {
		this.selected(value);
	}

	remove(item: string): void {
		const index = this.chipList.indexOf(item);
		if (index >= 0) {
			this.chipList.splice(index, 1);
		}
		let currentInputControlList = this.inputControl.value as string[];
		const objIndex = currentInputControlList.indexOf(item);
		currentInputControlList.splice(objIndex, 1);
		this.inputControl.patchValue(currentInputControlList);
	}

	selected(value): void {
		if (!value || value.trim() === '') {
			return;
		}
		if (!this.chipList.includes(value)) {
			this.chipList.push(value);
			let currentInputControlList = this.inputControl.value;
			if (!currentInputControlList) {
				currentInputControlList = [];
			}
			currentInputControlList.push(value);
			this.inputControl.patchValue(currentInputControlList);
		} else {
			this.toast.sendInfoToast(`The hashtag "${value}" already exists in the list`);
		}
		this.multiChipInput.nativeElement.value = '';
		this.autocompleteControl.setValue(null);
	}

	public onClickInput(): void {
		const nativeElement = this.multiChipInput.nativeElement;
		nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
