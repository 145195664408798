import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsInfluencerInterface } from './model/analytics-influencer.interface';

@Component({
	selector: 'app-analytics-influencer-card',
	templateUrl: './analytics-influencer-card.component.html',
	styleUrls: ['./analytics-influencer-card.component.scss']
})
export class AnalyticsInfluencerCardComponent implements OnInit {
	@Input() public profile: AnalyticsInfluencerInterface;
	@Output() public viewProfile: EventEmitter<AnalyticsInfluencerInterface>;
	constructor() {
		this.viewProfile = new EventEmitter<AnalyticsInfluencerInterface>();
	}

	public view(): void {
		this.viewProfile.emit(this.profile);
	}

	ngOnInit(): void {}
}
