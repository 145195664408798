import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-google-disclosure',
	templateUrl: './google-disclosure.component.html',
	styleUrls: ['./google-disclosure.component.scss']
})
export class GoogleDisclosureComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
