<div class="page-container">
	<div class="form-area">
		<div class="page-header">
			<div class="back-btn">
				<a (click)="backClicked()">
					<i class="k-icon k-i-arrow-chevron-left"></i>
					Back
				</a>
			</div>
		</div>
		<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<form [formGroup]="userForm">
					<div class="form">
						<div class="title">Pausing Account</div>
						<div>
							<div class="sub-title">
								Reason for leaving
								<span>*</span>
							</div>
							<div>
								<label>
									What is the reason for you to wish to pause our account?
								</label>
								<div>
									<app-select-item
										[dropDownControl]="userForm.controls['reason']"
										[placeholderText]="'Completed all campaigns, no more upcoming campaigns'"
										[showCaretIcon]="true"
										[allowFilter]="false"
										[dropDownData]="dropDownData"
										(onItemSelected)="dropDownValueChanges($event)"
									></app-select-item>
								</div>
							</div>
						</div>
						<div>
							<div class="sub-title">What should we improve?</div>
							<div>
								<label>
									We appreciate feedback you have on our services or product.
								</label>
								<div
									class="textarea"
									contenteditable
									(input)="onTextChange($event.target.innerHTML)"
									data-placeholder="Enter your feedback here"
								></div>
							</div>
						</div>
						<div>
							<div class="sub-title">
								Password confirmation
								<span>*</span>
							</div>
							<div>
								<label>
									This is require for account owner verification and prevention from unauthorised account access.
								</label>
								<div>
									<input type="password" class="password" placeholder="Enter your password here" formControlName="password" />
								</div>
							</div>
						</div>
					</div>
				</form>

				<div class="form-btn">
					<button class="button cancel" (click)="onCancel()">
						CANCEL
					</button>
					<button class="button left" (click)="onNext()" [disabled]="userForm.invalid" [class.active]="userForm.valid">
						CONTINUE
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
