<div class="d-flex justify-content-end" (click)="close()">
	<img src="assets/facebook-cb/adset/audience/close.svg" alt="" style="margin-bottom: -6px;" />
</div>
<div class="confirmation-dialog-container">
	<div class="d-flex flex-column">
		<div class="d-flex justify-content-center">
			<img class="main--icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" alt="" />
		</div>
		<div mat-dialog-title class="dialog-title">{{ title | translate }}</div>
		<mat-dialog-content>
			<div class="popup-message">{{ message | translate }}</div>
		</mat-dialog-content>
	</div>
</div>
