import { Injectable } from '@angular/core';
import { getSelectedAdAccount, SharedState } from '../state/shared.reducer';
import { select, Store } from '@ngrx/store';
// import { FacebookMetadataService } from './facebook-metadata.service';
// import { GoogleMetadataService } from './google-metadata.service';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';

@Injectable({
	providedIn: 'root'
})
export class ReportMetadataService {
	public activeService: any | any;
	// public activeService: FacebookMetadataService | GoogleMetadataService;//silsrang-migration

	constructor(private state: Store<SharedState>) {
		this.state.pipe(select(getSelectedAdAccount)).subscribe(resp => {
			this.activeService = this.getSelectedMetadataService(resp.sourceChannel);
		});
	}

	public getSelectedMetadataService(sourceChannel: SourceChannel): any | any {
		let activeService;

		switch (sourceChannel) {
			case SourceChannel.Facebook: {
				// activeService = this.facebookService;//silsrang-migration
				break;
			}
			case SourceChannel.Google: {
				// activeService = this.googleService;//silsrang-migration
				break;
			}
			default: {
				activeService = null;
			}
		}

		return activeService;
	}
}
