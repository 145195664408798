<ng-container>
	<div class="action-button-2 cursor-pointer">
		<mat-form-field [matMenuTriggerFor]="selectMenu" #selectMenuTrigger="matMenuTrigger" appearance="outline" class="outline" [floatLabel]="'never'">
			<input matInput class="search-text-2" type="text" [placeholder]="placeholder" [formControl]="drpFormControl" />
		</mat-form-field>
	</div>

	<mat-menu #selectMenu="matMenu" class="drp-menu-2" [ngStyle]="styleMenu()">
		<div (click)="$event.stopPropagation()" *ngFor="let listItem of filteredList; let itemSequence = index" class="menu-list-item-2">
			<mat-checkbox
				class="custom-checkbox-black"
				*ngIf="listItem?.selected != undefined || listItem?.selected != null"
				id="item-{{ itemSequence }}"
				type="checkbox"
				[(ngModel)]="listItem.selected"
				(ngModelChange)="toggleSelection(listItem, itemSequence)"
				[labelPosition]="'before'"
			>
				<span class="search-drp-name-2 w-100">
					{{ listItem?.name }}
				</span>
			</mat-checkbox>
		</div>
	</mat-menu>
</ng-container>
