import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SetPassword } from '../../_models/identity-models/set-password';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { AuthenticationState, getRedirectUrl } from '../state/authentication.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PasswordComponentEnum } from './set-password.component.enum';
import { LogInModel } from '../_models/login.model';
import { LogIn } from '../state/authentication.action';

@Component({
	selector: 'app-set-password',
	templateUrl: './set-password.component.html',
	styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
	public form: UntypedFormGroup;
	public emailFormControl: UntypedFormControl;
	public passwordFormControl: UntypedFormControl = new UntypedFormControl();
	public pageType: PasswordComponentEnum.Password | PasswordComponentEnum.ResetPassword;
	public customTrigger = false;

	private token: string;
	private decodedToken: any;
	private emailValue: string;
	private isForgotPasswordUrl: boolean;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userServiceApi: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		private authStore: Store<AuthenticationState>
	) {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.token = encodeURIComponent(params['token']);
			this.decodedToken = this.decodeToken(this.token);
			this.emailValue = this.decodedToken?.username;
		});
	}

	public decodeToken(token: string): any {
		const jwtData = token.split('.')[1];
		const decodedJwtJsonData = window.atob(jwtData);
		const fullTokenAsJson = JSON.parse(decodedJwtJsonData);

		fullTokenAsJson['aud'] = 'Filed-Client-Apps';
		fullTokenAsJson['sub'] = fullTokenAsJson['username'];
		fullTokenAsJson['user_firstname'] = fullTokenAsJson['first_name'];
		fullTokenAsJson['user_lastname'] = fullTokenAsJson['last_name'];

		return fullTokenAsJson;
	}

	public ngOnInit(): void {
		this.emailFormControl = new UntypedFormControl(this.emailValue);
		this.isForgotPasswordUrl = this.activatedRoute.snapshot.routeConfig.path?.includes('forgot-password');
		this.pageType = this.isForgotPasswordUrl ? PasswordComponentEnum.ResetPassword : PasswordComponentEnum.Password;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onSubmit(): void {
		if (this.passwordFormControl.invalid) {
			this.passwordFormControl.markAsTouched();
			this.passwordFormControl.updateValueAndValidity();
			this.customTrigger = true;
			return;
		}

		const newPassword: SetPassword = {
			EmailAddress: this.emailFormControl.value.trim(),
			NewPassword: this.passwordFormControl.value.trim(),
			ConfirmPassword: this.passwordFormControl.value.trim(),
			TwoFactorToken: this.token
		};

		const request$ = this.isForgotPasswordUrl ? this.userServiceApi.forgotPassword(newPassword) : this.userServiceApi.setPassword(newPassword);
		request$.pipe(takeUntil(this.unsubscriber$)).subscribe(
			res => {
				if (this.isForgotPasswordUrl && res) {
					this.toastNotificationService.sendSuccessToast('Password changed successfully.');
					this.router.navigate(['/authentication']);
				} else if (this.isForgotPasswordUrl && !res) {
					this.toastNotificationService.sendErrorToast('There was an error while creating your password.');
				} else {
					const loginPayload: LogInModel = {
						email: this.emailFormControl.value.trim(),
						password: this.passwordFormControl.value.trim()
					};
					this.authStore.dispatch(new LogIn(loginPayload));
				}
			},
			error => {
				if (error?.status == 405) {
					this.toastNotificationService.sendErrorToast(error?.error?.detail);
				} else {
					this.toastNotificationService.sendErrorToast('There was an error while creating your password.');
				}
			}
		);
	}
}
