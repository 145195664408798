import { SidenavComponent } from './sidenav/sidenav.component';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { UserManagementModule } from '../user-management/user-management.module';
import { SharedModule } from '../shared/shared.module';

import { LinkAccountDialogComponent } from './link-account-dialog/link-account-dialog.component';
import { TicketsViewComponent } from './tickets-view/tickets-view.component';
import { SidenavGroupComponent } from './sidenav/sidenav-group.component';
import { NavFilterPipe } from './sidenav/nav-filter.pipe';
import { KnowledgeBaseComponent } from './component-pieces/knowledge-base/components/knowledge-base/knowledge-base.component';
import { NotificationsDialogComponent } from './components/notifications-dialog/notifications-dialog.component';
import { NotificationsMainComponent } from './components/notifications-main/notifications-main.component';
import { OnboardingDialogComponent } from './components/onboarding-dialog/onboarding-dialog.component';
import { GetStartedComponent } from './components/get-started/get-started.component';

@NgModule({
	declarations: [
		NotificationsDialogComponent,
		NotificationsMainComponent,
		SidenavComponent,
		SidenavGroupComponent,
		LinkAccountDialogComponent,
		TicketsViewComponent,
		NavFilterPipe,
		KnowledgeBaseComponent,
		GetStartedComponent,
		OnboardingDialogComponent
	],
	imports: [SharedModule, TranslateModule, BrowserModule, RouterModule, UserManagementModule],
	providers: []
})
export class SidenavModule {}
