import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OnboardingDialogComponent } from '../onboarding-dialog/onboarding-dialog.component';
import { LandingPageService } from 'src/app/social-media-influencer/modules/landing-page/services/landing-page.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
	selector: 'app-get-started',
	templateUrl: './get-started.component.html',
	styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
	public isFirstTimeUser: boolean = false;
	public loading: boolean = false;
	public progress: number = 0;
	public unsubscriber$ = new Subject<void>();

	constructor(private matDialog: MatDialog, private sharedService: SharedService) {}

	ngOnInit(): void {
		this.sharedService.triggerFirstTimeGetStarted.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.openOnboardingDialog();
		});
	}

	public openOnboardingDialog(): void {
		this.matDialog
			.open(OnboardingDialogComponent, {
				width: '430px',
				height: '424px',
				panelClass: 'onboarding-dialog-no-shadow',
				backdropClass: 'light-backdrop'
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
