<!-- notification input type text -->
<div class="input-wrapper">
	<div class="example-form-icon">
		<mat-form-field
			[ngClass]="{
				'input-error-message': control.invalid && control.touched,
				'input-success-message': control.valid && control.touched,
				'input-disabled-message': control.disabled || readonlyInput
			}"
			appearance="outline"
		>
			<mat-label>{{ label }}</mat-label>
			<input
				(focus)="autocompleteFocus()"
				(focusin)="onFocusIn()"
				(focusout)="onFocusOut()"
				(keyup)="onKeyUp()"
				(click)="onClick($event)"
				[formControl]="control"
				matInput
				placeholder="{{ placeholder }}"
				type="text"
				autocomplete="off"
				[readonly]="readonlyInput"
			/>
			<!--Icon -->
			<ng-container matPrefix *ngIf="prefix">
				<i *ngIf="!showSpinner" class="fas {{ icon }}"></i>
			</ng-container>
			<ng-container matSuffix *ngIf="!prefix">
				<i *ngIf="!showSpinner" class="fas {{ icon }}"></i>
			</ng-container>
			<mat-spinner class="input-spinner" *ngIf="showSpinner" diameter="15"></mat-spinner>
			<!--Error message -->
			<mat-error *ngIf="control.invalid">{{ errorMessage }}</mat-error>
		</mat-form-field>
	</div>
</div>
