import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
import { SharedState } from '../../shared/state/shared.reducer';
import { Store } from '@ngrx/store';
import { HideGlobalSpinner } from '../../shared/state/shared.actions';
import { Router } from '@angular/router';
import { UserPaymentSubscriptionCodesEnum } from 'src/app/shared/models/plan-limits.model';
import { GenericSmDialogService } from 'src/app/shared/services/generic-sm-dialog.service';
import { UtilsService } from 'src/app/shared/utils';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { TrialDataModel } from 'src/app/shared/models/first-timer-user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { GenericSidePopupService } from 'src/app/shared/services/generic-side-popup.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<SharedState>,
		private sharedService: SharedService,
		private genericSidePopupService: GenericSidePopupService,
		private authenticationService: AuthenticationService,
		private toast: ToastNotificationService,
		private genericSmDialogService: GenericSmDialogService,
		private router: Router
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				this.store.dispatch(new HideGlobalSpinner());

				if (error instanceof HttpErrorResponse) {
					if (error?.status === 401) {
						if (error?.error?.detail?.error_code == UserPaymentSubscriptionCodesEnum.EMAIL_IS_NOT_CONFIRMED) {
							this.toast.sendErrorToast(error?.error?.detail?.message);
						} else {
							this.toast.sendErrorToast('Invalid email and password combination');
						}
						this.authenticationService.logout();
						return throwError(error);
					}

					if (error?.status === 402) {
						this.handlePaymentErrors(error);
						return throwError(error);
					}

					if (error?.status === 403) {
						this.handleLimitErrors(error);
						return throwError(error);
					}

					const reason = (error as HttpErrorResponse).headers.get('reason');
					if (reason === 'FrontOfficeUserWithZeroAdAccounts') {
						this.authenticationService.logout();
						return;
					}
				}
				return throwError(error);
			})
		);
	}

	private handlePaymentErrors(error): void {
		if (this.router.url === '/user-management/manage-plan') {
			return;
		}
		switch (error?.error?.error_code) {
			case UserPaymentSubscriptionCodesEnum.TRIAL_EXPIRED:
				const trialPeriodExpired: TrialDataModel = {
					days_left: 0,
					had_trial_period: true,
					is_on_trial: false,
					subscription_status: 'trialing'
				};
				this.sharedService.trialPeriodData$.next(trialPeriodExpired);
				this.handleTrialExpiredError();
				this.router.navigate(['/']);
				break;
			case UserPaymentSubscriptionCodesEnum.PAYMENT_FAILED:
				this.showPaymentFailedDialog();
				this.router.navigate(['/']);
				break;
			case UserPaymentSubscriptionCodesEnum.SUB_EXPIRED:
			case UserPaymentSubscriptionCodesEnum.SUB_NOT_FOUND:
			default:
				this.showSubscriptionCancelledDialog();
				this.router.navigate(['/']);
				break;
		}
	}

	private handleTrialExpiredError(): void {
		let name = 'home';
		const splitUrl = this.router.url.split('/');
		if (splitUrl.length > 0) {
			name = splitUrl[1];
		}
		this.genericSidePopupService.showPredefinedSidePopups(name).then(res => {
			if (res?.primary === true) {
				this.router.navigate(['user-management/manage-plan'], { queryParams: { openPlans: true } });
			}
			if (res?.secondary === true) {
				window.open(UtilsService.BOOK_DEMO_URL, '_blank');
			}
		});
	}

	private handleLimitErrors(error): void {
		if (this.router.url === '/user-management/manage-plan') {
			return;
		}
		this.showLimitReachedPopup();

		// ! BELOW CODE FOR SHOWING SPECIFIC LIMIT POPUPS
		// switch (error?.error?.error_code) {
		// 	case UserPlanLimitCodesEnum.DISCOVERY_SEARCH:
		// 	case UserPlanLimitCodesEnum.DISCOVERY_PROFILE:
		// 	case UserPlanLimitCodesEnum.DISCOVERY_EMAILS:
		// 	case UserPlanLimitCodesEnum.TEAM_SIZE:
		// 	case UserPlanLimitCodesEnum.EMAILS_INTEGRATION:
		// 	case UserPlanLimitCodesEnum.EXPORTS:
		// 	case UserPlanLimitCodesEnum.RESETED_COUNTERS:
		// 	default:
		// 		this.showLimitReachedPopup();
		// 		break;
		// }
	}

	private showLimitReachedPopup(): void {
		this.genericSmDialogService.showPredefinedPopups('limit', true).then(res => {
			if (res && res?.primaryAction === 'true') {
				this.router.navigate(['user-management/manage-plan'], { queryParams: { openPlans: true } });
			}

			if (res && res?.secondaryAction === 'true') {
				window.open(UtilsService.BOOK_DEMO_URL, '_blank');
			}
		});
	}

	private showSubscriptionCancelledDialog(): void {
		this.genericSmDialogService.showPredefinedPopups('sub_expired', true).then(res => {
			if (res && res?.primaryAction === 'true') {
				window.open(UtilsService.BOOK_DEMO_URL, '_blank');
			}

			if (res && res?.secondaryAction === 'true') {
				this.router.navigate(['user-management/manage-plan'], { queryParams: { openPlans: true } });
			}
		});
	}

	private showPaymentFailedDialog(): void {
		this.genericSmDialogService.showPredefinedPopups('payment_fail', true).then(res => {
			if (res && res?.primaryAction === 'true') {
				this.router.navigate(['user-management/manage-plan'], { queryParams: { openPlans: true } });
			}
		});
	}
}
