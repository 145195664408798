<!-- notification input type textarea-->
<div class="input-wrapper">
	<div class="textarea-wrapper">
		<mat-form-field
			[ngClass]="{
				'textarea-error': control.invalid && control.touched,
				'textarea-success': control.valid && control.touched,
				'textarea-disabled': control.disabled
			}"
			appearance="outline"
		>
			<mat-label>{{ label }}</mat-label>
			<textarea
				(focus)="autocompleteFocus()"
				matInput
				(focusout)="setErrorMessage()"
				[formControl]="control"
				class="text-section"
				placeholder="{{ placeholder }}"
			></textarea>

			<!--Error message -->
			<mat-error *ngIf="control.invalid">{{ errorMessage }}</mat-error>
		</mat-form-field>
	</div>
</div>
