<app-user-management-header></app-user-management-header>

<div class="page-container">
	<app-user-sub-header [pageTitle]="SettingsPageLabelsEnum.ChangePassword"></app-user-sub-header>
	<div class="container flex d-j-c-flex-between">
		<form [formGroup]="changePasswordForm">
			<p class="text-left font-17 font-normal third-color">{{ 'ENTER_CURRENT_PASSWORD' | translate }}</p>
			<div class="margin-bottom-spacing k-pb-3">
				<app-input-password class="input-box" [placeholder]="'currentPassword'" [control]="currentPasswordControl"></app-input-password>
			</div>
			<p class="text-left font-17 font-normal third-color">{{ 'ENTER_NEW_PASSWORD' | translate }}</p>
			<div class="margin-bottom-spacing k-pb-3 k-form-group">
				<app-input-password
					class="input-box"
					[placeholder]="'newPassword'"
					[control]="newPasswordControl"
					class="input-box"
					[validatorMessages]="passwordValidator"
				></app-input-password>
			</div>
			<div class="margin-bottom-spacing k-mb-4">
				<app-input-password
					class="input-box"
					[placeholder]="'confirmPassword'"
					[label]="'Confirm Password'"
					[control]="confirmPasswordControl"
				></app-input-password>
			</div>
			<mat-error *ngIf="changePasswordForm.controls['newPasswordControl'].valid && confirmPasswordControl.hasError('NoPasswordMatch')">
				Password do not match
			</mat-error>
			<!--Error password-->
			<mat-error
				class="error-text"
				*ngIf="
					changePasswordForm.controls['newPasswordControl'].hasError('required') ||
					changePasswordForm.controls['newPasswordControl'].hasError('minlength')
				"
			>
				Must be at least 8 characters!
			</mat-error>
			<mat-error
				class="error-text"
				*ngIf="
					changePasswordForm.controls['newPasswordControl'].hasError('required') ||
					changePasswordForm.controls['newPasswordControl'].hasError('hasNumber')
				"
			>
				Must contain at least 1 number!
			</mat-error>
			<mat-error
				class="error-text"
				*ngIf="
					changePasswordForm.controls['newPasswordControl'].hasError('required') ||
					changePasswordForm.controls['newPasswordControl'].hasError('hasCapitalCase')
				"
			>
				Must contain at least 1 in Capital Case!
			</mat-error>
			<mat-error
				class="error-text"
				*ngIf="
					changePasswordForm.controls['newPasswordControl'].hasError('required') ||
					changePasswordForm.controls['newPasswordControl'].hasError('hasSmallCase')
				"
			>
				Must contain at least 1 Letter in Small Case!
			</mat-error>
			<mat-error
				class="error-text"
				*ngIf="
					changePasswordForm.controls['newPasswordControl'].hasError('required') ||
					changePasswordForm.controls['newPasswordControl'].hasError('hasSpecialCharacters')
				"
			>
				Must contain at least 1 Special Character!
			</mat-error>
			<mat-error class="error-text" *ngIf="changePasswordForm.controls['newPasswordControl'].hasError('currentPasswordMatchNew')">
				Your new password is too similar to your old password!
				<br />
				Please enter another password.
			</mat-error>
		</form>
		<ng-template [ngIf]="currentPasswordControl">
			<div *ngIf="!currentPasswordControl.touched" class="image">
				<img alt="Password" src="assets/icons/user-management/account/pass1.png" />
			</div>
			<div
				*ngIf="currentPasswordControl.valid && !newPasswordControl.errors && !confirmPasswordControl.errors && currentPasswordControl.touched"
				class="image"
			>
				<img alt="Password" src="assets/icons/user-management/account/pass2.png" />
			</div>
			<div
				*ngIf="
					(currentPasswordControl.invalid || newPasswordControl.errors || confirmPasswordControl.errors) &&
					(currentPasswordControl.touched || newPasswordControl.touched)
				"
				class="image"
			>
				<img alt="Password" src="assets/icons/user-management/account/icon-error.svg" />
			</div>
		</ng-template>
	</div>
</div>

<div class="wrapper-pass">
	<app-button
		[disabled]="this.checkValidForm()"
		(buttonClicked)="checkValidation()"
		[buttonClass]="buttonClassEnum.Primary"
		[buttonType]="buttonTypeEnum.Simple"
		buttonLabel="SAVE"
	></app-button>
</div>
