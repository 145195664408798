<ng-container>
	<button
		class="outlined-icon"
		(menuOpened)="menuOpened()"
		(menuClosed)="menuClosed()"
		[matMenuTriggerFor]="notificationsMenu"
		#notificationsMenuTrigger="matMenuTrigger"
	>
		<img src="assets/icons/notifications-material.svg" />
		<!-- <div class="top-right-count" *ngIf="unreadCount > 0">
			{{ unreadCount > 9 ? '9+' : unreadCount }}
		</div> -->
	</button>

	<mat-menu #notificationsMenu="matMenu" class="notification-menu">
		<div class="menu-body-wrapper" (click)="$event.stopPropagation()">
			<div class="notification-header">
				<h1 class="notification-title">Notifications</h1>
			</div>

			<ng-container *ngIf="notifications?.length > 0">
				<div class="notification-body">
					<ng-container *ngFor="let notification of notifications; let notificationSequence = index">
						<div
							*ngIf="notificationSequence < 7"
							(click)="onNotificationClick(notification)"
							class="menu-list-item-notif {{ notification?.status == 'unread' ? 'unread' : '' }}"
						>
							<!-- <img class="pp mr-m" src="assets/images/creators/creatives/mock-influencer.png" /> -->
							<div class="d-flex flex-column w-100 notification-col">
								<div class="w-100 d-flex align-items-center justify-content-between">
									<div class="message">
										{{ notification?.message }}
									</div>
									<div class="date">
										{{ notification?.created_at | date: 'shortTime' }}
									</div>
								</div>
								<div class="d-flex align-items-center justify-content-between">
									<div class="action" (click)="onNotificationActionClick(notification); notificationsMenuTrigger.closeMenu()">
										{{ titleCase(notification?.action) }}
									</div>
									<div class="more-btn" [matMenuTriggerFor]="actionsMenu" #actionsMenuTrigger="matMenuTrigger">
										<img class="icon-btn" src="assets/creators/general/more-horizontal.svg" />
									</div>
									<mat-menu #actionsMenu="matMenu" class="edit-notification-menu">
										<div class="edit-body-wrapper">
											<div
												class="edit-menu-item"
												(click)="
													onEditNotificationClick(notification, 'delete'); $event.stopPropagation(); actionsMenuTrigger.closeMenu()
												"
											>
												<img class="edit-icon mr-s" src="assets/creators/general/trash.svg" />
												<div class="d-flex w-100 flex-column">
													<span class="txt-b">Delete</span>
													<span class="txt-n mt-xs">Delete this notification</span>
												</div>
											</div>

											<div
												class="edit-menu-item"
												(click)="
													onEditNotificationClick(notification, 'mute'); $event.stopPropagation(); actionsMenuTrigger.closeMenu()
												"
											>
												<ng-container *ngIf="notification?.active">
													<img class="edit-icon mr-s" src="assets/creators/general/unmute-notification.svg" />
													<div class="d-flex w-100 flex-column">
														<span class="txt-b">Turn on</span>
														<span class="txt-n mt-xs">Start receiving notification like this</span>
													</div>
												</ng-container>
												<ng-container *ngIf="!notification?.active">
													<img class="edit-icon mr-s" src="assets/creators/general/mute-notification.svg" />
													<div class="d-flex w-100 flex-column">
														<span class="txt-b">Turn off</span>
														<span class="txt-n mt-xs">Stop receiving notification like this</span>
													</div>
												</ng-container>
											</div>
										</div>
									</mat-menu>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="notification-footer">
					<a class="footer-text" (click)="routeToNotifications(); notificationsMenuTrigger.closeMenu()">View All</a>
				</div>
			</ng-container>

			<ng-container *ngIf="!notifications || notifications.length <= 0">
				<div class="no-data">
					<img class="no-data-img" src="assets/icons/notifications-empty.svg" />

					<span>
						No Notifications Yet
					</span>
				</div>
			</ng-container>
		</div>
	</mat-menu>
</ng-container>
