<div class="product-wrapper" [formGroup]="productSeedingForm">
	<div class="w-100 pb-m product-body">
		<filed-font [fontSize]="'18px'" [color]="'#121212'" [fontSize]="'600'">Discount Coupon</filed-font>
		<div class="mt-s">
			<app-dropdown-wo-input
				[inputPlaceholder]="'Select a discount coupon'"
				[dropdownControl]="productSeedingForm.get('productSeedingDiscountCode')"
				[options]="discountCoupons"
			></app-dropdown-wo-input>
			<div
				class="error-txt mt-1"
				*ngIf="
					productSeedingForm.controls.productSeedingDiscountCode?.errors?.required && productSeedingForm.controls.productSeedingDiscountCode?.touched
				"
			>
				Discount Coupon is required
			</div>
		</div>
	</div>
</div>
