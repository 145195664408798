import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

export interface DiscountDataModel {
	id: number;
	name: string;
	platform?: string;
}
@Component({
	selector: 'app-create-product-seeding-step-three',
	templateUrl: './create-product-seeding-step-three.component.html',
	styleUrls: ['./create-product-seeding-step-three.component.scss']
})
export class CreateProductSeedingStepThreeComponent implements OnInit {
	@Input() public discountCoupons: DiscountDataModel[] = [];
	@Input() public productSeedingForm: FormGroup;
	@Output() public currentStepValid = new EventEmitter<boolean>();

	constructor() {}

	ngOnInit(): void {
		this.formValidate();
	}

	public formValidate(): void {
		this.productSeedingForm.get('productSeedingDiscountCode').setValidators([Validators.required]);
		this.productSeedingForm.get('productSeedingDiscountCode').updateValueAndValidity();
		this.currentStepValid.emit(this.productSeedingForm.valid);
		this.checkForm();
	}

	public checkForm(): void {
		this.productSeedingForm.valueChanges.subscribe(res => {
			this.currentStepValid.emit(this.productSeedingForm.valid);
		});
	}

	public formUnValidate(): void {
		this.productSeedingForm.get('productSeedingDiscountCode').clearValidators();
		this.productSeedingForm.get('productSeedingDiscountCode').updateValueAndValidity();
		this.productSeedingForm.markAsUntouched();
		this.currentStepValid.emit(this.productSeedingForm.valid);
	}

	public ngOnDestroy(): void {
		this.formUnValidate();
	}
}
