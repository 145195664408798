<app-lolly-spinning-loader *ngIf="showSpinner" class="loading-indicator"></app-lolly-spinning-loader>

<div>
	<div class="grid-section">
		<div class="grid-section-one">
			<img class="grid-section-one-img" src="assets/images/login-background.png" />
		</div>
		<div class="grid-section-two">
			<div class="grid-gap-left"></div>
			<div class="grid-section-two-inner container">
				<div class="grid-inner-context">
					<div class="filed-logo">
						<img src="assets/logo/lolly-logo.svg" />
					</div>
					<div class="text-description">
						<h2>Connect your account to get going</h2>
						<p>Connect your account for targeted insights</p>
					</div>
					<div>
						<div class="btn-and-check-mark">
							<app-connect-to-facebook (connectionResponse)="onConnectionSuccess($event)"></app-connect-to-facebook>
						</div>
						<p class="logout">
							To change accounts or exit, please
							<span (click)="logout()">log out</span>
						</p>
					</div>
				</div>
			</div>
			<div class="grid-gap-right"></div>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
