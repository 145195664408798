<div class="container">
	<div class="left-dock">
		<div class="message">
			<span>
				Join
				<span class="bolder-text">1,000's</span>
				Of Users, Brands and Marketers from:
			</span>
		</div>
		<div class="brands">
			<img src="assets/icons/ext-brands/google-icon.svg" alt="Google" />
			<img src="assets/icons/ext-brands/microsoft-icon.svg" alt="microsoft" />
			<img src="assets/icons/ext-brands/airbnb-icon.svg" alt="airbnb" />
			<img src="assets/icons/ext-brands/appleinc-icon.svg" alt="Apple" />
			<img src="assets/icons/ext-brands/infosys-icon.svg" alt="Infosys" />
			<img src="assets/icons/ext-brands/amazon-icon.svg" alt="amazon" />
			<img src="assets/icons/ext-brands/alibaba-icon.svg" alt="Alibaba" />
			<img src="assets/icons/ext-brands/tcs-icon.svg" alt="tcs" />
		</div>
	</div>
	<div class="right-dock">
		<app-lolly-spinning-loader *ngIf="loading"></app-lolly-spinning-loader>

		<div [hidden]="loading" class="wrapper">
			<div class="logo">
				<img src="assets\auth\Path 19423.svg" />
			</div>
			<div class="started-text">{{ "Let's Get Started" }}</div>
			<div class="cta-text">{{ "You'll be up & running in 2 minutes" }}</div>
			<div class="d-flex w-100 k-align-items-center k-justify-content-center">
				<div class="continue-btn-wrapper">
					<div id="google-btn" #googleBtn></div>
				</div>
			</div>

			<div class="rule-a-text">
				<hr />
				<div class="text">{{ 'Or register with your email' }}</div>
			</div>

			<div class="text-input-split">
				<div class="d-flex flex-column">
					<div class="mat-field-wrapper short">
						<mat-form-field appearance="outline">
							<mat-label>First Name</mat-label>
							<input matInput placeholder="" [formControl]="firstNameFormControl" />
						</mat-form-field>
					</div>
					<ng-container *ngIf="firstNameFormControl?.touched && firstNameFormControl?.invalid">
						<div class="item k-pt-1">
							<span>Please enter a valid first name</span>
						</div>
					</ng-container>
				</div>

				<div class="d-flex flex-column">
					<div class="mat-field-wrapper short">
						<mat-form-field appearance="outline">
							<mat-label>Last Name</mat-label>
							<input matInput placeholder="" [formControl]="lastNameFormControl" />
						</mat-form-field>
					</div>
					<ng-container *ngIf="lastNameFormControl?.touched && lastNameFormControl?.invalid">
						<div class="item k-pt-1">
							<span>Please enter a valid last name</span>
						</div>
					</ng-container>
				</div>
			</div>

			<div class="full-text-input l-margin">
				<div class="mat-field-wrapper">
					<mat-form-field appearance="outline">
						<mat-label>Company Name</mat-label>
						<input matInput placeholder="" [formControl]="cmControl" />
					</mat-form-field>
				</div>

				<ng-container *ngIf="cmControl?.touched && cmControl?.invalid">
					<div class="item k-pt-1">
						<span>Please enter a valid company name</span>
					</div>
				</ng-container>
			</div>

			<div class="full-text-input l-margin">
				<div class="mat-field-wrapper">
					<mat-form-field appearance="outline">
						<mat-label>Email Address</mat-label>
						<input matInput placeholder="" [formControl]="emControl" />
					</mat-form-field>
				</div>

				<ng-container *ngIf="emControl?.touched && emControl?.invalid">
					<div class="item k-pt-1">
						<span>Please enter a valid email</span>
					</div>
				</ng-container>
			</div>

			<div class="full-text-input l-margin">
				<div class="mat-field-wrapper">
					<mat-form-field appearance="outline">
						<mat-label>Create Password</mat-label>
						<input matInput placeholder="" [formControl]="passFormControl" [type]="!showPassword ? 'password' : 'text'" />
						<mat-icon matSuffix (click)="showPassword = !showPassword">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
					</mat-form-field>
				</div>

				<ng-container *ngIf="passFormControl.touched">
					<div class="item k-pt-1">
						<span>{{ getPasswordErrorText() }}</span>
					</div>
				</ng-container>
			</div>

			<div (click)="processRegistrationDetail()" [ngClass]="{ 'invalid-register': !formFieldsValid() }" class="signin-btn-wrapper">
				<div class="btn">
					<app-primary-button [buttonLable]="'Continue'" [buttonType]="'Primary'"></app-primary-button>
				</div>
			</div>

			<div class="bottom-text-action">
				<div class="dark">{{ 'Already have an account?' }}</div>
				<div (click)="backToLogin()" class="theme">{{ 'Login' }}</div>
			</div>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
