<kendo-grid
	#grid
	style="height: 100%;"
	[kendoGridBinding]="gridView"
	[sortable]="sortable"
	[selectable]="selectableSettings"
	[resizable]="false"
	[columnMenu]="{ columnChooser: false, filter: false }"
	(cellClick)="campaignClick($event)"
	(selectionChange)="selectRow($event)"
	(selectedKeysChange)="keyChange($event)"
	kendoGridSelectBy="id"
	[selectedKeys]="selectedKeys"
	[pageable]="pageable"
>
	<kendo-grid-checkbox-column
		*ngIf="checkbox"
		[width]="48"
		[headerClass]="{ 'text-center': true }"
		[class]="{ 'text-center': true }"
		[resizable]="false"
		[columnMenu]="false"
		[showSelectAll]="true"
	></kendo-grid-checkbox-column>

	<ng-container *ngFor="let column of gridColumns; let i = index">
		<kendo-grid-column
			field="{{ column.name }}"
			[width]="column.width"
			title="{{ column.value }}"
			[headerStyle]="styleHeader(i)"
			[sortable]="column.type !== 'action' && column.type !== 'analytics' && (column.sort !== undefined ? column.sort : true)"
			[selectable]="column.type !== 'action' && column.type !== 'analytics'"
			*ngIf="column.type !== 'action'"
		>
			<ng-template kendoGridHeaderTemplate let-dataItem *ngIf="column.type !== 'analytics'">
				{{ column.value }}
				<div class="new-tooltip">
					<div class="tooltip-body-main">
						<div class="tooltip-body">
							<div class="tooltip-text">
								<span>{{ column.value }}</span>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" style="background-color: white !important;">
				<!-- case string -->
				<div
					*ngIf="
						!column.highlight &&
						column.type !== 'platform' &&
						column.type !== 'action' &&
						column.type !== 'editibleString' &&
						column.type !== 'edit_delete' &&
						column.type !== 'editibleInt' &&
						column.type !== 'analytics' &&
						column.type !== 'link' &&
						column.type !== 'hover' &&
						column.type !== 'creative_status' &&
						column.type !== 'payment_status' &&
						((column.name == 'numberOfCreators' && this.currentRoute == '/campaign') || column.name != 'numberOfCreators') &&
						column.type !== 'object' &&
						column.value != 'Creators Name'
					"
					[ngStyle]="styleRows(i)"
				>
					{{ dataItem[column.name] }}
				</div>
				<!-- case numberOfCreators -->
				<div
					*ngIf="column.name == 'numberOfCreators' && this.currentRoute != '/campaign'"
					[ngStyle]="styleRows(i)"
					[ngClass]="getColor(dataItem[column.name]) ? 'text-blue' : ''"
					(click)="openModal(dataItem['campaignJobId'])"
				>
					{{ dataItem[column.name] }}
				</div>
				<!-- case Creators Name -->
				<div *ngIf="column.value == 'Creators Name' && this.currentRoute != '/campaign' && this.currentRoute != '/payments'" [ngStyle]="styleRows(i)">
					<a [routerLink]="['/discovery', 'influencer-profile', dataItem.id, dataItem.name]" [queryParams]="{ state: 'Instagram' }">
						{{ dataItem[column.name] }}
					</a>
				</div>
				<div *ngIf="column.value == 'Creators Name' && this.currentRoute == '/payments'" [ngStyle]="styleRows(i)">
					{{ dataItem[column.name] }}
				</div>
				<div *ngIf="column.value == 'Creators Name' && this.currentRoute == '/campaign'" [ngStyle]="styleRows(i)">
					<!-- <a
						class="creator-name"
						[routerLink]="['/discovery', 'influencer-profile', dataItem.influencerId, dataItem.creatorName]"
						[queryParams]="{ state: 'Instagram' }"
					> -->
					<a class="creator-name" [routerLink]="['/discovery', 'profile', dataItem.influencerId]">
						{{ dataItem[column.name] }}
					</a>
				</div>
				<!-- case platform -->
				<div *ngIf="column.type === 'platform'" [ngStyle]="styleRows(i)">
					<ng-container *ngFor="let platform of dataItem[column.name]">
						<ng-container *ngIf="platform && platform.toLowerCase() == 'instagram'">
							<img class="platform" src="assets/icons/social-media-icons/instagram.svg" />
						</ng-container>
						<ng-container *ngIf="platform && platform.toLowerCase() == 'tiktok'">
							<img class="platform" src="assets/icons/social-media-icons/tiktok.svg" />
						</ng-container>
						<ng-container *ngIf="platform && platform.toLowerCase() == 'youtube'">
							<img class="platform" src="assets/icons/social-media-icons/youtube.svg" />
						</ng-container>
					</ng-container>
				</div>
				<!-- case link -->
				<div *ngIf="column.type === 'link'" (click)="onLinkClick(rowIndex); $event.stopPropagation()" [ngStyle]="styleRows(i, dataItem[column.name])">
					{{ (column.staticName | titlecase) || (dataItem[column.name] | titlecase) }}
				</div>
				<!-- case payment_status -->
				<div
					*ngIf="column.type === 'payment_status'"
					class="payment_status"
					(mouseenter)="column.showHoverOn == dataItem[column.name].toLowerCase() && showAction($event, rowIndex)"
					(mouseleave)="showAction($event, -1)"
					(click)="column.onClick(rowIndex); $event.stopPropagation()"
				>
					<span class="{{ dataItem[column.name] | lowercase }}" *ngIf="showActionRow != rowIndex">
						{{ dataItem[column.name] | titlecase }}
					</span>
					<ng-container *ngIf="showActionRow == rowIndex">
						<div *ngIf="gridColumns[i].hoverTemplate" [innerHtml]="gridColumns[i].hoverTemplate"></div>
					</ng-container>
				</div>
				<!-- case hover -->
				<div
					*ngIf="column.type === 'hover'"
					(click)="gridColumns[i].onClick && gridColumns[i].onClick(rowIndex); $event.stopPropagation()"
					[ngStyle]="styleRows(i, dataItem[column.name])"
					(mouseenter)="showAction($event, rowIndex)"
					(mouseleave)="showAction($event, -1)"
				>
					<ng-container *ngIf="showActionRow != rowIndex">{{ dataItem[column.name] }}</ng-container>
					<ng-container *ngIf="showActionRow == rowIndex">
						<div *ngIf="gridColumns[i].hoverTemplate" [innerHtml]="gridColumns[i].hoverTemplate"></div>
					</ng-container>
				</div>
				<!-- case creative_status  -->
				<div *ngIf="column.type === 'creative_status'" class="normal-text" [ngStyle]="styleRows(i)">
					<span *ngIf="column.highlightName == dataItem[column.name]" class="creative-status">
						{{ dataItem[column.name] | titlecase }}
					</span>
					<span *ngIf="column.highlightName != dataItem[column.name]">
						{{ dataItem[column.name] | titlecase }}
					</span>
				</div>
				<!-- case object -->
				<div *ngIf="column.type == 'object'" (click)="onObjectClick(rowIndex); $event.stopPropagation()" [ngStyle]="styleObjectRows(i, rowIndex)">
					{{ dataItem[column.name].title }}
				</div>
				<!-- case action -->
				<div *ngIf="column.type === 'action'" class="action-button action-data">
					<button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item *ngFor="let action of cellAction" (click)="action.click(dataItem)">
							<mat-icon>{{ action.icon }}</mat-icon>
							<span>{{ action.title }}</span>
						</button>
					</mat-menu>
				</div>
				<!-- case analytics -->
				<div *ngIf="column.type === 'analytics'" class="action-button action-data">
					<button mat-button class="analytics">
						View Analytics
					</button>
				</div>
				<!-- case editibleString -->
				<div class="kendo-editible" *ngIf="column.type == 'editibleString'" [ngStyle]="styleRows(i)">
					<div class="w-100">
						<span>{{ dataItem[column.name] }}</span>
						<mat-icon class="ml-10px" (click)="$event.stopPropagation(); edit(dataItem, dataItem[column.name], rowIndex)">
							edit
						</mat-icon>
					</div>
					<mat-menu #editnamemenu="matMenu">
						<div class="editmenu">
							<filed-font [fontSize]="'14px'" [fontWeight]="'400'" (click)="$event.stopPropagation()">{{ column.value }}</filed-font>

							<mat-form-field (click)="$event.stopPropagation()" class="edit-input mt-10px" appearance="outline">
								<input matInput value="{{ dataItem[column.name] }}" (keyup)="onKeydown($event.target.value)" />
							</mat-form-field>
							<div class="d-flex d-j-c-right">
								<app-primary-button [buttonLable]="'Cancel'" [buttonType]="'Secondary'" [isPeddingReduceSmall]="true"></app-primary-button>

								<app-primary-button
									[buttonLable]="'Save'"
									[buttonType]="'Primary'"
									[isPeddingReduceSmall]="true"
									(buttonClicked)="savestring(column.name, rowIndex)"
								></app-primary-button>
							</div>
						</div>
					</mat-menu>
				</div>
				<!-- case edit_delete -->
				<div class="kendo-editible" *ngIf="column.type == 'edit_delete'" [ngStyle]="styleRows(i)">
					<div class="w-100">
						<span>{{ dataItem[column.name] }}</span>
						<mat-icon class="ml-10px" (click)="$event.stopPropagation(); edit(dataItem, dataItem[column.name], rowIndex)">
							edit
						</mat-icon>
						<mat-icon class="ml-10px red-icon" (click)="$event.stopPropagation(); deleteEmit(dataItem, dataItem[column.name], rowIndex)">
							delete
						</mat-icon>
					</div>
				</div>
				<!-- case editibleInt -->
				<div class="kendo-editible justify-content-center" *ngIf="column.type == 'editibleInt'" [ngStyle]="styleRows(i)">
					<div class="d-flex d-align-items-c">
						<span>{{ dataItem[column.name] }}</span>
						<mat-icon
							class="ml-10px"
							[matMenuTriggerFor]="editIntnamemenu"
							(click)="$event.stopPropagation(); edit(dataItem, dataItem[column.name], rowIndex)"
						>
							edit
						</mat-icon>
					</div>
					<mat-menu #editIntnamemenu="matMenu">
						<div class="editmenu">
							<filed-font [fontSize]="'14px'" [fontWeight]="'400'" (click)="$event.stopPropagation()">{{ column.value }}</filed-font>
							<mat-form-field (click)="$event.stopPropagation()" class="edit-input" appearance="outline">
								<input matInput type="number" value="{{ editinput }}" (keyup)="onKeydown($event.target.value)" />
							</mat-form-field>
							<div class="d-flex d-j-c-right">
								<app-primary-button [buttonLable]="'Cancel'" [buttonType]="'Secondary'" [isPeddingReduceSmall]="true"></app-primary-button>

								<app-primary-button
									[buttonLable]="'Save'"
									[buttonType]="'Primary'"
									[isPeddingReduceSmall]="true"
									(buttonClicked)="save(column.name, rowIndex)"
								></app-primary-button>
							</div>
						</div>
					</mat-menu>
				</div>
				<!-- case highlight string -->
				<div
					*ngIf="
						column.highlight &&
						column.type !== 'platform' &&
						dataItem[column.name] &&
						column.type !== 'editibleString' &&
						column.type !== 'edit_delete' &&
						column.type !== 'link' &&
						column.type !== 'creative_status' &&
						column.type !== 'hover' &&
						column.type !== 'payment_status' &&
						column.type !== 'object'
					"
					[ngStyle]="styleRows(i)"
				>
					<span class="highlight">{{ dataItem[column.name] | titlecase }}</span>
				</div>
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-column
			field="{{ column.name }}"
			[width]="column.width"
			title="{{ column.value }}"
			[headerStyle]="styleHeader(i)"
			[sortable]="column.type !== 'action' && column.type !== 'analytics'"
			[selectable]="column.type !== 'action' && column.type !== 'analytics'"
			*ngIf="column.type === 'action'"
		>
			<ng-template kendoGridHeaderTemplate let-dataItem>
				<div *ngIf="column.type === 'action'" class="action-button action-header">
					<button mat-icon-button class="pr-15px" [matMenuTriggerFor]="headermenu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #headermenu="matMenu">
						<button mat-menu-item *ngFor="let action of headerAction" (click)="action.click()">
							<mat-icon>{{ action.icon }}</mat-icon>
							<span>{{ action.title }}</span>
						</button>
					</mat-menu>
				</div>
			</ng-template>
			<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" style="background-color: white !important;">
				<div
					*ngIf="
						!column.highlight &&
						column.type !== 'platform' &&
						column.type !== 'action' &&
						column.type !== 'editibleString' &&
						column.type !== 'edit_delete' &&
						column.type !== 'editibleInt' &&
						column.type !== 'analytics' &&
						column.type !== 'link' &&
						column.type !== 'creative_status' &&
						column.type !== 'hover' &&
						column.type !== 'payment_status' &&
						column.type !== 'object'
					"
					[ngStyle]="styleRows(i)"
				>
					{{ dataItem[column.name] }}
				</div>

				<div *ngIf="column.type === 'platform'" [ngStyle]="styleRows(i)">
					<ng-container *ngFor="let platform of dataItem[column.name]">
						<ng-container *ngIf="platform && platform.toLowerCase() == 'instagram'">
							<img class="platform" src="assets/icons/social-media-icons/instagram.svg" />
						</ng-container>
						<ng-container *ngIf="platform && platform.toLowerCase() == 'tiktok'">
							<img class="platform" src="assets/icons/social-media-icons/tiktok.svg" />
						</ng-container>
						<ng-container *ngIf="platform && platform.toLowerCase() == 'youtube'">
							<img class="platform" src="assets/icons/social-media-icons/youtube.svg" />
						</ng-container>
					</ng-container>
				</div>
				<div *ngIf="column.type === 'action'" class="action-button action-data">
					<button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item *ngFor="let action of cellAction" (click)="action.click(dataItem)">
							<mat-icon>{{ action.icon }}</mat-icon>
							<span>{{ action.title }}</span>
						</button>
					</mat-menu>
				</div>
				<div *ngIf="column.type === 'analytics'" class="action-button action-data">
					<button mat-button class="analytics">
						View Analytics
					</button>
				</div>
				<div class="kendo-editible" *ngIf="column.type == 'editibleString'" [ngStyle]="styleRows(i)">
					<div class="d-flex d-align-items-c">
						<span>{{ dataItem[column.name] }}</span>
						<mat-icon class="ml-10px" (click)="$event.stopPropagation(); edit(dataItem, dataItem[column.name], rowIndex)">
							edit
						</mat-icon>
					</div>
					<mat-menu #editnamemenu="matMenu">
						<div class="editmenu">
							<filed-font [fontSize]="'14px'" [fontWeight]="'400'" (click)="$event.stopPropagation()">{{ column.value }}</filed-font>
							<mat-form-field (click)="$event.stopPropagation()" class="edit-input mt-10px" appearance="outline">
								<input matInput value="{{ dataItem[column.name] }}" (keyup)="onKeydown($event.target.value)" />
							</mat-form-field>
							<div class="d-flex d-j-c-right">
								<app-primary-button [buttonLable]="'Cancel'" [buttonType]="'Secondary'" [isPeddingReduceSmall]="true"></app-primary-button>

								<app-primary-button
									[buttonLable]="'Save'"
									[buttonType]="'Primary'"
									[isPeddingReduceSmall]="true"
									(buttonClicked)="save(column.name, rowIndex)"
								></app-primary-button>
							</div>
						</div>
					</mat-menu>
				</div>
				<div class="kendo-editible justify-content-center" *ngIf="column.type == 'editibleInt'" [ngStyle]="styleRows(i)">
					<div class="d-flex d-align-items-c">
						<span>{{ dataItem[column.name] }}</span>
						<mat-icon
							class="ml-10px"
							[matMenuTriggerFor]="editIntnamemenu"
							(click)="$event.stopPropagation(); edit(dataItem, dataItem[column.name], rowIndex)"
						>
							edit
						</mat-icon>
					</div>
					<mat-menu #editIntnamemenu="matMenu">
						<div class="editmenu">
							<filed-font [fontSize]="'14px'" [fontWeight]="'400'" (click)="$event.stopPropagation()">{{ column.value }}</filed-font>
							<mat-form-field (click)="$event.stopPropagation()" class="edit-input" appearance="outline">
								<input matInput type="number" value="{{ editinput }}" (keyup)="onKeydown($event.target.value)" />
							</mat-form-field>
							<div class="d-flex d-j-c-right">
								<app-primary-button [buttonLable]="'Cancel'" [buttonType]="'Secondary'" [isPeddingReduceSmall]="true"></app-primary-button>

								<app-primary-button
									[buttonLable]="'Save'"
									[buttonType]="'Primary'"
									[isPeddingReduceSmall]="true"
									(buttonClicked)="save(column.name, rowIndex)"
								></app-primary-button>
							</div>
						</div>
					</mat-menu>
				</div>
				<div
					*ngIf="
						column.highlight &&
						column.type !== 'platform' &&
						dataItem[column.name] &&
						column.type !== 'editibleString' &&
						column.type !== 'edit_delete' &&
						column.type !== 'link' &&
						column.type !== 'creative_status' &&
						column.type !== 'hover' &&
						column.type !== 'payment_status' &&
						column.type !== 'object'
					"
					[ngStyle]="styleRows(i)"
				>
					<span class="highlight">{{ dataItem[column.name] }}</span>
				</div>
			</ng-template>
		</kendo-grid-column>
	</ng-container>

	<ng-template kendoPagerTemplate>
		<div class="center-align pager">
			<select class="form-control drop-size" style="margin-right: 18px;" (change)="pageSizeChange($event)">
				<ng-container *ngFor="let size of pageSizeOptions">
					<option [value]="size" [selected]="size == pageSize">{{ size }} per page</option>
				</ng-container>
			</select>
			<ng-container *ngIf="!hidePaginationArrows">
				<img *ngIf="pageNumber == 1" src="/assets/icons/social-media-icons/arrow-deactive.svg" />
				<img
					*ngIf="pageNumber != 1"
					src="/assets/icons/social-media-icons/arrow-active.svg"
					style="transform: rotate(180deg);"
					(click)="previousPage()"
				/>
				<span style="font-size: 12px; margin-left: 18px; margin-right: 18px;">{{ pageNumber + '/' + totalPages }}</span>
				<img *ngIf="pageNumber != totalPages" src="/assets/icons/social-media-icons/arrow-active.svg" (click)="nextPage()" />
				<img *ngIf="pageNumber == totalPages" src="/assets/icons/social-media-icons/arrow-deactive.svg" style="transform: rotate(180deg);" />
			</ng-container>
		</div>
	</ng-template>
</kendo-grid>
