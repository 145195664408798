<div class="dialog-root" *ngIf="!loading">
	<div class="dialog-header">
		<h4>Get started check</h4>
		<mat-icon mat-dialog-close class="close-icon">
			close
		</mat-icon>
	</div>

	<div class="dialog-body">
		<div class="custom-accordion-onboard">
			<mat-accordion [multi]="false">
				<mat-expansion-panel [expanded]="true">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<div class="custom-expansion-title">
								<mat-checkbox
									[checked]="getStartedData?.email && getStartedData?.is_payment_integrated"
									class="custom-checkbox-black pointer-none"
								></mat-checkbox>

								<h6>
									Integrations
								</h6>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="custom-expansion-body">
						<div class="col-wrapper">
							<div class="desc">
								Connect your email and payment systems here.
							</div>
							<button class="primary-btn" (click)="routeTo('/user-management/integrations')">
								Set up integrations
							</button>
						</div>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<div class="custom-expansion-title">
								<mat-checkbox [checked]="getStartedData?.find_an_influencer" class="custom-checkbox-black pointer-none"></mat-checkbox>

								<h6>
									Find an influencer
								</h6>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="custom-expansion-body">
						<div class="col-wrapper">
							<div class="desc">
								Search and select influencers for your projects.
							</div>
							<button class="primary-btn" (click)="routeTo('/discovery')">
								Search
							</button>
						</div>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<div class="custom-expansion-title">
								<mat-checkbox [checked]="getStartedData?.create_a_list" class="custom-checkbox-black pointer-none"></mat-checkbox>

								<h6>
									Create a list
								</h6>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="custom-expansion-body">
						<div class="col-wrapper">
							<div class="desc">
								Compile and manage your influencer lists.
							</div>
							<button class="primary-btn" (click)="routeToLists()">
								Organize influencer lists
							</button>
						</div>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<div class="custom-expansion-title">
								<mat-checkbox [checked]="getStartedData?.create_campaign" class="custom-checkbox-black pointer-none"></mat-checkbox>

								<h6>
									Campaign creation
								</h6>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="custom-expansion-body">
						<div class="col-wrapper">
							<div class="desc">
								Create and manage your marketing campaigns and contracts.
							</div>
							<button class="primary-btn" (click)="routeTo('/campaign-create')">
								Launch campaigns
							</button>
						</div>
					</div>
				</mat-expansion-panel>

				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<div class="custom-expansion-title">
								<mat-checkbox [checked]="getStartedData?.sent_outreach_email" class="custom-checkbox-black pointer-none"></mat-checkbox>

								<h6>
									Messaging
								</h6>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="custom-expansion-body">
						<div class="col-wrapper">
							<div class="desc">
								Communicate directly with creators about your projects.
							</div>
							<button class="primary-btn" (click)="routeTo('/outreach')">
								Message creators
							</button>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
</div>

<ng-container *ngIf="loading">
	<div class="d-flex w-100 h-100 k-align-items-center k-justify-content-center">
		<app-lolly-spinning-loader [rotating]="true"></app-lolly-spinning-loader>
	</div>
</ng-container>
