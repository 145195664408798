<section class="dialog-root">
	<!-- * Triangle Bubble -->
	<span class="triangle" *ngIf="showBubblePointer"></span>
	<!--  -->
	<div class="dialog-wrapper">
		<!-- * Header -->
		<div class="dialog-header">
			<span class="title">Add list to campaign</span>
		</div>

		<!-- * Body -->
		<div class="dialog-body">
			<ng-container *ngIf="loading">
				<app-lolly-spinning-loader></app-lolly-spinning-loader>
			</ng-container>

			<ng-container *ngIf="!loading">
				<div class="search-wrapper">
					<input type="text" placeholder="Search a list" class="custom-input-name" [formControl]="searchControl" />
				</div>

				<ul #scrollContainer class="existing-lists {{ this.selectedLists.hasValue() ? 'footer-padding' : '' }}">
					<ng-container *ngFor="let list of filteredLists">
						<li>
							<mat-checkbox
								[labelPosition]="'before'"
								(click)="$event.stopPropagation()"
								(change)="$event ? selectedLists.toggle(list) : null"
								[checked]="selectedLists.isSelected(list)"
								class="custom-checkbox-black"
							>
								{{ list?.name }} ({{ list?.creator_count ?? '0' }})
							</mat-checkbox>
						</li>
					</ng-container>
				</ul>

				<div class="footer" *ngIf="this.selectedLists.hasValue()">
					<button class="save" (click)="onSave()">
						Save and go to Messaging
					</button>
				</div>
			</ng-container>
		</div>
	</div>
</section>
