import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { DropdownInterface } from 'src/app/shared-components/models/dropdown.interface';
import { ValidatorMessages } from 'src/app/shared-components/models/validator-messages.interface';

@Component({
	selector: 'app-discovery-drp05',
	templateUrl: './discovery-drp.component.html',
	styleUrls: ['./discovery-drp.component.scss']
})
export class DiscoveryDrpComponent implements OnInit {
	@Input() public width = '100%';
	@Input() public height = '100%';
	@Input() public data: DropdownInterface[] = [];
	@Input() public disabled: boolean = false;
	@Input() public allowClear: boolean = true;
	@Input() public label: string = '';
	@Input() public placeholder: string = '';
	@Input() public validatorMessages: ValidatorMessages[] = [];
	@Input() public control: UntypedFormControl | AbstractControl = new UntypedFormControl();
	@Output() public valueChanges: EventEmitter<DropdownInterface[]> = new EventEmitter();
	@Output() public filterChange: EventEmitter<string> = new EventEmitter();
	@Output() public removed: EventEmitter<DropdownInterface> = new EventEmitter();
	public errorMessage: string;

	constructor() {
		this.valueChanges = new EventEmitter<DropdownInterface[]>();
		this.removed = new EventEmitter<DropdownInterface>();
	}

	public ngOnInit(): void {
		// this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public onRemove(event: DropdownInterface): void {
		this.removed.emit(event);
	}

	public setErrorMessage(): void {
		// this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public filters($event: any): void {
		this.filterChange.emit($event);
	}

	public changes($event: DropdownInterface[]): void {
		this.valueChanges.emit($event);
	}
}
