import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ContactUsComponent } from './components/subscription/contact-us/contact-us.component';
import { ConfirmDialogPermissionsComponent } from './dialogs/permissions/confirm-dialog-permissions.component';
import { CancelSubscriptionReasonComponent } from './dialogs/cancel-subscription/cancel-subscription-reason.component';
import { BillingAndPaymentComponent } from './shared/billing-and-payment/billing-and-payment.component';
import { MySubscriptionComponent } from './components/subscription/my-subscription/my-subscription.component';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { FiledUsersComponent } from './components/filed-users/users/filed-users.component';
import { MyAccountComponent } from './components/account/my-account/my-account.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import { userManagementReducer } from './state/user-management.reducer';
import { UserSubHeaderComponent } from './shared/user-sub-header/user-sub-header.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserManagementHeaderComponent } from './shared/user-management-header/user-management-header.component';
import { NewCardComponent } from './components/filed-cards/new-card/new-card.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { PaymentMethodComponent } from './components/filed-cards/payment-method/payment-method.component';
import { CardInputComponent } from './components/filed-cards/card-input/card-input.component';
import { CardTableComponent } from './components/filed-cards/card-table/card-table.component';
import { EditImageComponent } from './dialogs/edit-image/edit-image.component';
import { ConfirmPrimaryMethodComponent } from './dialogs/primary-method/confirm-primary-method.component';
import { ConfirmPasswordComponent } from './dialogs/confirm-password/confirm-password.component';
import { ShareInvoiceEmailComponent } from './dialogs/share-invoice-email/share-invoice-email.component';
import { AccountInputsComponent } from './components/account/account-inputs/account-inputs.component';
import { UserManagementEffects } from './state/user-management.effects';
import { EffectsModule } from '@ngrx/effects';
import { PermissionsResolver } from '../_services/user-management/resolvers/permissions.resolver';
import { FiledUsersResolver } from '../_services/user-management/resolvers/filed-users.resolver';
import { UpdateUserResolver } from '../_services/user-management/resolvers/update-user.resolver';
import { FiledCardsResolver } from '../_services/user-management/resolvers/filed-card-resolver.resolver';
import { ManageBusinessPermissionsComponent } from './components/account/facebook-inputs/business-inputs/manage-business-permissions.component';
import { AccountResolver } from '../_services/user-management/resolvers/account.resolver';
import { ActionsButtonUserManagementComponent } from './components/filed-users/actions-button-user-management/actions-button-user-management.component';
import { ToggleStateComponent } from './components/filed-users/toggle-state-component/toggle-state.component';
import { UserDetailsComponent } from './components/filed-users/user-details/user-details.component';
import { UserAccountAccessComponent } from './components/filed-users/user-account-access/user-account-access.component';
import { UserPermissionsComponent } from './components/filed-users/user-permissions/user-permissions.component';
import { CreateNewUserComponent } from './components/create-and-edit-user/create-new-user.component';
import { BillingAddressResolver } from '../_services/user-management/resolvers/billing-address.resolver';
import { DeleteConfirmationComponent } from './components/filed-cards/delete-confirmation/delete-confirmation.component';

import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { IntegrationConnectPopupComponent } from './components/integrations/component-pieces/integration-connect-popup/integration-connect-popup.component';
import { IntegrationListComponent } from './components/integrations/component-pieces/integration-list/integration-list.component';
import { ClosePopupComponent } from './components/integrations/component-pieces/close-popup/close-popup.component';
import { AuthenticationModule } from '../authentication/authentication.module';
import { PipesModule } from '../shared/shared.pipes.module';

@NgModule({
	imports: [
		ReactiveFormsModule,
		SharedModule,
		RouterModule,
		UserManagementRoutingModule,
		TranslateModule,
		FormsModule,
		MatButtonModule,
		MatDialogModule,
		StoreModule.forFeature('userManagement', userManagementReducer),
		EffectsModule.forFeature([UserManagementEffects]),

		AuthenticationModule,
		PipesModule
	],
	declarations: [
		ClosePopupComponent,
		NewCardComponent,
		UserManagementHeaderComponent,
		ChangePasswordComponent,
		UserSubHeaderComponent,
		BillingAndPaymentComponent,
		MySubscriptionComponent,
		MyAccountComponent,
		FiledUsersComponent,
		CancelSubscriptionReasonComponent,
		ContactUsComponent,
		ManageBusinessPermissionsComponent,
		BillingAndPaymentComponent,
		ConfirmDialogPermissionsComponent,
		EditImageComponent,
		InvoicesComponent,
		PaymentMethodComponent,
		CardInputComponent,
		CardTableComponent,
		EditImageComponent,
		ConfirmPrimaryMethodComponent,
		ConfirmPasswordComponent,
		ShareInvoiceEmailComponent,
		AccountInputsComponent,
		ActionsButtonUserManagementComponent,
		ToggleStateComponent,
		UserDetailsComponent,
		UserAccountAccessComponent,
		UserPermissionsComponent,
		CreateNewUserComponent,
		DeleteConfirmationComponent,
		IntegrationsComponent,
		IntegrationListComponent,
		IntegrationConnectPopupComponent
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		MySubscriptionComponent,
		MyAccountComponent,
		FiledUsersComponent,
		CancelSubscriptionReasonComponent,
		ContactUsComponent,
		ManageBusinessPermissionsComponent,
		BillingAndPaymentComponent,
		ConfirmDialogPermissionsComponent,
		IntegrationsComponent,
		IntegrationListComponent,
		AccountInputsComponent
	],
	providers: [PermissionsResolver, FiledUsersResolver, UpdateUserResolver, FiledCardsResolver, AccountResolver, BillingAddressResolver, InterceptorsProvider],
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class UserManagementModule {}
