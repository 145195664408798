import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NestedDropdownChildModel, NestedDropdownParentModel } from './nested-dropdown-checkbox.model';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-nested-dropdown-checkbox',
	templateUrl: './nested-dropdown-checkbox.component.html',
	styleUrls: ['./nested-dropdown-checkbox.component.scss']
})
export class NestedDropdownCheckboxComponent implements OnInit {
	@ViewChild('parentMenuTrigger', { static: true }) parentMenuTrigger: MatMenuTrigger;
	@Input() public width: string = '300px';
	public data: NestedDropdownParentModel[] = [
		{
			id: 1,
			name: 'Home Health Care Service something',
			is_checked: false,
			is_indeterminate: false,
			is_expanded: true,
			children: [
				{
					id: 1,
					name: 'Home Health Care',
					is_checked: false
				},
				{
					id: 2,
					name: 'H2',
					is_checked: false
				},
				{
					id: 3,
					name: 'H3',
					is_checked: false
				}
			]
		},
		{
			id: 2,
			name: 'Beauty',
			is_expanded: true,
			is_checked: false,
			is_indeterminate: false,
			children: [
				{
					id: 1,
					name: 'B1',
					is_checked: false
				},
				{
					id: 2,
					name: 'B2',
					is_checked: false
				},
				{
					id: 3,
					name: 'B3',
					is_checked: false
				}
			]
		},
		{
			id: 3,
			name: 'Cherry',
			is_expanded: true,
			is_checked: false,
			is_indeterminate: false,
			children: [
				{
					id: 1,
					name: 'C1',
					is_checked: false
				},
				{
					id: 2,
					name: 'C2',
					is_checked: false
				},
				{
					id: 3,
					name: 'C3',
					is_checked: false
				}
			]
		}
	];
	public filteredData: NestedDropdownParentModel[] = [];
	public searchFormControl: FormControl<string> = new FormControl<string>('');
	private unsubscriber$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		// for(let i=0; i<500; ++i){
		// 	this.data.push({
		// 		id: i,
		// 		name: 'Abba ' + i,
		// 		is_checked: false,
		// 		is_indeterminate: false,
		// 		is_expanded: false,
		// 		children: [
		// 			{
		// 				id: 1,
		// 				name: 'Jabba ' + i.toString() + '1',
		// 				is_checked: false
		// 			},
		// 			{
		// 				id: 2,
		// 				name: 'Dabba ' + i.toString() + '2',
		// 				is_checked: false
		// 			},
		// 			{
		// 				id: 3,
		// 				name: 'Kakka ' + i.toString() + '3',
		// 				is_checked: false
		// 			}
		// 		]
		// 	});
		// }
		this.filteredData = this.data;
		this.initSearchControl();
	}

	public initSearchControl(): void {
		this.searchFormControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscriber$)).subscribe(res => {
			if (res && res.trim() != '') {
				this.filteredData = this.data.filter(p => {
					let searchString = p.name;
					p.children.forEach(child => {
						searchString = searchString + child.name;
					});
					return searchString.toLowerCase().replace(/ /g, '').trim().includes(res.toLowerCase().replace(/ /g, '').trim());
				});
			} else {
				this.filteredData = this.data;
			}
		});
	}

	public styleMenu() {
		return {
			width: this.width + ' !important',
			'max-width': this.width + '!important'
		};
	}

	public onFocusIn(): void {
		// open mat menu here
		if (!this.parentMenuTrigger) {
			return;
		}
	}

	public onFocusOut(): void {
		// close mat menu here
		if (!this.parentMenuTrigger) {
			return;
		}
	}

	public setAll(parent: NestedDropdownParentModel, checked: boolean) {
		parent.is_checked = checked;
		if (parent.children == null) {
			return;
		}
		parent.children.forEach(t => (t.is_checked = checked));
	}

	public someComplete(parent: NestedDropdownParentModel): boolean {
		if (parent.children == null) {
			return false;
		}
		return parent.children.filter(t => t.is_checked).length > 0 && !parent.is_checked;
	}

	public updateAllComplete(parent: NestedDropdownParentModel) {
		parent.is_checked = parent.children != null && parent.children.every(t => t.is_checked);
	}

	public toggleParent(parent: NestedDropdownParentModel): void {
		parent.is_checked = !parent.is_checked;
		this.setAll(parent, parent.is_checked);
	}

	public toggleChild(parent: NestedDropdownParentModel, child: NestedDropdownChildModel): void {
		child.is_checked = !child.is_checked;
		this.updateAllComplete(parent);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
