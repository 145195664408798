<div class="main">
	<img src="assets\support\Group 35699.svg" class="close-btn" (click)="onNoClick()" />
	<!-- !nav -->
	<!-- <div *ngIf="page === 1 || page === 2" class="header w-100 d-flex justify-content-between align-items-center"> -->
	<div class="header w-100 d-flex justify-content-between align-items-center">
		<div class="name" *ngIf="page === 1 || page === 2">
			<h4>Hi {{ name }}</h4>
			<h6>Find your answers here</h6>
		</div>

		<div *ngIf="page !== 1 && page !== 2" (click)="goBack()" class="back">
			<img src="assets\support\back.svg" />
			Back
		</div>
		<button class="ticket-btn" *ngIf="page === 4" (click)="page = 3">Submit a Ticket</button>

		<div *ngIf="page === 1 || page === 2">
			<button [matMenuTriggerFor]="menu" class="ticket-btn">Ticket Support</button>
			<mat-menu #menu="matMenu">
				<button (click)="page = 3" mat-menu-item>Submit a Ticket</button>
				<button (click)="openViewTicket()" mat-menu-item>View your Tickets</button>
			</mat-menu>
		</div>

		<!-- <mat-icon class="close" (click)="onNoClick()">close</mat-icon> -->
	</div>
	<!-- <div *ngIf="page !== 1 && page !== 2" class="header w-100 d-flex justify-content-between">
		<button *ngIf="!isMobileView" class="back" (click)="goBack()">
			<mat-icon>chevron_left</mat-icon>
			Back
		</button>
		<mat-icon *ngIf="isMobileView" class="back" (click)="goBack()">chevron_left</mat-icon>
		<mat-icon class="close" (click)="onNoClick()">close</mat-icon>
	</div> -->

	<!-- !main -->
	<div class="main-con">
		<!-- !loader -->
		<app-lolly-spinning-loader *ngIf="loading"></app-lolly-spinning-loader>

		<div *ngIf="page !== 3 && page !== 5" class="main-head d-flex align-items-center">
			<h4 class="fw-bold" *ngIf="page !== 2 && page !== 4">Knowledge Base</h4>
			<h4 class="fw-bold" *ngIf="page === 4">Your Tickets</h4>
			<div *ngIf="page === 2" class="cat-details-head d-flex align-items-center">
				<h4 class="fw-bold" (click)="goBack()">Knowledge Base</h4>
				<img src="assets\support\Icon ionic-ios-arrow-back.svg" />
				<p class="title">{{ selectedCategory }}</p>
			</div>
		</div>

		<!-- !submit ticket -->
		<div *ngIf="page === 3" class="submit-ticket">
			<h3>We'll email you within one working day.</h3>

			<div class="submit-form d-flex flex-column gap-20px">
				<div class="topic">
					<mat-form-field appearance="outline">
						<mat-label>Topic*</mat-label>
						<input [(ngModel)]="topic" matInput />
					</mat-form-field>
				</div>
				<div class="des">
					<mat-form-field appearance="outline">
						<mat-label>Description*</mat-label>
						<textarea [(ngModel)]="des" matInput></textarea>
					</mat-form-field>
				</div>
				<div class="email">
					<mat-form-field appearance="outline">
						<mat-label>Email*</mat-label>
						<input [disabled]="true" [value]="email" readonly matInput />
					</mat-form-field>
				</div>
			</div>

			<div class="submit-btn">
				<button [disabled]="!topic || !des" (click)="submitTicket()">Submit a Ticket</button>
			</div>
		</div>

		<div class="main-cards" *ngIf="!loading">
			<!-- !category list -->
			<!-- <div class="page-content" *ngIf="page === 1">
				<mat-card *ngFor="let cat of categories" (click)="openCardPage(cat?.category_id, cat?.name)">
					<div class="mat-card d-flex align-items-center">
						<img class="card-img" src="assets/logo/lolly-logo-icon.svg" />
						<div class="card-content">
							<div class="card-title">
								{{ cat?.name }}
							</div>
							<div class="card-des">
								{{ cat?.description }}
							</div>
						</div>
					</div>
				</mat-card>
			</div> -->

			<!-- !category details -->
			<div class="page-content" *ngIf="page === 1">
				<div class="category-details">
					<div class="cat-title" *ngFor="let cat of categoryLinks" (click)="goTo(cat?.url)">
						{{ cat.title }}
					</div>
				</div>
			</div>

			<!-- !view ticket -->
			<div class="page-content" *ngIf="page === 4">
				<div class="ticket-list">
					<mat-card *ngFor="let ticket of tickets; let i = index" (click)="viewTicketDetails(i)">
						<div class="mat-card d-flex align-items-center mat-card-small">
							<div class="card-content-2">
								<div class="card-title card-title-nodes">
									{{ ticket.subject }}
								</div>
								<div [class]="'card-des status ' + ticket.status.toLowerCase()">
									{{ getStatusClass(ticket.status) }}
								</div>
							</div>
						</div>
					</mat-card>
				</div>

				<div *ngIf="!tickets.length" class="no-item">
					<img src="assets\support\Group 53830.svg" />
					<div class="status">
						No Ticket Found
					</div>
					<button (click)="goToSection(3)">Submit a Ticket</button>
				</div>
			</div>

			<!-- !view ticket details -->
			<div class="page-content" *ngIf="page === 5">
				<div class="view-ticket-details">
					<small class="text-muted">
						{{ selectedTicket.email }}
					</small>
					<div class="ticket-details mt-3">
						<div class="ticket-title d-flex mb-2 align-items-center justify-content-between">
							<h4 class="fw-bold">
								{{ selectedTicket.subject }}
							</h4>
							<div class="status-txt">
								{{ selectedTicket.status }}
							</div>
						</div>
						<p class="desc lh-base">
							{{ selectedTicket.description }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
