import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogDataModel } from './model/generic-dialog-data.model';

@Component({
	selector: 'app-generic-sm-dialog',
	templateUrl: './generic-sm-dialog.component.html',
	styleUrls: ['./generic-sm-dialog.component.scss']
})
export class GenericSmDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<GenericSmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GenericDialogDataModel) {}

	ngOnInit(): void {}

	public getImgHovered(imgSrc: string): string {
		return imgSrc.replace('black', 'white');
	}

	public primaryAction(): void {
		this.dialogRef.close({ primaryAction: 'true' });
	}

	public secondaryAction(): void {
		this.dialogRef.close({ secondaryAction: 'true' });
	}

	public alternativeAction(): void {
		this.dialogRef.close({ alternativeAction: 'true' });
	}
}
