import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupInterface } from './popup01.interface';

@Component({
	selector: 'filed-popup',
	templateUrl: './popup01.component.html',
	styleUrls: ['./popup01.component.scss']
})
export class GlobalPopupComponent implements OnInit {
	@Input() opened = false;
	@Input() minWidth = 250;
	@Input() width = 480;
	@Input() title = ' ';

	@Output() public close: EventEmitter<PopupInterface>;

	constructor() {
		this.close = new EventEmitter<PopupInterface>();
	}

	ngOnInit(): void {}

	public changes($event: PopupInterface): void {
		this.close.emit($event);
	}
}
