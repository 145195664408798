import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SourceChannel } from '../sidenav/sidenav/sidenav-channel-buttons.enum';

@Injectable({
	providedIn: 'root'
})
export class AccountTypeService {
	private _accountType = new ReplaySubject<SourceChannel>(1);

	public readonly accountType: Observable<SourceChannel> = this._accountType.asObservable();
	public readonly localStorageChannelKey = 'source_channel';

	public setAccountType(sourceChannel: SourceChannel): void {
		this._accountType.next(sourceChannel);
		localStorage.setItem(this.localStorageChannelKey, sourceChannel + '');
	}

	public getAccountTypeObservable(): Observable<SourceChannel> {
		return this.accountType;
	}
}
