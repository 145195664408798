import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GenericInputDataModel } from '../models/generic.interface';
import { GROUP_BY_DATE_OPTIONS } from 'src/app/shared/utils';

@Component({
	selector: 'app-custom-date-range-picker',
	templateUrl: './custom-date-range-picker.component.html',
	styleUrls: ['./custom-date-range-picker.component.scss']
})
export class CustomDateRangePickerComponent implements OnInit {
	@Input() public dateControl: UntypedFormControl = new UntypedFormControl();
	@Input() public groupByControl: UntypedFormControl = new UntypedFormControl();
	@Output() private emitDateChange: EventEmitter<any> = new EventEmitter<any>();

	public groupByDateOptions: GenericInputDataModel[] = GROUP_BY_DATE_OPTIONS;

	constructor() {}

	ngOnInit(): void {}

	private closeMenu(): void {
		let menu = document.querySelector('.cdk-overlay-backdrop') as HTMLElement;
		menu.click();
	}

	public choseDate(event): void {
		this.dateControl.patchValue(event.chosenLabel);
		this.closeMenu();
		this.emitDateChange.emit();
	}

	public onGroupByChange(event): void {
		this.closeMenu();
		this.emitDateChange.emit();
	}
}
