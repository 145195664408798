import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { UserState } from '../user/user.reducer';
import { SharedState } from '../shared.reducer';
import { InsightsLevelEnum } from '../../services/general-settings/models/insights-level.enum';
import _ from 'lodash';

export class ReducerFunctions {
	public static getBusinessOwnerIdByChannel(state: UserState, { channel }: { channel: SourceChannel }): string {
		switch (channel) {
			case SourceChannel.Facebook:
				return state.userDetails.FacebookBusinessOwnerId;
			case SourceChannel.Google:
				return state.userDetails.GoogleBusinessOwnerId;
			default:
				return null;
		}
	}

	public static getAdsManagerUserDashboard(slice: SharedState): any[] {
		//silsrang-migration
		const userDashboards = slice.generalSettings.shared.data?.userDashboards;
		if (userDashboards) {
			return userDashboards;
		}
		return [];
	}

	public static getAdAccountByIdFromState(state: SharedState, adAccountId: string, sourceChannel: SourceChannel) {
		return state.cachedAdAccounts.data
			.find(adAccountsModel => {
				return adAccountsModel.sourceChannel === sourceChannel;
			})
			?.adAccounts.find(adAccount => {
				return adAccount.id === adAccountId;
			});
	}

	public static checkIfStructureDataIsPopulated(adAccount: any, level: InsightsLevelEnum): boolean {
		//silsrang-migration
		if (adAccount) {
			if (!(level in adAccount)) {
				// @ts-ignore todo: find a better solution without ts-ignore
				Object.defineProperties(adAccount, { [level]: _.cloneDeep(NewAdAccountNulled) });
			}
			const insights = adAccount[level];

			if (insights?.active.data?.length) {
				return true;
			}

			if (insights?.paused.data?.length) {
				return true;
			}
		}
		return false;
	}

	public static getSelectedInsights(insights: any, statusKeys: any[]): any[] {
		//silsrang-migration
		let selectedInsights: any[] = []; //silsrang-migration
		if (insights) {
			// if (statusKeys?.includes(FilterKeysEnum.Active)) { //silsrang-migration
			// 	if (insights?.active?.data) {
			// 		selectedInsights = selectedInsights.concat(insights?.active?.data);
			// 	}
			// }
			// if (statusKeys?.includes(FilterKeysEnum.Paused) || statusKeys?.includes(FilterKeysEnum.Completed)) {
			// 	if (insights?.paused?.data) {
			// 		selectedInsights = selectedInsights.concat(insights?.paused?.data);
			// 	}
			// }
		}
		return selectedInsights;
	}

	public static filterAdSetsByCampaignId(basicStructureModels: any[], campaignsIds: string[]): any[] {
		//silsrang-migration
		return basicStructureModels.filter(structure => {
			return campaignsIds.includes(structure.keys.campaignId);
		});
	}

	public static filterAdsByAdSetId(basicStructureModels: any[], adSetIds: string[]): any[] {
		//silsrang-migration
		return basicStructureModels.filter(structure => {
			return adSetIds.includes(structure.keys.adsetId);
		});
	}

	public static getCurrentStructureKeyFactory(insight: any, reportLevel: any) {
		//silsrang-migration
		if (reportLevel === InsightsLevelEnum.Campaigns) {
			return insight.keys.campaignId;
		}
		if (reportLevel === InsightsLevelEnum.AdSets) {
			return insight.keys.adsetId;
		}
		if (reportLevel === InsightsLevelEnum.Ads) {
			return insight.keys.adId;
		}
	}

	public static mapReportType(displayName: string, key: string, sourceChannel: SourceChannel, reportLevel: any): any {
		//silsrang-migration
		return {
			id: key,
			displayName: displayName,
			currentKey: key,
			sourceChannel: sourceChannel,
			reportLevel: reportLevel
		};
	}
}
