<div class="product-wrapper" [formGroup]="productSeedingForm">
	<div class="w-100 d-flex k-align-items-center k-justify-content-between">
		<filed-font class="title" [fontSize]="'18px'" [color]="'#3C4043'">Select your Products</filed-font>
		<div class="d-flex k-align-items-center">
			<button class="secondary-btn-01 mr-m" *ngIf="productSeedingForm.value.products.length < products.length" (click)="selectAll()">
				Select All
			</button>
			<button class="secondary-btn-01 mr-m" *ngIf="productSeedingForm.value.products.length == products.length" (click)="deselectAll()">
				Deselect All
			</button>
			<div class="search-section">
				<mat-form-field class="width-full" appearance="outline" [formGroup]="formGroup">
					<input class="search-influencer" type="search" formControlName="search" matInput placeholder="Search by name" />
					<mat-icon matRipple class="search-icon cursor-pointer" matSuffix>search</mat-icon>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div *ngIf="!isLoading" class="w-100 pb-m product-grid mt-m">
		<div *ngFor="let product of products; let productSequence = index" class="product-card-wrapper">
			<div (click)="selectProduct(product)" class="product-card {{ productSeedingForm.value?.products && checkIfIncludes(product) ? 'selected' : '' }}">
				<div class="product-img-wrapper">
					<img class="product-img" [src]="product?.image" />
				</div>
				<div class="product-footer">
					<filed-font class="font-med" [fontSize]="'20px'" [color]="'#2585FE'">{{ product?.name }}</filed-font>
					<filed-font class="font-med" [fontSize]="'20px'" [fontWeight]="'600'">{{ getSymbol(product?.symbol) }} {{ product?.price }}</filed-font>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="isLoading" class="w-100 h-100">
		<app-lolly-spinning-loader [rotating]="true"></app-lolly-spinning-loader>
	</div>
</div>
