import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-simple-text-input',
	templateUrl: './simple-text-input.component.html',
	styleUrls: ['./simple-text-input.component.scss']
})
export class SimpleTextInputComponent implements OnInit {
	@Input() placeholder: string = 'Search..';
	@Input() showSuffix: boolean = false;
	@Input() matSuffixIcon: string = 'close';
	@Input() inputControl: UntypedFormControl;
	@Output() emitSave: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSuffixIconClick: EventEmitter<void> = new EventEmitter<void>();

	constructor() {}

	ngOnInit(): void {}

	public onSave(): void {
		this.emitSave.emit();
	}

	public onSuffixClick(): void {
		this.onSuffixIconClick.emit();
	}
}
