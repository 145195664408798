import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-auto-multi-drp',
	templateUrl: './auto-multi-drp.component.html',
	styleUrls: ['./auto-multi-drp.component.scss']
})
export class AutoMultiDrpComponent implements OnInit {
	@Input() list: any[] = [];
	@Input() width: string = '100%';
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl;
	@Output() emitSelection: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitDeselection: EventEmitter<any> = new EventEmitter<any>();
	public filteredList: Array<any> = [];
	public drpFormControl = new UntypedFormControl();
	private unsubscriber$ = new Subject<void>();
	constructor() {}

	ngOnInit(): void {
		if (!this.inputControl) {
			this.inputControl = new UntypedFormControl();
		}
		this.filteredList = this.list;
		this.drpFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			if (res && res.trim() != '') {
				this.filteredList = this.list.filter(item => {
					let searchString = item?.name;
					return searchString.toLowerCase().replace(/ /g, '').trim().includes(res.toLowerCase().replace(/ /g, '').trim());
				});
			} else {
				this.filteredList = this.list;
			}
		});
	}

	public toggleSelection = (data: any, index: number): void => {
		let selectedArr = this.list.filter(x => x.selected);
		this.inputControl.patchValue(selectedArr, { emitEvent: false });
		if (data.selected) {
			this.emitSelection.emit(selectedArr);
		} else {
			let deselected = data;
			this.emitDeselection.emit({ dataItem: deselected, index: index });
		}
	};

	public nestedCopy(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	public styleMenu() {
		return {
			width: this.width + ' !important',
			'max-width': this.width + '!important'
		};
	}

	public getCurrentValue(): string {
		const selectedArrOfObjs = this.inputControl.value as any[];
		return selectedArrOfObjs.map(x => x.name).join(', ');
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
