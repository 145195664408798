import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationActionEnum, NotificationModel } from 'src/app/shared/models/notifications.model';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
	selector: 'app-notifications-dialog',
	templateUrl: './notifications-dialog.component.html',
	styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {
	@Input() notifications: NotificationModel[] = [];
	@Input() unreadCount: number = 0;
	@Output() updateUnreadCount = new EventEmitter<number>();
	private unsubscriber$ = new Subject<void>();

	constructor(public router: Router, public notificationService: NotificationsService) {}

	ngOnInit(): void {}

	public onNotificationClick(notification: NotificationModel): void {
		// this.implementAction(notification);
	}

	public onNotificationActionClick(notification: NotificationModel): void {
		this.implementAction(notification);
	}

	public implementAction(notification: NotificationModel): void {
		switch (notification.type) {
			case NotificationActionEnum.BRAND_MESSAGE_RECEIVED:
				this.router.navigate(['messages'], { queryParams: { creatorId: notification.created_by } });
				break;
			case NotificationActionEnum.BRAND_EMAIL_RECEIVED:
				this.router.navigate(['messages'], { queryParams: { emailCreatorId: notification.created_by } });
				break;
			case NotificationActionEnum.BRAND_NOTIFY_PENDING_PAYMENT:
			case NotificationActionEnum.BRAND_PAYMENT_SUCCESSFUL:
			case NotificationActionEnum.BRAND_PAYMENT_FAILED:
				this.router.navigate(['payments']);
				break;
			case NotificationActionEnum.BRAND_JOB_ACCEPTED:
			case NotificationActionEnum.BRAND_JOB_DECLINED:
			case NotificationActionEnum.BRAND_JOB_APPLIED:
				this.router.navigate(['marketplace', notification.resource_id, 'view']);
				break;
			case NotificationActionEnum.BRAND_CREATIVE_UPLOADED:
			case NotificationActionEnum.BRAND_POST_UPLOADED:
				this.router.navigate(['creatives/view', notification.resource_id]);
				break;
			case NotificationActionEnum.BRAND_CRM_EMAIL_SENT:
				this.router.navigate(['outreach']);
				break;
			case NotificationActionEnum.BRAND_CAMPAIGN_LIMIT:
			case NotificationActionEnum.BRAND_JOB_LIMIT_PER_CAMPAIGN:
			case NotificationActionEnum.BRAND_INFLUENCER_LIMIT_PER_JOB:
			case NotificationActionEnum.BRAND_DISCOVERY_CREDIT_FINISH:
				this.router.navigate(['user-management/payment-and-billing']);
				break;
			case NotificationActionEnum.BRAND_THIRD_PARTY_CONNECTION:
				this.router.navigate(['campaign/setupIntegration']);
				break;
			case NotificationActionEnum.BRAND_EMAIL_CONNECTED:
				this.router.navigate(['user-management/integrations']);
				break;
			case NotificationActionEnum.BRAND_SUBUSER_ACCEPTED_INVITE:
				this.router.navigate(['user-management/teams']);
				break;
		}
	}

	public titleCase = s => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));

	public onEditNotificationClick(notification: NotificationModel, editAction: string): void {
		if (editAction == 'delete') {
			this.deleteNotification(notification);
		} else if (editAction == 'mute') {
			this.toggleMuteNotification(notification);
		}
	}

	public deleteNotification(notification: NotificationModel): void {
		const index = this.notifications.findIndex(n => n.id == notification.id);
		this.notifications.splice(index, 1);
		this.notificationService
			.deleteNotification(notification.id)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public toggleMuteNotification(notification: NotificationModel): void {
		if (notification.active) {
			this.muteNotification(notification);
		} else {
			this.unmuteNotification(notification);
		}
		notification.active = !notification.active;
	}

	public muteNotification(notification: NotificationModel): void {
		this.notificationService
			.muteNotification(notification.type)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public unmuteNotification(notification: NotificationModel): void {
		this.notificationService
			.unmuteNotification(notification.type)
			.pipe(take(1))
			.subscribe(res => {});
	}

	public menuOpened(): void {
		this.unreadCount > 7 ? (this.unreadCount -= 7) : (this.unreadCount = 0);
		// mark top 7 as read here in array
		for (let i = 0; i < 7; ++i) {
			if (this.notifications[i]) this.notifications[i].status = 'unread';
		}
		this.updateUnreadCount.emit(this.unreadCount);
		this.markTopSevenAsRead();
	}

	// function to mark top seven notitications as read
	public markTopSevenAsRead(): void {
		let notitificationIds = [];
		for (let i = 0; i < 7; ++i) {
			if (this.notifications[i]) {
				notitificationIds.push(this.notifications[i].id);
			}
		}
		const payload = {
			notification_ids: notitificationIds,
			status: 'read'
		};
		this.notificationService
			.readNotifications(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {});
	}

	public menuClosed(): void {
		// this.unreadCount > 7 ? (this.unreadCount -= 7) : (this.unreadCount = 0);
		// // mark top 7 as read here
		// for (let i = 0; i < 7; ++i) {
		// 	if (this.notifications[i]) this.notifications[i].status = 'unread';
		// }
		// this.updateUnreadCount.emit(this.unreadCount);
	}

	public routeToNotifications(): void {
		this.router.navigate(['notifications']);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
