import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'minus'
})
export class MinusPipe implements PipeTransform {
	transform(number: number, args?: any): any {
		return Math.abs(number);
	}
}
