<div class="product-wrapper" [formGroup]="productSeedingForm">
	<div class="w-100 d-flex k-align-items-center k-justify-content-between">
		<filed-font class="title" [fontSize]="'18px'" [color]="'#3C4043'">Select a shop</filed-font>
	</div>

	<div *ngIf="!isLoading" class="w-100 pb-m mt-m">
		<div class="row logo-content-box">
			<div class="col-12 col-md-5" (click)="selectShop('shopify')">
				<div class="card-logo-box {{ this.productSeedingForm?.get('shop')?.value === 'shopify' ? 'selected' : '' }}">
					<div class="card-body">
						<img src="assets/e-commerce/shopify.png" />
					</div>
				</div>
			</div>

			<div class="col-12 col-md-5" (click)="selectShop('bigcommerce')">
				<div class="card-logo-box {{ this.productSeedingForm?.get('shop')?.value === 'bigcommerce' ? 'selected' : '' }}">
					<div class="card-body">
						<img src="assets/e-commerce/bigcommerce.png" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="isLoading" class="w-100 h-100">
		<app-lolly-spinning-loader [rotating]="true"></app-lolly-spinning-loader>
	</div>
</div>
