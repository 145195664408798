<section class="user-management-navigation">
	<nav mat-tab-nav-bar>
		<div>
			<a
				#rla="routerLinkActive"
				*ngFor="let nav of navLinks"
				[active]="rla.isActive || checkActive(selectedTabName, nav.label)"
				[routerLinkActiveOptions]="{ exact: true }"
				[routerLink]="nav.link"
				mat-tab-link
				routerLinkActive
			>
				{{ nav.label }}
			</a>
		</div>
		<ng-content></ng-content>
	</nav>
</section>
