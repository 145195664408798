<ng-container>
	<div class="action-button-21 cursor-pointer">
		<mat-form-field appearance="outline" class="outline" [floatLabel]="'never'" (click)="onClickInput()">
			<mat-chip-list #chipListMat class="custom-chip-auto">
				<ng-container *ngIf="showInnerChips">
					<mat-chip *ngFor="let item of chipList; let i = index" (removed)="remove(item)" [disableRipple]="true">
						<div>
							{{ item }}
						</div>
						<div class="close" matChipRemove><mat-icon>close</mat-icon></div>
					</mat-chip>
				</ng-container>
				<input
					#multiChipInput
					matInput
					class="search-text-2"
					type="text"
					[placeholder]="placeholder"
					[formControl]="autocompleteControl"
					[matChipInputFor]="chipListMat"
					[matAutocomplete]="auto"
					(click)="$event.stopPropagation(); autoTrigger.openPanel()"
					#autoTrigger="matAutocompleteTrigger"
					[matChipInputSeparatorKeyCodes]="[]"
					[matChipInputAddOnBlur]="false"
					(matChipInputTokenEnd)="add($event)"
				/>
			</mat-chip-list>
			<mat-autocomplete class="auto-panel" #auto="matAutocomplete" (optionSelected)="selected($event)">
				<mat-option *ngFor="let item of locationListItems" [value]="item['name']">
					<div class="d-flex justify-content-between">
						<mat-checkbox class="custom-checkbox-black" type="checkbox" [checked]="chipList.includes(item['name'])" [labelPosition]="'before'">
							<span class="auto-search-drp-name w-100" #tooltip="matTooltip" matTooltip="{{ item['name'] }}">
								{{ item['name'] }}
							</span>
						</mat-checkbox>
					</div>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</ng-container>
