import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ButtonClassEnum } from 'src/app/shared/button-component/button-class.enum';
import { ButtonTypeEnum } from 'src/app/shared/button-component/button-type.enum';
import { AccountsPermissions } from 'src/app/shared/permisions/enums/accounts-permissions';
import { AdsManagerPermissions } from 'src/app/shared/permisions/enums/ads-manager-permissions';
import { AudiencePermissions } from 'src/app/shared/permisions/enums/audience-permissions';
import { BackofficePermissions } from 'src/app/shared/permisions/enums/backoffice-permissions';
import { CampaignBuilderPermissions } from 'src/app/shared/permisions/enums/campaign-builder-permissions';
import { CreativeBuilderPermissions } from 'src/app/shared/permisions/enums/creative-builder-permissions';
import { InfluencerPermissions } from 'src/app/shared/permisions/enums/influencer-permissions';
import { Modules } from 'src/app/shared/permisions/enums/modules';
import { OptimizePermissions } from 'src/app/shared/permisions/enums/optimize-permissions';
import { PixelPermissions } from 'src/app/shared/permisions/enums/pixel-permissions';
import { ProductCatalogPermissions } from 'src/app/shared/permisions/enums/product-catalog-permissions';
import { ReportsPermissions } from 'src/app/shared/permisions/enums/reports-permissions';
import { PermissionsDirectiveInterface } from 'src/app/shared/permisions/models/permissions-directive.interface';
import { hasBackOfficePermission, isUserImpersonated } from '../../../../shared/state/user/user.reducer';
import { getActiveUserManagementPage, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { UserState } from 'src/app/_models/user-models/user-state';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-welcome-popup-header',
	templateUrl: './welcome-popup-header.component.html',
	styleUrls: ['./welcome-popup-header.component.scss']
})
export class WelcomePopupHeaderComponent implements OnInit {
	public showUserManagement: boolean;
	private unsubscriber$ = new Subject<void>();
	@ViewChild('menuTrigger') public trigger: MatMenuTrigger;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public dropdownIsVisible = true;
	public isAdmin = false;
	public isImpersonated = false;

	public backOfficePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.BackOffice,
		permissions: [BackofficePermissions.CanAccessBackOffice]
	};
	public accountsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Accounts,
		permissions: [AccountsPermissions.CanAccessAccounts]
	};
	public adsManagerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.AdsManager,
		permissions: [AdsManagerPermissions.CanAccessAdsManager]
	};
	public campaignBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CampaignBuilder,
		permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
	};
	public creativeBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CreativeBuilder,
		permissions: [CreativeBuilderPermissions.CanAccessCreativeBuilder]
	};
	public optimizePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Optimize,
		permissions: [OptimizePermissions.CanAccessOptimize]
	};
	public pixelPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Pixel,
		permissions: [PixelPermissions.CanAccessPixels]
	};
	public audiencePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Audience,
		permissions: [AudiencePermissions.CanAccessAudience]
	};
	public reportsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Reports,
		permissions: [ReportsPermissions.CanAccessReports]
	};
	public influencerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Influencer,
		permissions: [InfluencerPermissions.FullAccess]
	};
	public productCatalogPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.ProductCatalog,
		permissions: [ProductCatalogPermissions.CanAccessProductCatalog]
	};

	constructor(
		private dialog: MatDialog,
		public translate: TranslateService,
		private store: Store<UserManagementState>,
		private router: Router,
		private userStore: Store<UserState>,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit(): void {
		this.impersonationHandle();
		this.checkUserManagementState();
	}

	public openKnowledgeBase(): void {}

	public checkUserManagementState(): void {
		this.store.pipe(select(getActiveUserManagementPage), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.showUserManagement = resp;
		});
	}

	public goToUserPage(): void {
		this.dialog.closeAll();
		this.trigger.closeMenu();
		this.router.navigate(['/user-management/my-profile']);
	}

	public impersonationHandle(): void {
		// this.userStore.pipe(select(isUserImpersonated), takeUntil(this.unsubscriber$)).subscribe(isImpersonated => {
		// 	this.isImpersonated = isImpersonated;
		// 	this.dropdownIsVisible = true;
		// 	this.resetPermissions();
		// });
		// this.userStore.pipe(select(hasBackOfficePermission), take(1)).subscribe(isAdmin => {
		// 	this.isAdmin = isAdmin;
		// 	this.dropdownIsVisible = !isAdmin;
		// });
	}

	private resetPermissions(): void {
		this.backOfficePermission = {
			moduleName: Modules.BackOffice,
			permissions: [BackofficePermissions.CanAccessBackOffice]
		};
		this.accountsPermission = {
			moduleName: Modules.Accounts,
			permissions: [AccountsPermissions.CanAccessAccounts]
		};
		this.adsManagerPermission = {
			moduleName: Modules.AdsManager,
			permissions: [AdsManagerPermissions.CanAccessAdsManager]
		};
		this.campaignBuilderPermission = {
			moduleName: Modules.CampaignBuilder,
			permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
		};
		this.optimizePermission = {
			moduleName: Modules.Optimize,
			permissions: [OptimizePermissions.CanAccessOptimize]
		};
		this.pixelPermission = {
			moduleName: Modules.Pixel,
			permissions: [PixelPermissions.CanAccessPixels]
		};
		this.audiencePermission = {
			moduleName: Modules.Audience,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.reportsPermission = {
			moduleName: Modules.Reports,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.influencerPermission = {
			moduleName: Modules.Influencer,
			permissions: [InfluencerPermissions.FullAccess]
		};
	}

	public logout(): void {
		this.dialog.closeAll();
		this.trigger.closeMenu();
		this.authenticationService.logout();
	}
}
