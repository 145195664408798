<div class="video-wrapper">
	<div class="video-container" id="video-container">
		<video controls id="video" preload="metadata" autoplay>
			<source [src]="data.link" type="video/mp4" />
		</video>

		<div class="play-button-wrapper">
			<div title="Play video" class="play-gif" id="circle-play-b">
				<!-- SVG Play Button -->
				<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
					<path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
				</svg> -->
			</div>
		</div>
	</div>
</div>
