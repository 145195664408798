import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-multi-select-auto-dropdown',
	templateUrl: './multi-select-auto-dropdown.component.html',
	styleUrls: ['./multi-select-auto-dropdown.component.scss']
})
export class MultiSelectAutoDropdownComponent implements OnInit {
	@Input() list: any[] = [];
	@Input() width: string = '100%';
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl;
	@Output() emitSelection: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitDeselection: EventEmitter<any> = new EventEmitter<any>();
	public filteredList: Array<any> = [];
	public drpFormControl = new UntypedFormControl();
	private unsubscriber$ = new Subject<void>();
	constructor() {}

	ngOnInit(): void {
		if (!this.inputControl) {
			this.inputControl = new UntypedFormControl();
		}
		this.filteredList = this.list;
		this.drpFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			if (res && res.trim() != '') {
				this.filteredList = this.list.filter(item => {
					let searchString = item?.name;
					return searchString.toLowerCase().replace(/ /g, '').trim().includes(res.toLowerCase().replace(/ /g, '').trim());
				});
			} else {
				this.filteredList = this.list;
			}
		});
	}

	// ngOnChanges(changes: SimpleChanges) {
	// 	this.filteredList = changes.list.currentValue;
	// }

	public toggleSelection = (data: any, index: number): void => {
		let selectedArr = this.list.filter(x => x.selected);
		this.inputControl.patchValue(selectedArr, { emitEvent: false });
		if (data.selected) {
			this.emitSelection.emit(selectedArr);
		} else {
			let deselected = data;
			this.emitDeselection.emit({ dataItem: deselected, index: index });
		}
	};

	public onMenuClosed(): void {
		this.drpFormControl.patchValue('');
	}

	public nestedCopy(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	public styleMenu() {
		return {
			width: this.width + ' !important',
			'max-width': this.width + '!important'
		};
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
