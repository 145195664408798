<div class="budget-wrapper">
	<div class="prefix-select">
		<div class="selected-wrapper {{ disableCurrencyChange ? 'tooltip' : '' }}">
			<div
				class="selected-trigger {{ disableCurrencyChange ? 'disabled' : '' }}"
				[matMenuTriggerFor]="currencyMenu"
				#currencyMenuTrigger="matMenuTrigger"
			>
				<div class="trigger-wrapper">
					<span>{{ currencyControl?.value?.iso }} {{ currencyControl?.value?.symbol }}</span>
					<img *ngIf="!disableCurrencyChange" src="assets/icons/arrow-down-filled.svg" />
				</div>
			</div>
			<mat-menu #currencyMenu="matMenu" class="currency-menu">
				<div
					(click)="$event.stopPropagation(); selectCurrency(currency); currencyMenuTrigger.closeMenu()"
					*ngFor="let currency of currencies; let currencySequence = index"
					class="currency-menu-list-item {{ currency == currencyControl?.value ? 'selected' : '' }}"
				>
					<span class="currency-drp-name w-100">{{ currency?.iso }} {{ currency?.symbol }}</span>
				</div>
			</mat-menu>
			<span class="tooltiptext tooltip-right">{{ currencyDisabledTooltip }}</span>
		</div>
	</div>

	<input min="0" oninput="this.value = Math.abs(this.value)" placeholder="{{ placeholder }}" class="input-inner" type="number" [formControl]="inputControl" />
</div>
