import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionsApiOptionsService } from 'src/app/shared/permisions/permissions-api-options.service';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { NewUserModel } from 'src/app/user-management/models/new-user-model';
import { SetAdminManagement } from 'src/app/user-management/state/user-management.actions';
import { getUserAdminState, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { CompleteModalComponent } from '../../../modals/complete-modal/complete-modal.component';
import { VerifyAddUserComponent } from '../../../modals/verify-add-user/verify-add-user.component';

@Component({
	selector: 'app-add-user',
	templateUrl: './add-user.component.html',
	styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, OnDestroy {
	@Input() isEdit: boolean = false;
	@Output() public onContinueNextAction: EventEmitter<boolean> = new EventEmitter();
	@Output() public onGoBack: EventEmitter<boolean> = new EventEmitter();

	public userForm: UntypedFormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public userId: string;
	public userDetail = new NewUserModel();
	public userRoles: any;
	public dropDownListItems: any[] = [
		{ label: 'Standard', value: 'Standard' },
		{ label: 'View Only', value: 'View Only' }
	];
	public userDetails: any;

	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private formBuilder: UntypedFormBuilder,
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService,
		private store: Store<UserManagementState>,
		private activatedRoute: ActivatedRoute,
		private roleServices: PermissionsApiOptionsService,
		private router: Router,
		private sharedStore: Store<SharedState>
	) {}

	public ngOnInit(): void {
		this.createForm();
		if (this.isEdit) {
			this.fetchStore();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private createForm(): void {
		this.userForm = this.formBuilder.group({
			firstname: new UntypedFormControl('', Validators.required),
			lastname: new UntypedFormControl('', Validators.required),
			email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(70)]),
			accessLevel: new UntypedFormControl('', Validators.required)
		});
	}

	private fetchStore(): void {
		this.store.pipe(select(getUserAdminState), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.userDetails = detail;
				this.userForm.patchValue({
					firstname: detail.user.name.split(' ')[0],
					lastname: detail.user.name.split(' ')[1] ? detail.user.name.split(' ')[1] : '',
					email: detail.user.email,
					accessLevel: detail.user.roles ? detail.user.roles : 'Standard'
				});
			}
		});
	}
	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.userForm.get('accessLevel').setValue(selected);
		}
	}

	public onFormChange(value: string): void {}

	public getRolesData(): void {
		this.roleServices
			.getAllRolesPermissions()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				//this.userRoles = res;
			});
	}
	public onContinueEdit(): void {
		if (this.userForm.invalid) {
			this.toastNotification.sendErrorToast('Please fill the required fields.');
			this.userForm.markAllAsTouched();
		} else {
			const payload: any = {
				user: {
					firstname: this.userForm.get('firstname').value,
					lastname: this.userForm.get('lastname').value,
					email: this.userForm.get('email').value,
					roles: this.userForm.get('accessLevel').value,
					position: '',
					telephone: '',
					accountState: 1
				},
				acccounts: this.userDetails?.accounts,
				permissions: this.userDetails?.permissions
			};
			this.store.dispatch(new SetAdminManagement(payload));
			this.onContinueNextAction.emit(true);
		}
	}
	public goBacks(): void {
		this.router.navigate(['/user-management/user-admin']);
	}
}
