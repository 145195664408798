import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective {
	@Output() scrolled = new EventEmitter<void>();

	constructor(private el: ElementRef) {}

	@HostListener('scrollend', ['$event'])
	onScroll(event: any): void {
		const container = this.el.nativeElement;

		// const scrolledToBottom = container.scrollHeight - Math.round(container.scrollTop) === container.clientHeight;
		const scrolledToBottom = container.scrollTop + container.clientHeight >= 0.85 * container.scrollHeight;

		if (scrolledToBottom) {
			this.scrolled.emit();
		}
	}
}
