import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
//silsrang-migration
// import { Dashboard } from '../../ads-manager/models/dashboard-widget-data';
// import { MinimalFacebookStructureResponse } from '../../ads-manager/models/minimal-facebook-structure-response.model';
import { BaseApiUrl } from '../base-api-urls';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { DateRange } from '../../shared/calendar/calendar.models';
import { QueryModel } from '../../shared/query-builder/models/query.model';
// import { BasicStructureModel } from '../../reports/models/basic-structure.model';

export class UserDashboard {
	//silsrang-migration
	// constructor(public dashboards: Dashboard[]) {}
	constructor() {}
}

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	constructor(protected http: HttpClient) {}

	public getWidgetDataWithQueryBuilder(model: QueryModel, date: DateRange, channel: SourceChannel): Observable<any[]> {
		switch (channel) {
			case SourceChannel.Facebook:
				return this.http.post<any>(`${BaseApiUrl.FacebookDataPipeline}ads-manager/reports`, model);
		}
	}
	//silsrang-migration
	// public getStructures(adAccountId: string, level: string): Observable<BasicStructureModel[]> {
	// 	return this.http.get<BasicStructureModel[]>(`${BaseApiUrl.FacebookDataPipeline}${level.toLowerCase()}/${adAccountId}`);
	// }
}
