import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthPaymentService } from 'src/app/authentication/services/auth-payment.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthenticationService } from 'src/app/_services/authentication.service';

import { Auth } from 'shopify-admin-api';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-connect-to-shopify',
	templateUrl: './connect-to-shopify.component.html',
	styleUrls: ['./connect-to-shopify.component.scss']
})
export class ConnectToShopifyComponent implements OnInit {
	public loading: boolean = false;
	public mainForm: UntypedFormGroup;
	public profileOpen: any;
	public userFName: string;
	public userLName: string;
	public userEmail: string;

	constructor(
		private authenticationService: AuthenticationService,
		public formBuilder: UntypedFormBuilder,
		public router: Router,
		public suthPaymentService: AuthPaymentService
	) {
		this.mainForm = this.formBuilder.group({
			site: new UntypedFormControl({ value: null, disabled: false }, Validators.required),
			webhook: new UntypedFormControl({ value: null, disabled: false }, Validators.required),
			shop: new UntypedFormControl({ value: null, disabled: false }, Validators.required)
		});
	}

	ngOnInit(): void {
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.userLName = storageJwt['user_lastname'];
		this.userEmail = storageJwt['sub'];
	}

	public confirmShopConnection(): void {
		let link = 'https://' + this.mainForm.value.shop + '.myshopify.com';
		Auth.buildAuthorizationUrl(environment.Shopify.SCOPES, link, environment.Shopify.SHOPIFY_API_KEY, environment.Shopify.REDIRECT_URI).then(res => {
			window.location.replace(res);
		});
	}

	public toggleProfileAction(): void {
		this.profileOpen ? (this.profileOpen = false) : (this.profileOpen = true);
	}

	public logout(): void {
		this.profileOpen = false;
		this.authenticationService.expandMenu$.next(false);
		this.authenticationService.logout();
	}
}
