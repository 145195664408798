<div class="dropdown {{ styleClass }} {{ disabled ? 'disabled' : '' }}" [style]="cssStyle">
	<mat-label class="label">{{ dropdownLabel }}</mat-label>
	<mat-form-field appearance="outline">
		<mat-select
			[disableOptionCentering]="true"
			[formControl]="dropdownControl"
			(selectionChange)="optionSelected($event)"
			placeholder="{{ inputPlaceholder }}"
		>
			<mat-option *ngFor="let option of options" [value]="option">
				<div class="d-flex justify-content-between">
					<span [style.color]="dropdownControl?.value == option ? '#006bff' : '#121212'">{{ option[titleKey] }}</span>
					<span *ngIf="dropdownControl?.value == option" class="fas fa-check" style="color: #006bff;"></span>
				</div>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
