import { FacebookAccountConnnection } from './../../_models/facebook-account-connection';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from '../base-api-urls';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Injectable()
export class BusinessOwnerService {
	private readonly baseURL = BaseApiUrl.GoogleTuring;

	constructor(protected http: HttpClient) {}

	public deleteBusinessOwnerPermissions(permissions: string): Observable<any> {
		return this.http.delete(`${this.baseURL}business-owner/` + permissions);
	}

	public connectToFacebookAccount(request: FacebookAccountConnnection): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${this.baseURL}user`, request, { observe: 'response' });
	}

	public listFacebookAds(uncached: string = 'False'): Observable<HttpResponse<any>> {
		const token = localStorage.getItem(StorageKey.token);
		const header = new HttpHeaders({
			Authorization: `Bearer ${token}`
		});
		return this.http.get<any>(`${this.baseURL}accounts?uncached=${uncached}`, { headers: header, observe: 'response' });
	}

	public trackFacebookAds(data: any): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${this.baseURL}accounts/`, data, { observe: 'response' });
	}
}
