<div class="input-wrapper">
	<div class="password-wrapper">
		<mat-form-field
			[ngClass]="{
				'password-error-message': control.invalid && control.touched,
				'password-success-message': control.valid && control.touched,
				'password-disabled-message': control.disabled
			}"
			appearance="outline"
		>
			<mat-label>{{ label }}</mat-label>

			<input
				(focus)="autocompleteFocus()"
				(focusout)="setErrorMessage()"
				[formControl]="control"
				matInput
				[type]="hidePassword ? 'password' : 'text'"
				placeholder="{{ placeholder }}"
				type="password"
			/>

			<button kendoButton look="clear" icon="eye" (click)="toggleEvent()"></button>

			<mat-error *ngIf="control.invalid && control.hasError('required')">
				Password is required
			</mat-error>
		</mat-form-field>
	</div>
</div>
