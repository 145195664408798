import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { AuthenticationState } from '../state/authentication.reducer';
import { Store } from '@ngrx/store';
import { LogInSuccess } from '../state/authentication.action';

@Component({
	selector: 'app-verify-token',
	templateUrl: './verify-token.component.html',
	styleUrls: ['./verify-token.component.scss']
})
export class VerifyTokenComponent implements OnInit {
	public token: string;
	public unsubscriber$ = new Subject<void>();

	constructor(
		private authStore: Store<AuthenticationState>,
		private toast: ToastNotificationService,
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthenticationService
	) {
		this.route.queryParams.pipe(take(1)).subscribe(params => {
			if (params && params['token']) {
				this.token = params['token'];
			}
		});
	}

	ngOnInit(): void {
		if (!this.token) {
			this.router.navigate(['authentication']);
		} else {
			this.verifyToken();
		}
	}

	public verifyToken(): void {
		this.authService
			.verifyEmail(this.token)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.authStore.dispatch(new LogInSuccess(res?.data?.access_token));
				},
				err => {
					this.toast.sendErrorToast('Invalid Token Found!');
					this.router.navigate(['authentication']);
				}
			);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
