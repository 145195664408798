import { Component, Input } from '@angular/core';
import { ErrorMessageService } from '../error-message.service';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidatorMessages } from '../validatorMessagesInputs';

@Component({
	selector: 'app-input-cvc',
	templateUrl: './input-cvc.component.html',
	styleUrls: ['./input-cvc.component.scss', '../shared.scss']
})
export class InputCvcComponent {
	constructor(public errorMessageService: ErrorMessageService) {}

	@Input() control: UntypedFormControl | AbstractControl;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() label: string;
	@Input() placeholder: string;

	public isDestinationFocused: boolean;
	public errorMessage: string;

	ngOnInit() {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}
}
