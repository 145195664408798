import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { getUserDetails, UserState } from '../state/user/user.reducer';
import { switchMap, take } from 'rxjs/operators';
import { MiscellaneousPermissions } from '../permisions/enums/miscellaneous-permissions';
import { Modules } from '../permisions/enums/modules';

// * ACTIVE PARENT GUARD ON Inside Of Platform
@Injectable({
	providedIn: 'root'
})
export class UserBusinessOwnerGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.authService.isLoggedIn()) {
			return this.userStore.pipe(
				select(getUserDetails),
				take(1),
				switchMap(userDetails => {
					// Backoffice and client employee handler
					let hasBackOfficePermission = false;
					let hasClientEmployeePermission = false;
					let backOfficeDefaultUrl = 'back-office';
					userDetails.Permissions.filter(module => module.module === Modules.Miscellaneous).forEach(module => {
						module.permissions.forEach(permission => {
							if (
								permission === MiscellaneousPermissions.IsAdmin ||
								permission === MiscellaneousPermissions.IsAccountManagerAdmin ||
								permission === MiscellaneousPermissions.IsSales ||
								permission === MiscellaneousPermissions.IsSalesAdmin ||
								permission === MiscellaneousPermissions.IsAccountManager
							) {
								if (permission === MiscellaneousPermissions.IsSales) {
									backOfficeDefaultUrl = 'back-office/role';
								}
								hasBackOfficePermission = true;
							}
						});
					});
					userDetails.Permissions.filter(module => module.module === Modules.Miscellaneous).forEach(module => {
						module.permissions.forEach(permission => {
							if (permission === MiscellaneousPermissions.IsClientEmployee) {
								hasClientEmployeePermission = true;
							}
						});
					});

					if (hasClientEmployeePermission) {
						return of(true);
					}

					if (hasBackOfficePermission) {
						this.router.navigate([backOfficeDefaultUrl]);
						return of(false);
					}
					if (state.url === '') {
						this.router.navigate(['/']);
					}

					const isInfluencer = userDetails.Permissions.find(module => {
						return module.module === Modules.Influencer;
					});
					const isCreativeBuilder = userDetails.Permissions.find(module => {
						return module.module === Modules.CreativeBuilder;
					});

					if (!this.authService.allowedMegaRoutes$.value) {
						let allMegaPermissions = [];

						if (isInfluencer && !isCreativeBuilder) {
							allMegaPermissions.push({
								name: 'sansa',
								title: 'Sansa',
								icon: 'assets/icons/nav-menu/smi.svg',
								isSelected: true,
								hoverOver: false
							});
						} else if (isCreativeBuilder && !isInfluencer) {
						} else if (isCreativeBuilder && isInfluencer) {
							allMegaPermissions.push({
								name: 'sansa',
								title: 'Sansa',
								icon: 'assets/icons/nav-menu/smi.svg',
								isSelected: true,
								hoverOver: false
							});
						}

						this.authService.allowedMegaRoutes$.next(allMegaPermissions);
					}

					this.authService.setMegaRouteIndex$.next(0);
					return of(true);
				})
			);
		}

		return of(false);
	}
}
