import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LocationService } from './services/location.service';
import { HttpParams } from '@angular/common/http';
import { LocationDataModel } from './models/locations.model';

@Component({
	selector: 'app-multi-drp-locations',
	templateUrl: './multi-drp-locations.component.html',
	styleUrls: ['./multi-drp-locations.component.scss']
})
export class MultiDrpLocationsComponent implements OnInit, OnDestroy {
	@ViewChild('multiChipInput') multiChipInput;
	@Input() width: string = '100%';
	@Input() showInnerChips: boolean = true;
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl;
	@Input() titleKey: string = 'name';
	@Output() emitSelection: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitDeselection: EventEmitter<any> = new EventEmitter<any>();
	public autocompleteControl = new UntypedFormControl();
	public separatorKeysCodes: number[] = [ENTER, COMMA];
	public chipList: string[] = [];
	public locationListItems: LocationDataModel[] = [];
	private subscription: Subscription;
	private unsubscriber$ = new Subject<void>();

	constructor(private locationService: LocationService) {}

	ngOnInit(): void {
		this.autocompleteControl.valueChanges
			.pipe(startWith(<string>null), debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscriber$))
			.subscribe(val => {
				this.getLocationItems();
			});
		this.inputControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(val => {
			if (!val) {
				this.chipList = [];
				this.multiChipInput.nativeElement.value = '';
				this.autocompleteControl.reset();
			} else {
				this.chipList = [];
				val.forEach(x => {
					this.chipList.push(x.name);
				});
			}
		});
		if (this.inputControl?.value?.length) {
			this.mapInitialValues();
		}
	}

	public getLocationItems(): void {
		let queryParams = this.getQueryParams();

		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
		this.subscription = this.locationService
			.fetchLocations(queryParams)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.locationListItems = res?.data;
			});
	}

	public mapInitialValues(): void {
		this.chipList = [];
		this.inputControl.value.forEach(x => {
			this.chipList.push(x.name);
		});
	}

	public getQueryParams(): HttpParams {
		let queryParams = new HttpParams();
		queryParams = queryParams.append('fetch', 'country, state, city');
		queryParams = queryParams.append('limit', '100');
		if (this.autocompleteControl?.value) {
			queryParams = queryParams.append('search', this.autocompleteControl?.value);
		}
		return queryParams;
	}

	public add(event) {}

	public remove(item: string): void {
		this.emitDeselection.emit(item);
		const index = this.chipList.indexOf(item);
		if (index >= 0) {
			this.chipList.splice(index, 1);
		}
		let currentInputControlList = this.inputControl.value;
		const objIndex = currentInputControlList.findIndex(x => x['name'] == item);
		currentInputControlList.splice(objIndex, 1);
		this.inputControl.patchValue(currentInputControlList);
	}

	public selected(event: MatAutocompleteSelectedEvent): void {
		const value = event.option.value;
		if (this.chipList.includes(value)) {
			this.remove(value);
		} else {
			this.chipList.push(value);
			const obj = this.locationListItems.find(x => x['name'] == value);
			let currentInputControlList = this.inputControl.value;
			if (!currentInputControlList) {
				currentInputControlList = [];
			}
			currentInputControlList.push(obj);
			this.inputControl.patchValue(currentInputControlList);
			this.emitSelection.emit(value);
		}
		this.multiChipInput.nativeElement.value = '';
		this.autocompleteControl.setValue(null);
	}

	public onClickInput(): void {
		const nativeElement = this.multiChipInput.nativeElement;
		nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}
}
