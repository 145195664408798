import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatTextPipe } from './pipes/format-text-pipe';
import { SideStepperComponent } from './side-stepper/side-stepper.component';
import { KendoGridComponent } from '../social-media-influencer/modules/dashboard/component-pieces/kendo-grid/kendo-grid.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ShortNumberPipe } from './pipes/shortnumber-pipe';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [FormatTextPipe, ShortNumberPipe, SideStepperComponent, KendoGridComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		GridModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatInputModule,
		MatFormFieldModule,
		RouterModule
	],
	exports: [FormatTextPipe, ShortNumberPipe, SideStepperComponent, KendoGridComponent],
	providers: [ShortNumberPipe]
})
export class PipesModule {}
