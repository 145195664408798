<div class="dialog-root">
	<mat-icon class="close-icon" mat-dialog-close>clear</mat-icon>

	<div class="title" [ngStyle]="data?.titleStyles">{{ data?.title }}</div>

	<div class="image">
		<img *ngIf="data?.image" src="{{ data?.image }}" />
		<div *ngIf="data?.videoSrc" class="video-wrapper" (click)="togglePlayPause()">
			<video #videoPlayer [src]="data?.videoSrc" [poster]="data?.videoPoster"></video>
			<mat-icon class="open-in-new-tab" (click)="$event.stopPropagation(); openInNewTab(data?.videoSrc)">open_in_new</mat-icon>
			<img *ngIf="videoPlayer?.paused" src="assets/icons/video-play.svg" class="center-icon {{ isFirstTimePlay ? 'visibleimp' : '' }}" />
			<img *ngIf="!videoPlayer?.paused" src="assets/icons/video-pause.svg" class="center-icon" />
		</div>
	</div>

	<div class="title text-center">{{ data?.midTitle }}</div>
	<div *ngIf="data?.description" class="description" [ngStyle]="data?.descriptionStyles">{{ data?.description }}</div>

	<ng-container [ngTemplateOutlet]="footer"></ng-container>
</div>

<ng-template #footer>
	<div class="footer" [ngStyle]="data?.footerStyles">
		<button *ngIf="!data?.oneButtonFooter" class="secondary-btn" (click)="primaryAction()" [ngStyle]="data?.secondaryButtonStyles">
			<span>
				{{ data?.primaryActionTitle ? data?.primaryActionTitle : 'Yes' }}
			</span>
		</button>

		<button class="primary-btn {{ data?.secondaryActionClass }}" [ngStyle]="data?.primaryButtonStyles" (click)="secondaryAction()">
			<img *ngIf="data?.secondaryPrefix" src="{{ data?.secondaryPrefix }}" />
			<span>
				{{ data?.secondaryActionTitle ? data?.secondaryActionTitle : 'No' }}
			</span>
		</button>
	</div>
</ng-template>
