<div class="dropdown {{ styleClass }} {{ disabled ? 'disabled' : '' }}" [style]="cssStyle">
	<mat-form-field appearance="outline" class="{{ disabled ? 'disabled-mat' : '' }}">
		<mat-select
			class="drp-select"
			panelClass="drp2"
			[disableOptionCentering]="true"
			[formControl]="dropdownControl"
			(selectionChange)="optionSelected($event)"
			placeholder="{{ inputPlaceholder }}"
		>
			<mat-option *ngIf="showNullOption" [value]="null">{{ firstOptionName }}</mat-option>
			<mat-option *ngFor="let option of options" [value]="option">
				<div class="d-flex justify-content-between">
					<span class="title">{{ option[titleKey] }}</span>
					<span *ngIf="dropdownControl?.value == option" class="fas fa-check d-flex k-align-items-center" style="color: #4a4a4a;"></span>
				</div>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
