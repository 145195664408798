import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { ShortNumberPipe } from 'src/app/shared/pipes/shortnumber-pipe';
import { getDefaultScaleConfig } from 'src/app/shared/utils';
import { GenericChartDataModel } from '../models/chart.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-generic-line-graph',
	templateUrl: './generic-line-graph.component.html',
	styleUrls: ['./generic-line-graph.component.scss']
})
export class GenericLineGraphComponent implements OnInit {
	@Input() public chartId: string = 'default-chart-id';
	@Input() public chartData: GenericChartDataModel;
	@Input() public chartHeight?: string = '100%';
	@Input() public chartWidth?: string = '100%';
	@Input() public chartXAxisLabel?: string;
	@Input() public chartYAxisLabel?: string;
	@Input() public chartXAxisCallback?: Function;
	@Input() public chartYAxisCallback?: Function;

	public lineChart: any = Chart;
	private resizeSubject$: Subject<void> = new Subject<void>();
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private shortNumberPipe: ShortNumberPipe) {}

	ngOnInit(): void {
		this.resizeSubject$.pipe(debounceTime(200), takeUntil(this.unsubscriber$)).subscribe(() => {
			this.generateChart();
		});
	}

	ngAfterViewInit(): void {
		this.generateChart();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.resizeSubject$.next();
	}

	public generateChart(): void {
		var chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist != undefined) chartExist.destroy();

		const chartPluginData: any = {
			legend: {
				display: false
			},
			tooltip: {
				position: 'nearest',
				backgroundColor: '#ffffff',
				bodyColor: '#121212',
				yAlign: 'bottom',
				xAlign: 'center',
				callbacks: {
					title: item => {
						return null;
					},
					label: item => {
						return `${this.shortNumberPipe.transform((item?.raw ?? 0) as number)}%`;
					}
				},
				displayColors: false
				// enabled: false,
				// external: context => this.customTooltip(context, '', {}, 'bar')
			}
		};

		if (!this.chartData) {
			return;
		}
		const chartData: any = {
			labels: this.chartData.labels,
			datasets: this.chartData.datasets
		};

		this.lineChart = new Chart(this.chartId, {
			type: 'line',
			data: chartData,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: getDefaultScaleConfig(this.chartXAxisLabel, this.chartYAxisLabel, this.chartYAxisCallback, this.chartXAxisCallback),
				plugins: chartPluginData
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.resizeSubject$.complete();
		var chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist != undefined) chartExist.destroy();
	}
}
