<section class="dialog-root">
	<mat-icon class="close-icon" mat-dialog-close>clear</mat-icon>
	<h4 class="title">Extend the Campaign Budget</h4>

	<div class="label-input-wrapper">
		<label>Current Campaign Budget</label>

		<app-new-budget-input [inputControl]="null" [currencyControl]="null" [currencies]="[]"></app-new-budget-input>
	</div>

	<div class="label-input-wrapper">
		<label>New Campaign Budget</label>

		<app-new-budget-input [inputControl]="null" [currencyControl]="null" [currencies]="[]"></app-new-budget-input>
	</div>

	<div class="footer">
		<button class="secondary-btn">
			Cancel
		</button>

		<button class="primary-btn">
			Update
		</button>
	</div>
</section>
