import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class KnowledgeBaseService {
	constructor(private http: HttpClient) {}

	public getAllCategories(): Promise<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}zoho/base-category`).toPromise();
	}

	public getAllCategoryById(id: string): Promise<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}zoho/?category_id=${id}`).toPromise();
	}

	public getAllBrandCategory(): Promise<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}zoho/brand-articles`).toPromise();
	}

	public submitTicket(topic, des): Promise<any> {
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}zoho/create-ticket?description=${des}&subject=${topic}`, {}).toPromise();
	}

	public getAllTickets(): Promise<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}zoho/search-user-tickets`).toPromise();
	}

	public getTicket(id: number): Promise<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}zoho/search-user-tickets/${id}`).toPromise();
	}
}
