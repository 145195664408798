<div *ngIf="_myPlan.componentToLoad == 'my-plan' ">
	<div class="container">
		<div>
			<div class="body">
				<app-lolly-spinning-loader class="spinner" *ngIf="loading"></app-lolly-spinning-loader>
				<div class="body-form" *ngIf="!loading">
					<div class="container-with-bottons">
						<div class="header-text"><b>Sansa Plan | Per Month</b></div>
						<div class="action-button">
							<ul class="menus">
								<li>
									<a class="btn">
										<b>Next Payment:</b>
										{{planData.EndDate | date:'longDate'}}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="PlanCharged">
					{{planData?.Price | currency}}
					<span class="perMonthText">/m</span>
				</div>
				<div class="featureslist">
					<div class="float-left fontweight100" *ngFor="let item of features">
						<div class="k-icon k-i-check iconstyle"></div>
						{{item}}
					</div>
				</div>

				<div class="float-end"></div>
				<button (click)="_myPlan.componentToLoad = 'all-plan'" class="float-rignt changePlanBtn">Change Plan</button>
			</div>
		</div>
	</div>
	<div class="myPlanOtherInfo">
		<div class="container2 margin-right">
			<div class="available-credit-content fltLeft">
				<div class="">Available Credits:</div>
				<a (click)="showAddCreditPage()" class="AddCredits">+ Add Credit</a>
				<br />
			</div>
			<div class="available-credit-number">
				{{planData?.AvailableInfluencerSearchCredit}}
			</div>
		</div>
	</div>
</div>
<app-all-plans
	*ngIf="_myPlan.componentToLoad != 'my-plan'"
	(FinalPlanBackToPlanPage)="changePlan($event)"
	(backToPlanPage)="changeCompSetting($event)"
	[allTarrifPlans]="tarrifPlans"
	[pageTitle]="subPageTitle"
	[dropDownData]="dropBoxData"
></app-all-plans>
