import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-new-verify-email',
	templateUrl: './new-verify-email.component.html',
	styleUrls: ['./new-verify-email.component.scss']
})
export class NewVerifyEmailComponent implements OnInit {
	public email: string;

	constructor(private router: Router, private location: Location) {
		const state = location?.getState() as any;
		this.email = state?.email;
	}

	ngOnInit(): void {
		if (!this.email) this.router.navigate(['authentication/register-email']);
	}

	public goToCreateLink(link: string): void {
		window.open(link, '_blank');
	}
}
