import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TableColumn } from '../../_models/TableModels/table-column';
// import { RecommendationDTO } from '../../optimise/models/recommendation-dto.model';
// import { RecommendationFilter } from '../../optimise/models/recommendation-filter';
// import { RecommendationSort } from '../../optimise/models/recommendation-sort.model';
import { BaseApiUrl } from '../base-api-urls';
import { BackendFormatSort } from './models/backend-format-sort.model';

@Injectable()
export class OptimizationService {
	private readonly baseURL = BaseApiUrl.OptimizationApi;

	constructor(private http: HttpClient) {}

	public dismissRecommendation(id: string) {
		return this.http.patch(`${this.baseURL}DismissRecommendation?id=${id}`, null);
	}

	public applyRecommendation(id: string) {
		return this.http.patch(`${this.baseURL}ApplyRecommendation?id=${id}`, null);
	}

	public getTableColumns(): Observable<TableColumn[]> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.get<TableColumn[]>(`${this.baseURL}metadatas?tableName=filedoptimizations`, {
			headers: headers
		});
	}

	public getRecommendationPage(
		pageNumber: number,
		pageSize: number,
		filter: any = null, //silsrang-migration
		sort: any = null, //silsrang-migration
		excludedIds: string[] = null
	): Observable<any> {
		//silsrang-migration
		const requestBody = {
			PageNumber: pageNumber,
			PageSize: pageSize
		};
		let body;
		if (filter) {
			body = requestBody as any;
			body['Filter'] = filter;
		}
		if (sort && sort.criteria.length > 0) {
			const backendFormatSort: BackendFormatSort = new BackendFormatSort();
			if (sort.direction) {
				sort.criteria.forEach((criterion: string) => {
					if (criterion === 'Date') {
						backendFormatSort.created_at = sort.direction;
					}
					if (criterion === 'Type') {
						backendFormatSort.recommendation_type = sort.direction;
					}
				});
			}
			if (sort.criteria.indexOf('importance') > -1 && sort.importanceDirection) {
				backendFormatSort.importance = sort.importanceDirection;
			}
			body = requestBody as any;
			body['Sort'] = backendFormatSort;
		}

		if (excludedIds) {
			body = requestBody as any;
			body['ExcludedIds'] = excludedIds;
		}
		return this.http.post<any>(`${this.baseURL}GetRecommendationsPage`, requestBody); //silsrang-migration
	}
}
