<kendo-card [width]="width" [style.height]="height" class="filed-card" [ngClass]="{ 'filed-selected-card': selected, disabled: disabled }">
	<kendo-card-body class="filed-card-body">
		<div class="detail-wrapper" [ngClass]="withRemove ? 'with-remove' : ''">
			<div class="biography">
				<div *ngIf="!withRemove" class="check-box">
					<input
						kendoCheckBox
						[disabled]="disabled"
						[(ngModel)]="selected"
						(ngModelChange)="toggle()"
						type="checkbox"
						(click)="$event.stopPropagation()"
					/>
				</div>
				<div class="image" [ngClass]="{ disabled: disabled }">
					<img [src]="data?.imgSrc" onError="this.src='http://www.gravatar.com/avatar/?d=mp'" />
				</div>
			</div>

			<div class="align-position-info">
				<div class="position-info">
					<div class="info">
						<i-tooltip [title]="data?.name">
							<div class="font05">
								{{ (data?.name | titlecase | slice: 0:14) + (data?.name?.length > 14 ? '...' : '') }}
							</div>
						</i-tooltip>
						<div class="font08">
							{{ (data?.location | slice: 0:14) + (data?.location?.length > 14 ? '...' : '') }}
						</div>
					</div>
				</div>
			</div>
			<btn07 *ngIf="withRemove" (onClick)="removeCardList()">REMOVE</btn07>
		</div>
	</kendo-card-body>
</kendo-card>
