<ng-container>
	<div class="action-button-nt cursor-pointer {{ disabled ? 'disabled' : '' }}">
		<mat-form-field appearance="outline" class="outline {{ disabled ? 'disabled-mat' : '' }}" [floatLabel]="'never'" (click)="onClickInput()">
			<mat-chip-list #chipListMat class="custom-chip-auto">
				<mat-chip *ngFor="let item of chipList; let i = index" (removed)="remove(item)" [disableRipple]="true">
					<div>
						{{ item }}
					</div>
					<div class="close" matChipRemove><mat-icon>close</mat-icon></div>
				</mat-chip>
				<input
					#multiChipInput
					matInput
					class="search-text-2"
					type="text"
					[placeholder]="placeholder"
					[formControl]="autocompleteControl"
					[matChipInputFor]="chipListMat"
					[matAutocomplete]="auto"
					(click)="$event.stopPropagation(); autoTrigger.openPanel()"
					#autoTrigger="matAutocompleteTrigger"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[matChipInputAddOnBlur]="false"
					(matChipInputTokenEnd)="add($event.value)"
				/>
			</mat-chip-list>
			<mat-autocomplete class="auto-panel-nt" #auto="matAutocomplete" (optionSelected)="selected($event.option.value)">
				<mat-option *ngFor="let item of list" [value]="item">
					<div class="d-flex justify-content-between">
						<span class="auto-search-drp-name w-100">
							{{ item }}
						</span>
					</div>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</ng-container>
