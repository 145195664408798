import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { LollyLoadingDialogComponent } from './lolly-loading-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class LollyLoadingDialogService {
	public dialogRef: MatDialogRef<LollyLoadingDialogComponent>;

	constructor(public dialog: MatDialog) {}

	public showLoader(): void {
		if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
			return;
		}
		this.dialogRef = this.dialog.open(LollyLoadingDialogComponent, {
			panelClass: 'trans-back',
			height: '200px',
			width: '260px',
			backdropClass: 'light-backdrop-blurred',
			disableClose: true
		});
	}

	public dismissLoader(): void {
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}
}
