import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ValidatorMessages } from '../../../shared/form-input/validatorMessagesInputs';
import { FieldValidators, PhoneValidator } from '../../models/validators';

@Component({
	selector: 'app-cancel-subscription-reason',
	templateUrl: './cancel-subscription-reason.component.html',
	styleUrls: ['./cancel-subscription-reason.component.scss']
})
export class CancelSubscriptionReasonComponent {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	public subscriptionPhoneValidatorMessages: ValidatorMessages[] = PhoneValidator;

	public phoneNumber: UntypedFormControl = new UntypedFormControl('', [
		Validators.required,
		Validators.pattern(FieldValidators.Phone),
		Validators.minLength(5)
	]);

	constructor(public router: Router, public dialog: MatDialog, public dialogRef: MatDialogRef<CancelSubscriptionReasonComponent>) {}

	public sendEmail(): void {
		this.dialogRef.close({ status: true, phone: this.phoneNumber.value });
	}

	public cancel(): void {
		this.dialogRef.close(false);
	}
}
