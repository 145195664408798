import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ValidatorMessages } from '../../../shared/form-input/validatorMessagesInputs';
import { FieldValidators } from '../../models/validators';

@Component({
	selector: 'app-share-invoice-email',
	templateUrl: './share-invoice-email.component.html',
	styleUrls: ['./share-invoice-email.component.scss']
})
export class ShareInvoiceEmailComponent implements OnInit {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	public emailFormControl: UntypedFormControl = new UntypedFormControl('', [
		Validators.required,
		Validators.maxLength(70),
		Validators.pattern(FieldValidators.Email)
	]);

	public validationLineMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'This field is mandatory'
		},
		{
			validationType: 'pattern',
			validationMessage: 'Invalid email'
		},
		{
			validationType: 'maxlength',
			validationMessage: 'Email must not exceed 50 characters'
		}
	];

	public billId: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ShareInvoiceEmailComponent>) {}

	public ngOnInit(): void {
		this.billId = this.data.invoiceId;
	}

	public close(): void {
		this.dialogRef.close({ status: false });
	}

	public send(): void {
		this.dialogRef.close({ status: true, email: this.emailFormControl.value });
	}
}
