<div class="container">
	<div class="left-dock">
		<div class="message">
			<span>
				Join
				<span class="bolder-text">1,000's</span>
				Of Users, Brands and Marketers from:
			</span>
		</div>
		<div class="brands">
			<img src="assets/icons/ext-brands/google-icon.svg" alt="Google" />
			<img src="assets/icons/ext-brands/microsoft-icon.svg" alt="microsoft" />
			<img src="assets/icons/ext-brands/airbnb-icon.svg" alt="airbnb" />
			<img src="assets/icons/ext-brands/appleinc-icon.svg" alt="Apple" />
			<img src="assets/icons/ext-brands/infosys-icon.svg" alt="Infosys" />
			<img src="assets/icons/ext-brands/amazon-icon.svg" alt="amazon" />
			<img src="assets/icons/ext-brands/alibaba-icon.svg" alt="Alibaba" />
			<img src="assets/icons/ext-brands/tcs-icon.svg" alt="tcs" />
		</div>
	</div>
	<div class="right-dock">
		<div class="signin-wrapper">
			<div class="signin-con">
				<div class="logo">
					<img src="assets\auth\Path 19423.svg" />
				</div>
				<p class="title-text">Verify your email</p>
				<p class="des-text">
					please check you inbox for your email verification.
					<br />
					<br />

					<span class="email-des">
						Link sent to "
						<span style="color: #2585fe;">{{ email }}</span>
						"
					</span>
				</p>
				<div class="email-cards">
					<div class="email-card" (click)="goToCreateLink('https://mail.google.com/mail/')">
						<img src="assets/crm/mailboxes/svgexport-1 (8).svg" />
						Open Gmail
					</div>
					<div class="email-card" (click)="goToCreateLink('https://outlook.office.com/mail/')">
						<img src="assets/crm/mailboxes/svgexport-1 (9).svg" />
						Open Outlook
					</div>
				</div>
				<div class="div-footer" style="height: 20px;"></div>
			</div>
		</div>
	</div>
</div>
