import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-custom-tooltip',
	templateUrl: './custom-tooltip.component.html',
	styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit {
	@Input() public text: string;
	@Input() public link: string;
	@Input() public linkText: string;
	@Input() public icon: string = 'help';
	constructor() {}

	ngOnInit(): void {}

	public goTo(): void {
		window.open(this.link, '_blank');
	}
}
