import { PriorityToasterType } from './priority-toaster-type-modified.enum';

export class PriorityToasterModel {
	public message: string;
	public type: PriorityToasterType;
	public duration: number;
	public customTitle: string;
	public footerMessage: string;

	constructor(message: string, type: PriorityToasterType, duration: number = 5000, title?: string, footerMessage?: string) {
		this.message = message;
		this.type = type;
		this.duration = duration;
		this.customTitle = title ?? type;
		this.footerMessage = footerMessage ?? '';
	}
}
