import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { CampaignFormService } from '../models/discovery/campaign-form.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ShopifyService } from 'src/app/_services/shopify/shopify.service';
import { BigcommerceService } from 'src/app/_services/bigcommerce/bigcommerce.service';

@Component({
	selector: 'app-create-product-seeding-step-one',
	templateUrl: './create-product-seeding-step-one.component.html',
	styleUrls: ['./create-product-seeding-step-one.component.scss']
})
export class CreateProductSeedingStepOneComponent implements OnInit {
	@Input() public productSeedingForm: FormGroup;
	@Output() public currentStepValid = new EventEmitter<boolean>();
	public shopifyPageRoute: string = 'shopify';
	public bigcommercePageRoute: string = 'bigcommerce';
	public isLoading: boolean = false;
	public catalogues: any[] = [];
	private unsubscriber$ = new Subject<void>();

	constructor(
		private campaignFormService: CampaignFormService,
		private toastNotificationService: ToastNotificationService,
		private shopifyService: ShopifyService,
		private bigcommerceService: BigcommerceService
	) {}

	ngOnInit(): void {
		this.formValidate();
	}

	public isShopIntegrated(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;
			if (value === null) {
				return { valueRequired: true };
			}

			if (value === false) {
				return { notIntegrated: true };
			}

			return null;
		};
	}

	public formValidate(): void {
		this.productSeedingForm.get('shop').setValidators([Validators.required]);
		this.productSeedingForm.get('shopIntegrated').setValidators([this.isShopIntegrated()]);
		this.productSeedingForm.get('shop').updateValueAndValidity();
		this.productSeedingForm.get('shopIntegrated').updateValueAndValidity();
		this.currentStepValid.emit(this.productSeedingForm.valid);
		this.checkForm();
		// if (this.productSeedingForm.get('shop')?.value) {
		// 	this.toOverview(this.productSeedingForm.get('shop').value);
		// }
	}

	public checkForm(): void {
		this.productSeedingForm.valueChanges.subscribe(res => {
			this.currentStepValid.emit(this.productSeedingForm.valid);
		});
	}

	public selectShop(shop: string): void {
		this.productSeedingForm.get('shop').patchValue(shop);
		this.productSeedingForm.get('shop').updateValueAndValidity();
		this.productSeedingForm.get('shopIntegrated').patchValue(false);
		this.productSeedingForm.get('shopIntegrated').updateValueAndValidity();
		this.productSeedingForm.get('products').patchValue([]);
		this.productSeedingForm.get('products').updateValueAndValidity();
		this.toOverview(shop);
	}

	toOverview(value) {
		switch (value) {
			case this.shopifyPageRoute:
				this.validateShopify();
				break;
			case this.bigcommercePageRoute:
				this.validateBigcommerce();
				break;
		}
	}

	public validateShopify(): void {
		this.isLoading = true;
		this.shopifyService
			.validate()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res?.status == 204) {
						this.productSeedingForm.get('shopIntegrated').patchValue(true);
					} else {
						this.productSeedingForm.get('shop').patchValue(null);
						this.productSeedingForm.get('shopIntegrated').patchValue(false);
						this.toastNotificationService.sendInfoToast('Please connect your Shopify account first.');
					}
					this.productSeedingForm.get('shop').updateValueAndValidity();
					this.productSeedingForm.get('shopIntegrated').updateValueAndValidity();
					this.isLoading = false;
				},
				err => {
					this.isLoading = false;
					this.productSeedingForm.get('shop').patchValue(null);
					this.productSeedingForm.get('shopIntegrated').patchValue(false);
					this.toastNotificationService.sendInfoToast('Please connect your Shopify account first.');
				}
			);
	}

	public validateBigcommerce(): void {
		this.isLoading = true;
		this.bigcommerceService
			.validate()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					if (res?.status == 204) {
						this.productSeedingForm.get('shopIntegrated').patchValue(true);
					} else {
						this.productSeedingForm.get('shop').patchValue(null);
						this.productSeedingForm.get('shopIntegrated').patchValue(false);
						this.toastNotificationService.sendInfoToast('Please connect your BigCommerce account first.');
					}
					this.productSeedingForm.get('shop').updateValueAndValidity();
					this.productSeedingForm.get('shopIntegrated').updateValueAndValidity();
					this.isLoading = false;
				},
				err => {
					this.isLoading = false;
					this.productSeedingForm.get('shop').patchValue(null);
					this.productSeedingForm.get('shopIntegrated').patchValue(false);
					this.toastNotificationService.sendInfoToast('Please connect your BigCommerce account first.');
				}
			);
	}

	public formUnValidate(): void {
		this.productSeedingForm.get('shop').clearValidators();
		if (this.productSeedingForm.get('shop')?.value && this.productSeedingForm.get('shopIntegrated')?.value === false) {
			this.productSeedingForm.get('shop').patchValue(null);
		}
		this.productSeedingForm.get('shop').updateValueAndValidity();
		this.productSeedingForm.get('shopIntegrated').clearValidators();
		this.productSeedingForm.get('shopIntegrated').updateValueAndValidity();
		this.productSeedingForm.markAsUntouched();
		this.currentStepValid.emit(this.productSeedingForm.valid);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.formUnValidate();
	}
}
