import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsContentInterface } from './model/analytics-content.interface';

@Component({
	selector: 'app-analytics-content-card',
	templateUrl: './analytics-content-card.component.html',
	styleUrls: ['./analytics-content-card.component.scss']
})
export class AnalyticsContentCardComponent implements OnInit {
	@Input() public content: AnalyticsContentInterface;
	@Output() public viewContent: EventEmitter<string>;

	constructor() {
		this.viewContent = new EventEmitter<string>();
	}

	public view(): void {
		this.viewContent.emit(this.content.contentUrl);
	}

	ngOnInit(): void {}
}
