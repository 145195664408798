import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonClassEnum } from '../../button-component/button-class.enum';
import { ButtonTypeEnum } from '../../button-component/button-type.enum';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
	@Input() public title: string;
	@Input() public message: string;
	@Input() public selectedAudience: string;
	// @Input() public selectedAudienceFile?: string;
	@Input() public btnCancelText: string;
	@Input() public btnCancelColor: string;
	@Input() public btnOkText: string;
	@Input() public btnOkColor: string;

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

	ngOnInit() {}

	public decline() {
		this.dialogRef.close(false);
	}

	public accept() {
		this.dialogRef.close(true);
	}
}
