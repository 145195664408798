import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LogInSuccess } from '../state/authentication.action';
import { AuthenticationState } from '../state/authentication.reducer';
import { UserDetailModel } from '../_models/login.model';

@Component({
	selector: 'app-sub-user-register',
	templateUrl: './sub-user-register.component.html',
	styleUrls: ['./sub-user-register.component.scss']
})
export class SubUserRegisterComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public passwordControl: UntypedFormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;
	public passwordIsValid: boolean = false;
	public stepToMark: number = 0;
	public passwordForm: UntypedFormGroup;

	public minimumChar: boolean = false;
	public upperChar: boolean = false;
	public numberChar: boolean = false;
	public specialChar: boolean = false;
	public allValidated: boolean = false;

	public validColor: string;

	public registrationDetail: UserDetailModel;
	public token: string;
	public emailValue: string;

	public firstNameValidationIcon = null;
	public lastNameValidationIcon = null;
	public passwordValidationIcon = null;

	public label: string = 'Enter your password';
	public newToken: string;

	constructor(
		private authService: AuthenticationService,
		private toastService: ToastNotificationService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private authStore: Store<AuthenticationState>
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParamMap.subscribe(res => {
			this.newToken = res.get('token');
			if (!this.newToken) {
				this.router.navigate(['/']);
			}
		});
		this.passwordControl = new UntypedFormControl('', [Validators.required]);

		this.passwordForm = new UntypedFormGroup({
			firstName: new UntypedFormControl(''),
			lastName: new UntypedFormControl(''),
			// phone: new UntypedFormControl(''),
			password: new UntypedFormControl('')
		});
		this.formChanges();
	}

	public formChanges(): void {
		this.passwordControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
	}

	public checkForInvalid(): void {
		const password = this.passwordControl.value;
		if (password === undefined || password === '') {
			this.passwordControl.setErrors({ dialCode: true });
			this.minimumChar = false;
			this.upperChar = false;
			this.numberChar = false;
			this.specialChar = false;
		}
	}

	public continueSignup(): void {
		this.updateValidators();
		if (this.passwordForm.valid) {
			let payload = new FormData();
			payload.append('first_name', this.passwordForm.get('firstName').value);
			payload.append('last_name', this.passwordForm.get('lastName').value);
			// payload.append('phone_number', this.passwordForm.get('phone').value);
			payload.append('password', this.passwordForm.get('password').value);
			payload.append('token', this.newToken);

			this.authService.signUpSubUser(payload).subscribe(
				res => {
					if (res) {
						this.authStore.dispatch(new LogInSuccess(res));
					} else {
						this.toastService.sendErrorToast('Something went wrong, Please try again later');
					}
				},
				err => {
					this.toastService.sendErrorToast('Something went wrong, Please try again later');
				}
			);
		}
	}

	public updateValidators(): void {
		this.passwordForm.setControl(
			'firstName',
			new UntypedFormControl(this.passwordForm.controls.firstName.value, {
				validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[A-Za-z]+$/)],
				updateOn: 'change'
			})
		);

		this.passwordForm.setControl(
			'lastName',
			new UntypedFormControl(this.passwordForm.controls.lastName.value, {
				validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[A-Za-z]+$/)],
				updateOn: 'change'
			})
		);
		// this.passwordForm.setControl(
		// 	'phone',
		// 	new UntypedFormControl(this.passwordForm.controls.phone.value, {
		// 		validators: [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern('[- +()0-9]+')],
		// 		updateOn: 'change'
		// 	})
		// );
		this.passwordForm.setControl(
			'password',
			new UntypedFormControl(this.passwordForm.controls.password.value, {
				validators: [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(50),
					Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
				],
				updateOn: 'change'
			})
		);
		this.passwordForm.markAllAsTouched();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
