import { Component, Input, OnInit } from '@angular/core';
import { MyPlanService } from '../../../my-plan/my-plan.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthPaymentService } from 'src/app/authentication/services/auth-payment.service';
import { catchError, concatMap, take, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { PlanDurationEnum, PlansDataModel, PlanTypeEnum } from 'src/app/shared-components/models/plans-data.model';
import { ConfirmPopupService } from 'src/app/shared/services/confirm-popup.service';

@Component({
	selector: 'app-manage-subscription',
	templateUrl: './manage-subscription.component.html',
	styleUrls: ['./manage-subscription.component.scss']
})
export class ManageSubscriptionComponent implements OnInit {
	@Input() public data: any;
	@Input() public isLoggedIn: boolean = false;
	@Input() public buttonText: string;
	public loading = false;
	public currentPlan;
	public PlanDurationEnum = PlanDurationEnum;
	public monthlyPlansData: PlansDataModel[] = [];
	public yearlyPlansData: PlansDataModel[] = [];
	public selectedPlansData: PlansDataModel[] = [];

	public pricingDetails: any[] = [];

	public unsubscriber$ = new Subject<void>();
	constructor(
		private confirmPopupService: ConfirmPopupService,
		private _myPlan: MyPlanService,
		private authPaymentService: AuthPaymentService,
		private toast: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.currentPlan = this.data;
		this.selectedPlansData = this.monthlyPlansData;
		this.getStripePlans();
	}

	public getStripePlans(): void {
		this.loading = true;
		this._myPlan
			.getStripePlans()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				try {
					this.initPlanKeysAndDataStructure(res);
				} catch (e) {
					console.log(e);
				}
			})
			.add(() => {
				this.loading = false;
			});
	}

	public redirectToStripe(plan: PlansDataModel): void {
		if (this.loading) {
			return;
		}
		const product_price_id = plan?.stripe_price_id;

		if (this.isLoggedIn) {
			if (this.data?.is_without_cc === true) {
				this.redirectStripeApi(plan);
			} else {
				this.changePlan(product_price_id);
			}
		} else {
			this.redirectStripeApi(plan);
		}
	}

	private changePlan(product_price_id: string): void {
		this.confirmPopupService.showPopup('Are you sure you want to change your plan ?', 'Yes', 'No').then(res => {
			if (res && res?.primaryAction === 'true') {
				this.authPaymentService
					.redirectToStripeUpdate(product_price_id)
					.pipe(take(1))
					.subscribe(
						response => {
							if (response?.data === 'References created' && response?.redirect_url) {
								window.location.href = response.redirect_url;
							} else {
								window.location.reload();
							}
						},
						err => {
							this.toast.sendErrorToast('Some error occurred. Try again later.');
						}
					);
			}
		});
	}

	private redirectStripeApi(plan: PlansDataModel): void {
		this.authPaymentService
			.redirectToStripe(plan)
			.subscribe(res => {
				window.open(res, '_self');
			})
			.add(() => {});
	}

	public changeDuration(duration: PlanDurationEnum): void {
		switch (duration) {
			case PlanDurationEnum.monthly:
				this.selectedPlansData = this.monthlyPlansData;
				break;
			case PlanDurationEnum.yearly:
				this.selectedPlansData = this.yearlyPlansData;
				break;
		}
	}

	public initPlanKeysAndDataStructure(res): void {
		let stripePlansData: PlansDataModel[] = res?.stripe_plans;
		const order = ['Starter', 'Teams', 'Business'];
		stripePlansData = stripePlansData.sort((a, b) => {
			const indexA = order.indexOf(a.title);
			const indexB = order.indexOf(b.title);
			return indexA - indexB;
		});
		this.monthlyPlansData = stripePlansData.filter(x => x.interval == PlanDurationEnum.monthly);
		this.yearlyPlansData = stripePlansData.filter(x => x.interval == PlanDurationEnum.yearly);
		this.selectedPlansData = this.monthlyPlansData;
	}

	public getButtonText(index: number): string {
		if (this.loading) {
			return '';
		}
		const plan = this.selectedPlansData[index];
		if (this.currentPlan?.status == 'active' && plan?.interval == this.currentPlan?.price_interval?.toLowerCase()) {
			if (plan?.stripe_price_id == this.currentPlan?.price_id) {
				return 'Current Plan';
			} else {
				return 'Change Plan';
			}
		}
		if (this.currentPlan?.status == 'trialing') {
			return 'Choose Plan';
		}
		return 'Change Plan';

		// else {
		// 	const currentPlanIndex =
		// 		this.currentPlan.name.toLowerCase() == PlanTypeEnum.business ? 2 : this.currentPlan.name.toLowerCase() == PlanTypeEnum.teams ? 1 : 0;
		// 	if (index > currentPlanIndex) {
		// 		return 'Upgrade Plan';
		// 	}
		// 	return 'Downgrade Plan';
		// }
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
