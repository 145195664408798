<div class="confirmation-dialog-container">
	<div class="d-flex flex-column">
		<div class="d-flex justify-content-center">
			<img class="main--icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" alt="" />
		</div>

		<div mat-dialog-title class="dialog-title">{{ title | translate }}</div>
		<mat-dialog-content>
			<div class="selected-audience" *ngIf="selectedAudience !== ''">
				<div class="popup-text">{{ selectedAudience | translate }}</div>
			</div>
			<div class="popup-message">{{ message | translate }}</div>
		</mat-dialog-content>
	</div>
	<div class="button-container" mat-dialog-actions>
		<app-button
			(buttonClicked)="decline()"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="{{ btnCancelText | translate }}"
		></app-button>

		<app-button
			(buttonClicked)="accept()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			class="button-confirm"
			buttonLabel="{{ btnOkText | translate }}"
		></app-button>
	</div>
</div>
