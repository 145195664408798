<div class="payment-confirm">
	<div class="popup-section">
		<div class="main-section">
			<p class="title">{{ successPage ? 'Thank you for your payment' : 'Payment failed' }}</p>
			<p class="receipt" style="font-size: 16px; margin-top: 25px; color: #4e4e4e;">
				{{ successPage ? 'Your Payment was Successful' : 'Your Payment was Unsuccessful' }}
			</p>
			<p class="redirect">You will be redirected in 5 seconds, please wait.</p>
		</div>
	</div>
</div>
