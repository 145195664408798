import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-bigcommerce-installation-guide',
	templateUrl: './bigcommerce-installation-guide.component.html',
	styleUrls: ['./bigcommerce-installation-guide.component.scss']
})
export class BigcommerceInstallationGuideComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
