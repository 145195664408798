<div class="catalog-import">
	<div class="child-container">
		<section class="text-center">
			<div class="header"></div>
			<div class="sub-header"><filed-font [fontSize]="'16px'">Your Connection was Successfull</filed-font></div>
			<img src="assets/images/catalog-product/welcome/welcome.png" />
			<br />
			<app-primary-button
				[buttonLable]="'Finish Connection'"
				[buttonType]="'Primary'"
				[isPeddingReduceSmall]="true"
				(buttonClicked)="nextcontent()"
			></app-primary-button>
		</section>
	</div>
</div>
