import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export interface GroupSelectionModelV2 {
	parentName: string;
	list: string[];
}

@Component({
	selector: 'app-auto-multi-select-grouped',
	templateUrl: './auto-multi-select-grouped.component.html',
	styleUrls: ['./auto-multi-select-grouped.component.scss']
})
export class AutoMultiSelectGroupedComponent implements OnInit {
	@Input() subcategorySelectionModel: SelectionModel<string>; // Stores names of selected subcategories
	@Input() list: GroupSelectionModelV2[] = [];
	@Input() width: string = '100%';
	@Input() disabled: boolean = false;
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl = new UntypedFormControl();
	@Output() emitSelection: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitDeselection: EventEmitter<any> = new EventEmitter<any>();
	public filteredList: GroupSelectionModelV2[] = [];
	public searchControl = new UntypedFormControl();
	private unsubscriber$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		this.filteredList = this.list;
		this.searchControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			if (res && res.trim() != '') {
				this.filteredList = this.list.map(group => ({
					parentName: group.parentName,
					list: group.list.filter(item => {
						let searchString = item;
						return searchString.toLowerCase().replace(/ /g, '').trim().includes(res.toLowerCase().replace(/ /g, '').trim());
					})
				}));
			} else {
				this.filteredList = this.list;
			}
		});
	}

	public onMenuClosed(): void {
		this.searchControl.patchValue('');
	}

	public nestedCopy(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	public styleMenu() {
		return {
			width: this.width + ' !important',
			'max-width': this.width + '!important'
		};
	}

	public areAllInnerItemsOfOuterSelected(outerItem: GroupSelectionModelV2): boolean {
		return outerItem.list.every(inner => {
			return this.subcategorySelectionModel.isSelected(inner);
		});
	}

	public isAnyInnerItemOfOuterSelected(outerItem: GroupSelectionModelV2): boolean {
		return outerItem.list.some(inner => {
			return this.subcategorySelectionModel.isSelected(inner);
		});
	}

	public isSelected(innerItem: string): boolean {
		return this.subcategorySelectionModel.isSelected(innerItem);
	}

	public onOuterItemClick(outerItem: GroupSelectionModelV2): void {
		if (this.areAllInnerItemsOfOuterSelected(outerItem)) {
			outerItem.list.forEach(inner => {
				this.subcategorySelectionModel.deselect(inner);
			});
		} else {
			outerItem.list.forEach(inner => this.subcategorySelectionModel.select(inner));
		}
		this.updateFormControl();
	}

	public onInnerItemClick(innerItem: string): void {
		this.subcategorySelectionModel.toggle(innerItem);
		this.updateFormControl();
	}

	public updateFormControl(): void {
		this.inputControl.patchValue(this.subcategorySelectionModel.selected);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
