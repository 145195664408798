<div class="container">
	<div class="left-dock">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="right-dock">
		<main>
			<ng-container [ngSwitch]="switchDivSection">
				<ng-container *ngSwitchDefault>
					<header>
						<img src="assets/logo/Brand_logo.svg" class="cursor-pointer" [routerLink]="['']" />
					</header>
					<div class="info">
						<span class="header">Forgot your password?</span>
						<span class="title">We’ll help you reset it and get back on track</span>
					</div>
					<form class="body-form" [formGroup]="resetEmailFormGroup">
						<input
							[ngStyle]="{
								border: resetEmailFormGroup.touched && resetEmailFormGroup.controls['resetEmailFormControl'].invalid ? '1px solid red' : ''
							}"
							formControlName="resetEmailFormControl"
							type="text"
							class="custom-input"
							placeholder="Email"
						/>
					</form>
					<button class="primary-btn submit" [disabled]="resetEmailFormGroup.invalid" (click)="onSubmit()">Reset password</button>
				</ng-container>
				<ng-container *ngSwitchCase="'resendEmailVerification'">
					<img src="assets\auth\reset-password\Group 54124.svg" />

					<div class="info">
						<span class="header">Email sent</span>
						<p class="desc" style="width: 302px;">We have sent you an email, please check your inbox for instructions.</p>
					</div>
				</ng-container>
			</ng-container>

			<span class="link" [routerLink]="['/']">Go to login</span>
		</main>
	</div>
</div>

<app-toast-notification></app-toast-notification>
