import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { UserRoleAdminGuard } from './shared/guards/user-role-admin-guard.service';
import { UserRoleClientGuard } from './shared/guards/user-role-client-guard.service';
import { NoResourceComponent } from './shared/404/no-resource.component';
import { FacebookLandingPageComponent } from './authentication/landing-pages/facebook-landing-page/facebook-landing-page.component';
import { GoogleLandingPageComponent } from './authentication/landing-pages/google-landing-page/google-landing-page.component';
import { TicketsViewComponent } from './sidenav/tickets-view/tickets-view.component';
import { UserBusinessOwnerGuard } from './shared/guards/user-business-owner.guard';
import { ClosePopupComponent } from './user-management/components/integrations/component-pieces/close-popup/close-popup.component';
import { SidenavGroupComponent } from './sidenav/sidenav/sidenav-group.component';
import { StripeLandingComponent } from './stripe-landing/stripe-landing.component';
import { ConnectToGoogleNewComponent } from './authentication/new-signup/new-signup-welcome-popup/connection/connect-to-google/connect-to-google.component';
import { ConnectToShopifyComponent } from './authentication/new-signup/new-signup-welcome-popup/connection/connect-to-shopify/connect-to-shopify.component';
import { AppCustomPreloader } from './app-routing-preloader';
import { ShopifyLandingComponent } from './shopify-landing/shopify-landing.component';
import { NotificationsMainComponent } from './sidenav/components/notifications-main/notifications-main.component';
import { BigcommerceInstallationGuideComponent } from './bigcommerce-installation-guide/bigcommerce-installation-guide.component';
import { GoogleAccountLandingComponent } from './shared-components/google-account-landing/google-account-landing.component';
import { ShopifyBillingComponent } from './shared-components/shopify-billing/shopify-billing.component';

const routes: Routes = [
	{
		path: 'authentication',
		loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
	},
	{ path: 'finish-connection', component: ClosePopupComponent },
	{
		path: '',
		component: SidenavGroupComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{ path: 'welcome-facebook', canActivate: [UserRoleClientGuard], component: FacebookLandingPageComponent },
			{ path: 'welcome-google', canActivate: [UserRoleClientGuard], component: GoogleLandingPageComponent },
			{ path: 'support-tickets', canActivate: [UserRoleClientGuard], component: TicketsViewComponent },
			{
				path: 'auth-landing',
				loadChildren: () => import('./auth-landing-new/auth-landing-new.module').then(m => m.AuthLandingNewModule)
			},
			{
				path: 'user-management',
				canActivate: [UserRoleClientGuard],
				loadChildren: () => import('./user-management/new-sprint/user-sprint.module').then(m => m.UserSprintModule)
			},
			{
				path: 'back-office',
				canActivate: [UserRoleAdminGuard],
				loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficeModule)
			},
			{
				canActivate: [UserBusinessOwnerGuard],
				path: '',
				loadChildren: () => import('./social-media-influencer/social-media-influencer.module').then(m => m.SocialMediaInfluencerModule)
			},
			{
				path: 'notifications',
				component: NotificationsMainComponent
			}
		]
	},
	{
		path: 'connect-to-google',
		component: ConnectToGoogleNewComponent
	},
	{
		path: 'connect-to-shopify',
		component: ConnectToShopifyComponent
	},
	{ path: 'stripeLanding', component: StripeLandingComponent },
	{ path: 'shopifyBilling', component: ShopifyBillingComponent },
	{ path: 'shopifyLanding', component: ShopifyLandingComponent },
	{ path: 'bigcommerce/installation-guide', component: BigcommerceInstallationGuideComponent },
	{ path: '404', component: NoResourceComponent },
	{ path: 'google-landing', component: GoogleAccountLandingComponent },
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: AppCustomPreloader })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
